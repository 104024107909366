import { Injectable } from '@angular/core';

import { GlobalService } from './global.service';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { tick } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService
  ) { }

  getTickets(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getTickets, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getTicket(token, ticket_id){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getTicket(ticket_id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  newTicket(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.newTicket, obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  assignTicket(token, ticketId, userId) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };

    return this.http.put<any>(this.globals.assignTicket(ticketId, userId), {}, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  setStatus(token, id, tk) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put<any>(this.globals.setStatus(id), tk, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  sendCommunication(token, id, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.sendCommunication(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getTicketCommunication(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getTicketCommunications(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getAssignedTicket(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getOperatorTickets, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteTicket(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteTicket(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  changeTicketPositionInStatus(token, id, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.changeTicketPositionInStatus(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }
  changeTicketPositionOutStatus(token, id, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.changeTicketPositionOutStatus(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getTicketActivity(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getGetTicketActivityURL(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getTicketAssignedOperators(token, ticket_id){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getOperatorAssigned(ticket_id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

}
