export class View extends draw2d.Canvas{
    currentDropConnection: any;
    constructor(id: any){
        super(id);
        super.setScrollArea('#'+id)
        this.currentDropConnection = null;

        $('#'+id).on('select', (event)=> {
            console.log("Selezione> ", event);
            
        })

        $('#'+id).select((event) => {
            console.log("Select: ", event);
            
        })
    }

    onDrag(droppedDomNode: any, x: any, y: any ){
    }

    onDrop(droppedDomNode: any, x: any, y: any, shiftKey: any, ctrlKey: any){
        // Shape Type
        let type = $(droppedDomNode).data("shape").slice();
        // Proprietà Shape
        let proprieties = Object.assign({}, $(droppedDomNode).data("proprieties"));
        // let proprieties = $(droppedDomNode).data("proprieties");
        // Creo la figura
        let figure = eval("new "+type+"();");
        // console.log("type: ", type);
        // console.log("proprieties: ", JSON.stringify(proprieties));
        // Aggiungo le proprietà personalizzate
        let kemata = Object.assign({}, proprieties.kemata);
        delete proprieties.kemata;
        // figure.setUserData(userData);
        let prop = Object.assign({}, proprieties)
        figure.attr(prop);
        // figure.kemata = kemata;
        // Creo un etichetta col nome della proprietà
        let label = new draw2d.shape.basic.Label(
            {
              text: kemata.task_name? kemata.task_name : kemata.name,
              stroke:0,
              resizeable: false
            }    
        );
        if(kemata.task_name){
            label.installEditor(new draw2d.ui.LabelInplaceEditor({
                // called after the value has been set to the LabelFigure
                onCommit: $.proxy((value) => {
                    $('#taskName').val(value);
                },this), 
                // called if the user abort the operation
                onCancel: function(){
                }
            })); 
        } 
        figure.add(label , new draw2d.layout.locator.CenterLocator(figure));
        if(!figure.kemata){
            figure.kemata = kemata;
            console.log("Inizializzo con: ", figure.kemata);
        }
        figure = this.addPorts(figure);
        // create a command for the undo/redo support
        let command = new draw2d.command.CommandAdd(this, figure, x, y);
        super.getCommandStack().execute(command);
    }

    addPorts(figure){
        if(figure.kemata.task_type != 'system'){
            let ports = figure.getOutputPorts();
            figure.removePort(ports.data[0]);
            if(figure.kemata.port_single){
                let port = new draw2d.OutputPort({ color: 'blue', bgColor: 'blue' });
                figure.addPort(port, new draw2d.layout.locator.OutputPortLocator());
                port.setName('OutputSingle');
            }
            if(figure.kemata.port_multiple){
                let port = new draw2d.OutputPort({ color: 'orange', bgColor: 'orange'} );
                figure.addPort(port, new draw2d.layout.locator.OutputPortLocator());
                port.setName('OutputMultiple');
            }
            if(figure.kemata.port_error){
                let port = new draw2d.OutputPort({ color: 'red', bgColor: 'red' });
                figure.addPort(port, new draw2d.layout.locator.OutputPortLocator());
                port.setName('OutputError');
            }
        }
        return figure
    }
}