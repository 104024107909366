import { Component, OnInit, Input, Inject } from '@angular/core';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { GlobalService } from 'src/app/services/global.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { NgxSpinnerService } from 'ngx-spinner';
import { User, Customer, Group, Participants } from 'src/app/classes';
import { CustomerService } from 'src/app/services/customer.service';
import { GroupService } from 'src/app/services/group.service';
import { OperatorService } from 'src/app/services/operator.service';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';


@Component({
  selector: 'app-uploadfile',
  templateUrl: './uploadfile.component.html',
  styleUrls: ['./uploadfile.component.scss']
})
export class UploadfileComponent implements OnInit {

  myControlOp = new FormControl();
  myControlCus = new FormControl();
  myControlGrp = new FormControl();
  filteredOperator: Observable<any[]>;
  filteredCustomer: Observable<any[]>;
  filteredGroups: Observable<any[]>;

  op: any;
  cus: any;
  grp: any;

  id: any;
  auth: boolean = false;;
  //string
  token: string
  userActive: User;
  //formData
  uploadForm: FormGroup;
  formData: any;
  disable: boolean = true;

  //array
  file: Array<any> = [];
  nameList: Array<any> = [];

  participants: Participants;
  customers: Array<Customer>;
  operators: Array<User>;
  groups: Array<Group>;

  constructor(
    private upload: UploadFileService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadfileComponent>,
    private globals: GlobalService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    public customerServices: CustomerService,
    public groupServices: GroupService,
    public operatorServices: OperatorService,
  ) {
    this.id = data.id;
    this.participants = new Participants();
  }

  ngOnInit() {
    this.spinner.show();
    this.userActive = this.globals.storagedUser;
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
    if (this.userActive.profile_id != 1) {
      this.getCustomers();
      this.getGroups();
      this.getOperators();
    }
    this.spinner.hide();
  }

  toArray(fileList: FileList) {
    return Array.prototype.slice.call(fileList);
  }

  onFileSelect(event) {
    let amount = event.target.files.length
    if (event.target.files.length > 0 && event.target.files[0]) {
      if (this.file.length == 0) {
        this.file = this.toArray(event.target.files);
      } else {
        let toA = this.toArray(event.target.files);
        this.file = this.file.concat(toA);
      }

      Array.from(event.target.files).forEach(file => {
        this.nameList.push(file)
      });
    }
    this.disable = false;
  }

  displayFnCus(cus: Customer): string {
    return cus && `${cus.view_name}`;
  }

  displayFn(user: User): string {
    return user && `${user.last_name} ${user.name} (Username: ${user.username})`;
  }

  displayFnGrp(grp: Group): string {
    return grp && `${grp.group_name}`;
  }

  private _filter(name: string, array: Array<any>): User[] {
    const filterValue = name.toLowerCase();
    this.globals.stampa("NameOp: ", name);

    return array.filter(
      ele => ele.search.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  getCustomers() {
    this.spinner.show();
    let _self = this;
    this.customerServices.getCustomer(this.token).subscribe((res) => {
      this.globals.messageServer(res);
      this.customers = res
      this.customers.forEach(cus => {
        if(cus.type=='GIU'){
          cus.search = this.globals.createLocalSearch({name: cus.view_name, business_name: cus.business_name})
        } else {
          cus.search = this.globals.createLocalSearch({view_name: cus.view_name, name: cus.name, last_name: cus.last_name });
        }
      });
      _self.filteredCustomer = _self.myControlCus.valueChanges.pipe(
        startWith(''),
        map((value) => {
          this.globals.stampa("Value Cus: ", value);
          return typeof value === "string" ? value : value.search
        }),
        map((search) => {
          this.globals.stampa("Search Cus: ", search);
          return search ? _self._filter(search, _self.customers) : _self.customers.slice()
        })
      );
      this.spinner.hide();
    },
      (err) => {
        console.error("Error on get customers: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      })
  }



  getOperators() {
    this.spinner.show();
    let _self = this;
    this.operatorServices.getOperator(this.token).subscribe((res) => {
      this.globals.messageServer(res);
      this.operators = res;
      this.operators.forEach(op => {
        op.search = this.globals.createLocalSearch({ name: op.name, last_name: op.last_name, username: op.username })
      });
      _self.filteredOperator = _self.myControlOp.valueChanges.pipe(
        startWith(''),
        map((value) => {
          this.globals.stampa("Value Op: ", value);
          return typeof value === "string" ? value : value.search
        }),
        map((search) => {
          this.globals.stampa("Search Op: ", search);
          return search ? _self._filter(search, _self.operators) : _self.operators.slice()
        })
      );
      this.spinner.hide();
    },
      (err) => {
        console.error("Error on get operators: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      })
  }


  getGroups() {
    this.spinner.show();
    let _self = this;
    this.groupServices.getGroups(this.token).subscribe((res) => {
      this.globals.messageServer(res);
      this.groups = res;
      this.groups.forEach(grp => {
        grp.search = this.globals.createLocalSearch({ name: grp.group_name })
      });
      _self.filteredGroups = _self.myControlGrp.valueChanges.pipe(
        startWith(''),
        map((value) => {
          this.globals.stampa("Value Op: ", value);
          return typeof value === "string" ? value : value.search
        }),
        map((search) => {
          this.globals.stampa("Search Op: ", search);
          return search ? _self._filter(search, _self.groups) : _self.groups.slice()
        })
      );
      this.spinner.hide();
    },
      (err) => {
        console.error("Error on get groups: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      })
  }

  uploadFile() {
    this.spinner.show();
    let obj: any;
    if (this.participants.customers.length > 0 || this.participants.operators.length > 0 || this.participants.groups.length > 0) {
      obj = {
        auth: [],
        inherited: false
      }
      if (this.participants.customers.length > 0) {
        this.participants.customers.forEach(element => {
          obj.auth.push({
            authorityId: element.alfresco_id,
            name: "Contributor",
            accessStatus: "ALLOWED",
          })
        });
      }
      if (this.participants.operators.length > 0) {
        this.participants.operators.forEach(element => {
          obj.auth.push({
            authorityId: element.alfresco_id,
            name: "Contributor",
            accessStatus: "ALLOWED",
          })
        });
      }
      if (this.participants.groups.length > 0) {
        this.participants.groups.forEach(element => {
          obj.auth.push({
            authorityId: element.alfresco_id,
            name: "Contributor",
            accessStatus: "ALLOWED",
          })
        });
      }
    }
    this.uploadForm.get('profile').setValue(this.file);
    const formData = new FormData();
    for (let i = 0; i < this.file.length; i++) {
      this.globals.stampa("Giro: ", i);

      formData.append('file', this.file[i], this.file[i].name);
    }
    formData.append('permission', JSON.stringify(obj));
    this.upload.uploadFile(this.token, this.id, formData).subscribe(
      res => {
        this.globals.messageServer(res);
        this.spinner.hide();
        this.dialogRef.close("upload");
      },
      err => {
        this.spinner.hide();
        console.error(err)
        this.globals.messageServer(err);
      }
    );

  }

  push(add) {
    let toAdd = true;
    if (add) {

      if (add.user_id) {
        this.participants.operators.forEach((op) => {
          if (add.user_id == op.user_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.operators.push(add);
          this.op = '';
        } else {
          this.globals.stampa("Already exist");
          this.op = '';

        }
      }

      if (add.customer_id) {
        this.participants.customers.forEach((cus) => {
          if (add.customer_id == cus.customer_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.customers.push(add);
          this.cus = '';
        } else {
          this.globals.stampa("Already exist");
          this.cus = '';
        }
      }

      if (add.group_id) {
        this.participants.groups.forEach((grp) => {
          if (add.group_id == grp.group_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.groups.push(add)
          this.grp = '';
        } else {
          this.globals.stampa("Already exist");
          this.grp = '';
        }
      }
    }
  }

  delete(list) {
    this.globals.stampa("Delete");

    if (list.customer_id) {
      let obj = {
        customer_id: list.customer_id,
        alfresco_id: list.alfresco_id
      }
      this.participants.customers.splice(this.participants.customers.indexOf(list), 1);
    }

    if (list.user_id) {
      let obj = {
        user_id: list.user_id,
        alfresco_id: list.alfresco_id
      }
      this.participants.operators.splice(this.participants.operators.indexOf(list), 1);

    }

    if (list.group_id) {
      let obj = {
        group_id: list.group_id,
        alfresco_id: list.alfresco_id

      }
      this.participants.groups.splice(this.participants.groups.indexOf(list), 1)
    }
  }

  svuota() {
    this.file = [];
    this.nameList = [];
    this.disable = true;
  }

  canceling() {
    this.dialogRef.close('cancel');
  }

}
