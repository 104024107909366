import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-stuckactivity',
  templateUrl: './stuckactivity.component.html',
  styleUrls: ['./stuckactivity.component.scss']
})
export class StuckactivityComponent implements OnInit {

  token: string;
  userActive: string;

  constructor(
    private globals: GlobalService,
    private activityService: ActivityService,
    private activeModal : NgbActiveModal,
  ) { }

  ngOnInit() {
    if(!this.token){
      this.token = this.globals.getToken();
    }
    this.userActive = this.globals.storagedUser;
  }

  close(){
    this.activeModal.close();
  }

  cancel(){
    this.activeModal.close('cancel')
  }

}
