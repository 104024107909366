import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService
  ) { }

  getUser(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getUserProfileURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateUser(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.updateupdateUserProfile(), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateImg(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.updateupdateImageProfile(), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updatePassword(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.updatePassword(), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }
}
