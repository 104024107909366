import { Component, OnInit, Input } from '@angular/core';
import { DocumentaryService } from 'src/app/services/documentary.service';
import { GlobalService } from 'src/app/services/global.service';
import { UploadfileComponent } from 'src/app/popup/uploadfile/uploadfile.component'
import { FormGroup, FormBuilder } from '@angular/forms';
import { faPlus, faFolderOpen as fafolderopen, faUser } from '@fortawesome/free-solid-svg-icons';
import { faFile, faFolderOpen, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewfolderComponent } from '../../popup/newfolder/newfolder.component';
import { ChangeNameFolderComponent } from '../../popup/change-name-folder/change-name-folder.component';
import { MatDialog } from '@angular/material';
import { NewfolderService } from 'src/app/services/newfolder.service';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';

@Component({
  selector: 'app-easydoc',
  templateUrl: './easydoc.component.html',
  styleUrls: ['./easydoc.component.scss']
})
export class EasydocComponent implements OnInit {

  @Input() folder_id;

  token: string;

  selectedDir: any;

  parentID: Array<any> = [];
  menu: Array<any> = [];
  navigationArray: Array<any> = [];

  backDir: string;
  // boolean
  show = false;
  enterIf = false;
  disable = false;
  double = false;

  // number
  i = 1;

  // formData
  formData: any;
  uploadForm: FormGroup;

  // Icone
  faPlus = faPlus;
  faFile = faFile;
  faFolderOpen = faFolderOpen;
  faUserCircle = faUserCircle;
  fafolderopen = fafolderopen;
  faUser = faUser;

  folderIdActive: string;

  constructor(
    private documentaryService: DocumentaryService,
    private globals: GlobalService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private dialog: MatDialog,
    public newfolderService: NewfolderService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.getFolderContent(this.folder_id);
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
    this.formData = new FormData();

  }

  getFolderContent(folderId) {
    this.documentaryService.getFolderContent(this.token, folderId).subscribe(
      res => {
        this.globals.messageServer(res);
        this.folderIdActive = folderId;
        this.selectedDir = res.list;
        this.backDir = res.list[0].entry.parentId;
        if (folderId == this.folder_id) {
          this.show = false;
        } else {
          this.show = true;
        }
        this.disable = false;
        this.changeLastModify(this.selectedDir);
        this.changeDimension(this.selectedDir);
        this.changeCreateAt(this.selectedDir);
        this.spinner.hide();
      },
      err => {
        console.error("Errore in openFolder: ", err);
        this.disable = false;
        this.globals.messageServer(err);
      }
    )
  }

  backFolder() {
    this.spinner.show();
    this.documentaryService.getFolderContent(this.token, this.backDir).subscribe(
      res => {
        this.globals.messageServer(res);
        this.folderIdActive = this.backDir;
        this.selectedDir = res.list;
        if (this.backDir == this.folder_id) {
          this.show = false;
        } else {
          this.show = true
        }
        this.backDir = res.parentId;
        this.disable = false;
        this.changeLastModify(this.selectedDir);
        this.changeDimension(this.selectedDir);
        this.changeCreateAt(this.selectedDir);
        this.spinner.hide();
      },
      err => {
        console.error("Errore in openFolder: ", err);
        this.globals.messageServer(err);
        this.disable = false;
        this.spinner.hide();
      }
    )
  }

  uploadFile() {
    this.spinner.show();
    const dialogRef = this.dialog.open(UploadfileComponent, {
      width: "50%",
      // maxHeight: '50%',
      data: {
        id: this.folderIdActive
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.documentaryService.getFolderContent(this.token, this.folderIdActive).subscribe(
          res => {
            this.globals.messageServer(res);
            this.selectedDir = res.list;
            this.changeLastModify(this.selectedDir);
            this.changeDimension(this.selectedDir);
            this.changeCreateAt(this.selectedDir);
            this.spinner.hide();
          },
          err => {
            console.error("Errore in getFolderContent: ", err);
            this.globals.messageServer(err);
            this.spinner.hide();
          })
      }

    });
  }

  openFolder(file) {
    this.spinner.show();
    this.disable = true;
    this.backDir = file.parentId;
    if (file == "home") {
      this.getFolderContent(this.folder_id);
    } else {
      this.documentaryService.getFolderContent(this.token, file.id).subscribe(
        res => {
          this.globals.messageServer(res);
          this.folderIdActive = file.id;
          this.selectedDir = res.list;
          if (file.id == this.folder_id) {
            this.show = false;
          } else {
            this.show = true
          }
          this.disable = false;
          this.changeLastModify(this.selectedDir);
          this.changeDimension(this.selectedDir);
          this.changeCreateAt(this.selectedDir);
          this.spinner.hide();
        },
        err => {
          console.error("Errore in openFolder: ", err);
          this.globals.messageServer(err);
          this.disable = false;
          this.spinner.hide();
        }
      )
    }
  }

  newFolder(name) {

    this.spinner.show();
    const dialogRef = this.dialog.open(NewfolderComponent, {
      width: "70%",
      // height: "70%",
      data: {
        name: name,
        id: this.folderIdActive
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.spinner.show();
        this.documentaryService.getFolderContent(this.token, this.folderIdActive).subscribe(
          res => {
            this.globals.messageServer(res);
            this.selectedDir = res.list;
            this.changeLastModify(this.selectedDir);
            this.changeDimension(this.selectedDir);
            this.changeCreateAt(this.selectedDir);
            this.spinner.hide();
          },
          err => {
            console.error("Errore in getFolderContent: ", err);
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        )
      }

    });

  }

  delFolder(file, type?) {
    this.spinner.show();
    let text = 'Eliminata'
    let title = 'Eliminata'
    if (type == 'FOLDER') {
      text = 'Cartella eliminata';
      title = 'Eliminata'
    } else if (type == 'FILE') {
      text = 'File eliminato';
      title = 'Eliminato'
    }
    this.documentaryService.delFolder(this.token, file.id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.documentaryService.getFolderContent(this.token, this.folderIdActive).subscribe(
          res => {
            this.globals.messageServer(res);
            this.selectedDir = res.list;
            this.changeLastModify(this.selectedDir);
            this.changeDimension(this.selectedDir);
            this.changeCreateAt(this.selectedDir);
            this.globals.showNotification(text, "success")
            this.spinner.hide();
          },
          err => {
            console.error("Errore in openFolder: ", err);
            this.globals.messageServer(err);
            this.disable = false;
            this.spinner.hide();
          }
        )
      },
      err => {
        console.error("Errore: ", err);
        this.globals.messageServer(err);
      }
    )
  }


  openPopup(file) {
    if (file) {
      this.globals.stampa("File: ", file);
      this.spinner.show();
      const dialogRef = this.dialog.open(ChangeNameFolderComponent, {
        width: "70%",
        // height: "90%",
        data: file
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'Cancel') {
          this.documentaryService.getFolderContent(this.token, this.folderIdActive).subscribe(
            res => {
              this.globals.messageServer(res);
              this.selectedDir = res.list;
              this.changeLastModify(this.selectedDir);
              this.changeDimension(this.selectedDir);
              this.changeCreateAt(this.selectedDir);
              this.spinner.hide();
            },
            err => {
              console.error("Errore in openFolder: ", err);
              this.disable = false;
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          )
          err => {
            console.error("errore in getFolderContent(open Folder): ", err);
            this.disable = false;
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        }
        err => {
          console.error("Errore in getFolderContent: ", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      });
    }

  }

  changeLastModify(file) {
    file.forEach((fil) => {
      if (fil.entry.isFile == true) {
        let date = new Date(fil.entry.modifiedAt);
        if (date.getMonth() + 1 > 10 && date.getDate() >= 10) {
          fil.entry.modifiedAt = '' + (date.getDate()) + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
          // this.globals.stampa("mese e giorno >10")
        } else {

          if (date.getMonth() + 1 < 10 && date.getDate() < 10) {
            fil.entry.modifiedAt = '' + (date.getDate()) + '-' + '0' + (date.getMonth() + 1) + '-' + '0' + date.getFullYear();
            // this.globals.stampa("mese e giorno <10")
          } else {

            if (date.getMonth() + 1 < 10) {
              fil.entry.modifiedAt = '' + (date.getDate()) + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear();
              // this.globals.stampa("mese <10")
            }

            if (date.getDate() < 10) {
              fil.entry.modifiedAt = '' + (date.getDate()) + '-' + (date.getMonth() + 1) + '-' + '0' + date.getFullYear();
              // this.globals.stampa("giorno <10")
            }
          }
        }
      }
    })
  }

  changeDimension(byte) {
    byte.forEach(byt => {
      if (byt.entry.isFile == true) {
        if (byt.entry.content.sizeInBytes < 1024) {
          Math.round(parseInt(byt.entry.content.sizeInBytes))
          byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes + " B";
        } else {
          if (byt.entry.content.sizeInBytes >= 1073741824) {
            byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes / 1073741824;
            byt.entry.content.sizeInBytes = Math.round(parseInt(byt.entry.content.sizeInBytes));
            byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes + " GB"
          } else {
            if (byt.entry.content.sizeInBytes >= 1048576) {
              byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes / 1048576;
              byt.entry.content.sizeInBytes = Math.round(parseInt(byt.entry.content.sizeInBytes));
              byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes + " MB"
            } else {
              if (byt.entry.content.sizeInBytes >= 1024) {
                byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes / 1024;
                byt.entry.content.sizeInBytes = Math.round(parseInt(byt.entry.content.sizeInBytes));
                byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes + " KB"
              }
            }
          }
        }
      }
    });
  }

  changeCreateAt(file) {

    file.forEach((fil) => {
      if (fil.entry.isFile == true) {
        let date = new Date(fil.entry.createdAt);
        if (date.getMonth() + 1 > 10 && date.getDate() >= 10) {
          fil.entry.createdAt = '' + (date.getDate()) + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
          // this.globals.stampa("mese e giorno >10")
        } else {

          if (date.getMonth() + 1 < 10 && date.getDate() < 10) {
            fil.entry.createdAt = '' + (date.getDate()) + '-' + '0' + (date.getMonth() + 1) + '-' + '0' + date.getFullYear();
            // this.globals.stampa("mese e giorno <10")
          } else {

            if (date.getMonth() + 1 < 10) {
              fil.entry.createdAt = '' + (date.getDate()) + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear();
              // this.globals.stampa("mese <10")
            }

            if (date.getDate() < 10) {
              fil.entry.createdAt = '' + (date.getDate()) + '-' + (date.getMonth() + 1) + '-' + '0' + date.getFullYear();
              // this.globals.stampa("giorno <10")
            }
          }
        }
      }
    })
  }

  deleteObject(type, file) {
    let title = 'Cancellare?'
    if (type == 'FOLDER') {
      title = 'Cancellare la cartella?'
    } else if (type == 'FILE') {
      title = 'Cancellare il file?'
    }
    swal({
      title: title,
      // text: 'You will not be able to recover this imaginary file!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Annulla',
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.delFolder(file, type);
      } else {

      }
    });
  }

  download(file) {
    this.documentaryService.getContent(this.token, file.id).subscribe(res => {
      this.globals.messageServer(res);
      let url = window.URL.createObjectURL(res);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = file.name;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }
}
