import { Component, OnInit, Input, Inject } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NewfolderService } from 'src/app/services/newfolder.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Participants, Group, Customer, User } from 'src/app/classes';
import { OperatorService } from 'src/app/services/operator.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-newfolder',
  templateUrl: './newfolder.component.html',
  styleUrls: ['./newfolder.component.scss']
})
export class NewfolderComponent implements OnInit {

  myControlOp = new FormControl();
  myControlCus = new FormControl();
  myControlGrp = new FormControl();
  filteredOperator: Observable<any[]>;
  filteredCustomer: Observable<any[]>;
  filteredGroups: Observable<any[]>;

  op: any;
  cus: any;
  grp: any;


  //string
  token: string;
  nameGroup: string = '';
  changeNameFolder: string;
  folder: string = 'Cartella senza nome';

  //array
  operators: Array<User> = [];
  groups: Array<Group> = [];
  customers: Array<Customer> = [];
  customerF: Array<Customer> = [];
  customerG: Array<Customer> = [];
  participants: Participants;
  activeUser: any;

  //boolean
  changeFolderName = false;
  // newGroup = false;
  // changed = false;
  // other = true;
  // permits: boolean = false;

  //number
  i: number = 1;

  //Input
  folderName;
  folderID;

  constructor(
    private globals: GlobalService,
    public newfolderService: NewfolderService,
    public operatorService: OperatorService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewfolderComponent>
  ) {
    $(document).ready(function () {
      $("#FolderName").focus();
      $(":focus").select();
    });
    this.participants = new Participants();
  }

  ngOnInit() {
    this.folderName = this.data.name;
    this.folderID = this.data.id;
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.activeUser = this.globals.storagedUser;
    this.spinner.show();
      this.getGroups();
    this.getCustomer();
    this.getOperators();
    this.spinner.hide();  
    // this.setList()
  }

  getOperators() {
    let _self = this;
    this.spinner.show();
    this.operatorService.getOperator(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.operators = res;
        this.operators.forEach(op => {
          op.search = this.globals.createLocalSearch({ name: op.name, last_name: op.last_name, username: op.username })
        });
        _self.filteredOperator = _self.myControlOp.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value Op: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search Op: ", search);
            return search ? _self._filter(search, _self.operators) : _self.operators.slice()
          })
        );
        this.spinner.hide();
      },
      err => {
        console.error("Errore in getOpeators: ", err)
        this.globals.messageServer(err)
        this.spinner.hide();
      }
    )
  }

  displayFnCus(cus: Customer): string {
    return cus && `${cus.view_name}`;
  }

  getCustomer() {
    let _self = this;
    this.spinner.show();
    this.newfolderService.getCustomer(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.customers = res;
        this.customers.forEach((element, k) => {
          if (element.type == "F") {
            this.customerF.push(this.customers[k])
          } else {
            this.customerG.push(this.customers[k])
          }
        });
        this.customers.forEach(cus => {
          if(cus.type=='GIU'){
            cus.search = this.globals.createLocalSearch({name: cus.view_name, business_name: cus.business_name})
          } else {
            cus.search = this.globals.createLocalSearch({view_name: cus.view_name, name: cus.name, last_name: cus.last_name });
          }
        });
        _self.filteredCustomer = _self.myControlCus.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value Cus: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search Cus: ", search);
            return search ? _self._filter(search, _self.customers) : _self.customers.slice()
          })
        );
        this.spinner.hide();
      },
      err => {
        console.error("Errore in getCustomer", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      }
    )
  }

  private _filter(name: string, array: Array<any>): User[] {
    const filterValue = name.toLowerCase();
    this.globals.stampa("NameOp: ", name);

    return array.filter(
      ele => ele.search.toLowerCase().indexOf(filterValue) >= 0
    );
  }


  getGroups() {
    let _self = this;
    this.spinner.show();
    this.newfolderService.getGroups(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.groups = res;
        this.groups.forEach(grp => {
          grp.select = false;
        })
        this.groups.forEach(grp => {
          grp.search = this.globals.createLocalSearch({ name: grp.group_name })
        });

        _self.filteredGroups = _self.myControlGrp.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value Op: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search Op: ", search);
            return search ? _self._filter(search, _self.groups) : _self.groups.slice()
          })
        );
        this.spinner.hide();
      },
      err => {
        console.error("Errori in getGroups: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      }
    )
  }

  canceling() {
    this.dialogRef.close('cancel');
  }

  addFolder() {
    if (this.folder != 'Cartella senza nome' && this.folder != '') {
      if(this.folder.length < 100){
      this.spinner.show();
      this.newfolderService.newFolder(this.token, this.folderID, this.folder).subscribe(
        res => {
          this.globals.messageServer(res);
          if (this.participants.customers.length > 0 || this.participants.operators.length > 0 || this.participants.groups.length > 0) {
            let obj: any;
            obj = {
              auth: [],
              inherited: false
            }
            if (this.participants.customers.length > 0) {
              this.participants.customers.forEach(element => {
                obj.auth.push({
                  authorityId: element.alfresco_id,
                  name: "Contributor",
                  accessStatus: "ALLOWED",
                })
              });
            }
            if (this.participants.operators.length > 0) {
              this.participants.operators.forEach(element => {
                obj.auth.push({
                  authorityId: element.alfresco_id,
                  name: "Contributor",
                  accessStatus: "ALLOWED",
                })
              });
            }
            if (this.participants.groups.length > 0) {
              this.participants.groups.forEach(element => {
                obj.auth.push({
                  authorityId: element.alfresco_id,
                  name: "Contributor",
                  accessStatus: "ALLOWED",
                })
              });
            }
            let id: string = res.id
            this.newfolderService.setPermission(this.token, obj, id).subscribe(
              res => {
                this.globals.messageServer(res);
              },
              err => {
                console.error("Errore in setPermission: ", err);
                this.globals.messageServer(err);
              }
            )
          }
          this.spinner.hide();
          this.dialogRef.close('aggiunta');
        },
        err => {
          console.error("Errore in addFolder: ", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )
      } else {
        this.globals.showNotification("Il nome cartella supera i 100 caratteri", "warning");
      }
    } else {
      // alert("Nome cartella non valido");
      this.globals.showNotification("Nome cartella non valido", "warning");
    }
  }

  push(add) {
    if (add) {
      if (add.customer_id) {
        if (this.participants.customers.indexOf(add) >= 0) {
          this.globals.stampa("Already exist");
          document.getElementById("addCustomer").blur();
          this.globals.showNotification("Cliente già presente in lista", "warning");
          this.cus = '';
        } else {
          add.customer_name = add.view_name;
          this.participants.customers.push(add);
          this.cus = '';
          document.getElementById("addCustomer").blur();
        }
      }
      if (add.user_id) {
        if (this.participants.operators.indexOf(add) >= 0) {
          this.globals.stampa("Already exist");
          this.op = '';
          this.globals.showNotification("Operatore già presente in lista", "warning");
          document.getElementById("addOperator").blur();
        } else {
          this.participants.operators.push(add);
          this.op = '';
          document.getElementById("addOperator").blur();
        }
      }
      if (add.group_id) {
        if (this.participants.groups.indexOf(add) >= 0) {
          this.grp = '';
          this.globals.stampa("Already exist");
          this.globals.showNotification("Gruppo già presente in lista", "warning");
          document.getElementById("addGroup").blur();
        } else {
          this.participants.groups.push(add);
          this.grp = '';
          document.getElementById("addGroup").blur();
        }

      }
    }
  }

  delete(list) {
    this.globals.stampa("Delete");

    if (list.customer_id) {
      let obj = {
        customer_id: list.customer_id,
        alfresco_id: list.alfresco_id
      }
      this.participants.customers.splice(this.participants.customers.indexOf(list), 1);
    }

    if (list.user_id) {
      let obj = {
        user_id: list.user_id,
        alfresco_id: list.alfresco_id
      }
      this.participants.operators.splice(this.participants.operators.indexOf(list), 1);

    }

    if (list.group_id) {
      let obj = {
        group_id: list.group_id,
        alfresco_id: list.alfresco_id

      }
      this.participants.groups.splice(this.participants.groups.indexOf(list), 1)
    }
  }

}