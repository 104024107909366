import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EliminaService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService
    ) { }

  deleteCustomer(token, id){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteCustomer(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteProfile(token, user_id){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteProfile(user_id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteContract(token, contractId): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteContractURL(contractId), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteGroup(token, groupId, id): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.getDeleteGroupURL(groupId), id, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  delFolder(token, id){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteFolder(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteFulfillment(token, id){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteFulfillment(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }
}
