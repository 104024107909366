import { Component, OnInit } from '@angular/core';
import { environment } from '@environment';
import { User, Fulfillment, Deadline, Process } from 'src/app/classes';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/services/global.service';
import { EliminaService } from 'src/app/services/elimina.service';
import { FulfillmentService } from 'src/app/services/fulfillment.service';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import swal from 'sweetalert2';

declare const $: any;

@Component({
  selector: 'app-fulfillment',
  templateUrl: './fulfillment.component.html',
  styleUrls: ['./fulfillment.component.scss']
})

export class FulfillmentComponent implements OnInit {
  envi = environment;
  activeUser: User;
  token: string;
  fulfillments: Array<Fulfillment>;
  isOnlyRead: boolean = true;
  choose: boolean = false;
  selectedFulfillment: Fulfillment;
  selected: any;
  deadlines: Array<Deadline> = [];
  myControlDeadline = new FormControl();
  filteredDeadline: Observable<any[]>;
  processes: Array<Process>;
  myControlProcess = new FormControl();
  filteredProcesses: Observable<any[]>;
  proc: string;
  edit: boolean = false;
  new: boolean = false;
  constructor(
    private modalService: NgbModal,
    private globals: GlobalService,
    private Delete: EliminaService,
    private spinner: NgxSpinnerService,
    private fulfillmentService: FulfillmentService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.activeUser = this.globals.storagedUser;
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.getFulfillments();
    
  }

  getFulfillments() {
    let _self = this;
    this.fulfillmentService.getFulfillments(this.token).subscribe((res) => {
      this.globals.messageServer(res);
      this.fulfillments = res.values.fulfillments;
      this.deadlines = res.values.deadlines;
      this.processes = res.values.processes;
      this.deadlines.forEach((deadline) => {
        deadline.search = this.globals.createLocalSearch({ name: deadline.name })
      });
      _self.filteredDeadline = _self.myControlDeadline.valueChanges.pipe(
        startWith(''),
        map((value) => {
          this.globals.stampa("Value deadline: ", value);
          return typeof value === "string" ? value : value.search
        }),
        map((search) => {
          this.globals.stampa("Search deadline: ", search);
          return search ? _self._filter(search, _self.deadlines) : _self.deadlines.slice()
        })
      );
      this.processes.forEach((process) => {
        process.search = this.globals.createLocalSearch({ name: process.name })
      });
      _self.filteredProcesses = _self.myControlProcess.valueChanges.pipe(
        startWith(''),
        map((value) => {
          this.globals.stampa("Value process: ", value);
          return typeof value === "string" ? value : value.search
        }),
        map((search) => {
          this.globals.stampa("Search process: ", search);
          return search ? _self._filter(search, _self.processes) : _self.processes.slice()
        })
      );
      this.globals.stampa("Adempimenti", this.fulfillments);
      let fulfillmentss = [];
      let tableObject = {
        data: []
      }
      this.fulfillments.forEach((fulfillment) => {
        let obj = {
          fulfillment_id: fulfillment.fulfillment_id,
          name: fulfillment.name,
          deadline: fulfillment.deadline.name ? fulfillment.deadline.name: '',
        }
        fulfillmentss.push(obj);
      });
      tableObject.data = fulfillmentss;
      let config = {
        pagingType: "full_numbers",
        lengthMenu: [
          [10, 25, 50, -1],
          [10, 25, 50, "All"]
        ],
        responsive: {
          details: {
            type: 'column',
            target: 'tr'
          }
        },
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Ricerca contratti",
          url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
        },
        ajax: (data, callback, settings) => {
          callback(tableObject)
        },
        columns: [
          { title:'ID', data: 'fulfillment_id' },
          { title:'Adempimento', data: 'name' },
          { title:'Scanze', data: 'deadline' },
          { title:'Azione', data: null, className: 'td-actions text-right' }
        ],
        columnDefs: [
          {
            targets: [0],
            visible: false
          },
          {
            targets: [3],
            visible: true,
            render: function (data, type, full, meta) {
              full = JSON.stringify(full);
              return `<a data-fulfillment='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                    <i class="material-icons">search</i>
                  </a>
                  <a data-fulfillment='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                    <i class="material-icons">edit</i>
                  </a>
                  <a data-fulfillment='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                    <i class="material-icons">close</i>
                  </a>`
            }
          }
        ]
      }
      $(document).ready(function () {
        this.table = $('#datatablesFulfillment').DataTable(config);
        $('#datatablesFulfillment').on('click', 'a.btn-info', function () {
          let fulfillmentR: any = $(this).data('fulfillment')
          _self.fulfillments.forEach((fulfillment) => {
            if (fulfillment.fulfillment_id == fulfillmentR.fulfillment_id) {
              _self.showForm(fulfillment, true);
            }
          })
        });
        $('#datatablesFulfillment').on('click', 'a.btn-success', function () {
          let fulfillmentR = $(this).data('fulfillment')
          _self.fulfillments.forEach((fulfillment) => {
            if (fulfillment.fulfillment_id == fulfillmentR.fulfillment_id) {
              _self.edit = true;
              _self.new = false;
              _self.showForm(fulfillment);
            }
          })
        });
        $('#datatablesFulfillment').on('click', 'a.btn-danger', function () {
          let fulfillmentR = $(this).data('fulfillment')
          _self.fulfillments.forEach((fulfillment) => {
            if (fulfillment.fulfillment_id == fulfillmentR.fulfillment_id) {
              _self.deletefulfillment(fulfillment);
            }
          })
        });
        _self.spinner.hide();
      })
    })
  }

  reload() {
    this.spinner.show();
    $('#datatablesFulfillment').DataTable().destroy();
    this.selectedFulfillment = new Fulfillment();
    this.choose = false;
    this.isOnlyRead = false;
    this.selected = '';
    this.proc = '';
    this.edit = false;
    this.new = false;
    this.selected = new Deadline();
    this.getFulfillments();
  }

  showForm(fulfillment: Fulfillment, onlyRead?: boolean) {
    this.isOnlyRead = false;
    if (onlyRead) {
      this.isOnlyRead = true;
    }
    if (this.edit) {
      this.deadlines.forEach((dead) => {
        if (dead.deadline_id == fulfillment.deadline.deadline_id) {
          this.selected = dead;
        }
      })
    }

    // this.newCont = false;
    // this.del = true;
    this.choose = true;
    this.selectedFulfillment = fulfillment;
    this.globals.stampa("Adempimento: ", this.selectedFulfillment)
  }

  private _filter(name: string, array: Array<any>): User[] {
    const filterValue = name.toLowerCase();
    this.globals.stampa("NameOp: ", name);

    return array.filter(
      ele => ele.search.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  displayFn(deadline: Deadline): string {
    return deadline && `${deadline.name}`;
  }

  selectDeadline(deadline) {
    this.selectedFulfillment.deadline = deadline;
    this.selected = deadline;
  }

  push(process: Process) {
    let toAdd = true
    if (process) {
      this.selectedFulfillment.processes.forEach((proc) => {
        if (proc.process_id == process.process_id) {
          toAdd = false;
        }
      })
      if (toAdd) {
        this.selectedFulfillment.processes.push(process);
        this.proc = '';
      } else {
        this.proc = '';
        this.globals.showNotification("Processo già selezionato", "warning");

      };

      document.getElementById("addProcesses").blur();

    } else {
      // document.getElementById("addGroup").blur();
      // document.getElementById("addOperator").blur();
      // document.getElementById("addCustomer").blur();
      this.globals.showNotification("Non è stato selezionato nessun processo", "warning");
    }
  }

  deletefulfillment(fulfillment: Fulfillment) {
    swal({
      title: `Cancellare l'adempimento?`,
      // text: 'You will not be able to recover this imaginary file!',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annulla',
      confirmButtonText: 'Conferma',
      cancelButtonClass: "btn btn-danger",
      confirmButtonClass: "btn btn-success",
      buttonsStyling: false
    }).then((result) => {
      this.globals.stampa('entrato: ', result)
      if (result.value) {
        this.Delete.deleteFulfillment(this.token, fulfillment.fulfillment_id).subscribe(
          res => {
            this.globals.messageServer(res);
            this.reload();
            this.globals.showNotification("Adempimento eliminato", "success");
          },
          err => {
            console.error("Errore in delContract ", err);
            this.globals.messageServer(err);
          }
        )
      } else {
      }
    });
  }

  delete(fulfillment) {
    if (fulfillment) {
      if (this.selectedFulfillment.processes.indexOf(fulfillment) < 0) {
        this.globals.stampa("Doesen't exist");
        this.globals.showNotification("Error on remove process", 'warning')
      } else {
        this.selectedFulfillment.processes.splice(this.selectedFulfillment.processes.indexOf(fulfillment), 1)
      }
    }
  }

  newFulfillment() {
    this.new = true;
    this.selected = '';
    this.edit = false;
    this.selectedFulfillment = new Fulfillment();
    this.selected = new Deadline();
    this.showForm(this.selectedFulfillment);
  }

  save() {
    this.spinner.show();
    if (!this.selectedFulfillment.name || !this.selectedFulfillment.deadline.deadline_id) {
      this.globals.showNotification("i campi nome e scadenza sono obbilgatori", "danger");
    } else {
      // update

      if (this.edit) {
        this.fulfillmentService.updateFulfillment(this.token, this.selectedFulfillment).subscribe((res) => {
          this.globals.messageServer(res);
          this.reload();
        }, (err) => {
          console.error("Error on update fulfillment");
          this.globals.showNotification("Errore nell'aggiornare l'adempimento, riprova più tardi", "danger")
          this.spinner.hide();
        })
      } else {
        // nuovo
        this.fulfillmentService.newFulfillment(this.token, this.selectedFulfillment).subscribe(
          res => {
            this.globals.messageServer(res);
            this.reload();
          },
          err => {
            console.error("Error on update fulfillment");
            this.globals.showNotification("Errore nell'inserire l'adempimento, riprova più tardi", "danger")
            this.spinner.hide();
          }
        )
      }
    }
  }

}
