import { Component, OnInit, Input, Inject } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { ActivityService } from 'src/app/services/activity.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Procces, Customer, InfoInstance, User } from '../../classes';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


export interface DialogData {
  event: string;
  state: string;
  activityName?: string;
  info?: InfoInstance;
  operator: User;
  customer: Customer;
  process: any;
}

@Component({
  selector: 'app-acitivitypopup',
  templateUrl: './acitivitypopup.component.html',
  styleUrls: ['./acitivitypopup.component.scss']
})
export class ActivitypopupComponent implements OnInit {

  event: any;
  activityName: string;
  process: Procces;
  @Input() customer: Customer;
  @Input() operator: any;
  info: InfoInstance;
  state: string;
  token: string;
  userActive: string;
  folder_id: string;
  color: string;
  emptySignal: boolean = false;
  showDoc: boolean = false;
  showGetColor: boolean = false;
  showCheckDoc: boolean = false;
  showList: boolean = false;

  constructor(
    private globals: GlobalService,
    private activityService: ActivityService,
    public dialogRef: MatDialogRef<ActivitypopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    
   }

  ngOnInit() {
    if(!this.token){
      this.token = this.globals.getToken();
    }
    this.userActive = this.globals.storagedUser;
    this.globals.stampa("Info instance: ", this.info );
    this.event = this.data.event;
    if(this.data.info){
      this.info = this.data.info;
      this.activityName = this.data.info.pop_up;
      this.process = this.data.process
    }
    this.selectTemplate();
  }

  selectTemplate(){
    if(this.info){
      if(this.activityName == 'Upload documenti' || this.activityName == 'Carica documenti' || this.activityName == 'Upload documenti cliente' || this.activityName == 'Ricezione documenti'){
        this.folder_id = this.info.customer.folder_id;
        this.showDoc = true;
        return ;
      } else  if(this.activityName == 'Cambia colore'){
        this.showGetColor = true;
        return ;
      } else if(this.activityName == 'Controllo documenti'){
        this.globals.stampa("Dentro");
        this.folder_id = this.info.customer.folder_id;
        this.showCheckDoc = true;
        return ;
      } else {
        this.emptySignal = true;
      }
    } else {
      this.showList = true
      return;
    }
    
  }

  confirm(){
    let toSend;
    if(this.showDoc){
      toSend = {
        procces: this.process
      }
    } else if(this.showCheckDoc) {
        toSend = {
          procces: this.process,
          info: {
            doc_ok: true
          }
        }
    } else if(this.showGetColor) {
      toSend = {
        procces: this.process,
        info: {
          color: this.color
        }
      }
    } else if(this.emptySignal){
      toSend = {
        procces: this.process,
        info: {}
      }
    }
    
    this.activityService.inputInstance(this.token, toSend).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa("Inviata: ", res);
        this.close();
      },
      err => {
        console.error("Errore: ", err);
        this.globals.messageServer(err);
      }
    )
  }
  refuses(){
    let toSend;
    if(this.showCheckDoc){
      toSend = {
        procces: this.process,
        info: {
          doc_ok: false
        }
      }
    }
    this.activityService.inputInstance(this.token, toSend).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa("Inviata: ", res);
        this.close();
      },
      err => {
        console.error("Errore: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  close(){
    this.dialogRef.close();
  }

  cancel(){
    this.dialogRef.close('cancel')
  }

}
