import { Component, OnInit, Input, Inject } from '@angular/core';
import { TicketService } from 'src/app/services/ticket.service';
import { GlobalService } from 'src/app/services/global.service';
import { CustomerService } from 'src/app/services/customer.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Ticket, User, Customer, Contract } from 'src/app/classes';
import { ContractService } from 'src/app/services/contract.service';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

export interface DialogData {
  ticket: Ticket;
}

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.scss']
})
export class NewTicketComponent implements OnInit {

  myControlCus = new FormControl();
  myControlCon = new FormControl();
  filteredContract: Observable<any[]>;
  filteredCustomer: Observable<any[]>;

  constructor(
    public dialogRef: MatDialogRef<NewTicketComponent>,
    private globals: GlobalService,
    private ticketService: TicketService,
    private customerService: CustomerService,
    private upload: UploadFileService,
    private formBuilder: FormBuilder,
    private contractService: ContractService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  // Object
  contracts: any;
  userActive: User;
  // String
  token: string;
  cus: any;
  con: any;
  // Boolean
  new: boolean = true;
  operator: boolean = false;
  // Array
  tk: Ticket = new Ticket();
  communications: Array<any> = [];
  customers: any;
  customer_id: number;
  forCustomer: boolean = false;
  file: any;
  nameList: any;
  uploadForm: FormGroup;
  amountFile: number;
  desc: boolean;
  nome: boolean;
  fileBoolean: boolean = false;

  tickett: any;
  color;


  ngOnInit() {
    this.tk.priority = 1;
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
    this.userActive = this.globals.storagedUser;
    this.checkProfile()
    this.selectedTicket();
    this.getCommunication();
    this.getCustomers();
    if (this.data && this.data.ticket) {
      this.tickett = this.data.ticket;

    }
    this.spinner.hide()
  }

  checkProfile() {
    if (this.userActive.profile_id > 1) {
      this.operator = true;
    } else {
      this.operator = false;
    }
  }

  ticket(tick) {
    if (!tick.ticket_name) {
      this.globals.showNotification("Titolo è obbligatorio", "warning");
      return;
    }
    //green
    this.color = '#43a047';
    if (tick.priority == 1) {
      this.color = '#43a047'
    } else if (tick.priority == 2) {
      //yellow
      this.color = '#fb8c00'
    } else if (tick.priority == 3) {
      //Red
      this.color = '#e53935'
    }
    let customer_id, contract_id;
    if (this.cus) {
      customer_id = this.cus.customer_id;
    }
    if (this.con) {
      contract_id = this.con.contract_id;
    }
    let obj = {
      ticket: {
        ticket_name: tick.ticket_name,
        description: tick.description,
        priority: tick.priority,
        state: "Aperto",
        color: this.color,
        contract_id: contract_id,
        customer_id: customer_id
      }
    }
    if (this.forCustomer) {
      if (this.customer_id) {
        if (tick.ticket_name.length <= 80 && tick.description.length <= 300) {
          this.spinner.show();
          this.ticketService.newTicket(this.token, obj).subscribe(
            res => {
              this.globals.messageServer(res);
              if (this.amountFile > 0) {
                this.uploadFile(res.ticket_id);
              }
              this.close();
            },
            err => {
              console.error(err);
              this.globals.messageServer(err);
              this.spinner.hide();
            })
        } else {
          if (tick.ticket_name.length > 80) {
            this.globals.stampa("Il titolo supera i 80 caratteri", "warning");
          }
          if (tick.description.length > 300) {
            this.globals.stampa("La descrizione supera i 300 caratteri", "warning");
          }
        }
      } else {
        this.globals.showNotification("Il cliente è obbligatorio", "warning");
      }

    } else {
      if (tick.ticket_name.length <= 80 && tick.description.length <= 300) {
        this.spinner.show();
        this.ticketService.newTicket(this.token, obj).subscribe(
          res => {
            this.globals.messageServer(res);
            if (this.amountFile > 0) {
              this.uploadFile(res.ticket_id);
            }
            this.close();
          },
          err => {
            console.error(err);
            this.globals.messageServer(err);
            this.spinner.hide();
          })
      } else {
        if (tick.ticket_name.length > 80) {
          this.globals.stampa("Il titolo supera i 80 caratteri", "warning");
        }
        if (tick.description.length > 300) {
          this.globals.stampa("La descrizione supera i 300 caratteri", "warning");
        }
      }
    }
  }

  svuota() {
    this.file = [];
    this.fileBoolean = false;
    this.amountFile = this.file.length;
  }

  uploadFile(id) {
    this.uploadForm.get('profile').setValue(this.file);
    const formData = new FormData();
    for (let i = 0; i < this.file.length; i++) {
      formData.append('file', this.file[i], this.file[i].name);
    }
    this.upload.uploadTicketFile(this.token, formData, id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.close();
      },
      err => {
        console.error(err)
        this.globals.messageServer(err);
      }
    );
  }

  getCustomers() {
    let _self = this;
    this.customerService.getCustomer(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.customers = res;
        this.customers.forEach(cus => {
          if (cus.type == 'GIU') {
            cus.search = this.globals.createLocalSearch({ name: cus.view_name, business_name: cus.business_name })
          } else {
            cus.search = this.globals.createLocalSearch({ view_name: cus.view_name, name: cus.name, last_name: cus.last_name });
          }
        });
        _self.filteredCustomer = _self.myControlCus.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value Cus: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search Cus: ", search);
            return search ? _self._filter(search, _self.customers) : _self.customers.slice()
          })
        );
      },
      err => {
        console.error("Error on get customers", err);
        this.globals.messageServer(err);
      }
    )
  }

  selectedTicket() {
    if (this.tickett) {
      this.tk = this.tickett;
      this.new = false;
    }
  }

  communication(comm) {
    let obj: any = {
      text: comm
    }
    this.ticketService.sendCommunication(this.token, this.tickett.ticket_id, obj).subscribe(
      res => {
        this.globals.messageServer(res);
      },
      err => {
        console.error("Errore in sendCommunication", err);
        this.globals.messageServer(err);
      }
    )
  }

  getCommunication() {
    if (this.tickett) {
      this.ticketService.getTicketCommunication(this.token, this.tickett.ticket_id).subscribe(
        res => {
          this.globals.messageServer(res);
          this.communications = res;
        },
        err => {
          console.error("Errore in getCommunication: ", err);
          this.globals.messageServer(err);
        }
      )
    } else {
    }
  }

  assignTo(cus) {
    if (cus.customer_id) {
      this.customer_id = cus.customer_id;
      this.globals.stampa('CustomerID: ', this.customer_id);
      document.getElementById('addCustomer').blur();
      this.getContracts();
    }
  }

  onFileSelect(event) {
    this.amountFile = event.target.files.length;
    if (this.amountFile > 0 && event.target.files[0]) {
      this.file = event.target.files;
      this.fileBoolean = true;
      // Array.from(event.target.files).forEach(file => {
      //   this.nameList.push(file)
      // });
    }
  }

  canceling() {
    this.dialogRef.close('cancel');
  }

  close() {
    this.dialogRef.close();
  }

  displayFnCus(cus: Customer): string {
    return cus && `${cus.view_name}`;
  }

  // displayFnCon(con: Contract): string {
  //   return con && `${con.contract_name}`;
  // }

  private _filter(name: string, array: Array<any>): User[] {
    const filterValue = name.toLowerCase();
    this.globals.stampa('NameOp: ', name);

    return array.filter(
      ele => ele.search.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  getContracts() {
    let _self = this
    if (this.customer_id) {
      this.contractService.getCustomerContract(this.token, this.customer_id).subscribe(
        res => {
          this.globals.messageServer(res);
          this.contracts = res;
          this.globals.stampa('contratti ottenuti:', this.contracts);
        },
        err => {
          console.error('Error on get contracts: ', err);
          this.globals.messageServer(err);
        }
      )
    }

  }

} 
