import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-storeactivity',
  templateUrl: './storeactivity.component.html',
  styleUrls: ['./storeactivity.component.scss']
})
export class StoreactivityComponent implements OnInit {
  
  @Input() calendar_id: number;

  token: string;
  userActive: string;

  constructor(
    private globals: GlobalService,
    private activityService: ActivityService,
    private activeModal : NgbActiveModal,
  ) { }

  ngOnInit() {
    if(!this.token){
      this.token = this.globals.getToken();
    }
    this.userActive = this.globals.storagedUser;
  }

  confirm(){
    this.activityService.storeActivity(this.token, this.calendar_id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa("Attività archiviate");
        this.close();
      },
      err => {
        console.error("Errore nell'archiviare l'attività");
        this.globals.messageServer(err);
      }
    )
  }

  close(){
    this.activeModal.close();
  }

  cancel(){
    this.activeModal.close('cancel')
  }

}
