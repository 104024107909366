import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

import { CookieService } from 'ngx-cookie-service';
import { homepage, Numbers } from 'src/app/classes';
import { NgxSpinnerService } from 'ngx-spinner';

// import * as Chartist from 'chartist';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  header: Array<any> = [];
  ticket: boolean = false;
  homepage: homepage;
  token: any;
  numbers: any;
  activeUser: any;

  constructor(
    private globals: GlobalService,
    private cookie: CookieService,
    private spinner: NgxSpinnerService
  ) {
    this.homepage = new homepage();
    this.numbers = new Numbers();
  }

  ngOnInit() {
    this.spinner.show();
    if (!this.token) {
      this.globals.getToken();
    }
    this.header = this.globals.header;
    this.globals.stampa("Header: ", this.header);
    this.header.forEach(head => {
      if (head.key == 5) {
        this.ticket = true;
      }
    });
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.activeUser = this.globals.storagedUser;
    this.getHome();
  }

  goTo(path) {
    this.globals.goTo(path);
  }


  getHome() {
    this.spinner.show();
    this.globals.getHome(this.token).subscribe(
      res => {
        this.globals.messageServer(res)
        this.homepage = res;
        this.numbers = res.numbers;
        this.spinner.hide();
      },
      err => {
        this.spinner.hide()
        console.error("Errore: ", err);
        this.globals.messageServer(err);
      }
    )
  }

}
