import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';
import { environment } from '@environment';
import { ChangepwdComponent } from 'src/app/popup/changepwd/changepwd.component';
import { MatDialog } from '@angular/material';
import { User } from 'src/app/classes';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  envi = environment;
  file: File;
  activeUser: User;
  

  //String
  token: string;
  domain: string;
  pathImage: string = `${this.envi.assetsBaseUrl}/assets/image/img_avatar.png`;
  imageRender: string;

  //Boolean
  validEmailType: boolean = true;
  newImage: boolean = true;
  //Array
  selectedUser: any = {};
  selectedAddress: Array<any> = [];

  constructor(
    private globals: GlobalService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private userService: UserService,

  ) { }

  ngOnInit() {
    this.activeUser = this.globals.storagedUser;
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.getUser();
  }

  getUser() {
    this.spinner.show();
    this.userService.getUser(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa(res);
        this.domain = res.user.domain;
        this.selectedUser = res.user.info;
        this.selectedUser.description = this.selectedUser.description ? this.selectedUser.description :  '';
        this.pathImage = `${this.envi.http}://${this.envi.server}${this.envi.port}${res.user.info.path_img}`;
        this.selectedAddress = res.user.address
        this.imageRender = res.user.info.base64;
        this.globals.stampa(this.selectedUser);

        this.spinner.hide();
      },
      err => {
        console.error("Errore in getUser: ", err);
        this.globals.messageServer(err)
        this.spinner.hide();
      }
    )
  }

  emailValidationType(e) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(e).toLowerCase())) {
      this.validEmailType = true;
      // document.getElementById("emailSpan").innerHTML = "";
    } else {
      this.validEmailType = false;
      // document.getElementById("emailSpan").innerHTML = "↓ Email non valida";
    }
  }

  openChangePassword() {
    this.spinner.show()
    const dialogRef = this.dialog.open(ChangepwdComponent, {
      width: '70%',
      // height: '35%',
      data: {
        canClose: true,
        activeUser: this.activeUser
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.globals.stampa('The dialog was closed', result);
      if (result != 'cancel') {

      }
      this.spinner.hide();
    });
  }

  updateUser(use, addr) {
    if (this.validEmailType && use.description.length <= 200){
      this.spinner.show();
      
      let user = {
        user_id: use.user_id,
        username: use.username,
        email: use.email,
        name: use.name,
        last_name: use.last_name,
        description: use.description,
      }

      let addres

      if(addr.address_id){
      addres = {
        address_id: addr.address_id,
        address: addr.address,
        city: addr.city,
        province: addr.province,
        country: addr.country,
        zip_code: addr.zip_code,
        type_id: 4
      }
    } else {
      addres = {
        address: addr.address,
        city: addr.city,
        province: addr.province,
        country: addr.country,
        zip_code: addr.zip_code,
        type_id: 4
      }
    }

      let info = {
        info: user,
        address: addres
      }

      this.userService.updateUser(this.token, { user: info }).subscribe(
        res => {
          this.globals.messageServer(res);
          this.globals.goTo("homepage")
          this.globals.stampa(res);
          this.selectedUser = res.values.info;
          this.selectedAddress = res.values.address;
          this.spinner.hide();
        }, 
        err => {
          console.error("Errore in updateUser: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
        }
      )
    } else {
      if(use.description.length <= 200){
      this.globals.showNotification("Email non valida", "warning")
      } else {
        this.globals.showNotification("La descrizione supera i 200 caratteri", "warning")
      }
    }
  }

  base64textString
  loadAvatar(event){
    this.newImage = false;
    if (event.target.files.length > 0 && event.target.files[0]) {
      let image: any = document.getElementById('#preview')
      this.globals.stampa("Immagini: ", event.target.files);
      image.src =  window.URL.createObjectURL(event.target.files[0]);
      this.file = event.target.files[0];
      var reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(this.file);
    }
  }
  handleReaderLoaded(readerEvt) {
    let binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
   }

  remove(){
    this.newImage = true;
    let image: any = document.getElementById('#preview')
    window.URL.revokeObjectURL(image.src);
    image.src = `data:image/jpeg;base64,${this.imageRender}`;
  }

  uploadAvatar(){
    this.spinner.show();
    let formData = new FormData()
    formData.append('base64', this.base64textString);
    this.userService.updateImg(this.token, formData).subscribe(
      res => {
        this.globals.messageServer(res);
        this.activeUser = res.values.info;
        let user = this.globals.storagedUser;
        user.base64 = res.values.info.base64;
        localStorage.setItem('user', JSON.stringify(user));
        this.selectedAddress = res.values.address;
        this.newImage = true;
        this.spinner.hide();
        this.globals.messageServer(res);
      },
      err => {
        this.spinner.hide();
        this.globals.messageServer(err); 
      }
    )
  }

  
}
