import { Component, OnInit, Input, Inject } from '@angular/core';
import { OperatorService } from 'src/app/services/operator.service';
import { GlobalService } from 'src/app/services/global.service';
import { TicketService } from 'src/app/services/ticket.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Ticket, User, TicketInfo } from 'src/app/classes';
import swal from 'sweetalert2';
import { ContractService } from 'src/app/services/contract.service';
import { OpenfolderComponent } from '../openfolder/openfolder.component';
import { TicketactivityComponent } from '../ticketactivity/ticketactivity.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UploadFileService } from 'src/app/services/upload-file.service';


export interface DialogData {
  tk: TicketInfo;
  customer_view_name: string;
  read: boolean;
}
@Component({
  selector: 'app-waitingticket',
  templateUrl: './waitingticket.component.html',
  styleUrls: ['./waitingticket.component.scss']
})
export class WaitingticketComponent implements OnInit {

  tk: TicketInfo;
  token: string;
  ope: any;
  activeUser: User;
  comunications: Array<any> = [];
  message: boolean = true;
  operator: boolean = false;
  disabled: boolean = true;
  text: string = '';
  iscustumer: boolean = false;
  contracts: any;
  customer_view_name: string;
  read: boolean = false;
  assocOperator: string = '';
  opera: any;
  uploadForm: FormGroup;
  file: any;
  amountFile: number;
  fileBoolean: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<WaitingticketComponent>,
    private customerService: OperatorService,
    private contractService: ContractService,
    private globals: GlobalService,
    private ticketService: TicketService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private upload: UploadFileService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.read = this.data.read;
    this.tk = this.data.tk;
    this.globals.stampa("TIcket: ", this.tk)
    this.activeUser = this.globals.storagedUser;
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
    this.globals.stampa("Test");
    this.getOpeContract();
    this.iscustumer = true;
    this.getCusContract();
    if (this.activeUser.profile_id != 1 && this.tk.ticket.state != 'Archiviato') {
      this.disabled = false;
    }
    this.customer_view_name = this.data.customer_view_name;
    this.checkProfile();
    this.getComunications(this.tk);
    // this.spinner.hide();
  }

  onFileSelect(event) {
    this.amountFile = event.target.files.length
    if (this.amountFile > 0 && event.target.files[0]) {
      this.file = event.target.files
      this.fileBoolean = true;
      // Array.from(event.target.files).forEach(file => {
      //   this.nameList.push(file)
      // });
    }
  }

  getOpeContract() {
    this.spinner.show();
    if (this.tk.customer.customer_id) {
      this.globals.stampa("id - : ", this.tk.customer.customer_id)
      this.contractService.getAssocOperatorToContract(this.token, this.tk.ticket.ticket_id).subscribe(
        res => {
          this.globals.messageServer(res);
          this.opera = res.values;
          this.opera.forEach(op => {
            this.assocOperator = `${op.last_name} ${op.name}`;
          });
          this.spinner.hide();
        },
        err => {
          console.error("Error on get contracts: ", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )
    }
  }

  getCusContract() {
    if (this.tk.customer.view_name) {
      this.contractService.getCustomerContract(this.token, this.tk.customer.customer_id).subscribe(
        res => {
          this.globals.messageServer(res);
          this.contracts = res;
          this.globals.stampa("contratti ottenuti:", this.contracts);

        },
        err => {
          console.error("Error on get contracts: ", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )
    }

  }

  checkProfile() {
    if (this.activeUser.profile_id > 1) {
      this.operator = true;
    } else {
      this.operator = false;
    }
  }

  getComunications(tk) {
    this.ticketService.getTicketCommunication(this.token, tk.ticket.ticket_id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.comunications = res;
        this.globals.stampa("Messaggi: ", res);
        this.comunications.forEach((com) => {
          com.createdat = this.globals.cleanDateAndHour(com.createdat);
          this.spinner.hide();
        })
        this.checkFocus();
      },
      err => {
        console.error("Error on get communications", err);
        this.globals.messageServer(err);
        this.spinner.hide();

      }
    )
  }

  checkFocus() {
    let max = new Date(null)
    let foc: string;
    this.globals.stampa("Date cablata: ", max)
    this.globals.stampa(this.activeUser);
    if (this.comunications.length > 0) {
      this.comunications.forEach(mex => {
        if (this.activeUser.user_customer_profile_id == mex.writeby_ucp_id) {

          let appoDate = new Date(mex.updatedat);

          if (max.getTime() < appoDate.getTime()) {
            max = new Date(mex.updatedat);
            foc = mex.ticket_communication_id.toString();
          }
        }
      });
      setTimeout(() => {
        let mexBox = document.getElementById('mexBox');
        let mexToFocus = document.getElementById(`mex${foc}`);
        // console.dir(mexToFocus);
        let scrollTo = mexToFocus.offsetTop - mexBox.offsetTop;
        mexBox.scrollTop = scrollTo;
      }, 500);
    }
    this.spinner.hide();
  }

  messagArea() {
    if (this.message) {
      this.message = false;
    } else {
      this.message = true;
    }
  }

  send() {
    let text = this.text;
    this.text = '';
    if (text.trim() != '') {
      this.ticketService.sendCommunication(this.token, this.tk.ticket.ticket_id, { text: text }).subscribe(
        res => {
          this.globals.messageServer(res);
          text = '';
          this.getComunications(this.tk);
          // $('#exampleFormControlTextarea1').removeClass('disabled');
        },
        err => {
          console.error("Error on Send comunications", err);
          this.globals.messageServer(err);
        }
      )
    } else {
      this.globals.showNotification("Inserisci un messaggio", "info");
      this.text = '';
    }

  }

  save() {
    this.globals.stampa("ticket: ", this.tk);
    delete this.tk.ticket.openby;
    delete this.tk.ticket.correctOpenat;
    delete this.tk.ticket.createdat;
    delete this.tk.ticket.assignedat;
    delete this.tk.ticket.openFor;
    this.globals.stampa(this.tk);
    if (this.tk.ticket.ticket_name.length <= 80 && this.tk.ticket.description.length <= 300) {
      this.spinner.show();
      this.ticketService.setStatus(this.token, this.tk.ticket.ticket_id, this.tk).subscribe(
        res => {
          this.globals.messageServer(res);
          this.globals.stampa("Ticekt aggiornato");
          if (this.amountFile > 0) {
            this.uploadFile(this.tk.ticket.ticket_id);
          } else {
            this.close();
          }

        },
        err => {
          console.error("Error on update Ticket");
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )
    } else {
      if (this.tk.ticket.ticket_name.length > 80) {
        this.globals.showNotification("Il titolo supera i 80 caratteri", "warning");
      }
      if (this.tk.ticket.description.length > 300) {
        this.globals.showNotification("La descrizione supera i 300 caratteri", "warning");
      }
    }
  }

  reloadMex() {
    this.getComunications(this.tk);
  }

  filed() {
    this.globals.stampa(this.tk)
    if (this.tk.ticket.state != 'Archiviato') {
      this.ticketService.setStatus(this.token, this.tk.ticket.ticket_id, {ticket: { state: 'Archiviato' }}).subscribe(
        res => {
          this.globals.messageServer(res);
          this.globals.stampa("Ticekt aggiornato");
          this.close();
        },
        err => {
          console.error("Error on update Ticket");
          this.globals.messageServer(err);
        }
      )
    } else {
      this.ticketService.setStatus(this.token, this.tk.ticket.ticket_id, {ticket: { state: 'Chiuso' }}).subscribe(
        res => {
          this.globals.messageServer(res);
          this.globals.stampa("Ticekt aggiornato");
          this.close();
        },
        err => {
          console.error("Error on update Ticket");
          this.globals.messageServer(err);
        }
      )
    }
  }

  svuota() {
    this.file = [];
    this.fileBoolean = false;
    this.amountFile = this.file.length;
  }

  showDoc() {
    if (this.tk.ticket.folder_id) {
      this.spinner.show();
      this.dialog.open(OpenfolderComponent, {
        width: "70%",
        data: {
          folder_id: this.tk.ticket.folder_id
        }
      }).afterClosed().subscribe(result => {
        if (result != 'Cancel') { }
        err => {
          console.error("Errore in getFolderContent: ", err);
          this.globals.messageServer(err);
        }
      });
    }

  }

  async uploadFile(id) {
    this.uploadForm.get('profile').setValue(this.file);
    const formData = new FormData();
    for (let i = 0; i < this.file.length; i++) {
      formData.append('file', this.file[i], this.file[i].name);
    }
    this.upload.uploadTicketFile(this.token, formData, id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.close();
        return
      },
      err => {
        console.error(err)
        this.globals.messageServer(err);
      }
    );
  }

  deleteTicket(tk) {

    if (tk && tk.ticket.ticket_id) {

      swal({
        title: 'Eliminare il ticket?',
        // text: 'You will not be able to recover this imaginary file!',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annulla',
        confirmButtonText: 'Conferma',
        cancelButtonClass: "btn",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.ticketService.deleteTicket(this.token, tk.ticket.ticket_id).subscribe(
            res => {
              this.globals.messageServer(res);
              this.globals.showNotification("Ticket eliminato", "success")
              this.spinner.hide();
              this.close();
            },
            err => {
              console.error("Errore nell'assegnare il ticket", err);
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          )
        } else {
        }
      });


    }
  }

  ticketActivity() {
    this.spinner.show();
    const dialogRef = this.dialog.open(TicketactivityComponent, {
      width: '80%',
      // height: '80%',
      data: {
        ticket_id: this.tk.ticket.ticket_id
      }
    })

    dialogRef.afterClosed().subscribe(
      result => {
        this.globals.stampa("Attività: ", result);

      }
    )
  }

  close() {
    this.dialogRef.close();
  }
}
