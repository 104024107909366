import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AddeventComponent } from '../addevent/addevent.component';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { lang } from 'moment';
import { ActivityTimeUserListComponent } from '../activity-time-user-list/activity-time-user-list.component';

declare const $: any;

@Component({
  selector: 'app-list-activity',
  templateUrl: './list-activity.component.html',
  styleUrls: ['./list-activity.component.scss']
})
export class ListActivityComponent implements OnInit {

  envi = environment;
  token: string;
  progress: Array<any> = [];
  activeUser: any;
  table_late: any;
  table_progress: any;
  table_soon: any;
  late: Array<any> = [];
  soon: Array<any> = [];




  constructor(
    private globals: GlobalService,
    private calendarService: CalendarService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ListActivityComponent>,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.activeUser = this.globals.storagedUser;
    this.getActivity();
  }

  getActivity() {
    this.spinner.show();
    this.calendarService.getActivity(this.token, this.activeUser.user_id).subscribe(
      res => {
        this.globals.messageServer(res);

        this.late = res.values.late;
        this.progress = res.values.progress;
        this.soon = res.values.soon;

        this.late.forEach((act) => {
          if (act.start_time) {
            act.startTime = this.globals.cleanDateAndHour(act.start_time);
          } else {
            act.startTime = '';
          }
          if (act.end_time) {
            act.endTime = this.globals.cleanDateAndHour(act.end_time);
          }
        });

        this.progress.forEach((act) => {
          if (act.start_time) {
            act.startTime = this.globals.cleanDateAndHour(act.start_time);
          } else {
            act.startTime = '';
          }
          if (act.end_time) {
            act.endTime = this.globals.cleanDateAndHour(act.end_time);
          }
        });

        this.soon.forEach((act) => {
          if (act.start_time) {
            act.startTime = this.globals.cleanDateAndHour(act.start_time);
          } else {
            act.startTime = '';
          }
          if (act.end_time) {
            act.endTime = this.globals.cleanDateAndHour(act.end_time);
          }
        });

        if ($.fn.DataTable.isDataTable('#activities_progress')) {
          $('#activities_progress').DataTable().destroy();
          $('#activities_late').DataTable().destroy();
          $('#activities_soon').DataTable().destroy();
          this.createTable();
        } else {
          this.createTable(true);
        }
      }, err => {
        this.spinner.hide();
      }
    )
  }

  createTable(withFunctions?) {
    let _self = this;

    // IN RITARDO
    let acti_delay = [];
    let tableObject_late = {
      data: []
    }
    this.globals.stampa('late: ', this.late);
    this.late.forEach((act) => {
      let obj = {
        calendar_id: act.calendar_id,
        calendar_name: act.calendar_name,
        state: act.cstate,
        start_time: act.startTime,
        end_time: act.endTime ? act.endTime : ''
      }
      acti_delay.push(obj);
    });
    tableObject_late.data = acti_delay;
    let config_late = {
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: {
        details: true
      },
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Ricerca operatori",
        url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
      },
      ajax: (data, callback, settings) => {
        callback(tableObject_late);
        _self.disableStop('late');
      },
      columns: [
        { title:'ID', data: 'calendar_id' },
        { title:'Nome attività', data: 'calendar_name' },
        { title:'Stato', data: 'state' },
        { title:'Inizio', data: 'start_time' },
        { title:'Fine', data: 'end_time' },
        { title:'Azione', data: null, className: 'td-actions text-right' }
      ],
      columnDefs: [
        {
          targets: [0],
          visible: false
        },
        {
          targets: [5],
          visible: true,
          render: function (data, type, full, meta) {
            let calendar_id = full.calendar_id;
            full = JSON.stringify(full);
            return `
                <a data-act='${full}' matTooltip="Tempi" mat-raised-button type="button" class="btn btn-primary action-button">
                  <i class="material-icons">update</i>
                </a>
                <a data-act='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info action-button">
                  <i class="material-icons">search</i>
                </a>
                <a data-act='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                  <i class="material-icons">edit</i>
                </a>
                <a data-act='${full}' matTooltip="Stop attività" id='${calendar_id}' mat-raised-button type="button" class="btn btn-danger action-button">
                  <i class="material-icons" id='${calendar_id + '-A'}'>stop</i>
                </a>`
          }
        }
      ]
    }

    // ↓ DA TENERE
    // <a data-act='${full}' matTooltip="Tempi" mat-raised-button type="button" class="btn btn-primary action-button">
    //             <i class="material-icons">update</i>
    //             </a>
    // ↑ DA TENERE

    // IN CORSO
    let acti = [];
    let tableObject_progress = {
      data: []
    }
    this.globals.stampa('progress: ', this.progress);
    this.progress.forEach((act) => {
      let obj = {
        calendar_id: act.calendar_id,
        calendar_name: act.calendar_name,
        state: act.cstate,
        start_time: act.startTime,
        end_time: act.endTime ? act.endTime : ''
      }
      acti.push(obj);
    });
    tableObject_progress.data = acti;
    let config = {
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Ricerca operatori",
        url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
      },
      ajax: (data, callback, settings) => {
        callback(tableObject_progress);
        _self.disableStop('progress');
      },
      columns: [
        { title:'ID', data: 'calendar_id' },
        { title:'Nome attività', data: 'calendar_name' },
        { title:'Stato', data: 'state' },
        { title:'Inizio', data: 'start_time' },
        { title:'Fine', data: 'end_time' },
        { title:'Azione', data: null, className: 'td-actions text-right' }
      ],
      columnDefs: [
        {
          targets: [0],
          visible: false
        },
        {
          targets: [5],
          visible: true,
          render: function (data, type, full, meta) {
            let calendar_id = full.calendar_id;
            full = JSON.stringify(full);
            return ` 
                <a data-act='${full}' matTooltip="Tempi" mat-raised-button type="button" class="btn btn-primary action-button">
                  <i class="material-icons">update</i>
                </a>
                 <a data-act='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info action-button">
                   <i class="material-icons">search</i>
                 </a>
                 <a data-act='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                   <i class="material-icons">edit</i>
                 </a>
                 <a data-act='${full}' matTooltip="Stop attività" id='${calendar_id}' mat-raised-button type="button" class="btn btn-danger action-button">
                   <i class="material-icons" id='${calendar_id + '-A'}'>stop</i>
                 </a>`
          }
        }
      ]
    }

    // ↓ DA TENERE
    // <a data-act='${full}' matTooltip="Tempi" mat-raised-button type="button" class="btn btn-primary action-button">
    //             <i class="material-icons">update</i>
    //             </a>
    // ↑ DA TENERE

    //IN ANTICIPO
    let acti_soon = [];
    let tableObject_soon = {
      data: []
    }
    this.globals.stampa('soon: ', this.soon);
    this.soon.forEach((act) => {
      let obj = {
        calendar_id: act.calendar_id,
        calendar_name: act.calendar_name,
        state: act.cstate,
        start_time: act.startTime,
        end_time: act.endTime ? act.endTime : ''
      }
      acti_soon.push(obj);
    });
    tableObject_soon.data = acti_soon;
    let config_soon = {
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Ricerca operatori",
        url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
      },
      ajax: (data, callback, settings) => {
        callback(tableObject_soon);
        _self.disableStop('soon');
      },
      columns: [
        { title:'ID', data: 'calendar_id' },
        { title:'Nome attività', data: 'calendar_name' },
        { title:'Stato', data: 'state' },
        { title:'Inizio', data: 'start_time' },
        { title:'Fine', data: 'end_time' },
        { title:'Azione', data: null, className: 'td-actions text-right' }
      ],
      columnDefs: [
        {
          targets: [0],
          visible: false
        },
        {
          targets: [5],
          visible: true,
          render: function (data, type, full, meta) {
            let calendar_id = full.calendar_id;
            full = JSON.stringify(full);
            return `
                <a data-act='${full}' matTooltip="Tempi" mat-raised-button type="button" class="btn btn-primary action-button">
                  <i class="material-icons">update</i>
                </a>
                <a data-act='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info action-button">
                  <i class="material-icons">search</i>
                </a>
                <a data-act='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                  <i class="material-icons">edit</i>
                </a>
                <a data-act='${full}' matTooltip="Stop attività" id='${calendar_id}' mat-raised-button type="button" class="btn btn-danger action-button">
                  <i class="material-icons" id='${calendar_id + '-A'}'>stop</i>
                </a>`
          }
        }
      ]
    }

    // ↓ DA TENERE
    // <a data-act='${full}' matTooltip="Tempi" mat-raised-button type="button" class="btn btn-primary action-button">
    //             <i class="material-icons">update</i>
    //             </a>
    // ↑ DA TENERE

    $(document).ready(function () {
      this.table_late = $('#activities_late').DataTable(config_late);
      _self.table_late = this.table_late;

      this.table = $('#activities_progress').DataTable(config);
      _self.table_progress = this.table;

      this.table_soon = $('#activities_soon').DataTable(config_soon);
      _self.table_soon = this.table_soon;

      if (withFunctions) {

        //LATE
        $('#activities_late').on('click', 'a.btn-info', function () {
          let ACT: any = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.late.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.openActivity(act, true);
            }
          })
        });
        $('#activities_late').on('click', 'a.btn-success', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.late.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.openActivity(act);
            }
          })
        });
        $('#activities_late').on('click', 'a.btn-danger', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.late.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.stop(act, 'late');
            }
          })
        });

        $('#activities_late').on('click', 'a.greenButton', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.late.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.start(act, 'late');
            }
          })
        });
        $('#activities_late').on('click', 'a.btn-primary', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.late.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.openListActivityTime(act);
            }
          })
        });

        //PROGRESS
        $('#activities_progress').on('click', 'a.btn-info', function () {
          let ACT: any = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.progress.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.openActivity(act, true);
            }
          })
        });
        $('#activities_progress').on('click', 'a.btn-success', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.progress.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.openActivity(act);
            }
          })
        });
        $('#activities_progress').on('click', 'a.btn-danger', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.progress.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.stop(act, 'progress');
            }
          })
        });
        $('#activities_progress').on('click', 'a.greenButton', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.progress.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.start(act, 'progress');
            }
          })
        });
        $('#activities_progress').on('click', 'a.btn-primary', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.progress.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.openListActivityTime(act);
            }
          })
        });

        //SOON
        $('#activities_soon').on('click', 'a.btn-info', function () {
          let ACT: any = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.soon.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.openActivity(act, true);
            }
          })
        });
        $('#activities_soon').on('click', 'a.btn-success', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.soon.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.openActivity(act);
            }
          })
        });
        $('#activities_soon').on('click', 'a.btn-danger', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.globals.stampa("stop: ", ACT);
          _self.soon.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.stop(act, 'soon');
            }
          })
        });

        $('#activities_soon').on('click', 'a.greenButton', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.soon.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.start(act, 'soon');
            }
          })
        });
        $('#activities_soon').on('click', 'a.btn-primary', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.soon.forEach((act) => {
            if (act.calendar_id == ACT.calendar_id) {
              _self.openListActivityTime(act);
            }
          })
        });
      }
      _self.spinner.hide();
    })
  }

  disableStop(type) {
    let _self = this;
    let actA = [];
    let table: any;
    switch (type) {
      case 'late':
        actA = this.late;
        table = this.table_late;
        break;
      case 'progress':
        actA = this.progress;
        table = this.table_progress;
        break;
      case 'soon':
        actA = this.soon;
        table = this.table_soon;
        break;
    }

    table.rows().every(function (rowIdx, tableLoop, rowLoop) {
      var data = this.data();
      actA.forEach(acta => {
        if ((acta.calendar_id == data['calendar_id']) && !acta.start_time) {
          this.data(data);
          $(`#${data['calendar_id']}`).removeClass('btn-danger');
          $(`#${data['calendar_id']}`).addClass('greenButton');
          let text = 'play_arrow'
          $(`#${acta.calendar_id + '-A'}`).html(`${text}`);
        }
      });
    })
  }

  start(act, type) {
    this.spinner.show();
    let _self = this;
    let actA = [];
    let table: any;
    switch (type) {
      case 'late':
        actA = this.late;
        table = this.table_late;
        break;
      case 'progress':
        actA = this.progress;
        table = this.table_progress;
        break;
      case 'soon':
        actA = this.soon;
        table = this.table_soon;
        break;
    }
    let now = new Date();
    this.calendarService.start(this.token, act.calendar_id, { start: { start_time: moment(now).format() } }).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa("Attività iniziata");
        actA.forEach((acta) => {
          if (acta.calendar_id == res.values.calendar_id) {
            acta.start_time = res.values.start_time;
            acta.activity_time_id = res.values.activity_time_id
            acta.end_time = '';
            acta.state = res.values.cstate;
            acta.startTime = this.globals.cleanDateAndHour(acta.start_time);

            table.rows().every(function (rowIdx, tableLoop, rowLoop) {
              var data = this.data();
              if (acta.calendar_id == data['calendar_id']) {
                data['start_time'] = _self.globals.cleanDateAndHour(acta.start_time);
                data['end_time'] = '';
                data['state'] = acta.cstate;

                this.data(data);
                $(`#${data['calendar_id']}`).removeClass('greenButton');
                $(`#${data['calendar_id']}`).addClass('btn-danger');
                let text = 'stop'
                $(`#${acta.calendar_id + '-A'}`).html(`${text}`);
              }
            })
          }
        })
        this.spinner.hide();
      }, err => {
        this.globals.messageServer(err);
        console.error("Error on stop activity", err);
        this.spinner.hide();
      }
    )
  }

  stop(act, type) {
    this.spinner.show();
    let _self = this;
    let now = new Date();
    let obj = {
      end_time: moment(now).format()
    }

    let actA = [];
    let table: any;
    switch (type) {
      case 'late':
        actA = this.late;
        table = this.table_late;
        break;
      case 'progress':
        actA = this.progress;
        table = this.table_progress;
        break;
      case 'soon':
        actA = this.soon;
        table = this.table_soon;
        break;
    }

    this.calendarService.stop(this.token, act.calendar_id, { stop: obj }).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa("Attività stoppate", res);
        actA.forEach((acta) => {
          if (acta.activity_time_id == res.values.activity_time_id) {
            acta.start_time = res.values.start_time;
            acta.end_time = res.values.end_time;
            acta.state = res.values.cstate;
            acta.startTime = this.globals.cleanDateAndHour(acta.start_time);
            if (acta.end_time) {
              acta.endTime = this.globals.cleanDateAndHour(acta.end_time);
            }
            table.rows().every(function (rowIdx, tableLoop, rowLoop) {
              var data = this.data();
              if (acta.calendar_id == data['calendar_id']) {
                data['end_time'] += _self.globals.cleanDateAndHour(acta.end_time);
                data['state'] = acta.cstate;

                this.data(data);
                $(`#${data['calendar_id']}`).removeClass('btn-danger');
                $(`#${data['calendar_id']}`).addClass('greenButton');
                let text = 'play_arrow'
                $(`#${acta.calendar_id + '-A'}`).html(`${text}`);
              }
            })
          }
        })
        this.spinner.hide();
      }, err => {
        this.globals.messageServer(err);
        console.error("Error on stop activity", err);
        this.spinner.hide();
      }
    )
  }

  openActivity(act, onlyShow?) {
    this.globals.stampa("Activity: ", act)
    this.spinner.show();
    if (!onlyShow) {
      onlyShow = false;
    }
    const dialogRef = this.dialog.open(AddeventComponent, {
      width: '70%',
      // height: '80%',
      autoFocus: false,
      data: { start: moment(act.start_date).format(), end: moment(act.end_date).format(), eventTypes: act.type_id, state: act.cstate, onlyShow, new: false, event: { calendar_id: act.calendar_id, calendar_name: act.calendar_name, description: act.description, type_id: this.globals.activityTypeId }, default_type: act.type_id, onlyRicorrenza: false, }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getActivity();
    });
  }

  openListActivityTime(act, onlyShow?) {
    // this.globals.stampa("Activity: ", act)
    this.spinner.show();
    if (!onlyShow) {
      onlyShow = false;
    }
    const dialogRef = this.dialog.open(ActivityTimeUserListComponent, {
      width: '70%',
      // height: '80%',
      data: { calendar_id: act.calendar_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getActivity();
    });
  }

  canceling() {
    this.dialogRef.close('cancel');
  }

}
