import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { User, Notification } from '../classes';
import { ToastService } from '../services/toast.service';
import { environment } from '@environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';

declare const $: any;

@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  /* Global variables */
  tokenName: string = 'KEMATA_JWT';
  storagedUser: any = {};
  user: User;
  header: Array<any> = [];
  enviroVar = environment;
  check: boolean = false;
  sidebarVisible: boolean = false;
  mobile_menu_visible: any = 0;
  activityNumber: number = 0;
  notify: Notification;
  password_changed: any;
  notifyInterval: any;

  constructor(
    private cookie: CookieService,
    private http: HttpClient,
    private router: Router,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) {
    this.notify = new Notification();
    if (localStorage.getItem('header'))
      this.header = JSON.parse(localStorage.getItem('header'));
    if (localStorage.getItem('user'))
      this.storagedUser = JSON.parse(localStorage.getItem('user'));

  }

  stampa(message?: any, ...optionalParams: any[]) {
    if (this.enviroVar.log) {
      if (message) {
        console.log(message, ...optionalParams)
      } else {
        console.log(...optionalParams)
      }
    }
  }

  checkPage(id) {
    this.check = false;
    this.header.forEach(head => {
      if (head.key == id) {
        this.check = true;
      }
    });
    if (!this.check) {
      this.goToHome();
    }
  }

  getToken(login?) {
    let tk: string = this.cookie.get(this.tokenName);
    return tk;
  }

  stopInterval() {
    clearInterval(this.notifyInterval);
  }

  getNotifications() {
    const token = this.cookie.get(this.tokenName);
    this.getsNotifications(token).subscribe(
      res => {
        this.messageServer(res);
        this.activityNumber = res.activityNumber;
        this.notify = res.notify;
      },
      err => {
        console.error("Errore in getNotifications: ", err);
        this.messageServer(err);
      }
    )
  }

  getNotify() {
    this.notifyInterval = setInterval(() => {
      const token = this.cookie.get(this.tokenName);
      if (token != '') {
        this.getNotifications();
        // this.stampa("Notifiche: ", this.notify)
      }
      // this.stampa("No notifiche ma si interval");

    }, 35000);
  }

  checkLogin() {
    const token = this.cookie.get(this.tokenName);
    if (token) {
      this.checkToken(token)
        .subscribe(
          resp => {
            this.stampa(resp);
            this.user = resp.user;
            this.goToHome();
          },
          err => console.error(err)
        );
    }
  }

  showNotification(message: string, type: string) {
    let icon = 'check';

    if (type == 'info') {
      icon = 'add_alert';
    } else if (type == 'success') {
      icon = 'check';
    } else if (type == 'error') {
      icon = 'error_outline';
    } else if (type == 'warning') {
      icon = 'warning';
    }

    $.notify({
      icon: icon,
      message: message
    }, {
      type: type,
      timer: 1000,
      placement: {
        from: 'top',
        align: 'right'
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  cleanDate(data) {
    let date = new Date(data);
    if (date.getMonth() + 1 >= 10 && date.getDate() >= 10) {
      data = '' + (date.getDate()) + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
      // this.stampa("mese e giorno >10" , data)
    } else {

      if (date.getMonth() + 1 < 10 && date.getDate() < 10) {
        data = '0' + (date.getDate()) + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear();
        // this.stampa("mese e giorno <10", data)
      } else {

        if (date.getMonth() + 1 < 10) {
          data = '' + (date.getDate()) + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear();
          // this.stampa("mese <10", data)
        }

        if (date.getDate() < 10) {
          data = '0' + (date.getDate()) + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
          // this.stampa("giorno <10", data)
        }
      }
    }
    return data;
  }

  getOnlyTime(data) {
    let date = new Date(data);
    let minuts;
    let hours;
    if (date.getMinutes() < 10) {
      minuts = `0${date.getMinutes()}`
    } else {
      minuts = date.getMinutes();
    }
    if (date.getHours() < 10) {
      hours = `0${date.getHours()}`
    } else {
      hours = `${date.getHours()}`
    }
    return `${hours}:${minuts}`;
  }

  cleanDateAndHour(data) {
    let date = new Date(data);
    let minuts;
    if (date.getMinutes() < 10) {
      minuts = `0${date.getMinutes()}`
    } else {
      minuts = date.getMinutes();
    }
    if (date.getMonth() + 1 >= 10 && date.getDate() >= 10) {
      data = '' + (date.getDate()) + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' ' + (date.getHours()) + ':' + minuts;
      // this.stampa("mese e giorno >10" , data)
    } else {

      if (date.getMonth() + 1 < 10 && date.getDate() < 10) {
        data = '0' + (date.getDate()) + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' ' + (date.getHours()) + ':' + minuts;
        // this.stampa("mese e giorno <10", data)
      } else {

        if (date.getMonth() + 1 < 10) {
          data = '' + (date.getDate()) + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' ' + (date.getHours()) + ':' + minuts;
          // this.stampa("mese <10", data)
        }

        if (date.getDate() < 10) {
          data = '0' + (date.getDate()) + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' ' + (date.getHours()) + ':' + minuts;
          // this.stampa("giorno <10", data)
        }
      }
    }
    return data;
  }


  createLocalSearch(obj) {
    let search = '';
    let ArrayProp = []
    for (let prop in obj) {
      ArrayProp.push(prop.toString());
    }
    if (ArrayProp.length == 1) {
      search += `${obj[ArrayProp[0]]}`;
    }
    if (ArrayProp.length == 2) {
      search += `${obj[ArrayProp[0]]} ${obj[ArrayProp[1]]} ${obj[ArrayProp[0]]}`;
    }
    if (ArrayProp.length == 3) {
      search += `${obj[ArrayProp[0]]} ${obj[ArrayProp[1]]} ${obj[ArrayProp[2]]} ${obj[ArrayProp[1]]} ${obj[ArrayProp[0]]} ${obj[ArrayProp[2]]} ${obj[ArrayProp[0]]} ${obj[ArrayProp[2]]} ${obj[ArrayProp[1]]} ${obj[ArrayProp[2]]} ${obj[ArrayProp[0]]} ${obj[ArrayProp[1]]}`;
    }


    return search;

  }


  checkToken(token): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.checkTokenURL, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /* SERVICES */
  getsNotifications(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.getNotificationsURL, httpOptions).pipe(
      catchError(this.handleError)
    )
  }


  handleError(error: HttpErrorResponse) {
    console.error("Errore: ", error);
    if (error.error instanceof ErrorEvent) {
      console.error("Errore in handel: ", error.error);

      // this.messageServer(error.error);

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error("Errore in handel: ", error.error);

      // this.messageServer(error.error);
      // this.stampa("Error status: ", error.status);

      if (error.status == 0) {
        console.error("IMPOSSIBILE CONNETTERSI AL SERVER");
      }
      else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
    }
    // return an observable with a user-facing error message
    return throwError(error.error);
  };

  messageServer(res) {
    /*
    legenda code:

    Error:
    100 -> no action
    101 -> stampa messaggio di errore


    Success:
    200 -> no action
    201 -> stampa messaggio di successo
    211 -> token scaduto


    in aggiornamento...
    */

    if (res && res.code) {
      res.code = res.code ? res.code : 0;
      if (res.code == 101) {
        this.showNotification(res.message, 'danger');
      }

      if (res.code == 201) {
        this.showNotification(res.message, 'success');
      }

      if (res.code == 202) {
        this.showNotification(res.message, 'warning');
      }

      if (res.code == 211) {
        this.showNotification(res.message, 'warning');
        this.dialog.closeAll();
        this.goToLogin();
        this.removeCache();
        this.spinner.hide();
      }
    }
  }

  server = this.enviroVar.server;
  port = this.enviroVar.port;
  base = `${this.enviroVar.http}://${this.server}${this.port}`;

  // CheckToken
  checkTokenURL = `${this.base}/login/checktoken`;
  // Login
  loginURL = `${this.base}/login`;
  // secondLogin
  secondLoginURL(id) {
    return `${this.base}/userlogin/${id}`;
  }
  // Logout
  logoutURL = `${this.base}/logout`;
  // Anagrafica
  getTypesURL = `${this.base}/operator/types`;
  getRegistryURL = `${this.base}/operator/registry`;
  getUpdatePersonURL(id) {
    return `${this.base}/operator/updateperson/${id}`;
  }
  addPersonURL = `${this.base}/operator/addperson`;
  getDelPersonURL(id) {
    return `${this.base}/operator/delperson/${id}`;
  }

  //Documentary
  getRoot = `${this.base}/alfresco/root`;
  getFolderContent(id) {
    return `${this.base}/alfresco/folder/${id};`;
  }
  newFolder(parentF, nameF) {
    return `${this.base}/alfresco/newfolder/${parentF}/${nameF}`;
  }
  deleteFolder(id) {
    return `${this.base}/alfresco/delfolder/${id}`;
  }
  updateFolderName(id) {
    return `${this.base}/alfresco/changefoldername/${id}`;
  }

  getUploadFileURL(id) {
    return `${this.base}/alfresco/uploadfile/${id};`;
  }
  getNewFolderURL(id) {
    return `${this.base}/alfresco/newfolder/${id};`;
  }


  //setNodePermission
  setNodePermission(id) {
    return `${this.base}/alfresco/setnodeauth/${id}`;
  }
  //getNodePermission
  getNodePermission(id) {
    return `${this.base}/alfresco/getnodeauth/${id}`
  }

  //UploadFile
  uploadFile(id) {
    return `${this.base}/alfresco/uploadfile/${id}`
  }

  //getContent
  getContent(id) {
    return `${this.base}/alfresco/getcontent/${id}`
  }

  getGroup(id) {
    return `${this.base}/group/${id}`
  }

  //TICKET
  newTicket = `${this.base}/ticket/new`
  newTicketFile(id) {
    return `${this.base}/ticket/new/file/${id}`
  }
  getTickets = `${this.base}/ticket/tickets`

  getTicket(id) {
    return `${this.base}/ticket/${id}`
  }

  assignTicket(ticketId, userId) {
    return `${this.base}/ticket/${ticketId}/assign/${userId}`
  }

  getOperatorAssigned(id) {
    return `${this.base}/ticket/${id}/operators`
  }

  getTicketsCustomer(id) {
    return `${this.base}/ticket/${id}/customers`
  }

  setStatus(id) {
    return `${this.base}/ticket/${id}/status`;
  }
  sendCommunication(id) {
    return `${this.base}/ticket/${id}/communication`
  }

  getTicketCommunications(id) {
    return `${this.base}/ticket/${id}/communications`
  }

  deleteTicket(id) {
    return `${this.base}/ticket/del/${id}`
  }

  changeTicketPositionInStatus(id) {
    return `${this.base}/ticket/drop/in/${id}`
  }

  changeTicketPositionOutStatus(id) {
    return `${this.base}/ticket/drop/out/${id}`
  }

  getOperatorTickets = `${this.base}/ticket/get/operatorticket`

  getGetTicketActivityURL(id) {
    return `${this.base}/ticket/activity/${id}`
  }

  //Group
  newGroupURL = `${this.base}/group/new`;
  getGroupsURL = `${this.base}/groups`;
  getGetGroupURL(id) {
    return `${this.base}/group/${id}`;
  }
  getUpdateGroupNameURL(id) {
    return `${this.base}/group/updatename/${id}`;
  }
  getAddMembersToGroupURL(id) {
    return `${this.base}/group/${id}/addmember`;
  }
  getDelMembersGroupURL(id) {
    return `${this.base}/group/${id}/removemember`;
  }
  getDeleteGroupURL(id) {
    return `${this.base}/group/${id}/delete`;
  }
  getOperatorsGroupsURL = `${this.base}/group/list/operators`;
  //setGroups
  // updateGroup

  // Homrpsgr
  getHomePageURL = `${this.base}/homepage/home`;
  //Operator
  getOperator = `${this.base}/operator/getusers`;
  //newOperator
  addOperator = `${this.base}/operator/newuser`;
  //richiedi profili
  getProfile = `${this.base}/profiles`;
  getCustomerProfile = `${this.base}/customer/profiles`
  getOperatorProfile = `${this.base}/operator/profiles`;
  operatorDisponibilityURL = `${this.base}/operator/free`;
  getOperatorPageURL = `${this.base}/operator/initpage`;
  //delete profili
  deleteProfile(id) {
    return `${this.base}/deluser/${id}`;
  }
  updateUser(id) {
    return `${this.base}/operator/updateuser/${id}`;
  }

  //company
  getCompanyURL = `${this.base}/operator/company/info`;

  updateCompany() {
    return `${this.base}/operator/company/info`;
  }
  addEmail() {
    return `${this.base}/operator/company/addemail`;
  }
  deleteEmail(id) {
    return `${this.base}/operator/company/delemail/${id}`;
  }
  addAddress() {
    return `${this.base}/operator/company/addaddress`;
  }
  updateCompanyAddress() {
    return `${this.base}/operator/company/upaddress`;
  }
  deleteCompanyAddress(id) {
    return `${this.base}/operator/company/deladdress/${id}`;
  }

  //UserProfile
  getUserProfileURL = `${this.base}/user/profile`;

  updatePassword() {
    return `${this.base}/user/password/changepassword`;
  }


  updateupdateUserProfile() {
    return `${this.base}/user/profile`;
  }
  updateupdateImageProfile() {
    return `${this.base}/user/profile/image`;
  }

  //Customer
  downloadPFExcelURL = `${this.base}/customers/from/template/pf`
  downloadPGExcelURL = `${this.base}/customers/from/template/pg`
  getCustomer = `${this.base}/getcustomers`;
  getGetCustomer(id) {
    return `${this.base}/customer/get/${id} `;
  }

  //addCustomer
  addCustomer = `${this.base}/addcustomer`;
  //updateCustomer
  updateCustomer(id) {
    return `${this.base}/updatecustomer/${id}`;
  }

  //deleteCustomer
  deleteCustomer(id) {
    return `${this.base}/delcustomer/${id}`;
  }

  customerDisponibilityURL = `${this.base}/customers/free`;

  getAddressTypesURL = `${this.base}/customer/address/types`;
  getNextIdCusURL = `${this.base}/customer/next/id`
  //Address
  addAddressURL = `${this.base}/customer/address/add`;
  updateAddress(id) {
    return `${this.base}/customer/address/update/${id}`;
  }
  deleteAddress(id) {
    return `${this.base}/customer/address/del/${id}`;
  }



  //User
  newUser = `${this.base}/customer/newuser`;
  //Get user of customer
  getUser(id) {
    return `${this.base}/customer/getusers/${id}`;
  }
  //Set user
  setUser(id) {
    return `${this.base}/customer/setuser/${id}`;
  }

  getUserCustomer = `${this.base}/user/usercustomer`;
  //Get all user
  getUAllUser = `${this.base}/users/customer`;

  // Account
  getGetInactiveAccountURL(id) {
    return `${this.base}/account/inactive/${id}`;
  }
  updateAccountURL = `${this.base}/account/update`;
  getProfileURL = `${this.base}/account/profile`;

  updateUserCustomer = `${this.base}/user/updateusercustomer`;

  getCustomerAssociati(id) {
    return `${this.base}/user/usercustomerprofile/${id}`;
  }

  setCustomer(id) {
    return `${this.base}/user/setcustumers/${id}`;
  }


  // CONTRACT
  getNewContractURL = `${this.base}/contract/new`;
  getGetContractsURL = `${this.base}/contract/list`;
  getContractURL(id) {
    return `${this.base}/contract/${id}`;
  }
  updateContractURL(id) {
    return `${this.base}/contract/${id}`;
  }
  deleteContractURL(id) {
    return `${this.base}/contract/${id}`;
  }
  getAssocContractToCustomerURL(contractId) {
    return `${this.base}/contract/${contractId}/assoctocustomers`;
  }
  getAssocedCustomerToContractURL(customer_id) {
    return `${this.base}/contract/${customer_id}/get`
  }
  getAssocedOperatorToContractURL(contractId) {
    return `${this.base}/ticket/${contractId}/operators`

  }

  // Upload excel
  uploadExcelURL = `${this.base}/customer/add/fromexcel`;

  // PROCESS

  // getNewProccesURL = `${this.base}/procces/new`;
  getNewProcessURL = `${this.base}/process/new`;
  getProccesTemplateURL = `${this.base}/process/templates`;
  getInstancesURL = `${this.base}/process/instances`;
  getSaveProcessURL(process_id) {
    return `${this.base}/process/update/${process_id}`;
  }
  getDeleteProcessURL(process_id) {
    return `${this.base}/process/delete/${process_id}`;
  }
  getGetProccesURL = `${this.base}/procces/list`;
  getRunProccesCusURL(id) {
    return `${this.base}/bpmn/start/cus/${id}`;
  }
  getRunProccesGroupURL(id) {
    return `${this.base}/bpmn/start/group/${id}`;
  }
  getProccesInstanceURL(id) {
    return `${this.base}/bpmn/procces/instance/${id}`;
  }
  getTypeDeadline(id) {
    return `${this.base}/procces/deadline/${id}/type`;
  }
  getInputInstance = `${this.base}/bpmn/input/test`;

  // calendar
  getEventsURL = `${this.base}/calendar/event/list`;
  addEventURL = `${this.base}/calendar/new`;
  resizeEventURL = `${this.base}/calendar/resize/event`;
  storActivityURL(id) {
    return `${this.base}/calendar/event/filed/${id}`;
  }
  getEventParticipantsURL(id) {
    return `${this.base}/calendar/get/participants/${id}`;
  }
  getDeadlineList() {
    return `${this.base}/calendar/deadline/list`;
  }
  updateEventURL(id) {
    return `${this.base}/calendar/update/${id}`;
  }

  updateSingleDeadlineURL(id) {
    return `${this.base}/calendar/single/deadline/update/${id}`;
  }

  updateDeadlineURL(id) {
    return `${this.base}/calendar/deadline/update/${id}`;
  }

  deleteEventURL(id) {
    return `${this.base}/calendar/del/${id}`;
  }

  deleteDeadlineURL(id) {
    return `${this.base}/calendar/deadline/del/${id}`;
  }

  deleteSingleDeadlineURL(id) {
    return `${this.base}/calendar/single/deadline/del/${id}`;
  }

  getStartStopURL(cid, uid) {
    return `${this.base}/calendar/${cid}/startstop/${uid}`;
  }

  startURL(calendar_id) {
    return `${this.base}/calendar/activity/start/${calendar_id}`;
  }

  stopURL(calendar_id) {
    return `${this.base}/calendar/activity/stop/${calendar_id}`;
  }

  setDeadline = `${this.base}/calendar/deadline/new`;

  getListActivity(id) {
    return `${this.base}/calendar/list/activity/${id}`;
  }

  deleteActivityTime(id) {
    return `${this.base}/calendar/activitytime/${id}`;
  }

  updateActivityTime(id) {
    return `${this.base}/calendar/activitytime/${id}`;
  }

  addActivityTime() {
    return `${this.base}/calendar/activitytime/add`;
  }


  getFulfillment(id) {
    return `${this.base}/calendar/${id}/fulfillment`;
  }

  //GetActivity
  getActivity(id) {
    return `${this.base}/calendar/openactivity/get/${id}`;
  }

  //NOTIFICATION
  //Open activity
  getNotificationsURL = `${this.base}/user/notification`;
  getNotifivationReadURL(id) {
    return `${this.base}/notification/read/${id}`
  }
  getNotificationTypeURL = `${this.base}/notification/types`;

  // FULFILLMENT
  getGetFulfillmentsURL = `${this.base}/fulfillment`;

  deleteFulfillment(id: number) {
    return `${this.base}/fulfillment/delete/${id}`;
  }

  getUpdateFulfillmentsURL(id: number) {
    return `${this.base}/fulfillment/update/${id}`;
  }

  newFulfillmentURL = `${this.base}/fulfillment/new`;

  getRecipientsList(calendar_id: number) {
    return `${this.base}/calendar/${calendar_id}/recipients`;
  }

  /* ROOT */

  // PREIMPOSTATE
  goToHome() {
    this.router.navigate(['/homepage']);
  }
  goToLogin() {
    this.router.navigate(['/login']);
  }
  // GENERALE
  goTo(location: string) {
    this.router.navigate([`/${location}`]);
  }


  /* ERROR SNIFFER */
  errorSniffer(err) {
    let tk = this.tokenName
    let errToken = err.code.indexOf("JWT");
    if (errToken > -1) {
      console.error(err);
      this.dialog.closeAll();
      this.goToLogin();
      this.cookie.delete(tk)
      this.showNotification("La tua sessione è scaduta, effettua nuovamente il login", 'danger');
    }
    this.spinner.hide();
  }

  removeCache() {
    let tk = this.tokenName
    this.cookie.delete(tk)
    localStorage.removeItem('header');
    localStorage.removeItem('user');
  }

  // Servizi homepage
  getHome(token): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.getHomePageURL, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  activityTypeId: number = 1;
  deadlineTypeID: number = 2;
  eventTypeId: number = 3
}
