import { Component, OnInit } from '@angular/core';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { GlobalService } from 'src/app/services/global.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS, MatProgressSpinner } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';

declare interface DataTable {
  headerRow: string[];
  // footerRow: string[];
}

@Component({
  selector: 'app-excelupload',
  templateUrl: './excelupload.component.html',
  styleUrls: ['./excelupload.component.scss']
})
export class ExceluploadComponent implements OnInit {
  public uploadTable: DataTable;

  //string
  token: string

  //formData
  uploadForm: FormGroup;
  formData: any;
  nameList: Array<any> = [];

  //array
  file: Array<any> = [];
  faDownload = faDownload;

  //Boolean
  disable: boolean = true;

  constructor(
    private upload: UploadFileService,
    private globals: GlobalService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ExceluploadComponent>,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.uploadTable = {
      headerRow: ['Descrizione', 'Azione']
    }
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
    this.spinner.hide();
  }

  onFileSelectF(event) {
    let amount = event.target.files.length
    this.globals.stampa("Test F: ", event.target.files);
    if (amount > 0 && event.target.files[0]) {
      this.file[0] = event.target.files[0]
      this.globals.stampa(this.file);

    }

    Array.from(event.target.files).forEach(file => {
      this.nameList.push(file)
    })
    this.disable = false;
  }

  onFileSelectG(event) {
    let amount = event.target.files.length;
    this.globals.stampa("Test G: ", event.target.files);

    if (amount > 0 && event.target.files[0]) {
      this.file[1] = event.target.files[0];
      this.globals.stampa(this.file);
    }
    Array.from(event.target.files).forEach(file => {
      this.nameList.push(file)
    })
    this.disable = false;
  }

  uploadFile() {
    this.globals.stampa("Upload init");
    this.uploadForm.get('profile').setValue(this.file);
    const formData = new FormData();
    this.globals.stampa("formData creato");
    if (this.file[0] || this.file[1]) {
      this.spinner.show()
      if (this.file[0]) {
        formData.append('filepf', this.file[0], this.file[0].name);
      }
      if (this.file[1]) {
        formData.append('filepg', this.file[1], this.file[1].name);
      }


      this.globals.stampa("Campoi inseriti");
      this.globals.stampa('Form Data: ', formData);
      this.upload.uploadExcel(this.token, formData).subscribe(
        res => {
          this.globals.messageServer(res);
          this.globals.stampa(res)
          this.close();
          this.spinner.hide();
        },
        err => {
          console.error(err)
          this.spinner.hide();
          this.globals.messageServer(err);
        }
      );
    } else {
      this.globals.stampa("Nessun file caricato");
      this.globals.showNotification("Nessun file selezionato", "warning");
    }

  }

  svuota() {
    this.file = null;
    this.nameList = [];
    this.disable = true;
    this.globals.stampa(this.file);

  }

  canceling() {
    this.dialogRef.close('cancel');
  }

  close() {
    this.dialogRef.close();
  }

  downloadPF() {
    this.upload.downloadPFExcel(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa("Risposta: ", res);
        let extension = 'xlsx';
        let url;
        let a = document.createElement('a');
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, `Persone fisiche.${extension}`);
        } else {
          url = URL.createObjectURL(res);
        }

        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Persone fisiche.${extension}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      err => {
        console.error("Errore: ", err);
        this.spinner.hide();
        this.globals.messageServer(err);
      }
    )
  }

  downloadPG() {
    this.upload.downloadPGExcel(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa("Risposta: ", res);
        let extension = 'xlsx';
        let url;
        let a = document.createElement('a');
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, `Persone giuridiche.${extension}`);
        } else {
          url = URL.createObjectURL(res);
        }
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Persone giuridiche.${extension}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      err => {
        console.error("Errore: ", err);
        this.spinner.hide();
        this.globals.messageServer(err);
      }
    )
  }


}
