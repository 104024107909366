import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { environment } from '@environment';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';

import { GlobalService } from 'src/app/services/global.service';
import { GroupService } from 'src/app/services/group.service';
import { CustomerService } from 'src/app/services/customer.service';
import { OperatorService } from 'src/app/services/operator.service';
import { Participants, Customer, User } from 'src/app/classes';
import { EliminaService } from 'src/app/services/elimina.service';

declare const $: any;


@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})

export class GroupComponent implements OnInit {

  myControlOp = new FormControl();
  myControlCus = new FormControl();
  myControlGrp = new FormControl();
  filteredOperator: Observable<any[]>;
  filteredCustomer: Observable<any[]>;
  filteredGroups: Observable<any[]>;

  op: any;
  cus: any;
  grp: any;

  envi = environment;

  myControl = new FormControl();
  filteredOptions: Observable<User[]>;

  //string
  token: string;
  tempID: string;
  tempAlfrescoID: string;

  //boolean
  choose: boolean = false;
  newGrou = false;
  del = false;
  deleteUser = false;
  new = false;
  groupNew = false;
  action = false;
  addList = false;
  nome: boolean;
  isOnlyRead = false;
  close: boolean = true;

  //temp
  tempUser: any;
  deleteGroups: any = [];
  deleteOperators: any = [];
  deleteCustomer: any = [];
  addGroups: any = [];
  addOperators: any = [];
  addCustomer: any = [];
  participants: Participants;
  groups: Array<any>;
  tableGroups: Array<any>;
  activeUser: User;
  selectedGroup: any;
  customers: Array<Customer>;
  operators: Array<User>;
  view_groups: Array<any>;

  constructor(
    private groupService: GroupService,
    private globals: GlobalService,
    private customerService: CustomerService,
    private operatorService: OperatorService,
    private spinner: NgxSpinnerService,
    private Delete: EliminaService,

  ) {
    this.participants = new Participants();
    this.globals.stampa("partecipanti: ", this.participants);
  }

  ngOnInit() {
    this.globals.checkPage(7);
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.spinner.show();
    this.getGroups();
    this.getOperators();
    this.getCustomers();
    this.activeUser = this.globals.storagedUser;
    this.isOnlyRead = false;
  }

  reload() {
    this.spinner.show();
    $('#datatablesGroup').DataTable().destroy();
    this.close = true;
    this.isOnlyRead = false;
    this.groupNew = false;
    this.deleteGroups = []
    this.deleteOperators = []
    this.deleteCustomer = []
    this.addCustomer = [];
    this.addGroups = [];
    this.addOperators = [];
    this.newGrou = false;
    this.action = false;
    this.new = false;
    this.deleteUser = false;
    this.participants.customers = [];
    this.participants.operators = [];
    this.participants.groups = [];
    this.selectedGroup = {};
    this.selectedGroup.customers = [];
    this.selectedGroup.operators = [];
    this.selectedGroup.groups = [];
    this.del = false;
    // this.groupNew = true;
    this.tempUser = null;
    this.choose = false;
    this.getGroups();
    this.getOperators();
    this.getCustomers();
  }

  getGroups() {
    let _self = this;
    this.spinner.show();
    this.groupService.getGroups(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.new = false;
        this.deleteUser = false;
        new Promise((resolve, reject) => {
          this.groups = res;
          this.view_groups = res;
          this.tableGroups = this.groups.slice()
          this.globals.stampa(this.groups);
          this.groups.forEach((group) => {
            group.view_creatat = moment(group.createdat).format('DD-MM-YYYY');
            if (group.group_name == 'clienti' || group.group_name == 'operatori') {
              group.visible = false;
              this.tableGroups.splice(this.tableGroups.indexOf(group), 1);
            } else {
              group.visible = true;
            }
          })

          this.tableGroups.forEach(group => {
            group.createdat = this.globals.cleanDate(group.createdat);
          });

          this.tableGroups.forEach(group => {
            group.updatedat = this.globals.cleanDate(group.updatedat);
          });

          // Creo il campo descrizione per il campo di ricerca
          this.tableGroups.forEach(group => {
            group.searchField = `${group.group_name} ${group.group_name}`;
          })
          resolve();
        }).then(() => {

          let _self = this;
          let groupss = [];
          let tableObject = {
            data: []
          }
          this.groups.forEach((grp) => {
            let obj = {
              group_id: grp.group_id,
              group_name: grp.group_name,
              description: grp.description,
              view_creatat: grp.view_creatat,
            }
            groupss.push(obj);
          });
          tableObject.data = groupss;
          let config = {
            pagingType: "full_numbers",
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "All"]
            ],
            responsive: {
              details: {
                type: 'column',
                target: 'tr'
              }
            },
            language: {
              search: "_INPUT_",
              searchPlaceholder: "Ricerca gruppi",
              url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
            },
            ajax: (data, callback, settings) => {
              callback(tableObject)
            },
            columns: [
              { title:'ID', data: 'group_id' },
              { title:'Nome del gruppo', data: 'group_name' },
              { title:'Descrizione', data: 'description' },
              { title:'Data creazione', data: 'view_creatat' },
              { title:'Azione', data: null, className: 'td-actions text-right' }
            ],
            columnDefs: [
              {
                targets: [0],
                visible: false
              },
              {
                targets: [4],
                visible: true,
                render: function (data, type, full, meta) {
                  if (full.group_name == 'operatori' || full.group_name == 'clienti') {
                    full = JSON.stringify(full);
                    return `<a data-grp='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info" style="margin: auto;">
                          <i class="material-icons">search</i>
                        </a>`
                  } else {
                    full = JSON.stringify(full);
                    return `<a data-grp='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                          <i class="material-icons">search</i>
                        </a>
                        <a data-grp='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                          <i class="material-icons">edit</i>
                        </a>
                        <a data-grp='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                          <i class="material-icons">close</i>
                        </a>`
                  }
                }
              }
            ]
          }
          $(document).ready(function () {
            this.table = $('#datatablesGroup').DataTable(config);

            $('#datatablesGroup').on('click', 'a.btn-info', function () {
              let GRP: any = $(this).data('grp')
              _self.globals.stampa("Op: ", GRP);
              _self.groups.forEach((grp) => {
                if (grp.group_id == GRP.group_id) {
                  _self.showForm(grp, true);
                }
              })
            });
            $('#datatablesGroup').on('click', 'a.btn-success', function () {
              // _self.globals.stampa($(this).data('cus'));
              let GRP = $(this).data('grp')
              _self.globals.stampa("Op: ", GRP);
              _self.groups.forEach((grp) => {
                if (grp.group_id == GRP.group_id) {
                  _self.showForm(grp);
                }
              })
            });
            $('#datatablesGroup').on('click', 'a.btn-danger', function () {
              // _self.globals.stampa($(this).data('cus'));
              let GRP = $(this).data('grp')
              _self.globals.stampa("Op: ", GRP);
              _self.groups.forEach((grp) => {
                if (grp.group_id == GRP.group_id) {
                  _self.deleteGroup(grp);
                }
              })
            });
            _self.spinner.hide();
          })

        }).catch((err) => {
          console.error("Error on get  Customers or Operators", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        })
        this.groups.forEach(grp => {
          grp.search = this.globals.createLocalSearch({ name: grp.group_name })
        });
        this.spinner.hide();
        _self.filteredGroups = _self.myControlGrp.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value Op: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search Op: ", search);
            return search ? _self._filter(search, _self.groups) : _self.groups.slice()
          })
        );
        this.spinner.hide()
      },
      err => {
        this.spinner.hide();
        console.error("Errore in getOperators", err);
        this.globals.messageServer(err);
      }
    )
  }

  newGroup() {
    this.close = true;
    this.isOnlyRead = false;
    this.groupNew = false;
    this.deleteUser = false;
    this.action = false;
    this.new = false;
    this.deleteUser = false;
    this.participants.customers = [];
    this.participants.operators = [];
    this.participants.groups = [];
    this.del = true;
    this.choose = true;
    this.newGrou = true;
    this.op = '';
    this.cus = '';
    this.grp = '';
    this.selectedGroup = {};
    new Promise((resolve, reject) => {
      this.getCustomers();
      this.getOperators();
      resolve();
    }).then(() => {
    }).catch((err) => {
      console.error("Error on get  Customers or Operators", err);
      this.globals.messageServer(err);
    })
  }

  displayFnCus(cus: Customer): string {
    return cus && `${cus.view_name}`;
  }

  getCustomers() {
    let _self = this;
    this.spinner.show();
    this.customerService.getCustomer(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.customers = res;
        this.new = false;
        this.deleteUser = false;
        this.customers.forEach(cus => {
          if (cus.type == 'GIU') {
            cus.search = this.globals.createLocalSearch({ name: cus.view_name, business_name: cus.business_name })
          } else {
            cus.search = this.globals.createLocalSearch({ view_name: cus.view_name, name: cus.name, last_name: cus.last_name });
          }
        });
        _self.filteredCustomer = _self.myControlCus.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value Cus: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search Cus: ", search);
            return search ? _self._filter(search, _self.customers) : _self.customers.slice()
          })
        );
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        console.error("Errore: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  private _filter(name: string, array: Array<any>): User[] {
    this.globals.stampa("Da ciclare: ", array)
    const filterValue = name.toLowerCase();
    this.globals.stampa("NameOp: ", name);

    return array.filter(
      ele => {
        this.globals.stampa("Ris cerca: ", ele.search.toLowerCase().indexOf(filterValue))
        return ele.search.toLowerCase().indexOf(filterValue) >= 0
      }
    );
  }

  getOperators() {
    let _self = this;
    this.spinner.show();
    this.operatorService.getOperator(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.operators = res;
        this.new = false;
        this.deleteUser = false;
        this.operators.forEach(op => {
          op.search = this.globals.createLocalSearch({ name: op.name, last_name: op.last_name, username: op.username })
        });
        this.spinner.hide();
        _self.filteredOperator = _self.myControlOp.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value Op: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search Op: ", search);
            return search ? _self._filter(search, _self.operators) : _self.operators.slice()
          })
        );
        this.spinner.hide();
      },
      err => {
        console.error("Errore: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      }
    )
  }

  pushNew(add) {
    if (add) {
      if (add.customer_id) {
        if (this.participants.customers.indexOf(add) >= 0) {
          this.globals.showNotification("Cliente già presente in lista", "warning");
          this.cus = '';
        } else {
          this.participants.customers.push(add);
          this.cus = '';
        }
        document.getElementById("addCustomer").blur();
      }
      if (add.user_id) {
        if (this.participants.operators.indexOf(add) >= 0) {
          this.globals.showNotification("Operatore già presente in lista", "warning");
          this.op = '';
        } else {
          this.participants.operators.push(add);
          this.op = '';
        }
        document.getElementById("addOperator").blur();
      }
      if (add.group_id) {
        if (this.participants.groups.indexOf(add) >= 0) {
          this.globals.showNotification("Gruppo già presente in lista", "warning");
          this.grp = '';
        } else {
          this.participants.groups.push(add);
          this.grp = '';
        }
        document.getElementById("addGroup").blur();
      }

    } else {
      document.getElementById("addGroup").blur();
      document.getElementById("addOperator").blur();
      document.getElementById("addCustomer").blur();
      this.globals.showNotification("Non è stato selezionato nessun operatore", "warning");
    }
  }

  push(add) {
    this.globals.stampa("Add: ", add);
    let obj: any;
    let control: boolean = true;
    if (add) {
      if (add.customer_id) {
        this.participants.customers.forEach(cus => {
          if (cus.customer_id == add.customer_id) {
            control = false;
          }
        });
        if (control) {
          this.selectedGroup.customers.push(add);
          document.getElementById("addCustomer").blur();
          if (this.groupNew) {
            obj = {
              customer_id: add.customer_id,
              alfresco_id: add.alfresco_id
            }
            this.addCustomer.push(obj);
            this.cus = '';
          }
        } else {
          document.getElementById("addCustomer").blur();
          this.globals.showNotification("Cliente già presente in lista", "warning");
          this.cus = '';
        }
      }

      if (add.user_id) {
        this.participants.operators.forEach(op => {
          if (op.user_id == add.user_id) {
            control = false;
          }
        });
        if (control) {
          this.selectedGroup.operators.push(add);
          document.getElementById("addOperator").blur();
          if (this.groupNew) {
            obj = {
              user_id: add.user_id,
              alfresco_id: add.alfresco_id
            }
            this.addOperators.push(obj);
            this.op = '';
          }
        } else {
          document.getElementById("addOperator").blur();
          this.globals.showNotification("Operatore già presente in lista", "warning");
          this.op = '';
        }

      }
      if (add.group_id) {

        this.participants.groups.forEach(group => {
          if (group.group_id == add.group_id) {
            control = false;
          }
        });
        if (control) {
          this.selectedGroup.groups.push(add);
          document.getElementById("addGroup").blur();
          if (this.groupNew) {
            obj = {
              group_id: add.group_id,
              alfresco_id: add.alfresco_id
            }
            this.addGroups.push(obj);
            this.grp = '';
          }
        } else {
          document.getElementById("addGroup").blur();
          this.globals.showNotification("Gruppo già presente in lista", "warning");
          this.grp = '';
        }

      }
    }
  }

  addMemberGroup() {
    return new Promise((resolve, reject) => {
      let obj = {
        alfresco_id: this.tempAlfrescoID,
        customers: this.addCustomer,
        operators: this.addOperators,
        groups: this.addGroups
      }

      this.globals.stampa(obj);

      this.groupService.addMember(this.token, this.tempID, obj).subscribe(
        res => {
          this.globals.messageServer(res);
          resolve();
        },
        err => {
          console.error("Errore in addMemberGroup: ", err);
          this.globals.messageServer(err);
          reject(err);
        }

      )
    })

  }

  deleteNew(remove) {
    if (remove) {
      if (remove.customer_id) {
        if (this.participants.customers.indexOf(remove) < 0) {
          this.globals.stampa("Doesen't exist");

        } else {
          this.participants.customers.splice(remove, 1)
        }
      }
      if (remove.user_id) {
        if (this.participants.operators.indexOf(remove) < 0) {
          this.globals.stampa("Doesen't exist");

        } else {
          this.participants.operators.splice(remove, 1)
        }
      }
      if (remove.group_id) {
        if (this.participants.groups.indexOf(remove) < 0) {
          this.globals.stampa("Doesen't exist");

        } else {
          this.participants.groups.splice(remove, 1)
        }
      }
    }
  }

  deleteFull() {
    return new Promise((resolve, reject) => {
      let obj = {
        alfresco_id: this.tempAlfrescoID,
        customers: this.deleteCustomer ? this.deleteCustomer : [],
        operators: this.deleteOperators ? this.deleteOperators : [],
        groups: this.deleteGroups ? this.deleteGroups : []
      }

      this.globals.stampa(obj);

      this.groupService.delMember(this.token, this.tempID, obj).subscribe(
        res => {
          this.globals.messageServer(res);
          resolve(res);
        },
        err => {
          console.error("Errore in deleteFull: ", err);
          this.globals.messageServer(err);
          reject(err)
        }

      )
    })

  }

  save(name, desc) {

    if (name) {
      document.getElementById("groupName").innerHTML = "";
    } else {
      document.getElementById("groupName").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (name && desc.length <= 300 && name.length <= 100) {

      this.spinner.show()
      let obj = {
        group_name: name,
        description: desc
      }
      this.groupService.updateNameGroup(this.token, this.tempID, obj).subscribe(
        res => {
          this.globals.messageServer(res);
          new Promise((resolve, reject) => {
            if (this.deleteCustomer.length > 0 || this.deleteOperators.length > 0 || this.deleteGroups.length > 0) {
              this.deleteFull().then((res) => {
                this.globals.messageServer(res);
                resolve();
              }).catch((err) => {
                this.globals.messageServer(err);
                console.error("Errore: ", err);
                reject(err);
              })
            } else {
              resolve();
            }
          }).then(() => {
            return new Promise((resolve, reject) => {
              if (this.addCustomer.length > 0 || this.addGroups.length > 0 || this.addOperators.length > 0) {
                this.addMemberGroup().then((res) => {
                  this.globals.messageServer(res);
                  resolve()
                }).catch((err) => {
                  this.globals.messageServer(err);
                  console.error("Errore: ", err);
                  reject(err);
                })
              } else {
                resolve()
              }
            })
          }).then(() => {
            this.globals.stampa("Risposta in updateGroup: ", res);
            this.reload();
            this.spinner.hide();
            this.globals.showNotification("Gruppo salvato con successo!", "success");


          }).catch((err) => {
            this.globals.messageServer(err);
            this.spinner.hide();
            console.error("Errore: ", err);
            this.globals.showNotification("Errore salvataggio gruppo!", "error");


          })

        },
        err => {
          console.error("errore in updateNameGroup: ", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )
    } else {
      if (desc.length <= 300 && name.length <= 100) {
        this.globals.showNotification("Dati mancanti", "warning")
      } else {
        if (desc.length > 300) {
          this.globals.showNotification("La descrizione supera i 300 caratteri", "warning")
        }
        if (name.length > 100) {
          this.globals.showNotification("Il titolo supera i 100 caratteri", "warning")
        }
      }
    }
  }

  delete(list) {
    this.globals.stampa("Delete");

    if (list.customer_id) {
      let isAdded = false;
      let temp;
      let obj = {
        customer_id: list.customer_id,
        alfresco_id: list.alfresco_id
      }
      this.addCustomer.forEach((cus) => {
        if (cus.user_id = obj.customer_id) {
          isAdded = true;
          temp = cus;
        }
      })
      if (isAdded) {
        this.addCustomer.splice(this.addCustomer.indexOf(temp), 1);
        this.selectedGroup.customers.splice(this.selectedGroup.customers.indexOf(list), 1);

      } else {
        this.deleteCustomer.push(obj);
        this.selectedGroup.customers.splice(this.selectedGroup.customers.indexOf(list), 1);
      }
    }

    if (list.user_id) {
      let isAdded = false;
      let temp;
      let obj = {
        user_id: list.user_id,
        alfresco_id: list.alfresco_id
      }
      this.addOperators.forEach((op) => {
        if (op.user_id = obj.user_id) {
          isAdded = true;
          temp = op;
        }
      })
      if (isAdded) {
        this.addOperators.splice(this.addOperators.indexOf(temp), 1);
        this.selectedGroup.operators.splice(this.selectedGroup.operators.indexOf(list), 1);

      } else {
        this.deleteOperators.push(obj);
        this.selectedGroup.operators.splice(this.selectedGroup.operators.indexOf(list), 1);
      }
    }

    if (list.group_id) {
      let isAdded = false;
      let temp;
      let obj = {
        group_id: list.group_id,
        alfresco_id: list.alfresco_id
      }
      this.addGroups.forEach((group) => {
        if (group.user_id = obj.group_id) {
          isAdded = true;
          temp = group;
        }
      })
      if (isAdded) {
        this.addGroups.splice(this.addGroups.indexOf(temp), 1);
        this.selectedGroup.groups.splice(this.selectedGroup.groups.indexOf(list), 1);
      } else {
        this.deleteGroups.push(obj);
        this.selectedGroup.groups.splice(this.selectedGroup.groups.indexOf(list), 1);
      }
    }
  }



  createGroup(group) {
    this.globals.stampa("group: ", group)

    if (group.description == null) {
      group.description = '';
    }

    if (group.group_name) {
      this.nome = true;
      document.getElementById("groupName").innerHTML = "";
    } else {
      this.nome = false;
      document.getElementById("groupName").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (this.nome && group.description.length <= 300 && group.group_name.length <= 100) {

      let groupToSend = {
        info: group,
        customers: this.participants.customers,
        operators: this.participants.operators,
        groups: this.participants.groups
      }

      this.groupService.newGroup(this.token, groupToSend).subscribe(
        res => {
          this.globals.showNotification("Gruppo creato con successo!", "success");
          this.globals.messageServer(res);
          this.reload();
        },
        err => {
          console.error("Errore nel creare il nuovo gruppo", err);
          // this.globals.showNotification("Errore creazione gruppo!", "error");
          this.globals.messageServer(err);
        }
      )
    } else {
      if (group.description.length <= 300 && group.group_name.length <= 80) {
        this.globals.showNotification("Dati mancanti", "warning")
      } else {
        if (group.description.length > 300) {
          this.globals.showNotification("La descrizione supera i 300 caratteri", "warning")
        }
        if (group.group_name.length > 80) {
          this.globals.showNotification("Il titolo supera i 80 caratteri", "warning")
        }
      }
    }
  }

  showForm(group, onlyRead?) {

    if (group.group_name == 'operatori' || group.group_name == 'clienti') {
      this.close = false;
    } else {
      this.close = true;
    }

    this.globals.stampa("Group: ", group)
    this.spinner.show();
    if (onlyRead) {
      this.isOnlyRead = true;
    }
    this.selectedGroup = {};
    this.participants.customers = [];
    this.groupService.getGroup(this.token, group.group_id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.view_groups = this.groups
        this.view_groups.forEach(grp => {
          if (grp.group_id == group.group_id) {
            this.view_groups.splice(this.view_groups.indexOf(grp), 1)
          }
        });

        this.newGrou = false;
        this.action = true;
        this.deleteUser = false;
        this.new = false;
        this.deleteUser = false;
        this.selectedGroup = res;
        this.participants.customers = res.customers;
        this.participants.operators = res.operators;
        this.participants.groups = res.groups;
        this.groupNew = true;
        this.del = true;
        this.choose = true;
        this.tempUser = `group${group.group_id}`;
        this.tempID = group.group_id;
        this.tempAlfrescoID = group.alfresco_id
        this.spinner.hide();
      },
      err => {
        this.globals.messageServer(err);
        console.error("Errore in getGroup: ", err);
        this.spinner.hide();
      }
    )
  }

  deleteGroup(group) {
    this.globals.stampa("gruppo: ", group);
    swal({
      title: `Cancellare ${group.group_name}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false
    }).then((result) => {

      if (result.value) {
        this.spinner.show();
        this.Delete.deleteGroup(this.token, group.group_id, { alfresco_id: group.alfresco_id }).subscribe(
          res => {
            this.globals.messageServer(res);
            this.selectedGroup = {};
            this.choose = false;
            this.reload();
            this.spinner.hide();
            this.globals.showNotification("Gruppo eliminato con successo!", "success");
          },
          err => {
            this.spinner.hide();
            console.error("Errore in deleteGroup ", err);
            // this.globals.showNotification("Errore cancellazione gruppo!", "error");
            this.globals.messageServer(err);
          }
        )
      } else {
      }
    })
  }
}
