import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService,
  ) { }

  getNotifications(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getNotificationsURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  notificationRead(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put<any>(this.globals.getNotifivationReadURL(id), {}, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getNotificationType(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getNotificationTypeURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

}
