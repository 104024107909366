import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './component/login/login.component';

import { HomepageComponent } from './component/homepage/homepage.component';
import { DocumentaryComponent } from './component/documentary/documentary.component';
import { HeaderComponent } from './component/header/header.component';
import { ActivityComponent } from './component/activity/activity.component';
import { OperatorComponent } from './component/operator/operator.component';
import { CustomerComponent } from './component/customer/customer.component';
import { TicketComponent } from './component/ticket/ticket.component';
import { ClienteComponent } from './popup/cliente/cliente.component';
import { NewCustomerComponent } from './popup/new-customer/new-customer.component';
import { NewfolderComponent } from './popup/newfolder/newfolder.component';
import { UploadfileComponent } from './popup/uploadfile/uploadfile.component';
import { NewTicketComponent } from './popup/new-ticket/new-ticket.component';
import { ToastContainer } from './toast/toast.component';
import { LoadingScreenComponent } from './component/loading-screen/loading-screen.component';
import { ContractComponent } from './component/contract/contract.component';
import { ListPipe } from './list.pipe';
import { AssocComponent } from './popup/assoc/assoc.component';
import { GroupComponent } from './component/group/group.component';
import { OpenticketComponent } from './popup/openticket/openticket.component';
import { WaitingticketComponent } from './popup/waitingticket/waitingticket.component';
import { FullcalendarComponent } from './component/fullcalendar/fullcalendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AddeventComponent } from './popup/addevent/addevent.component';
import { ExceluploadComponent } from './popup/excelupload/excelupload.component';
import { ChangeNameFolderComponent } from './popup/change-name-folder/change-name-folder.component';
import { OpenfolderComponent } from './popup/openfolder/openfolder.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivitypopupComponent } from './popup/acitivitypopup/acitivitypopup.component';
import { EasydocComponent } from './component/easydoc/easydoc.component';
import { StoreactivityComponent } from './popup/storeactivity/storeactivity.component';
import { StuckactivityComponent } from './popup/stuckactivity/stuckactivity.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AngularMaterialModule } from './angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';


import { SidebarModule } from './sidebar/sidebar.module';
import { NavbarModule } from './shared/navbar/navbar.module';

import { MatDialogModule } from '@angular/material/dialog';
import { ToastrModule } from 'ngx-toastr';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ColorPickerModule } from 'ngx-color-picker';
import { TicketactivityComponent } from './popup/ticketactivity/ticketactivity.component';
import { ListActivityComponent } from './popup/list-activity/list-activity.component';
import { ProfileComponent } from './component/profile/profile.component';
import { CompanyComponent } from './component/company/company.component';
import { ChangepwdComponent } from './popup/changepwd/changepwd.component';
import { ManageUserComponent } from './component/manage-user/manage-user.component';
import { ListCustomerAssociatesComponent } from './popup/list-customer-associates/list-customer-associates.component';
import { ActivitiesComponent } from './component/activities/activities.component';
import { MemberselectorComponent } from './component/memberselector/memberselector.component';
import { UpdatemembersComponent } from './popup/updatemembers/updatemembers.component';
import { SeveprocessComponent } from './popup/seveprocess/seveprocess.component';
import { FulfillmentComponent } from './component/fulfillment/fulfillment.component';
import { ListDeadlineComponent } from './component/list-deadline/list-deadline.component';
import { RecipientslistComponent } from './popup/recipientslist/recipientslist.component';
import { ActivityTimeUserListComponent } from './popup/activity-time-user-list/activity-time-user-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomepageComponent,
    DocumentaryComponent,
    HeaderComponent,
    ActivityComponent,
    OperatorComponent,
    CustomerComponent,
    ClienteComponent,
    NewCustomerComponent,
    NewfolderComponent,
    UploadfileComponent,
    TicketComponent,
    NewTicketComponent,
    ToastContainer,
    LoadingScreenComponent,
    ContractComponent,
    ListPipe,
    AssocComponent,
    GroupComponent,
    AddeventComponent,
    OpenticketComponent,
    WaitingticketComponent,
    FullcalendarComponent,
    ExceluploadComponent,
    ChangeNameFolderComponent,
    OpenfolderComponent,
    ActivitypopupComponent,
    EasydocComponent,
    StoreactivityComponent,
    StuckactivityComponent,
    TicketactivityComponent,
    ListActivityComponent,
    ProfileComponent,
    CompanyComponent,
    ChangepwdComponent,
    ManageUserComponent,
    ListCustomerAssociatesComponent,
    ActivitiesComponent,
    MemberselectorComponent,
    UpdatemembersComponent,
    SeveprocessComponent,
    FulfillmentComponent,
    ListDeadlineComponent,
    RecipientslistComponent,
    ActivityTimeUserListComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    AngularMaterialModule,
    SidebarModule,
    NavbarModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    ScrollingModule,
    NgxMaterialTimepickerModule.setLocale('it-IT'),
    ColorPickerModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true
    })
  ],
  providers: [
    CookieService
  ],
  bootstrap: [
    AppComponent,
    ToastContainer
  ],
  entryComponents: [
    ClienteComponent,
    NewCustomerComponent,
    NewfolderComponent,
    UploadfileComponent,
    NewTicketComponent,
    AssocComponent,
    AddeventComponent,
    OpenticketComponent,
    WaitingticketComponent,
    ExceluploadComponent,
    ChangeNameFolderComponent,
    OpenfolderComponent,
    ActivitypopupComponent,
    StoreactivityComponent,
    StuckactivityComponent,
    TicketactivityComponent,
    ListActivityComponent,
    ChangepwdComponent,
    ListCustomerAssociatesComponent,
    UpdatemembersComponent,
    SeveprocessComponent,
    ListDeadlineComponent,
    RecipientslistComponent,
    ActivityTimeUserListComponent
  ],
  exports: [
    ClienteComponent,
    MatDialogModule
  ]
})

export class AppModule { }