/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable space-before-function-paren */
const SUITABILITY_SCORE_HIGH = 100,
  SUITABILITY_SCORE_AVERGE = 50,
  SUITABILITY_SCORE_LOW = 25;

import CmdHelper from 'bpmn-js-properties-panel/lib/helper/CmdHelper';

export default class CustomPalette {
  constructor(bpmnFactory, create, elementFactory, palette, translate, modeling, renderer) {
    this.bpmnFactory = bpmnFactory;
    this.create = create;
    this.elementFactory = elementFactory;
    this.translate = translate;
    this.cmdHelper = CmdHelper;
    this.modeling = modeling;
    this.renderer = renderer;

    palette.registerProvider(this);
  }

  getPaletteEntries(element) {
    const {
      bpmnFactory,
      create,
      elementFactory,
      translate,
      cmdHelper,
      modeling,
      renderer
    } = this;

    function createTask(suitabilityScore) {
      return function (event) {
        const businessObject = bpmnFactory.create('bpmn:Task');

        businessObject.suitable = suitabilityScore;

        const shape = elementFactory.createShape({
          type: 'bpmn:Task',
          businessObject: businessObject
        });

        create.start(event, shape);
      };
    }

    /* function createTest() {
      return function(event) {
        const testObject = bpmnFactory.create('bpmn:Task');

        const shape = elementFactory.createShape({
          type: 'bpmn:Task',
          businessObject: testObject
        });

        create.start(event, shape);
      };
    }*/

    function createStartEvent(name) {
      return function (event) {
        const businessObject = bpmnFactory.create('bpmn:StartEvent');
        businessObject.name = name;

        const shape = elementFactory.createShape({
          type: 'bpmn:StartEvent',
          businessObject: businessObject
        });
        // modeling.createShape(shape, { x: 300, y: 250 }, parent);
        create.start(event, shape);
      };
    }

    function createEndEvent(name) {
      return function (event) {
        const businessObject = bpmnFactory.create('bpmn:EndEvent');
        businessObject.name = name;

        const shape = elementFactory.createShape({
          type: 'bpmn:EndEvent',
          businessObject: businessObject
        });
        // modeling.createShape(shape, { x: 300, y: 250 }, parent);
        create.start(event, shape);
      };
    }

    function createExclusiveGateway(name) {
      return function (event) {
        const businessObject = bpmnFactory.create('bpmn:ExclusiveGateway');
        businessObject.name = name;

        const shape = elementFactory.createShape({
          type: 'bpmn:ExclusiveGateway',
          businessObject: businessObject
        });
        // modeling.createShape(shape, { x: 300, y: 250 }, parent);
        create.start(event, shape);
      };
    }

    function createScriptTask(name, script, scriptFormat) {
      return function (event) {
        const businessObject = bpmnFactory.create('bpmn:ScriptTask');
        businessObject.scriptFormat = scriptFormat;
        businessObject.script = script;
        businessObject.name = name;

        const shape = elementFactory.createShape({
          type: 'bpmn:ScriptTask',
          businessObject: businessObject
        });
        // modeling.createShape(shape, { x: 300, y: 250 }, parent);
        create.start(event, shape);
      };
    }

    function createServiceTask(name, serviceExpression) {
      return function (event) {
        const businessObject = bpmnFactory.create('bpmn:ServiceTask');
        businessObject.expression = serviceExpression;
        businessObject.name = name;
        const shape = elementFactory.createShape({
          type: 'bpmn:ServiceTask',
          businessObject: businessObject
        });
        // modeling.createShape(shape, { x: 300, y: 250 }, parent);
        create.start(event, shape);
      };
    }

    function createUserTask(name) {
      return function (event) {
        const businessObject = bpmnFactory.create('bpmn:UserTask');
        businessObject.name = name;
        const shape = elementFactory.createShape({
          type: 'bpmn:UserTask',
          businessObject: businessObject
        });
        // modeling.createShape(shape, { x: 300, y: 250 }, parent);
        create.start(event, shape);
      };
    }

    function createConnection(sourceShape, targetShape, waypoints) {
      return elementFactory.createConnection({
        type: 'bpmn:SequenceFlow',
        source: sourceShape,
        target: targetShape,
        waypoints: waypoints
      });
    }

    function createCluster() {
      return function (event) {

        const invokeMyServicetaskShape = elementFactory.createShape({ type: 'bpmn:ServiceTask', x: 0, y: 0 });

        const exclusiveGatewayShape = elementFactory.createShape({ type: 'bpmn:ExclusiveGateway', x: 150, y: 15 });
        exclusiveGatewayShape.businessObject.name = 'continue?';

        const nextThingServiceTaskShape = elementFactory.createShape({ type: 'bpmn:ServiceTask', x: 250, y: 0 });

        const otherThingServiceTaskShape = elementFactory.createShape({ type: 'bpmn:ServiceTask', x: 250, y: 130 });

        const correctItServiceTaskShape = elementFactory.createShape({ type: 'bpmn:ServiceTask', x: 120, y: 210 });

        // error event definition
        var erroreventDefinition = bpmnFactory.create('bpmn:ErrorEventDefinition', {
          errorCodeVariable: 'errorCode',
          errorMessageVariable: 'errorMessage',
        });

        // attached boundary error event
        const erroreventShape = elementFactory.createShape({ type: 'bpmn:BoundaryEvent', x: 50, y: 62 });
        erroreventShape.businessObject.name = 'hallo error';
        erroreventShape.businessObject.attachedToRef = invokeMyServicetaskShape.businessObject;
        erroreventShape.businessObject.eventDefinitions = [erroreventDefinition];
        erroreventShape.host = invokeMyServicetaskShape;

        erroreventDefinition.$parent = erroreventShape.businessObject;

        const sequenceFlowMyServiceExclusive =
          createConnection(invokeMyServicetaskShape, exclusiveGatewayShape, [{ x: 100, y: 40 }, { x: 150, y: 40 }]);

        // need a FormularExpression
        const sequenceFlowExclusiveNext =
          createConnection(exclusiveGatewayShape, nextThingServiceTaskShape, [{ x: 200, y: 40 }, { x: 250, y: 40 }]);
        sequenceFlowExclusiveNext.businessObject.name = 'yes';
        sequenceFlowExclusiveNext.businessObject.conditionExpression =
          bpmnFactory.create('bpmn:FormalExpression', { body: '${continue}' });

        const sequenceFlowExclusiveOther =
          createConnection(exclusiveGatewayShape, otherThingServiceTaskShape, [{ x: 175, y: 60 }, { x: 175, y: 170 }, { x: 250, y: 170 }]);
        sequenceFlowExclusiveOther.businessObject.name = 'no';
        sequenceFlowExclusiveOther.businessObject.conditionExpression =
          bpmnFactory.create('bpmn:FormalExpression', { body: '${not continue}' });

        const sequenceFlowErrorCorrect =
          createConnection(erroreventShape, correctItServiceTaskShape, [{ x: 68, y: 98 }, { x: 68, y: 250 }, { x: 120, y: 250 }]);

        const sequenceFlowCorrectHello =
          createConnection(correctItServiceTaskShape, invokeMyServicetaskShape,
            [{ x: 220, y: 250 }, { x: 250, y: 250 }, { x: 250, y: 320 }, { x: -30, y: 320 }, { x: -30, y: 50 }, { x: 0, y: 50 }]);

        create.start(event, [
          invokeMyServicetaskShape,
          erroreventShape,
          exclusiveGatewayShape,
          nextThingServiceTaskShape,
          otherThingServiceTaskShape,
          correctItServiceTaskShape,
          sequenceFlowMyServiceExclusive,
          sequenceFlowExclusiveNext,
          sequenceFlowExclusiveOther,
          sequenceFlowErrorCorrect,
          sequenceFlowCorrectHello
        ]);
      };
    }

    return {
      'create.start-event': {
        group: 'activity', // tab nella palette
        className: 'bpmn-icon-start-event-none custom-entry', // classe: icona colore
        title: translate('Inizio'), // testo in mouseOver
        action: {
          dragstart: createStartEvent('Inizio'), // azione necessaria per creare l'elemento al drag
          click: createStartEvent('Inizio') // azione necessaria per creare l'elemento al click
        }
      },
      'create.end-event': {
        group: 'activity', // tab nella palette
        className: 'bpmn-icon-end-event-none custom-entry', // classe: icona colore
        title: translate('Fine'), // testo in mouseOver
        action: {
          dragstart: createEndEvent('Fine'), // azione necessaria per creare l'elemento al drag
          click: createEndEvent('Fine') // azione necessaria per creare l'elemento al click
        }
      },
      'create.exclusive-gateway': {
        group: 'activity', // tab nella palette
        className: 'bpmn-icon-gateway-xor custom-entry', // classe: icona colore
        title: translate('Condizione'), // testo in mouseOver
        action: {
          dragstart: createExclusiveGateway('Condizione'), // azione necessaria per creare l'elemento al drag
          click: createExclusiveGateway('Condizione') // azione necessaria per creare l'elemento al click
        }
      },
      'create.console-log-scripttask': {
        group: 'activity', // tab nella palette
        className: 'bpmn-icon-script-task custom-entry', // classe: icona colore
        title: translate('Funzione Log'), // testo in mouseOver
        action: {
          dragstart: createScriptTask('console log', 'console.log("lallo");\nnext(null, "loggato");', 'Javascript'), // azione necessaria per creare l'elemento al drag
          click: createScriptTask('console log', 'console.log("lallo");\nnext(null, "loggato");', 'Javascript') // azione necessaria per creare l'elemento al click
        }
      },
      'create.send-mail-servicetask': {
        group: 'activity', // tab nella palette
        className: 'bpmn-icon-service-task custom-entry', // classe: icona colore
        title: translate('Invio Mail'), // testo in mouseOver
        action: {
          dragstart: createServiceTask('send mail', '${environment.services.sendMail}'), // azione necessaria per creare l'elemento al drag
          click: createServiceTask('send mail', '${environment.services.sendMail}') // azione necessaria per creare l'elemento al click
        }
      },
      'create.pick-color-usertask': {
        group: 'activity', // tab nella palette
        className: 'bpmn-icon-user-task custom-entry', // classe: icona colore
        title: translate('Seleziona Colore'), // testo in mouseOver
        action: {
          dragstart: createUserTask('pick color'), // azione necessaria per creare l'elemento al drag
          click: createUserTask('pick color') // azione necessaria per creare l'elemento al click
        }
      },
      /*
      'create.cluster': {
        group: 'activity', // tab nella palette
        className: 'bpmn-icon-task custom-entry', // classe: icona colore
        title: translate('Cluster'), // testo in mouseOver
        action: {
          dragstart: createCluster(), // azione necessaria per creare l'elemento al drag
          click: createCluster() // azione necessaria per creare l'elemento al click
        }
      },*/
      'create.task': {
        className: 'hidden'
      },
      'create.subprocess-expanded': {
        className: 'hidden'
      },
      'create.intermediate-event': {
        className: 'hidden'
      },
      'create.data-object': {
        className: 'hidden'
      },
      'create.data-store': {
        className: 'hidden'
      },
      'create.participant-expanded': {
        className: 'hidden'
      },
      'create.group': {
        className: 'hidden'
      }
    };
  }
}

CustomPalette.$inject = [
  'bpmnFactory',
  'create',
  'elementFactory',
  'palette',
  'translate',
  'modeling'
];