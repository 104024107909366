import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'BlocktrisBackoffice';
  constructor(private router: Router) { }
  ngOnInit() {
    // const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    // const html = document.getElementsByTagName('html')[0];
    // if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
    //   let ps = new PerfectScrollbar(elemMainPanel);
    //   html.classList.add('perfect-scrollbar-on');
    // }
    // else {
    //   html.classList.add('perfect-scrollbar-off');
    // }
  }
  ngAfterViewInit() {
    this.runOnRouteChange();
  }
  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
      ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }
  isHomeRoute() {
    return this.router.url === "/login";
  }
}
