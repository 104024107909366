import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faTicketAlt, faUser, faPlus } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material';

import { NewTicketComponent } from 'src/app/popup/new-ticket/new-ticket.component';
import { OpenticketComponent } from 'src/app/popup/openticket/openticket.component';
import { WaitingticketComponent } from 'src/app/popup/waitingticket/waitingticket.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../utils/format-datepicker';

import { GlobalService } from 'src/app/services/global.service';
import { TicketService } from 'src/app/services/ticket.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment-timezone';
import { FormControl } from '@angular/forms';
import { environment } from '@environment';
import { Ticket, TicketInfo, InfoInstance } from 'src/app/classes';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

declare const $: any;

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class TicketComponent implements OnInit {
  envi = environment;
  //string
  token: string;
  activeUser: any;
  // Boolean
  operator: boolean = false;
  cards: boolean = true;
  table: boolean = false;
  disabled: boolean = false;
  //array
  tickets: Array<TicketInfo> = [];
  openTickets: Array<TicketInfo> = [];
  waitingTickets: Array<TicketInfo> = [];
  progessTickets: Array<TicketInfo> = [];
  closeTickets: Array<TicketInfo> = [];
  competences: Array<any> = [];
  // Icone
  faUserCircle = faUserCircle;
  faTicketAlt = faTicketAlt;
  faUser = faUser;
  faPlus = faPlus;
  ticket_idURL: number;
  //any
  temp: any;

  dropListOrientation = 'vertical';
  dragStartDelay = 0;

  tticket = true;
  aticket = false;

  constructor(
    private modalService: NgbModal,
    private globals: GlobalService,
    private ticketService: TicketService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((p) => {
      this.ticket_idURL = p['ticket_id'];
      this.globals.stampa("Id: ", this.ticket_idURL);
    });

    this.dragStartDelay = 0;
    this.dropListOrientation = 'vertical';

    if (window.innerWidth <= 1280) {
      this.dropListOrientation = 'horizontal';
      this.dragStartDelay = 500;
    }
    this.spinner.show();
    this.globals.checkPage(5);
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.activeUser = this.globals.storagedUser;
    this.globals.stampa("Active user: ", this.activeUser);
    if (this.activeUser.profile_id == 1) {
      this.disabled = true;
    }
    this.checkProfile();
    // this.getTickets();
    this.inizio();
  }
  onResize(event) {
    this.dropListOrientation = 'vertical';
    this.dragStartDelay = 0;
    if (event.target.innerWidth <= 1280) {
      this.dragStartDelay = 500;
      this.dropListOrientation = 'horizontal';
    }
  }

  changeView() {

    this.spinner.show();
    if (this.cards) {
      this.createTable();
      this.cards = false;
      this.table = true;
    } else if (this.table) {
      this.spinner.show();
      $('#datatablesTicket').DataTable().destroy();
      this.cards = true;
      this.table = false;
      this.spinner.hide();
    }
  }

  // Controllo il profilo
  checkProfile() {
    if (this.activeUser.profile_id == 2 || this.activeUser.profile_id == 3)
      this.operator = true;
  };

  inizio() {
    this.spinner.show();
    // var element = document.getElementById(`ticketT`);
    // element.classList.add("color-change");
    this.temp = "ticketT";
    this.tticket = true;
    this.aticket = false;
    this.getTickets();
  }

  // ricarico la pagina
  reload() {
    this.inizio();
  }


  // Creo nuovo ticket
  newTicket() {
    this.spinner.show();
    const dialogRef = this.dialog.open(NewTicketComponent,
      {
        width: '60%',
        // height: '80%',
      });
    dialogRef.afterClosed().subscribe(result => {
      this.globals.stampa('The dialog was closed', result);
      this.reload();
      // this.animal = result;
    });
  }

  changeTime(time) {
    time.forEach((dat) => {
      let date = new time(dat.createdat);
      if (date.getMonth() + 1 > 10 && date.getDate() >= 10) {
        dat.createdat = '' + (date.getDate()) + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        // this.globals.stampa("mese e giorno >10")
      } else {

        if (date.getMonth() + 1 < 10 && date.getDate() < 10) {
          dat.createdat = '' + (date.getDate()) + '/' + '0' + (date.getMonth() + 1) + '/' + '0' + date.getFullYear();
          // this.globals.stampa("mese e giorno <10")
        } else {

          if (date.getMonth() + 1 < 10) {
            dat.createdat = '' + (date.getDate()) + '/' + '0' + (date.getMonth() + 1) + '/' + date.getFullYear();
            // this.globals.stampa("mese <10")
          }

          if (date.getDate() < 10) {
            dat.createdat = '' + (date.getDate()) + '/' + (date.getMonth() + 1) + '/' + '0' + date.getFullYear();
            // this.globals.stampa("giorno <10")
          }
        }
      }
    })
  }

  // Ottengo i ticket
  getTickets() {
    this.ticketService.getTickets(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.tickets = res.values.tickets;
        this.resetArray();
        this.tickets.forEach((tk) => {
          tk.ticket.correctOpenat = moment(tk.ticket.createdat).format('DD-MM-YYYY');
          if (tk.ticket.state == 'Aperto') {
            this.openTickets.push(tk);
          } else if (tk.ticket.state == 'In attesa') {
            this.waitingTickets.push(tk);
          } else if (tk.ticket.state == 'In elaborazione') {
            this.progessTickets.push(tk);
          } else if (tk.ticket.state == 'Chiuso') {
            this.closeTickets.push(tk);
          }
        })
        if (this.table == true) {
          this.createTable();
        }
        if (this.ticket_idURL) {
          this.globals.stampa("Tickets: ", this.tickets)
          this.tickets.forEach((ticket) => {
          if (parseInt(ticket.ticket.ticket_id.toString()) == parseInt(this.ticket_idURL.toString())) {
            if (ticket.ticket.state == 'Aperto') {
              this.spinner.show();
              const dialogRef = this.dialog.open(OpenticketComponent, {
                width: '60%',
                // height: '65%',
                data: {
                  tk: ticket,
                  read: false
                }
              })
              dialogRef.afterClosed().subscribe((result) => {
                this.globals.stampa('The dialog was closed', result);
                this.ticket_idURL = null;
                this.globals.goTo('ticket');
                this.reload();
              })
            } else {
              this.spinner.show();
              const dialogRef = this.dialog.open(WaitingticketComponent, {
                width: '60%',
                // height: '80%',
                data: {
                  tk: ticket,
                  custoemr_view_name: ticket.customer.view_name,
                  read: false
                }
              })
              dialogRef.afterClosed().subscribe((result) => {
                this.globals.stampa('The dialog was closed', result);
                this.ticket_idURL = null;
                this.globals.goTo('ticket');
                this.reload();
              })
            }
          }
        })
        }
        this.spinner.hide();
      },
      err => {
        console.error("Errore in GetTickets", err);
        this.spinner.hide();
        this.globals.messageServer(err);

      }
    )
  }

  // Ottengo i ticket assegnati all'operatore
  getMyTickets() {
    this.ticketService.getAssignedTicket(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.tickets = res;
      },
      err => {
        console.error("Errore: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  // PopUpTicket Da Assegnare
  showOpenTicket(ticket, onlyRead?) {
    let read = false;
    if (onlyRead) {
      read = true;
      this.spinner.show();
      const dialogRef = this.dialog.open(OpenticketComponent, {
        width: '60%',
        // height: '65%',
        data: {
          tk: ticket,
          read: read
        }
      })
      dialogRef.afterClosed().subscribe((result) => {
        this.globals.stampa('The dialog was closed', result);
        if (result != 'cancel') {
          this.reload();
        }

      }, reason => {
      })
    } else {
      this.spinner.show();
      const dialogRef = this.dialog.open(OpenticketComponent, {
        width: '60%',
        // height: '75%',
        data: {
          tk: ticket,
          read: read
        }
      })
      dialogRef.afterClosed().subscribe((result) => {
        this.globals.stampa('The dialog was closed', result);
        if (result != 'cancel') {
          this.reload();
        }

      }, reason => {
      })
    }

  }

  // popUp DeiTicket già assegnati 
  showWaitingTicket(ticket, onlyRead?) {
    let read = false;
    if (onlyRead) {
      read = true;
      this.spinner.show();
      const dialogRef = this.dialog.open(WaitingticketComponent, {
        width: '60%',
        // height: '80%',
        data: {
          tk: ticket,
          custoemr_view_name: ticket.customer.view_name,
          read: read
        }
      })
      dialogRef.afterClosed().subscribe((result) => {
        this.globals.stampa('The dialog was closed', result);
        if (result != 'cancel') {
          this.reload();
        }

      }, reason => {
      })
    } else {
      this.spinner.show();
      const dialogRef = this.dialog.open(WaitingticketComponent, {
        width: '60%',
        // height: '80%',
        data: {
          tk: ticket,
          custoemr_view_name: ticket.customer.view_name,
          read: read
        }
      })
      dialogRef.afterClosed().subscribe((result) => {
        this.globals.stampa('The dialog was closed', result);
        if (result != 'cancel') {
          this.reload();
        }

      }, reason => {
      })
    }

  }

  shTicket(ticket, read?) {
    if (read) {
      if (ticket.ticket.state == 'Aperto') {
        this.showOpenTicket(ticket, true);
      } else {
        this.showWaitingTicket(ticket, true);
      }
    } else {
      if (ticket.ticket.state == 'Aperto') {
        this.showOpenTicket(ticket);
      } else {
        this.showWaitingTicket(ticket);
      }
    }

  }

  // Seleziona la pagina
  page(pagina: string) {

    if (pagina == "tticket") {
      if (this.temp) {
        var elementTwo = document.getElementById(this.temp);
        // elementTwo.classList.remove("color-change");
      }

      var element = document.getElementById(`ticketT`);
      // element.classList.add("color-change");

      this.temp = "ticketT";
    }

    if (pagina == "aticket") {
      if (this.temp) {
        var element = document.getElementById(this.temp);
        // element.classList.remove("color-change");
      }

      var element = document.getElementById(`ticketA`);
      // element.classList.add("color-change");

      this.temp = "ticketA";
    }


    switch (pagina) {
      case 'tticket':
        this.tticket = true;
        this.aticket = false;
        this.getTickets();
        break;
      case 'aticket':
        this.aticket = true;
        this.tticket = false;
        this.getMyTickets();
        break;
    }
  }

  drop(event: CdkDragDrop<Array<TicketInfo>>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      //Creo l'oggetto con il vecchio e il nuovo indice
      let obj = {
        oldP: event.container.data[event.currentIndex].ticket.position,
        newP: event.currentIndex,
        state: this.getTicketState(event.container.id)
      };
      //Chiamo il servizio del reorder
      this.ticketService.changeTicketPositionInStatus(this.token, event.container.data[event.currentIndex].ticket.ticket_id, obj).subscribe(
        res => {
          this.globals.messageServer(res);
          event.container.data[event.currentIndex].ticket.position = event.currentIndex;
        },
        err => {
          this.getTickets();
          console.error("Errore nell'assegnare il ticket", err);
          this.globals.messageServer(err);
        }
      )

    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      if (event.container.id != "openTickets" && event.previousContainer.id != "openTickets") {

        let obj = {
          oldP: {
            position: event.previousIndex,
            state: this.getTicketState(event.previousContainer.id)
          },
          newP: {
            position: event.currentIndex,
            state: this.getTicketState(event.container.id)
          }
        }
        event.container.data[event.currentIndex].ticket.state = this.getTicketState(event.container.id);
        this.ticketService.changeTicketPositionOutStatus(this.token, event.container.data[event.currentIndex].ticket.ticket_id, obj).subscribe(
          res => {
            this.globals.messageServer(res);
            event.container.data[event.currentIndex].ticket.position = event.currentIndex;
          },
          err => {
            console.error("Errore: ", err);
            this.getTickets();
            this.globals.messageServer(err);
          }
        );

        this.globals.stampa('event drop', event.container.data[event.currentIndex]);
      } else if (event.previousContainer.id == "openTickets" || event.container.id == "openTickets") {
        let obj = {
          oldP: {
            position: event.previousIndex,
            state: this.getTicketState(event.previousContainer.id)
          },
          newP: {
            position: event.currentIndex,
            state: this.getTicketState(event.container.id)
          }
        }
        let userId = -1;
        if (event.previousContainer.id == "openTickets") {
          userId = this.globals.storagedUser.user_id;
        }
        let newState = this.getTicketState(event.container.id);
        if (newState) {
          event.container.data[event.currentIndex].ticket.state = newState;

          this.ticketService.assignTicket(this.token, event.container.data[event.currentIndex].ticket.ticket_id, userId).subscribe(
            res => {
              this.globals.messageServer(res);
              this.ticketService.changeTicketPositionOutStatus(this.token, event.container.data[event.currentIndex].ticket.ticket_id, obj).subscribe(
                res => {
                  this.globals.messageServer(res);
                  event.container.data[event.currentIndex].ticket.position = event.currentIndex;
                },
                err => {
                  this.getTickets();
                  console.error("Errore nell'assegnare il ticket", err);
                  this.globals.messageServer(err);
                }
              )
            },
            err => {
              this.getTickets();
              console.error("Errore nell'assegnare l'utente", err);
              this.globals.messageServer(err);
            }
          );
        }
      }
    }
  }

  getTicketState(type): string {
    let state: string;
    switch (type) {
      case 'openTickets':
        state = 'Aperto'
        break;
      case 'waitingTickets':
        state = 'In attesa';
        break;
      case 'progessTickets':
        state = 'In elaborazione';
        break;
      case 'closeTickets':
        state = 'Chiuso';
        break;
    }
    return state;
  }
  deleteTicket(ticket) {
    if (ticket && ticket.ticket.ticket_id) {
      swal({
        title: 'Eliminare il ticket?',
        // text: 'You will not be able to recover this imaginary file!',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annulla',
        confirmButtonText: 'Conferma',
        cancelButtonClass: "btn btn-danger",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.ticketService.deleteTicket(this.token, ticket.ticket.ticket_id).subscribe(
            res => {
              this.globals.messageServer(res);
              this.getTickets();
            },
            err => {
              console.error("Errore nell'assegnare il ticket", err);
              this.globals.messageServer(err);
            }
          )
        } else {
          this.spinner.hide();
        }
      });

    }

  }

  createTable() {
    let _self = this;
    let tickett = [];
    let tableObject = {
      data: []
    }
    if (this.activeUser.profile_id == 1) {
      this.tickets.forEach((tk) => {
        let obj = {
          ticket_id: tk.ticket.ticket_id,
          ticket_name: tk.ticket.ticket_name,
          correctOpenat: tk.ticket.correctOpenat,
          // username: tk.openby.username,
          username:'cablato',
          view_name: this.activeUser.view_name,
          state: tk.ticket.state
        }
        tickett.push(obj);
      });
    } else {
      this.tickets.forEach((tk) => {
        let obj = {
          ticket_id: tk.ticket.ticket_id,
          ticket_name: tk.ticket.ticket_name,
          correctOpenat: tk.ticket.correctOpenat,
          username: tk.openBy.username ? tk.openBy.username : '',
          view_name: tk.customer.view_name ? tk.customer.view_name: '',
          state: tk.ticket.state
        }
        tickett.push(obj);
      });
    }
    tableObject.data = tickett;
    let config = {
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: {
        details: {
          type: 'column',
          target: 'tr'
        }
      },
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Ricerca ticket",
        url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
      },
      ajax: (data, callback, settings) => {
        callback(tableObject)
      },
      columns: [
        { title:'ID', data: 'ticket_id' },
        { title:'Titolo', data: 'ticket_name' },
        { title:'Aperto il', data: 'correctOpenat' },
        { title:'Aperto da', data: 'username' },
        { title:'Cliente', data: 'view_name' },
        { title:'Stato', data: 'state' },
        { title:'Azione', data: null, className: 'td-actions text-right' }
      ],
      columnDefs: [
        {
          targets: [6],
          visible: true,
          render: function (data, type, full, meta) {
            full = JSON.stringify(full);
            return `<a data-tkt='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                  <i class="material-icons">search</i>
                </a>
                <a data-tkt='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                  <i class="material-icons">edit</i>
                </a>
                <a data-tkt='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                  <i class="material-icons">close</i>
                </a>`
          }
        }
      ]
    }
    if (!$.fn.DataTable.isDataTable('#datatablesTicket')) {
      $(document).ready(function () {
        $('#datatablesTicket').DataTable(config);

        $('#datatablesTicket').on('click', 'a.btn-info', function () {
          let tik: any = $(this).data('tkt')
          _self.globals.stampa("Op: ", tik);
          _self.tickets.forEach((tk) => {
            if (tk.ticket.ticket_id == tik.ticket_id) {
              _self.shTicket(tk, true);
            }
          })
        });
        $('#datatablesTicket').on('click', 'a.btn-success', function () {
          // _self.globals.stampa($(this).data('cus'));
          let tik = $(this).data('tkt')
          _self.globals.stampa("Op: ", tik);
          _self.tickets.forEach((tk) => {
            if (tk.ticket.ticket_id == tik.ticket_id) {
              _self.shTicket(tk);
            }
          })
        });
        $('#datatablesTicket').on('click', 'a.btn-danger', function () {
          // _self.globals.stampa($(this).data('cus'));
          let tik = $(this).data('tkt')
          _self.globals.stampa("Op: ", tik);
          _self.tickets.forEach((tk) => {
            if (tk.ticket.ticket_id == tik.ticket_id) {
              _self.deleteTicket(tk);
            }
          })
        });
        _self.spinner.hide();
      })
    } else {
      // Creazione
      $('#datatablesTicket').DataTable().destroy();
      $('#datatablesTicket').DataTable(config);
      _self.spinner.hide();
    }
  }

  resetArray(){
    this.openTickets = [];
    this.waitingTickets = [];
    this.progessTickets = [];
    this.closeTickets = [];
  }

}