import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService
  ) { }

  getTemplate(token): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getProccesTemplateURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getInstances(token): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }
    return this.http.get<any>(this.globals.getInstancesURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  newProcess(token, process): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.getNewProcessURL, process, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  saveProcess(token, process, process_id): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put<any>(this.globals.getSaveProcessURL(process_id), process, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteProcess(token, process_id): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.getDeleteProcessURL(process_id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getProcces(token): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getGetProccesURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  executeProccesCus(token, obj, id): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.getRunProccesCusURL(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  executeProccesGroup(token, obj, id): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.getRunProccesGroupURL(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getProccesInstances(token, id): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getProccesInstanceURL(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getTypeDeadline(token, id): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getTypeDeadline(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  inputInstance(token: string, obj: any): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.getInputInstance, obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  storeActivity(token: string, id: number): Observable<any>{
    let httpOptions  = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.storActivityURL(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

}
