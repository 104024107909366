import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DocumentaryService {
  token: string;
  constructor(
    private globals: GlobalService,
    private http: HttpClient
  ) {
    this.token = this.globals.getToken();
  }

  getRoot(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getRoot, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getFolderContent(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getFolderContent(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  newFolder(token, parentF, folderN) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.newFolder(parentF, folderN), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  delFolder(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteFolder(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateName(token, id, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put<any>(this.globals.updateFolderName(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  uploadFile(token, folder, formData) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.uploadFile(folder), formData, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }


  getContent(token, id): Observable<Blob> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      }),
      _responseType: "blob",
      get responseType() {
        return this._responseType;
      },
      set responseType(value) {
        this._responseType = value;
      },

    };
    return this.http.get<any>(this.globals.getContent(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

}