import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faList, faUser, faPlus } from '@fortawesome/free-solid-svg-icons';

import * as camundaExtension from '../../../assets/resources/camunda.json';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import propertiesPanelModule from 'bpmn-js-properties-panel';
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda';
import customModule from './custom';
import { BusinessObject, PropertiesPanelSelectOption, SCRIPT_OPTIONS, SERVICE_OPTIONS, CONDITION_OPTIONS } from 'src/app/CommonClasses';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityService } from 'src/app/services/activity.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GroupService } from 'src/app/services/group.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss',
    '../../../../node_modules/bpmn-js/dist/assets/diagram-js.css', '../../../../node_modules/bpmn-js/dist/assets/bpmn-font/css/bpmn.css', '../../../../node_modules/bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css'],
  encapsulation: ViewEncapsulation.None
})

export class ActivityComponent implements OnInit {
  @ViewChild('diagram', { static: false }) private diagramEl: ElementRef;
  private bpmnModeler: BpmnModeler;
  private moddle: any;
  private modeling: any;
  private elementRegistry: any;
  private canvas: any;
  private eventBus: any;

  public scriptOptions: Array<PropertiesPanelSelectOption>;
  public serviceOptions: Array<PropertiesPanelSelectOption>;
  public conditionOptions: Array<PropertiesPanelSelectOption>;
  public executionTime: Array<PropertiesPanelSelectOption>;
  public selectedObject: BusinessObject;

  activeUser: any;
  faUserCircle = faUserCircle;
  faUser = faUser;
  faList = faList;
  xml = ``; // my BPMN 2.0 xml
  modeler: any;
  procces: any;
  token: string;
  showP: boolean = false;
  showD: boolean = true;
  openP: boolean = false;
  selectedPro: any = {};
  customers: Array<any> = [];
  groups: Array<any> = [];
  cus: any;
  group: any;
  instances: any;
  aperto: Array<any> = [];
  chiuso: Array<any> = [];
  openI: boolean = true;
  closeI: boolean = false;
  newShow: boolean;
  tempUser: any;
  processType: Array<any> = [];

  faPlus = faPlus;

  constructor(
    private globals: GlobalService,
    private modalService: NgbModal,
    private activityService: ActivityService,
    private customerService: CustomerService,
    public spinner: NgxSpinnerService,
    private groupService: GroupService,
  ) {
    this.scriptOptions = SCRIPT_OPTIONS;
    this.serviceOptions = SERVICE_OPTIONS;
    this.conditionOptions = CONDITION_OPTIONS;
  }

  ngOnInit() {
    this.spinner.show()
    this.activeUser = this.globals.storagedUser;
    this.globals.checkPage(4);
    this.token = this.globals.getToken();
    this.showProcces();
  }

  ngAfterContentInit(): void { }

  public logDiagram(): void {
    let _self = this;
    _self.bpmnModeler.saveXML({ format: true }, function (err, xml) {
      if (err) {
        console.error(err);
        return;
      }
      this.globals.stampa(xml);
    });
  }

  public importDiagram(): void {
    let _self = this;
    _self.bpmnModeler.importXML(_self.xml, function (err) {
      if (err) {
        console.error(err);
        return;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.bpmnModeler)
      this.bpmnModeler.destroy();
  }

  public updateBusinessObject(businessObject: BusinessObject): void {
    let _self = this;
    let element = _self.elementRegistry.get(businessObject.id);
    _self.modeling.updateProperties(element, {
      businessObject: businessObject
    });
  }

  public updateConditionFlow(businessObject: BusinessObject): void {
    let _self = this;
    businessObject.conditionExpression = {
      type: "bpmn:FormalExpression",
      body: "test"
    }
    let element = _self.elementRegistry.get(businessObject.id);
    _self.modeling.updateProperties(element, {
      businessObject: businessObject
    });
  }

  private is(element, type): any {
    var bo = this.getBusinessObject(element);
    return bo && (typeof bo.$instanceOf === 'function') && bo.$instanceOf(type);
  }

  private getBusinessObject(element): any {
    return (element && element.businessObject) || element;
  }

  saveProcces() {
    let _self = this;
    _self.bpmnModeler.saveXML({ format: true }, function (err, xml) {
      if (err) {
        console.error(err);
        return;
      }
      // const modalRef = _self.modalService.open(SavebpmnComponent, { backdrop: 'static', centered: true, size: 'xl' });
      // modalRef.componentInstance.xml = xml;
      // modalRef.componentInstance.types = _self.processType;
      // modalRef.result.then(result => {
      //   if (result != 'cancel') {
      //     _self.showProcces();
      //   }
      // }, reason => {
      // })
      // this.globals.stampa(xml);
    });
  }

  getProcces() {
    this.activityService.getProcces(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa(res);
        this.procces = res.procces;
        this.processType = res.types;
        // this.spinner.hide();
      },
      err => {
        console.error("Errore nell'ottenre i processi");
        this.globals.messageServer(err);
        this.spinner.hide();
      }
    )
  }

  test() {
    if (!this.openI) {
      this.openI = true;
      this.closeI = false;
    } else {
      this.closeI = true;
      this.openI = false;
    }
  }

  viewOpenI() {
    if (!this.openI) {
      this.openI = true;
      this.closeI = false;
    }
  }

  showNew() {
    if (this.newShow != true) {
      this.showP = false;
      this.openP = false;
      this.newShow = true;
      this.openI = true;
      this.closeI = false;
      this.showD = false;
      if (this.tempUser) {
        let elementTwo = document.getElementById(this.tempUser);
        elementTwo.classList.remove("color-change");
      }
      setTimeout(() => {
        let _self = this;
        let extension: any = camundaExtension;
        if (extension.default) {
          extension = extension.default;
        }
        _self.bpmnModeler = new BpmnModeler({
          container: _self.diagramEl.nativeElement,
          propertiesPanel: {
            parent: '#js-properties-panel'
          },
          additionalModules: [
            customModule,
            propertiesPanelModule,
            propertiesProviderModule
          ],
          moddleExtensions: {
            camunda: extension
          }
        });
        _self.bpmnModeler.createDiagram((err) => {
          if (err) {
            console.error(err);
          }
          _self.moddle = _self.bpmnModeler.get('moddle');
          _self.modeling = _self.bpmnModeler.get('modeling');
          _self.elementRegistry = _self.bpmnModeler.get('elementRegistry');
          _self.canvas = _self.bpmnModeler.get('canvas');
          _self.eventBus = _self.bpmnModeler.get('eventBus');
          _self.eventBus.on('selection.changed', (e) => {
            let selectedObject;
            if (e.newSelection.length == 1) {
              selectedObject = e.newSelection[0];
            } else {
              selectedObject = null;
            }
            this.globals.stampa(selectedObject);
            if (selectedObject && selectedObject.businessObject) {
              if (_self.is(selectedObject.businessObject, 'bpmn:SequenceFlow')) {
                if (selectedObject.businessObject.sourceRef.default !== selectedObject.businessObject && (_self.is(selectedObject.businessObject.sourceRef, 'bpmn:ExclusiveGateway') ||
                  _self.is(selectedObject.businessObject.sourceRef, 'bpmn:InclusiveGateway') ||
                  _self.is(selectedObject.businessObject.sourceRef, 'bpmn:ComplexGateway'))) {
                  if (!selectedObject.businessObject.conditionExpression) {
                    let element = _self.elementRegistry.get(selectedObject.businessObject.id);

                    /*selectedObject.businessObject.conditionExpression = {
                      $type: "bpmn:FormalExpression",
                      body: "lallo"
                    };*/
                    var newCondition = _self.moddle.create('bpmn:FormalExpression', { "type": "bpmn:tFormalExpression", "body": "" });
                    //newCondition.$parent = selectedObject.businessObject;
                    selectedObject.businessObject.conditionExpression = newCondition;
                    _self.modeling.updateProperties(element, {
                      businessObject: selectedObject.businessObject
                    });
                  }
                } else {
                  _self.selectedObject = null;
                  return;
                }
              }
              _self.selectedObject = selectedObject.businessObject;
            } else {
              _self.selectedObject = null;
            }
          });
          let rootElement = _self.canvas.getRootElement();
          rootElement.children.forEach(element => {
            _self.modeling.removeElements([element]);
          });
          _self.modeling.updateProperties(rootElement, {
            isExecutable: true
          });
        });
      }, 200);
    }
  }

  show() {
    this.newShow = false;
    this.openI = true;
    this.closeI = false;
    if (this.tempUser) {
      let elementTwo = document.getElementById(this.tempUser);
      elementTwo.classList.remove("color-change");
    }
    this.showP = false;
    this.openP = false;
    this.globals.stampa(this.showD);
    this.showD = false;
    // Solo she show arriva come valsa, viene trasformata in vera
    if (!this.showD) {
      this.showD = true;
      setTimeout(() => {
        let _self = this;
        let extension: any = camundaExtension;
        if (extension.default) {
          extension = extension.default;
        }
        _self.bpmnModeler = new BpmnModeler({
          container: _self.diagramEl.nativeElement,
          propertiesPanel: {
            parent: '#js-properties-panel'
          },
          additionalModules: [
            customModule,
            propertiesPanelModule,
            propertiesProviderModule
          ],
          moddleExtensions: {
            camunda: extension
          }
        });
        _self.bpmnModeler.createDiagram((err) => {
          if (err) {
            console.error(err);
          }
          _self.moddle = _self.bpmnModeler.get('moddle');
          _self.modeling = _self.bpmnModeler.get('modeling');
          _self.elementRegistry = _self.bpmnModeler.get('elementRegistry');
          _self.canvas = _self.bpmnModeler.get('canvas');
          _self.eventBus = _self.bpmnModeler.get('eventBus');
          _self.eventBus.on('selection.changed', (e) => {
            let selectedObject;
            if (e.newSelection.length == 1) {
              selectedObject = e.newSelection[0];
            } else {
              selectedObject = null;
            }
            //this.globals.stampa(selectedObject);
            if (selectedObject && selectedObject.businessObject) {
              if (_self.is(selectedObject.businessObject, 'bpmn:SequenceFlow')) {
                if (selectedObject.businessObject.sourceRef.default !== selectedObject.businessObject && (_self.is(selectedObject.businessObject.sourceRef, 'bpmn:ExclusiveGateway') ||
                  _self.is(selectedObject.businessObject.sourceRef, 'bpmn:InclusiveGateway') ||
                  _self.is(selectedObject.businessObject.sourceRef, 'bpmn:ComplexGateway'))) {
                  if (!selectedObject.businessObject.conditionExpression) {
                    let element = _self.elementRegistry.get(selectedObject.businessObject.id);
                    /*selectedObject.businessObject.conditionExpression = {
                      $type: "bpmn:FormalExpression",
                      body: "lallo"
                    };*/
                    var newCondition = _self.moddle.create('bpmn:FormalExpression', { "type": "bpmn:tFormalExpression", "body": "" });
                    //newCondition.$parent = selectedObject.businessObject;
                    selectedObject.businessObject.conditionExpression = newCondition;
                    /*_self.modeling.updateProperties(element, {
                      businessObject: selectedObject.businessObject
                    });*/
                  }
                } else {
                  _self.selectedObject = null;
                  return;
                }
              }
              _self.selectedObject = selectedObject.businessObject;
            } else {
              _self.selectedObject = null;
            }
          });
          let rootElement = _self.canvas.getRootElement();
          rootElement.children.forEach(element => {
            _self.modeling.removeElements([element]);
          });
          _self.modeling.updateProperties(rootElement, {
            isExecutable: true
          });
        });
      }, 200);
    }
  }

  showProcces() {
    this.newShow = false;
    this.openI = true;
    this.closeI = false;
    this.showD = false;
    this.openP = false;
    if (!this.showP) {
      this.showP = true;
      this.getProcces();
    } else {
      this.spinner.hide();
    }
  }

  open(pro) {
    if (this.tempUser) {
      let elementTwo = document.getElementById(this.tempUser);
      elementTwo.classList.remove("color-change");
    }
    var element = document.getElementById(`customer${pro.procces_id}`);
    element.classList.add("color-change");
    this.tempUser = `customer${pro.procces_id}`;
    this.newShow = false;
    this.showP = false;
    this.openP = true;
    this.showD = false;
    this.selectedPro = pro;
    this.getCustomers();
    this.getGroups();
    this.getProccesInstance();
  }

  getCustomers() {
    this.customerService.getCustomer(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.customers = res;
      },
      err => {
        console.error("Error on get customers");
        this.globals.messageServer(err);
      }
    )
  }

  getGroups() {
    this.groupService.getGroups(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.groups = res;
      },
      err => {
        console.error(("Error on get gropus"));
        this.globals.messageServer(err);
      }
    )
  }

  executeCus(obj) {
    let objToSend = {
      customer: obj,
      procces: this.selectedPro
    }
    this.activityService.executeProccesCus(this.token, objToSend, this.selectedPro.procces_id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa("Processo eseguito correttamente", res);
        this.getProccesInstance();
      },
      err => {
        console.error("Errore nell'eseguire il processo", err);
        this.globals.messageServer(err);
      })
  }

  executeGroup(obj) {
    let objToSend = {
      group: obj,
      procces: this.selectedPro
    }
    this.selectedPro.start_date = this.selectedPro.end_date;
    this.activityService.executeProccesGroup(this.token, objToSend, this.selectedPro.procces_id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa("Processo eseguito correttamente", res);
        this.getProccesInstance();
      },
      err => {
        console.error("Errore nell'eseguire il processo", err);
        this.globals.messageServer(err);
      })
  }

  changeData(data) {
    data.forEach((dat) => {
      let date = new Date(dat.createdat);
      if (date.getMonth() + 1 > 10 && date.getDate() >= 10) {
        dat.createdat = '' + (date.getDate()) + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        // this.globals.stampa("mese e giorno >10")
      } else {
        if (date.getMonth() + 1 < 10 && date.getDate() < 10) {
          dat.createdat = '' + (date.getDate()) + '/' + '0' + (date.getMonth() + 1) + '/' + '0' + date.getFullYear();
          // this.globals.stampa("mese e giorno <10")
        } else {
          if (date.getMonth() + 1 < 10) {
            dat.createdat = '' + (date.getDate()) + '/' + '0' + (date.getMonth() + 1) + '/' + date.getFullYear();
            // this.globals.stampa("mese <10")
          }
          if (date.getDate() < 10) {
            dat.createdat = '' + (date.getDate()) + '/' + (date.getMonth() + 1) + '/' + '0' + date.getFullYear();
            // this.globals.stampa("giorno <10")
          }
        }
      }
    })
  }

  getProccesInstance() {
    this.aperto = []
    this.chiuso = []
    this.activityService.getProccesInstances(this.token, this.selectedPro.procces_id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.instances = res;
        this.instances.forEach(proc => {
          proc.createdat = this.globals.cleanDateAndHour(proc.createdat);
          this.changeData(this.chiuso);
          if (proc.state == "Chiuso") {
            this.chiuso.push(proc);
          } else {
            this.aperto.push(proc);
          }
        });

      },
      err => {
        console.error("Errore nell'ottenere le istanze di processo: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  viewCloseI() {
    if (!this.closeI) {
      this.closeI = true;
      this.openI = false;
    }
  }

  showBpmn() {
    this.xml = this.selectedPro.bpmn;
    this.show();
    setTimeout(() => {
      this.importDiagram();
    }, 700);
  }

}
