import { Component, OnInit, Input, Inject } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalendarService } from 'src/app/services/calendar.service';

export interface DialogData {
  calendar_id: number;
}

@Component({
  selector: 'app-recipientslist',
  templateUrl: './recipientslist.component.html',
  styleUrls: ['./recipientslist.component.scss']
})
export class RecipientslistComponent implements OnInit {

  calendarId: number;
  token: string;
  recipients: any;

  constructor(
    public dialogRef: MatDialogRef<RecipientslistComponent>,
    private globals: GlobalService,
    private spinner: NgxSpinnerService,
    private calendarService: CalendarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    if(!this.token){
      this.token = this.globals.getToken();
    }
    this.calendarId = this.data.calendar_id; 
    this.getRecipients()
  }

  getRecipients(){
    this.calendarService.getRecipientsList(this.token, this.calendarId).subscribe(
      (res) => {
        this.recipients = res.values;
        this.spinner.hide();
      }, 
      (err) => {
        console.error('Error on get recipients: ', err);
        this.spinner.hide();
      }
    )
  }

  close() {
    this.dialogRef.close();
  }

}
