import { Component, OnInit, Input } from '@angular/core';
import { AssocService } from 'src/app/services/assoc.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/services/global.service';
import { faUserCircle, faBuilding } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-assoc',
  templateUrl: './assoc.component.html',
  styleUrls: ['./assoc.component.scss']
})
export class AssocComponent implements OnInit {

  @Input() id

  //sting
  token

  //array
  profile: Array<any> = [];
  selectedUser: Array<any> = [];
  customers: Array<any> = [];

  //Number
  i = 0;

  //Icone
  faUserCircle = faUserCircle;
  faBuilding = faBuilding;

  constructor(
    private associa: AssocService,
    private modalService: NgbModal,
    private globals: GlobalService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    if(!this.token){
      this.token = this.globals.getToken();
    }
    this.getCustomer();
    this.getAssoc();
  }

  assoc() { 
    this.selectedUser.forEach(id => {
      this.customers.push({
        customer_id: id.customer_id
      })
    });

    this.associa.assocContractToCustomers(this.token, { customers: this.customers }, this.id.contract_id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.activeModal.close();
      },
      err => {
        console.error("Errore in assocToCustomer: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  getAssoc() {
    this.associa.getAssocCustomersToContract(this.token, this.id.contract_id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.selectedUser = res ? res : [];
      },
      err => {
        console.error("Errore in getAssoc: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  getCustomer() {
    this.associa.getCustomer(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.profile = res;
      }
    )
  }

  addUser(passUser) {
    let control: boolean = false

    this.selectedUser.forEach(use => {
      if(use.customer_id == passUser.customer_id){
        control = true;
      }
    });

    if(control==false){
    this.i = this.i + 1;

    this.selectedUser.push(passUser);

    } else {
      alert ("il profilo selezionato è già stato inserito nella lista")
    }

    

  }

  deleteUtenza(id) {
    this.selectedUser.splice(this.selectedUser.indexOf(id), 1)
  }

  leave() {
    this.activeModal.close('cancel');
  }

}
