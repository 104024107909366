import { environment as defaultEnvironment } from './environment.defaults';

export const environment = {
  ...defaultEnvironment,
  production: false,
  server: 'demo.lamatech.it/service',
  port: '',
  http: 'https',
  assetsBaseUrl: 'https://demo.lamatech.it'
};
