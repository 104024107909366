import { Component, OnInit, AfterViewInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { OperatorService } from 'src/app/services/operator.service';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faSearch, faPlus, faUserCog, faUser } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

import swal from 'sweetalert2';
import { EliminaService } from 'src/app/services/elimina.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { ListCustomerAssociatesComponent } from 'src/app/popup/list-customer-associates/list-customer-associates.component';

declare const $: any;

@Component({
  selector: 'app-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss']
})

export class OperatorComponent implements OnInit {

  envi = environment;
  //tipe
  token: string;
  id: number;
  profile: number;
  selectedOP: number;
  _listFilter = '';
  selectedOPName: string;
  disponibility: number;
  cPassword: string;
  cEmail: string;

  //any
  activeUser: any;
  tempUser: any;

  //Oggetto
  obj: any = {};
  objUpdate: any = {};
  selectedOperator: object = {};

  //Array
  profiles: Array<any> = [];
  operators: Array<any> = [];
  descrizione: Array<any> = [];
  filteredOperators: Array<any> = [];

  //Boolean
  del_update: boolean = false;
  vero: boolean = true;
  new: boolean = true;
  choose: boolean;
  vanish: boolean
  nome: boolean;
  cognome: boolean;
  email: boolean;
  username: boolean;
  pass: boolean;
  CPass: boolean;
  CMail: boolean;
  isOnlyRead = false;
  validEmailType: boolean = false;

  // Icone
  faUserCircle = faUserCircle;
  faSearch = faSearch;
  faUserCog = faUserCog;
  faUser = faUser;
  faPlus = faPlus;

  table: any;

  constructor(
    private operatorService: OperatorService,
    private globals: GlobalService,
    private modalService: NgbModal,
    private Delete: EliminaService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    this.filteredOperators = this.operators;
  }

  ngOnInit() {
    this.globals.checkPage(1);
    this.activeUser = this.globals.storagedUser;
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    // this.getProfile();
    // this.getOperator();
    // this.getDisponibility();
    this.getInitPage();

    
  }


  get listFilter(): string {
    return this._listFilter;
  }

  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredOperators = this.listFilter ? this.doFilter(this.listFilter) : this.operators;
  }

  reload() {
    $('#datatables').DataTable().destroy();
    this.svuotaSpan();
    this.isOnlyRead = false;
    this.tempUser = null;
    this.del_update = false;
    this.choose = false;
    this.vero = true;
    this.new = true;
    this.operators = [];
    this.getInitPage();
  }

  getInitPage() {
    this.spinner.show();
    this.operatorService.getInitPage(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        let varTemp
          new Promise((resolve, reject) => {
            this.operators = res.operators;
            this.profiles = res.profiles;
            this.disponibility = res.disponibility;
            this.operators.forEach((op, k) => {
              if (op.user_id == this.activeUser.user_id) {
                varTemp = k;
              }
            });
            // Creo il campo descrizione per il campo di ricerca
            this.operators.forEach(operator => {
              operator.description = `${operator.username} ${operator.email} ${operator.name} ${operator.last_name} ${operator.name}`;
            })
            // Setto il filtro per la ricerca a campo vuoto
            this.listFilter = '';
            this.operators.forEach((op) => {
              this.profiles.forEach((prof) => {
                if (op.profile_id == prof.profile_id) {
                  op.profile_name = prof.description;
                }
              })
            })
            resolve();
          }).then(() => {
            let _self = this;
            let ops = [];
            let tableObject = {
              data: []
            }
            this.operators.forEach((op) => {
              let obj = {
                user_id: op.user_id,
                username: op.username,
                last_name: op.last_name,
                name: op.name,
                email: op.email,
                profile_name: op.profile_name
              }
              ops.push(obj);
            });
            tableObject.data = ops;
            let config = {
              pagingType: "full_numbers",
              lengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
              ],
              responsive: {
                details: {
                  type: 'column',
                  target: 'tr'
                }
              },
              language: {
                search: "_INPUT_",
                searchPlaceholder: "Ricerca operatori",
                url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
              },
              ajax: (data, callback, settings) => {
                callback(tableObject)
              },
              columns: [
                { title:'ID', data: 'user_id' },
                { title:'Username', data: 'username' },
                { title:'Cognome', data: 'last_name' },
                { title:'Nome', data: 'name' },
                { title:'Email', data: 'email' },
                { title:'Tipologia', data: 'profile_name' },
                { title:'Azione', data: null, className: 'td-actions text-right' }
              ],
              columnDefs: [
                {
                  targets: [0],
                  visible: false
                },
                {
                  targets: [6],
                  visible: true,
                  render: function (data, type, full, meta) {
                    full = JSON.stringify(full);
                    return `<a data-op='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                          <i class="material-icons">search</i>
                        </a>
                        <a data-op='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                          <i class="material-icons">edit</i>
                        </a>
                        <a data-op='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                          <i class="material-icons">close</i>
                        </a>`
                  }
                }
              ]
            }
            $(document).ready(function () {
              this.table = $('#datatables').DataTable(config);

              $('#datatables').on('click', 'a.btn-info', function () {
                let OP: any = $(this).data('op')
                _self.globals.stampa("Op: ", OP);
                _self.operators.forEach((op) => {
                  if (op.user_id == OP.user_id) {
                    _self.showFormOp(op, true);
                  }
                })
              });
              $('#datatables').on('click', 'a.btn-success', function () {
                // _self.globals.stampa($(this).data('cus'));
                let OP = $(this).data('op')
                _self.globals.stampa("Op: ", OP);
                _self.operators.forEach((op) => {
                  if (op.user_id == OP.user_id) {
                    _self.showFormOp(op);
                  }
                })
              });
              $('#datatables').on('click', 'a.btn-danger', function () {
                // _self.globals.stampa($(this).data('cus'));
                let OP = $(this).data('op')
                _self.globals.stampa("Op: ", OP);
                _self.operators.forEach((op) => {
                  if (op.user_id == OP.user_id) {
                    _self.delProfile(op);
                  }
                })
              });
              _self.spinner.hide();
            })
          }).catch((err) => {
            this.globals.messageServer(err);
            console.error("Error on get  Operators", err);
            this.spinner.hide();
          })
      }
    )
  }

  getOperator() {

    this.spinner.show();
    this.operatorService.getOperator(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
          this.operators = res;
          this.operators.forEach((op) => {
            this.profiles.forEach((prof) => {
              if (op.profile_id == prof.profile_id) {
                op.profile_name = prof.description;
              }
            })
          })
      },
      err => {
        console.error("Errore in getOperators", err);
        this.spinner.hide();
        this.globals.messageServer(err);
      }
    )
  }

  changeStatus(profile) {
    this.isOnlyRead = false;
    this.newOperator()
    this.selectedOP = profile.profile_name;
    this.selectedOPName = profile.description;
  }

  showFormOp(op, onlyShow?) {
    this.globals.stampa(op);
    if (onlyShow) {
      this.isOnlyRead = true;
    }
    this.svuotaSpan();
    this.del_update = true;
    this.choose = true;
    this.new = false;
    this.selectedOperator = op;
    this.id = op.user_id;
    this.getProfileName(op.profile_id);
  }

  newOperator() {
    this.svuotaSpan();
    this.del_update = false;
    this.new = true;
    this.selectedOperator = {}
    this.choose = true;
    this.cPassword = '';
    this.cEmail = '';
  }

  getProfile() {
    this.choose = false;
    this.operatorService.getOperatorProfile(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.profiles = res
      },
      err => {
        console.error("Errore in getProfile: ", err);
        this.globals.messageServer(err);
      })
  }

  svuotaSpan() {
    if (document.getElementById("opName")) {
      document.getElementById("opName").innerHTML = "";
    }
    if (document.getElementById("opLast_Name")) {
      document.getElementById("opLast_Name").innerHTML = "";
    }
    if (document.getElementById("opEmail")) {
      document.getElementById("opEmail").innerHTML = "";
    }
    if (document.getElementById("cusLast_Name")) {
      document.getElementById("cusLast_Name").innerHTML = ""
    }
    if (document.getElementById("opUsername")) {
      document.getElementById("opUsername").innerHTML = ""
    }
    if (document.getElementById("opPassword")) {
      document.getElementById("opPassword").innerHTML = ""
    }
    if (document.getElementById("opC_Email")) {
      document.getElementById("opC_Email").innerHTML = ""
    }
    if (document.getElementById("opC_Password")) {
      document.getElementById("opC_Password").innerHTML = ""
    }
  }

  emailValidationType(e) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(e).toLowerCase())) {
      this.validEmailType = true;
      document.getElementById("opEmail").innerHTML = "";
    } else {
      this.validEmailType = false;
      document.getElementById("opEmail").innerHTML = "↓ Email non valida";
    }
  }

  addOperator(selectedOperator, cPassword, cEmail) {
    if (selectedOperator.name) {
      this.nome = true;
      document.getElementById("opName").innerHTML = "";
    } else {
      this.nome = false;
      document.getElementById("opName").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (selectedOperator.last_name) {
      this.cognome = true;
      document.getElementById("opLast_Name").innerHTML = "";
    } else {
      this.cognome = false;
      document.getElementById("opLast_Name").innerHTML = "↓ Il campo è obbligatorio";
    }
    if (selectedOperator.email) {
      this.email = true;
      document.getElementById("opEmail").innerHTML = "";
    } else {
      this.email = false;
      document.getElementById("opEmail").innerHTML = "↓ Il campo è obbligatorio";
    }
    if (selectedOperator.username) {
      this.username = true;
      document.getElementById("opUsername").innerHTML = "";
    } else {
      this.username = false;
      document.getElementById("opUsername").innerHTML = "↓ Il campo è obbligatorio";
    }


    if (selectedOperator.password) {
      this.pass = true;
      document.getElementById("opPassword").innerHTML = "";
    } else {
      this.pass = true;
    }
    if (cPassword) {
      this.CPass = true;
      document.getElementById("opC_Password").innerHTML = "";
    } else {
      this.CPass = true;
    }

    if (cEmail) {
      this.CMail = true;
      document.getElementById("opC_Email").innerHTML = "";
    } else {
      this.CMail = false;
      document.getElementById("opC_Email").innerHTML = "↓ Il campo è obbligatorio";
    }


    if (this.nome && this.cognome && this.email && this.username && this.pass && this.CPass && this.CMail && this.validEmailType) {
      this.profile = this.selectedOP;
      if (selectedOperator.email == cEmail) {
        if (selectedOperator.password || cPassword) {
          if (selectedOperator.password == cPassword) {
            this.spinner.show();
            this.obj = {
              user: this.selectedOperator,
              profile: this.profile
            }
            this.operatorService.addOperator(this.token, this.obj).subscribe(
              res => {
                this.globals.messageServer(res);
                this.globals.showNotification("Operatore creato", 'success');
                this.reload();
                this.selectedOperator = {};
                this.cPassword = '';
                this.cEmail = '';
              },
              err => {
                this.spinner.hide();
                console.error("Errore in addOperator: ", err);
                this.globals.messageServer(err);
              }
            )
          } else {
            this.globals.showNotification("Le due passsword non corrispondono", "warning");
          }
        } else {
          this.spinner.show();
          this.obj = {
            user: this.selectedOperator,
            profile: this.profile
          }
          this.operatorService.addOperator(this.token, this.obj).subscribe(
            res => {
              this.globals.messageServer(res);
              this.globals.showNotification("Operatore creato", 'success');
              this.reload();
              this.selectedOperator = {};
              this.cPassword = '';
              this.cEmail = '';
            },
            err => {
              this.spinner.hide();
              console.error("Errore in addOperator: ", err);
              this.globals.messageServer(err);
            }
          )
        }
      } else {
        this.globals.showNotification("Le due mail non corrispondono", "warning");
      }
    } else {
      if (this.email && this.cEmail && !this.validEmailType)
        document.getElementById("opEmail").innerHTML = "↓ Email non valida";
    }
  }

  delProfile(user) {
    this.globals.stampa("ActiverUse: ", this.activeUser, "User: ", user);
    if (this.activeUser.profile_id >= user.profile_id) {
      swal({
        title: `Cancellare ${user.last_name} ${user.name}?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: false
      }).then((result) => {

        if (result.value) {
          this.spinner.show();
          this.Delete.deleteProfile(this.token, user.user_id).subscribe(
            res => {
              this.globals.messageServer(res);
              this.selectedOperator = {};
              this.choose = false;
              this.reload();

              this.globals.showNotification("Operatore eliminato con successo!", "success");
              this.spinner.hide();
            },
            err => {
              this.spinner.hide();
              console.error("Errore in delOperator ", err);
              this.globals.showNotification("Errore cancellazione operatore!", "danger");
              this.globals.messageServer(err);
            }
          )
        } else {
        }
      })
    } else {
      this.globals.showNotification("Non puoi cancellare questo profilo", "danger");
    }

  }

  updateProfile(username, email, name, last_name, user_id) {

    if (name) {
      this.nome = true;
      document.getElementById("opName").innerHTML = "";
    } else {
      this.nome = false;
      document.getElementById("opName").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (last_name) {
      this.cognome = true;
      document.getElementById("opLast_Name").innerHTML = "";
    } else {
      this.cognome = false;
      document.getElementById("opLast_Name").innerHTML = "↓ Il campo è obbligatorio";
    }
    if (email) {
      this.email = true;
      document.getElementById("opEmail").innerHTML = "";
    } else {
      this.email = false;
      document.getElementById("opEmail").innerHTML = "↓ Il campo è obbligatorio";
    }
    if (username) {
      this.username = true;
      document.getElementById("opUsername").innerHTML = "";
    } else {
      this.username = false;
      document.getElementById("opUsername").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (this.nome && this.cognome && this.email && this.username) {

      this.objUpdate = {
        username,
        email,
        name,
        last_name
      }
      this.spinner.show();
      this.operatorService.updateProfile(this.token, user_id, { user: this.objUpdate }).subscribe(
        res => {
          this.globals.messageServer(res);
          this.reload();
          this.globals.showNotification("Operatore salvato con successo!", "success");
          this.spinner.hide();
          this.globals.stampa("Risposta in updateOperator (profile): ", res);
        },
        err => {
          console.error("Errore in updateUser", err);
          this.globals.showNotification("Errore salvataggio operatore!", "error");
          this.spinner.hide();
          this.globals.messageServer(err);
        }
      )
    }
  }

  doFilter(filterBy: string) {
    filterBy = filterBy.toLocaleLowerCase();
    return this.operators.filter((operator) =>
      operator.description.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  getProfileName(profile_id) {
    this.profiles.forEach((prof) => {
      if (prof.profile_id == profile_id) {
        return this.selectedOPName = prof.description;
      }
    })
  }

  getDisponibility() {
    this.operatorService.getDisponibility(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.disponibility = res.disponibility;
      },
      err => {
        console.error("Error on get disponibility", err);
        this.globals.messageServer(err);
      }
    )
  }

  openList(us) {
    this.spinner.show();
    const dialogRef = this.dialog.open(ListCustomerAssociatesComponent, {
      width: '50%',
      // height: '70%',
      data: {
        user_id: us
      },
      // disableClose: true
    })

  }

}