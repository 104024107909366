import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';


import { catchError, retry } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { User } from '../classes';
import { GlobalService } from '../services/global.service';


@Injectable({
  providedIn: 'root'
})

export class LoginService {

  constructor(private http: HttpClient,
    private globals: GlobalService) { }

  doLogin(user: User): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
    return this.http.post<any>(this.globals.loginURL, user, httpOptions).pipe(
      catchError(this.globals.handleError)
    );
  }

  secondLogin(token, id): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };

    return this.http.get<any>(this.globals.secondLoginURL(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    );
  }

  doLogout(token): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.get<any>(this.globals.logoutURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

}