import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPlus, faFolderOpen as fafolderopen, faUser } from '@fortawesome/free-solid-svg-icons';
import { faFile, faFolderOpen, faUserCircle } from '@fortawesome/free-regular-svg-icons';

import { GlobalService } from 'src/app/services/global.service';
import { DocumentaryService } from '../../services/documentary.service';
import { NewfolderComponent } from 'src/app/popup/newfolder/newfolder.component';
import { UploadfileComponent } from 'src/app/popup/uploadfile/uploadfile.component'
import { ChangeNameFolderComponent } from 'src/app/popup/change-name-folder/change-name-folder.component';
import { MatDialog } from '@angular/material';
import { NewfolderService } from 'src/app/services/newfolder.service';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-documentary',
  templateUrl: './documentary.component.html',
  styleUrls: ['./documentary.component.scss'],
  providers: [DocumentaryService]
})

export class DocumentaryComponent implements OnInit {
  // string
  token: string;
  folderid: string;

  // any
  root: any;
  selectedDir: any;
  activeUser: any;
  user: any;

  // array
  parentID: Array<any> = [];
  menu: Array<any> = [];
  navigationArray: Array<any> = [];

  //variabili doppie
  selectedDirTwo: Array<any> = [];
  menuTwo: Array<any> = [];
  parentIDTwo: Array<any> = [];
  folderidTwo: string;
  disableTwo = false;
  showTwo = false;

  tempID: string;
  tempIDTwo: string;


  // boolean
  show = false;
  enterIf = false;
  disable = false;
  double = false;


  // number
  i = 1;

  // formData
  formData: any;
  uploadForm: FormGroup;

  // Icone
  faPlus = faPlus;
  faFile = faFile;
  faFolderOpen = faFolderOpen;
  faUserCircle = faUserCircle;
  fafolderopen = fafolderopen;
  faUser = faUser;


  //test
  mq = window.matchMedia("(min-width: 700px)");

  constructor(
    private documentaryService: DocumentaryService,
    private modalService: NgbModal,
    private globals: GlobalService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public newfolderService: NewfolderService,
    private spinner: NgxSpinnerService
  ) {

  }

  ngOnInit() {
    this.globals.checkPage(3);
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.activeUser = this.globals.storagedUser;
    this.globals.stampa("AcrtiveUser: ", this.activeUser)
    this.getRoot();
    this.getRootSecond();
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
    this.formData = new FormData();

    if (this.activeUser.profile_id == 1) {
      this.double = true;
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('profile').setValue(file);
    }
  }

  changeLastModify(file) {
    file.forEach((fil) => {
      if (fil.entry.isFile == true) {
        fil.entry.modifiedAt = this.globals.cleanDateAndHour(fil.entry.modifiedAt);
      }
    })
  }

  changeDimension(byte) {
    byte.forEach(byt => {
      if (byt.entry.isFile == true) {
        if (byt.entry.content.sizeInBytes < 1024) {
          Math.round(parseInt(byt.entry.content.sizeInBytes))
          byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes + " B";
        } else {
          if (byt.entry.content.sizeInBytes >= 1073741824) {
            byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes / 1073741824;
            byt.entry.content.sizeInBytes = Math.round(parseInt(byt.entry.content.sizeInBytes));
            byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes + " GB"
          } else {
            if (byt.entry.content.sizeInBytes >= 1048576) {
              byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes / 1048576;
              byt.entry.content.sizeInBytes = Math.round(parseInt(byt.entry.content.sizeInBytes));
              byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes + " MB"
            } else {
              if (byt.entry.content.sizeInBytes >= 1024) {
                byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes / 1024;
                byt.entry.content.sizeInBytes = Math.round(parseInt(byt.entry.content.sizeInBytes));
                byt.entry.content.sizeInBytes = byt.entry.content.sizeInBytes + " KB"
              }
            }
          }
        }
      }
    });
  }

  changeCreateAt(file) {
    file.forEach((fil) => {
      if (fil.entry.isFile == true) {
        fil.entry.createdAt = this.globals.cleanDateAndHour(fil.entry.createdAt)
      }
    })
    this.globals.stampa("createAt: ", file);

  }

  getRoot() {
    this.spinner.show();
    this.documentaryService.getRoot(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.root = res;
        this.folderid = res.folder_id
        this.navigationArray = res;
        this.selectedDir = res;
        if (this.activeUser.profile_id == 1) {
          this.root.splice("Clienti", 1);
        }
        this.changeLastModify(this.selectedDir);
        this.changeDimension(this.selectedDir);
        this.changeCreateAt(this.selectedDir);

        this.show = false;
        this.menu = [];
        this.parentID = [];
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        console.error("Errore in getRoot: ", err);
        this.globals.messageServer(err);
      }
    )

  }

  getRootSecond() {
    if (this.activeUser.profile_id == 1) {
      this.spinner.show();
      this.documentaryService.getFolderContent(this.token, this.activeUser.folder_id).subscribe(
        res => {
          this.globals.messageServer(res);
          this.selectedDirTwo = res.list
          this.folderidTwo = res.list.folder_id
          this.showTwo = false;
          this.menuTwo = [];
          this.parentIDTwo = [];
          this.changeLastModify(this.selectedDirTwo);
          this.changeDimension(this.selectedDirTwo);
          this.changeCreateAt(this.selectedDirTwo);
          this.spinner.hide();
        },
        err => {
          console.error("Errore in getFolderContent, profile ID = 1", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )
    }
  }

  changeFolder(file) {
    this.spinner.show();
    this.disable = true;
    this.documentaryService.getFolderContent(this.token, file.id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.folderid = file.id;
        if (this.parentID.indexOf(file.id) <= 0) {
        } else {
          this.menu.splice(this.menu.indexOf(file) + 1, this.menu.length);
          let index2 = this.parentID.indexOf(file.id);
          let lungheezza2 = this.parentID.length
          let passo2 = lungheezza2 - index2
          this.parentID.splice(index2, passo2);
          this.selectedDir = res.list;
          this.changeLastModify(this.selectedDir);
          this.changeDimension(this.selectedDir);
          this.changeCreateAt(this.selectedDir);
          this.spinner.hide();
        }
        this.disable = false;
        this.spinner.hide();
      },
      err => {
        console.error("Errore in getFolderContent (changeFolder): ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
        this.disable = false;
      }
    )
  }


  changeFolderTwo(file) {
    this.spinner.show();
    this.disableTwo = true;
    this.documentaryService.getFolderContent(this.token, file.id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.folderidTwo = file.id;
        if (this.parentIDTwo.indexOf(file.id) <= 0) {
        } else {
          this.menuTwo.splice(this.menuTwo.indexOf(file) + 1, this.menu.length);
          let index2 = this.parentIDTwo.indexOf(file.id);
          let lungheezza2 = this.parentIDTwo.length
          let passo2 = lungheezza2 - index2
          this.parentIDTwo.splice(index2, passo2);
          this.selectedDirTwo = res.list;

          this.changeLastModify(this.folderidTwo);
          this.changeDimension(this.folderidTwo);
          this.changeCreateAt(this.folderidTwo);
          this.spinner.hide();

        }
        this.disableTwo = false;
        this.spinner.hide();
      },
      err => {
        this.disableTwo = false;
        this.spinner.hide();
      }
    )
  }


  openFolder(file) {

    this.disable = true;
    if (file == "home") {
      this.getRoot();
    } else {
      this.spinner.show();
      this.documentaryService.getFolderContent(this.token, file.id).subscribe(
        res => {
          this.globals.messageServer(res);
          this.folderid = file.id;
          this.parentID.push(file.parentId);
          this.menu.push(file);
          this.selectedDir = res.list;
          this.changeLastModify(this.selectedDir);
          this.changeDimension(this.selectedDir);
          this.changeCreateAt(this.selectedDir);
          this.show = true;
          this.disable = false;
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          console.error("Errore in openFolder: ", err);
          this.globals.messageServer(err);
          this.disable = false;
        }
      )
      err => {
        this.spinner.hide();
        console.error("errore in getFolderContent(open Folder): ", err);
        this.globals.messageServer(err);
        this.disable = false;
      }
    }
  }

  openFolderTwo(file) {

    this.disableTwo = true;
    if (file == "home") {
      this.getRootSecond();
    } else {
      this.spinner.show();
      this.documentaryService.getFolderContent(this.token, file.id).subscribe(
        res => {
          this.globals.messageServer(res);
          this.folderidTwo = file.id;
          this.parentIDTwo.push(file.parentId);
          this.menuTwo.push(file);
          this.selectedDirTwo = res.list;
          this.changeLastModify(this.selectedDirTwo);
          this.changeDimension(this.selectedDirTwo);
          this.changeCreateAt(this.selectedDirTwo);
          this.showTwo = true;
          this.disableTwo = false;
          this.spinner.hide();
        },
        err => {
          console.error("Errore in openFolder: ", err);
          this.disableTwo = false;
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )
      err => {
        console.error("errore in getFolderContent(open Folder): ", err);
        this.disableTwo = false;
        this.globals.messageServer(err);
        this.spinner.hide();
      }
    }
  }

  addChild(file) {
    this.spinner.show();
    if (!file.child) {
      this.documentaryService.getFolderContent(this.token, file.id).subscribe(
        res => {
          this.globals.messageServer(res);
          file.child = res.list;
          this.spinner.hide();
        },
        err => {
          console.error("Error on get child: ", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )
    }
  }

  backFolder() {

    this.disable = true;
    if (this.menu.length - 1 == 0) {
      this.getRoot();
    } else {
      this.spinner.show();
      this.documentaryService.getFolderContent(this.token, this.parentID[this.parentID.length - 1]).subscribe(
        res => {
          this.folderid = this.parentID[this.parentID.length - 1]
          this.globals.messageServer(res);
          this.menu.splice(this.menu.length - 1, 1);
          this.parentID.splice(this.parentID.length - 1, 1);
          this.selectedDir = res.list;
          this.changeLastModify(this.selectedDir);
          this.changeDimension(this.selectedDir);
          this.changeCreateAt(this.selectedDir);
          this.disable = false;
          this.spinner.hide();
        },
        err => {
          console.error("Errore in backFolder: ", err);
          this.disable = false;
          this.spinner.hide();
          this.globals.messageServer(err);
        }
      )
    }
  }

  backFolderTwo() {

    this.disableTwo = true;
    if (this.menuTwo.length - 1 == 0) {
      this.getRootSecond();
    } else {
      this.spinner.show();
      this.documentaryService.getFolderContent(this.token, this.parentIDTwo[this.parentIDTwo.length - 1]).subscribe(
        res => {
          this.globals.messageServer(res);
          this.folderidTwo = this.parentIDTwo[this.parentIDTwo.length - 1]
          this.menuTwo.splice(this.menuTwo.length - 1, 1);
          this.parentIDTwo.splice(this.parentIDTwo.length - 1, 1);
          this.selectedDirTwo = res.list;
          this.changeLastModify(this.selectedDirTwo);
          this.changeDimension(this.selectedDirTwo);
          this.changeCreateAt(this.selectedDirTwo);
          this.disableTwo = false;
          this.spinner.hide();
        },
        err => {
          console.error("Errore in backFolder: ", err);
          this.disableTwo = false;
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )
    }
  }

  newFolder(name) {
    this.spinner.show();
    const dialogRef = this.dialog.open(NewfolderComponent, {
      width: "70%",
      // height: "80%",
      data: {
        name: name,
        id: this.folderid
      }
    })
    dialogRef.afterClosed().subscribe(result => {

      if (result != undefined) {
        if (result != 'cancel') {
          this.spinner.show();
          this.documentaryService.getFolderContent(this.token, this.folderid).subscribe(
            res => {
              this.globals.messageServer(res);
              this.selectedDir = res.list;
              this.changeLastModify(this.selectedDir);
              this.changeDimension(this.selectedDir);
              this.changeCreateAt(this.selectedDir);
              this.spinner.hide();
            },
            err => {
              console.error("Errore in getFolderContent: ", err);
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          )
        }
      }
    });
  }

  newFolderTwo(name) {
    this.spinner.show();
    const dialogRef = this.dialog.open(NewfolderComponent, {
      width: "70%",
      data: {
        name: name,
        id: this.folderid
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result != 'cancel') {
          this.spinner.show();
          this.documentaryService.getFolderContent(this.token, this.folderid).subscribe(
            res => {
              this.globals.messageServer(res);
              this.selectedDirTwo = res.list;
              this.changeLastModify(this.selectedDirTwo);
              this.changeDimension(this.selectedDirTwo);
              this.changeCreateAt(this.selectedDirTwo);
              this.spinner.hide();
            },
            err => {
              console.error("Errore in getFolderContentTwo: ", err);
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          )
        }
      }

    });
  }

  delFolder(file, type?) {
    if (file) {
      let text = 'Eliminata'
      let title = 'Eliminata'
      if (type == 'FOLDER') {
        text = 'Cartella eliminata';
        title = 'Eliminata'
      } else if (type == 'FILE') {
        text = 'File eliminato';
        title = 'Eliminato'
      }
      this.spinner.show();
      this.documentaryService.delFolder(this.token, file.id).subscribe(
        res => {
          this.globals.messageServer(res);
          this.documentaryService.getFolderContent(this.token, this.folderid).subscribe(
            res => {
              this.globals.messageServer(res);
              this.selectedDir = res.list;
              this.changeLastModify(this.selectedDir);
              this.changeDimension(this.selectedDir);
              this.changeCreateAt(this.selectedDir);

              this.globals.showNotification(text, 'success');

              this.spinner.hide();
            },
            err => {
              console.error("errore in getFolderContent(open Folder): ", err);
              this.disable = false;
              this.globals.messageServer(err);
              this.spinner.hide();;
            }
          )

        },
        err => {
          console.error("Errore in newFolder: ", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )

    }

  }

  delFolderTwo(file, type?) {

    let text = 'Eliminata'
    let title = 'Eliminata'
    if (type == 'FOLDER') {
      text = 'Cartella eliminata';
      title = 'Eliminata'
    } else if (type == 'FILE') {
      text = 'File eliminato';
      title = 'Eliminato'
    }
    this.spinner.show();
    this.documentaryService.delFolder(this.token, file.id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.documentaryService.getFolderContent(this.token, this.folderidTwo).subscribe(
          res => {
            this.globals.messageServer(res);
            this.selectedDirTwo = res.list;
            this.changeLastModify(this.selectedDirTwo);
            this.changeDimension(this.selectedDirTwo);
            this.changeCreateAt(this.selectedDirTwo);
            this.showTwo = true;
            this.disableTwo = false;

            this.globals.showNotification(text, "success")
            this.spinner.hide();
          },
          err => {
            console.error("Errore in openFolder: ", err);
            this.disableTwo = false;
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        )
        err => {
          console.error("errore in getFolderContent(open Folder): ", err);
          this.disableTwo = false;
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      },
      err => {
        console.error("Errore in newFolder: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      })
  }

  updateFolderName(id) {
    let obj
    this.spinner.show();
    this.documentaryService.updateName(this.token, id, obj).subscribe(
      res => {
        this.globals.messageServer(res);
        this.documentaryService.getFolderContent(this.token, this.folderid).subscribe(
          res => {
            this.globals.messageServer(res);
            this.selectedDir = res.list;
            this.changeLastModify(this.selectedDir);
            this.changeDimension(this.selectedDir);
            this.changeCreateAt(this.selectedDir);
            this.spinner.hide();
          },
          err => {
            console.error("Errore in openFolder: ", err);
            this.disable = false;
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        )
        err => {
          console.error("errore in getFolderContent(open Folder): ", err);
          this.disable = false;
          this.globals.messageServer(err);
          this.spinner.hide();
        }

      },
      err => {
        console.error("Errore in newFolder: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      }
    )
  }
  updateFolderNameTwo(id) {
    this.globals.stampa(id);

    let obj
    this.spinner.show();
    this.documentaryService.updateName(this.token, id, obj).subscribe(
      res => {
        this.globals.messageServer(res);
        this.documentaryService.getFolderContent(this.token, this.folderidTwo).subscribe(
          res => {
            this.globals.messageServer(res);
            this.selectedDirTwo = res.list;
            this.changeLastModify(this.selectedDirTwo);
            this.changeDimension(this.selectedDirTwo);
            this.changeCreateAt(this.selectedDirTwo);
            this.spinner.hide();
          },
          err => {
            console.error("Errore in openFolder: ", err);
            this.disable = false;
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        )
        err => {
          console.error("errore in getFolderContent(open Folder): ", err);
          this.disable = false;
          this.globals.messageServer(err);
          this.spinner.hide();
        }

      },
      err => {
        console.error("Errore in newFolder: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      }
    )
  }


  openPopup(file) {
    if (file) {
      this.globals.stampa("File: ", file);
      this.spinner.show();
      const dialogRef = this.dialog.open(ChangeNameFolderComponent, {
        width: "70%",
        // height: "90%",
        data: file
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'Cancel') {
          this.spinner.show();
          this.documentaryService.getFolderContent(this.token, this.folderid).subscribe(
            res => {
              this.globals.messageServer(res);
              this.selectedDir = res.list;
              this.changeLastModify(this.selectedDir);
              this.changeDimension(this.selectedDir);
              this.changeCreateAt(this.selectedDir);
              this.spinner.hide();
            },
            err => {
              console.error("Errore in openFolder: ", err);
              this.disable = false;
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          )
          err => {
            console.error("errore in getFolderContent(open Folder): ", err);
            this.disable = false;
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        }
        err => {
          console.error("Errore in getFolderContent: ", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      });
    }

  }

  openPopupTwo(file) {
    if (file) {
      this.globals.stampa("File: ", file);
      this.spinner.show();
      const dialogRef = this.dialog.open(ChangeNameFolderComponent, {
        width: "70%",
        // height: "90%",
        data: file
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'Cancel') {
          this.spinner.show();
          this.documentaryService.getFolderContent(this.token, this.folderid).subscribe(
            res => {
              this.globals.messageServer(res);
              this.selectedDirTwo = res.list;
              this.changeLastModify(this.selectedDirTwo);
              this.changeDimension(this.selectedDirTwo);
              this.changeCreateAt(this.selectedDirTwo);
              this.spinner.hide();
            },
            err => {
              console.error("Errore in openFolder: ", err);
              this.disable = false;
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          )
          err => {
            console.error("errore in getFolderContent(open Folder): ", err);
            this.disable = false;
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        }
        err => {
          console.error("Errore in getFolderContent: ", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      });
    }
  }



  download(file) {
    this.documentaryService.getContent(this.token, file.id).subscribe(res => {
      this.globals.messageServer(res);
      let url = window.URL.createObjectURL(res);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = file.name;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  uploadFile() {
    this.spinner.show();
    const dialogRef = this.dialog.open(UploadfileComponent, {
      width: "45%",
      // height: '70%',
      data: {
        id: this.folderid
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      // this.globals.stampa(result);

      if (result != undefined) {
        if (result != 'cancel') {
          this.spinner.show();
          this.documentaryService.getFolderContent(this.token, this.folderid).subscribe(
            res => {
              this.globals.messageServer(res);
              this.selectedDir = res.list;
              this.changeLastModify(this.selectedDir);
              this.changeDimension(this.selectedDir);
              this.changeCreateAt(this.selectedDir);
              this.spinner.hide();
            },
            err => {
              console.error("Errore in openFolder: ", err);
              this.disable = false;
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          )
          err => {
            console.error("errore in getFolderContent(open Folder): ", err);
            this.disable = false;
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        }
      }

    });

  }

  uploadFileTwo() {
    this.spinner.show();
    const dialogRef = this.dialog.open(UploadfileComponent, {
      width: "45%",
      // height: "70%",
      data: {
        id: this.folderidTwo
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result != 'cancel') {
          this.spinner.show();
          if (this.activeUser.profile_id == 1) {
            this.documentaryService.getFolderContent(this.token, this.folderidTwo).subscribe(
              res => {
                this.globals.messageServer(res);
                this.selectedDirTwo = res.list;
                this.changeLastModify(this.selectedDirTwo);
                this.changeDimension(this.selectedDirTwo);
                this.changeCreateAt(this.selectedDirTwo);
                this.showTwo = true;
                this.disableTwo = false;
                this.spinner.hide();
              },
              err => {
                console.error("Errore in openFolder: ", err);
                this.disableTwo = false;
                this.globals.messageServer(err);
                this.spinner.hide();
              }
            )
            err => {
              console.error("errore in getFolderContent(open Folder): ", err);
              this.disableTwo = false;
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          } else {
            this.spinner.show();
            this.documentaryService.getFolderContent(this.token, this.folderid).subscribe(
              res => {
                this.globals.messageServer(res);
                this.selectedDir = res.list;
                this.changeLastModify(this.selectedDirTwo);
                this.changeDimension(this.selectedDirTwo);
                this.changeCreateAt(this.selectedDirTwo);
                this.spinner.hide();
              },
              err => {
                console.error("Errore in openFolder: ", err);
                this.globals.messageServer(err);
                this.disable = false;
                this.spinner.hide();
              }
            )
            err => {
              console.error("errore in getFolderContent(open Folder): ", err);
              this.globals.messageServer(err);
              this.disable = false;
              this.spinner.hide();
            }
          }


        }
      }

    });


  }

  onSubmit() {
    const formData = new FormData();
    formData.append('files', this.uploadForm.get('profile').value);
    this.documentaryService.uploadFile(this.token, this.selectedDir[0].entry.parentId, formData).subscribe(
      (res) => { 
        this.globals.messageServer(res);
      },
      (err) => {
        console.error(err);
        this.globals.messageServer(err);
      }
    );
  }

  deleteObject(type, file, isTwo?) {
    let title = 'Cancellare?'
    if (type == 'FOLDER') {
      title = 'Cancellare la cartella?'
    } else if (type == 'FILE') {
      title = 'Cancellare il file?'
    }
    swal({
      title: title,
      // text: 'You will not be able to recover this imaginary file!',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annulla',
      confirmButtonText: 'Conferma',
      cancelButtonClass: "btn btn-danger",
      confirmButtonClass: "btn btn-success",
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (isTwo) {
          this.delFolderTwo(file, type);
        } else {
          this.delFolder(file, type);
        }
      } else {
      }
    });
  }

}