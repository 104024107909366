import { Component, OnInit } from '@angular/core';
import { createInjectable } from '@angular/compiler/src/core';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';
import { ManageUserService } from 'src/app/services/manage-user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListCustomerAssociatesComponent } from 'src/app/popup/list-customer-associates/list-customer-associates.component';
import { NewCustomerComponent } from 'src/app/popup/new-customer/new-customer.component';
import swal from 'sweetalert2';
import { EliminaService } from 'src/app/services/elimina.service';

declare const $: any;


@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit {
  envi = environment;

  //String
  token: string;

  //Any
  activeUser: any;

  //Array
  users: Array<any> = [];

  //Oggetto
  selectedUser: Object = {};

  //Boolean
  choose: boolean = false;
  isOnlyRead: boolean = false;
  validEmailType: boolean = false;


  constructor(
    private globals: GlobalService,
    private manageUser: ManageUserService,
    private Delete: EliminaService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.globals.checkPage(9);
    this.activeUser = this.globals.storagedUser;
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.getUsers();
  }

  reload() {
    this.spinner.show();
    $('#datatables').DataTable().destroy();
    this.choose = false;
    this.selectedUser = {};
    this.isOnlyRead = false;
    this.svuotaSpan()
    this.getUsers();
    // this.spinner.hide();
  }

  showForm(us, onlyShow?) {

    if (onlyShow) {
      this.isOnlyRead = true;
    }

    this.choose = true;
    this.selectedUser = us;

  }

  getUsers() {
    this.spinner.show();
    this.manageUser.getUserCustomer(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.users = res;
        this.createTable();
        this.globals.stampa("User: ", this.users)
        this.spinner.show();
      },
      err => {
        this.spinner.hide();
        this.globals.messageServer(err);
        console.error("Errore in getUserCustomer: ", err);
      }
    )
  }

  openList(us) {
    this.spinner.show();
    const dialogRef = this.dialog.open(ListCustomerAssociatesComponent, {
      width: '50%',
      // height: '70%',
      data: {
        user_id: us
      },
      // disableClose: true
    })
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  emailValidationType(e) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(e).toLowerCase())) {
      this.validEmailType = true;
      document.getElementById("userEmail").innerHTML = "";
    } else {
      this.validEmailType = false;
      document.getElementById("userEmail").innerHTML = "↓ Email non valida";
    }
  }

  update(user) {

    if (user.name) {
      document.getElementById("userName").innerHTML = "";
    } else {
      document.getElementById("userName").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (user.last_name) {
      document.getElementById("userLast_Name").innerHTML = "";
    } else {
      document.getElementById("userLast_Name").innerHTML = "↓ Il campo è obbligatorio";
    }
    if (user.email) {
      document.getElementById("userEmail").innerHTML = "";
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(user.email).toLowerCase())) {
        this.validEmailType = true;
        document.getElementById("userEmail").innerHTML = "";
      } else {
        this.validEmailType = false;
        document.getElementById("userEmail").innerHTML = "↓ Email non valida";
      }
    } else {
      document.getElementById("userEmail").innerHTML = "↓ Il campo è obbligatorio";
    }
    if (user.username) {
      document.getElementById("userUsername").innerHTML = "";
    } else {
      document.getElementById("userUsername").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (user.username && user.name && user.last_name && user.email) {
      if (this.validEmailType) {
        this.spinner.show();
        this.manageUser.udateUserCustomer(this.token, { user: user }).subscribe(
          res => {
            this.globals.messageServer(res);
            this.reload();
          },
          err => {
            this.spinner.hide();
            this.globals.messageServer(err);
            console.error("Errore in updateUserCustomer: ", err);
          }
        )
      } else {
        this.globals.showNotification("email non valida", "warning");
      }
    } else {
      this.globals.showNotification("Dati mancanti", "warning");
    }

  }

  createTable() {

    let _self = this;
    let userss = [];
    let tableObject: any = {};
    this.users.forEach((us) => {
      let obj = {
        user_id: us.user_id,
        username: us.username,
        last_name: us.last_name,
        name: us.name,
        email: us.email,
      }
      userss.push(obj);
    });
    tableObject.data = userss;
    let config = {
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: {
        details: {
          type: 'column',
          target: 'tr'
        }
      },
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Ricerca utenti",
        url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
      },
      ajax: (data, callback, settings) => {
        callback(tableObject)
      },
      columns: [
        { title:'ID', data: 'user_id' },
        { title:'Username', data: 'username' },
        { title:'Cognome', data: 'last_name' },
        { title:'Nome', data: 'name' },
        { title:'Email', data: 'email' },
        { title:'Azione', data: null, className: 'td-actions text-right' }
      ],
      columnDefs: [
        {
          targets: [0],
          visible: false
        },
        {
          targets: [5],
          visible: true,
          render: function (data, type, full, meta) {
            full = JSON.stringify(full);
            return `<a data-use='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                          <i class="material-icons">search</i>
                        </a>
                        <a data-use='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                          <i class="material-icons">edit</i>
                        </a>
                        <a data-use='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                          <i class="material-icons">close</i>
                        </a>`
          }
        }
      ]
    }
    $(document).ready(function () {
      $('#datatables').DataTable(config);
      $('#datatables').on('click', 'a.btn-info', function () {
        let userr: any = $(this).data('use')
        _self.users.forEach((use) => {
          if (use.user_id == userr.user_id) {
            _self.showForm(use, true);
          }
        })
      });
      $('#datatables').on('click', 'a.btn-success', function () {
        let userr = $(this).data('use')
        _self.users.forEach((use) => {
          if (use.user_id == userr.user_id) {
            _self.showForm(use);
          }
        })
      });
      $('#datatables').on('click', 'a.btn-danger', function () {
        let userr = $(this).data('use')
        _self.users.forEach((use) => {
          if (use.user_id == userr.user_id) {
            _self.delProfile(use);
          }
        })
      });
      _self.spinner.hide();
    })
  }

  delProfile(us) {
    swal({
      title: `Cancellare ${us.last_name} ${us.name}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false
    }).then((result) => {

      if (result.value) {
        this.spinner.show();
        this.Delete.deleteProfile(this.token, us.user_id).subscribe(
          res => {
            this.globals.messageServer(res);
            this.selectedUser = {};
            this.choose = false;
            this.reload();

            this.globals.showNotification("Operatore eliminato con successo!", "success");

            this.spinner.hide();
          },
          err => {
            this.spinner.hide();
            console.error("Errore in delOperator ", err);
            this.globals.showNotification("Errore cancellazione operatore!", "danger");
            this.globals.messageServer(err);
          }
        )
      } else {
      }
    })

  }

  svuotaSpan() {
    if (document.getElementById("userName")) {
      document.getElementById("userName").innerHTML = "";
    }
    if (document.getElementById("userLast_Name")) {
      document.getElementById("userLast_Name").innerHTML = "";
    }
    if (document.getElementById("userEmail")) {
      document.getElementById("userEmail").innerHTML = "";
    }
    if (document.getElementById("cusLast_Name")) {
      document.getElementById("cusLast_Name").innerHTML = ""
    }
    if (document.getElementById("userUsername")) {
      document.getElementById("userUsername").innerHTML = ""
    }
  }

  newUtente() {
    this.spinner.show();
    const dialogRef = this.dialog.open(NewCustomerComponent, {
      width: '50%',
    })
    dialogRef.afterClosed().subscribe(result => {
      this.reload();
    });
  }

}
