import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { GlobalService } from './global.service';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(
    private globals: GlobalService,
    private http: HttpClient) { }

  uploadFile(token, folder, formData) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.uploadFile(folder), formData, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  uploadExcel(token, formData) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.uploadExcelURL, formData, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  uploadTicketFile(token, formData, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.newTicketFile(id), formData, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  downloadPFExcel(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      }),
      _responseType: "blob",
      get responseType() {
        return this._responseType;
      },
      set responseType(value) {
        this._responseType = value;
      },
    };

    return this.http.get<any>(this.globals.downloadPFExcelURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  downloadPGExcel(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      }),
      _responseType: "blob",
      get responseType() {
        return this._responseType;
      },
      set responseType(value) {
        this._responseType = value;
      },
    };

    return this.http.get<any>(this.globals.downloadPGExcelURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

}
