import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarService } from 'src/app/services/calendar.service';
import { GlobalService } from 'src/app/services/global.service';
import { Calendar } from '@fullcalendar/core';

import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import dayGridPlugin from '@fullcalendar/daygrid' // for dayGrid view
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AddeventComponent } from 'src/app/popup/addevent/addevent.component';
import { ActivityService } from 'src/app/services/activity.service';
import { OpenfolderComponent } from 'src/app/popup/openfolder/openfolder.component';
import { ActivitypopupComponent } from 'src/app/popup/acitivitypopup/acitivitypopup.component';
import { StoreactivityComponent } from 'src/app/popup/storeactivity/storeactivity.component';
import { StuckactivityComponent } from 'src/app/popup/stuckactivity/stuckactivity.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Type, User, Event } from 'src/app/classes';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';


import * as moment from 'moment-timezone';

@Component({
  selector: 'app-fullcalendar',
  templateUrl: './fullcalendar.component.html',
  styleUrls: ['./fullcalendar.component.scss']
})
export class FullcalendarComponent implements OnInit {

  //string
  token: string;
  eventTypes: Array<Type>;
  title = 'calendar';
  calendarPlugins = [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin];
  calendarEvents = []
  header = {
    locales: 'it',
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
  }
  buttonText = {
    today: 'Oggi',
    month: 'Mese',
    week: 'Settimana',
    day: 'Giornata',
    list: 'Lista'
  }
  aspectRatio = 1.8;
  activeUser: User;
  filter: any = {
    e: true,
    a: true,
    s: true
  }
  events: Array<Event> = [];
  deadlines: Array<Event> = [];
  activities: Array<Event> = [];
  calendarEl: any;
  calendar: any;
  calendar_idURL: any = null;

  constructor(
    private globals: GlobalService,
    private calendarService: CalendarService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((p) => {
      this.calendar_idURL = p['calendar_id'];
      this.globals.stampa("Id: ", this.calendar_idURL);
    });
    this.spinner.show();
    this.globals.checkPage(8);
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.activeUser = this.globals.storagedUser;
    this.getEvents();
  }

  getEvents() {
    this.calendarEvents = [];
    this.calendarService.getEvents(this.token).subscribe(
      (res) => {
        this.globals.messageServer(res);
        let events = res.events
        this.eventTypes = res.types;
        events.forEach((event) => {
          this.colorSelector(event);
          if (event.allday == 'Y') {
            event.allday = true;
          } else {
            event.allday = false;
          }
          this.calendarEvents = this.calendarEvents.concat([
            {
              title: event.calendar_name,
              start: event.start_date,
              end: event.end_date,
              allDay: event.allday,
              backgroundColor: event.backgroundColor ? event.backgroundColor : null,
              borderColor: event.borderColor ? event.borderColor : null,
              extendedProps: {
                calendar_id: event.calendar_id,
                instance_id: event.instance_id,
                customer_id: event.customer_id,
                state: event.state,
                info: event.json,
                event: event
              }
            }
          ]);
        });
        if (this.calendar_idURL) {
          this.globals.stampa("Entra");
          this.globals.stampa("Events: ", events)
          events.forEach((event) => {
            if (event.calendar_id == this.calendar_idURL) {
              this.spinner.show();
              const dialogRef = this.dialog.open(AddeventComponent, {
                width: '70%',
                // height: '80%',
                data: { start: event.start_date, end: event.end_date, eventTypes: event.type_id, new: false, event: event, default_type: event.type_id }
              })
              dialogRef.afterClosed().subscribe(result => {
                if (result != 'cancel') {
                  this.calendarEvents = [];
                  this.calendar_idURL = null;
                  this.globals.goTo('calendar');
                  this.getEvents();
                } else {
                  this.calendar_idURL = null;
                  this.globals.goTo('calendar');
                }

              });
            }
          })
        }
        this.spinner.hide();
      },
      (err) => {
        console.error("errore in getEvents: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  colorSelector(event) {
    if (event.type_id == this.globals.activityTypeId) {
      if (event.state) {
        if (event.state == 'Chiuso') {
          event.backgroundColor = 'orange';
          event.borderColor = 'black';
        }

        if (event.state == 'Test') {
          event.backgroundColor = 'red';
          event.borderColor = 'orange';

        }

        if (event.state == 'Bloccato') {
          event.backgroundColor = 'yellow';
          event.borderColor = 'orange';
        }

        if (event.state == 'In attesa') {
          event.backgroundColor = 'green';
          event.borderColor = 'orange';

        }
      }
    } else if (event.type_id == this.globals.deadlineTypeID) {
      event.backgroundColor = '#EB605D';
      event.borderColor = '#EB605D';
    } else if (event.type_id == this.globals.eventTypeId) {
      event.backgroundColor = '#00bcd4';
      event.borderColor = '#00bcd4';
    }

  }

  handleSelect(arg) {
    let dateStart = new Date(arg.start);
    let dateEnd = new Date(arg.end - 1);
    dateStart.setHours(8);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);
    dateEnd.setHours(18);
    dateEnd.setMinutes(0);
    dateEnd.setSeconds(0);
    this.spinner.show();
    const dialogRef = this.dialog.open(AddeventComponent, {
      width: '70%',
      // height: '80%',
      data: { start: dateStart, end: dateEnd, eventTypes: this.eventTypes, new: true }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getEvents();
      }
    });
  }
  info: any;
  eventDragStart(info) {
    this.globals.stampa("Start");
    this.globals.stampa("Event Start: ", info.event.start);
    this.globals.stampa("Event End: ", info.event.end);
    this.info = info
  }

  eventDragStop(info) {
    this.globals.stampa("Stop");
    this.globals.stampa("Event Start: ", info.event.start);
    this.globals.stampa("Event End: ", info.event.end);

  }

  eventDrop(info) {
    this.globals.stampa("Stop");
    this.globals.stampa("Info: ", info.event.extendedProps);
    this.globals.stampa("Event Start: ", info.event.start);
    this.globals.stampa("Event End: ", info.event.end);
    let event: Event = info.event.extendedProps.event;
    event.start_date = moment(info.event.start).format();
    event.end_date = moment(info.event.end).format();
    this.globals.stampa("Evento da mandare: ", event);
    this.calendarService.resizeEvent(this.token, { event: event }).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa("Res: ", res);
        this.globals.stampa("Evento aggiornato");
        this.globals.messageServer(res);
      },
      err => {
        console.error("Error on update event: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  eventResizeStart(info) {
    this.globals.stampa("Start");
    // this.globals.stampa("Info: ", info);
    this.globals.stampa("Event Start: ", info.event.start);
    this.globals.stampa("Event End: ", info.event.end);
    this.info = info
  }

  eventResizeStop(info) {
    this.globals.stampa("Stop");
    // this.globals.stampa("Info: ", info);
    this.globals.stampa("Event Start: ", info.event.start);
    this.globals.stampa("Event End: ", info.event.end);
  }

  eventResize(info) {
    if (info.event.extendedProps.event.type_id != 2) {
      this.globals.stampa("Stop");
      this.globals.stampa("Info: ", info.event.extendedProps);
      this.globals.stampa("Event Start: ", info.event.start);
      this.globals.stampa("Event End: ", info.event.end);
      let event: Event = info.event.extendedProps.event;
      event.start_date = moment(info.event.start).format();
      event.end_date = moment(info.event.end).format();
      this.globals.stampa("Evento da mandare: ", event);
      this.calendarService.resizeEvent(this.token, { event: event }).subscribe(
        res => {
          this.globals.messageServer(res);
          this.globals.stampa("Res: ", res);
          this.globals.stampa("Evento aggiornato");
          this.globals.messageServer(res);

        },
        err => {
          console.error("Error on update event: ", err);
          this.globals.messageServer(err);
        }
      )
    } else {
      this.spinner.show();
      this.getEvents();
      this.globals.showNotification("impossibile modificare la scadenza", "danger")
    }
  }

  newEvent() {
    let dateStart = new Date();
    let dateEnd = new Date();
    this.spinner.show();
    const dialogRef = this.dialog.open(AddeventComponent, {
      width: '70%',
      // height: '80%',
      data: { start: dateStart, end: dateEnd, eventTypes: this.eventTypes, new: true }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {

        this.getEvents();
      }
    });
  }

  eventClick(info) {
    if (info.event.extendedProps.event.type_id == this.globals.activityTypeId) {

      this.spinner.show();
      const dialogRef = this.dialog.open(AddeventComponent, {
        width: '70%',
        // height: '80%',
        data: { start: info.event.start, end: info.event.end, eventTypes: info.event.extendedProps.event.type_id, new: false, event: info.event.extendedProps.event, default_type: info.event.extendedProps.event.type_id }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cancel') {

          this.getEvents();
        }
      });
    }

    if (info.event.extendedProps.event.type_id == this.globals.eventTypeId) {
      this.spinner.show();
      const dialogRef = this.dialog.open(AddeventComponent, {
        width: '70%',
        // height: '80%',
        data: { start: info.event.start, end: info.event.end, eventTypes: info.event.extendedProps.event.type_id, new: false, event: info.event.extendedProps.event }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cancel') {

          this.getEvents();
        }
      });
    }

    if (info.event.extendedProps.event.type_id == this.globals.deadlineTypeID) {
      this.spinner.show();
      this.globals.stampa("info: ", info)
      const dialogRef = this.dialog.open(AddeventComponent, {
        width: '70%',
        // height: '80%',
        data: { start: info.event.start, end: info.event.end, eventTypes: info.event.extendedProps.event.type_id, new: false, event: info.event.extendedProps.event, default_type: info.event.extendedProps.event.type_id }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cancel') {

          this.getEvents();
        }
      });
    }
  }

  modifyTitle(eventIndex, newTitle) {
    let calendarEvents = this.calendarEvents.slice(); // a clone
    let singleEvent = Object.assign({}, calendarEvents[eventIndex]); // a clone
    singleEvent.title = newTitle;
    calendarEvents[eventIndex] = singleEvent;
    this.calendarEvents = calendarEvents; // reassign the array
  }

  mouseEnter(event) {
    let ele = document.getElementById("fullCalendar");
    ele.classList.add("pointer");

  }

  mouseLeave(event) {
    let ele = document.getElementById("fullCalendar");
    ele.classList.remove("pointer");
  }

  filtered() {
    let calendarEvents = this.calendarEvents.slice(); // a clone
    let eventId = this.globals.eventTypeId;
    let activityId = this.globals.activityTypeId;
    let deadlineId = this.globals.deadlineTypeID;
    if (!this.filter.e) { // se è falseo
      this.calendarEvents.forEach((event, k) => {
        if (event.extendedProps.event.type_id == eventId) {
          if (this.events.indexOf(event) < 0) {
            this.events.push(event);
            calendarEvents.splice(calendarEvents.indexOf(event), 1);
          }
        }
      })
    } else {
      this.events.forEach((event) => {
        if (calendarEvents.indexOf(event) < 0) {
          calendarEvents.push(event);
        }
      })
      this.events = [];

    }

    if (!this.filter.a) { // se è falseo
      this.calendarEvents.forEach((activity, k) => {
        if (activity.extendedProps.event.type_id == activityId) {
          if (this.activities.indexOf(activity) < 0) {
            this.activities.push(activity);
            calendarEvents.splice(calendarEvents.indexOf(activity), 1);
          }
        }
      })
    } else {
      this.activities.forEach((activity) => {
        if (calendarEvents.indexOf(activity) < 0) {
          calendarEvents.push(activity);
        }
      })
      this.activities = [];
    }

    if (!this.filter.s) { // se è falseo
      this.calendarEvents.forEach((deadline, k) => {
        if (deadline.extendedProps.event.type_id == deadlineId) {
          if (this.deadlines.indexOf(deadline) < 0) {
            this.deadlines.push(deadline);
            calendarEvents.splice(calendarEvents.indexOf(deadline), 1);
          }
        }
      })
    } else {
      this.deadlines.forEach((activity) => {
        if (calendarEvents.indexOf(activity) < 0) {
          calendarEvents.push(activity);
        }
      })
      this.deadlines = [];
    }
    this.calendarEvents = calendarEvents;
  }

}