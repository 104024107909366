import { Injectable } from '@angular/core';

import { GlobalService } from './global.service';
import { Observable } from 'rxjs';


import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService,
    private clienteService: ClienteService
    ) { }


  getProfile(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getProfile, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }


  
  newUser(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.newUser, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getUser(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getUser(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  setUser(token, obj, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.setUser(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getAllUser(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getUAllUser, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  addCustomer(token, obj){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.addCustomer, obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }
}
