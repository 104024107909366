import { Component, OnInit, Input, Inject } from '@angular/core';
import { OperatorService } from 'src/app/services/operator.service';
import { GlobalService } from 'src/app/services/global.service';
import { TicketService } from 'src/app/services/ticket.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenfolderComponent } from '../openfolder/openfolder.component';
import { Ticket, User, Customer, TicketInfo } from 'src/app/classes';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import swal from 'sweetalert2';
import { ContractService } from 'src/app/services/contract.service';
import { TicketactivityComponent } from '../ticketactivity/ticketactivity.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadFileService } from 'src/app/services/upload-file.service';

export interface DialogData {
  tk: TicketInfo;
  read: boolean;
}

@Component({
  selector: 'app-openticket',
  templateUrl: './openticket.component.html',
  styleUrls: ['./openticket.component.scss']
})
export class OpenticketComponent implements OnInit {

  myControlOp = new FormControl();
  filteredOperator: Observable<any[]>;

  tk: TicketInfo;
  operators: Array<any>;
  token: string;
  ope: any;
  userActive: User;
  operator: boolean = false;
  op: any;
  take: boolean = false;
  iscustumer: boolean = false;
  contracts: any;
  disabled: boolean = true;
  read: boolean = false;
  uploadForm: FormGroup;
  file: any;
  amountFile: number;
  fileBoolean: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<OpenticketComponent>,
    private customerService: OperatorService,
    private globals: GlobalService,
    private ticketService: TicketService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private contractService: ContractService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private upload: UploadFileService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData

  ) { }

  ngOnInit() {
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.read = this.data.read;
    this.tk = this.data.tk;
    // this.getTicket();
    this.globals.stampa(this.tk);
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
    this.globals.stampa("Test");
    this.iscustumer = true;
    this.getCusContract();
    this.userActive = this.globals.storagedUser;
    if (this.userActive.profile_id != 1) {
      this.disabled = false;
    }
    this.getAssignOperators();
    this.checkProfile();
    this.getOperators();
    this.spinner.hide();
  }


  getTicket() {
    this.ticketService.getTicket(this.token, this.tk.ticket.ticket_id).subscribe(
      (res) => {
        this.tk = res.values;
        this.globals.messageServer(res);
      },
      (err) => {
        console.error("Error on get Ticket");
        this.globals.messageServer(err);
      }
    )
  }

  push(ope) {
    if (ope.user_id) {
      this.op = ope;
      this.globals.stampa("Operator: ", this.op)
    }
  }

  onFileSelect(event) {
    this.amountFile = event.target.files.length
    if (this.amountFile > 0 && event.target.files[0]) {
      this.file = event.target.files
      this.fileBoolean = true;
    }
  }

  getAssignOperators() {
    this.ticketService.getTicketAssignedOperators(this.token, this.tk.ticket.ticket_id).subscribe(
      (res) => {
        this.globals.messageServer(res);
        this.tk.assignedAt = res.values;
      },
      (err) => {
        console.error("error on get operators assigned", err);
        this.globals.messageServer(err);
      }
    )
  }

  saveTicket() {
    this.globals.stampa(this.tk);
    delete this.tk.ticket.openFor;
    delete this.tk.ticket.openby;
    delete this.tk.ticket.correctOpenat;
    delete this.tk.ticket.createdat;
    delete this.tk.ticket.assignedat;
    this.globals.stampa(this.tk);
    if (this.op) {
      this.spinner.show();
      this.ticketService.assignTicket(this.token, this.tk.ticket.ticket_id, this.op.user_id).subscribe(
        res => {
          this.globals.messageServer(res);
          delete this.tk.ticket.state;
          this.ticketService.setStatus(this.token, this.tk.ticket.ticket_id, this.tk).subscribe(
            async res => {
              this.globals.messageServer(res);
              if (this.amountFile > 0) {
                this.spinner.show();
                await this.uploadFile(this.tk.ticket.ticket_id);
              }
              this.globals.stampa("Ticekt aggiornato");
              this.close();
              this.spinner.hide();
            },
            err => {
              this.spinner.hide();
              console.error("Error on update Ticket");
              this.globals.messageServer(err);
            }
          )
          this.spinner.hide();
        },
        err => {
          console.error("Error on assign Ticket");
          this.globals.messageServer(err);
        }
      )
    } else {
      this.spinner.show();
      this.ticketService.setStatus(this.token, this.tk.ticket.ticket_id, this.tk).subscribe(
        async res => {
          this.globals.messageServer(res);
          if (this.amountFile > 0) {
            await this.uploadFile(this.tk.ticket.ticket_id);
          }
          this.globals.stampa("Ticekt aggiornato");
          this.close();
          this.spinner.hide();
        },
        err => {
          console.error("Error on update Ticket");
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )
    }
  }

  getCusContract() {
    if (this.tk.customer.customer_id) {
      this.contractService.getCustomerContract(this.token, this.tk.customer.customer_id).subscribe(
        res => {
          this.globals.messageServer(res);
          this.contracts = res;
          this.globals.stampa("contratti ottenuti:", this.contracts);

        },
        err => {
          console.error("Error on get contracts: ", err);
          this.globals.messageServer(err);
        }
      )
    }

  }

  checkProfile() {
    if (this.userActive.profile_id > 1) {
      this.operator = true;
    } else {
      this.operator = false;
    }
  }

  displayFn(user: User): string {
    return user && `${user.last_name} ${user.name} (Username: ${user.username})`;
  }

  private _filter(name: string, array: Array<any>): User[] {
    const filterValue = name.toLowerCase();
    this.globals.stampa("NameOp: ", name);

    return array.filter(
      ele => ele.search.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  getOperators() {
    let _self = this;
    this.customerService.getOperator(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.operators = res;
        this.operators.forEach(op => {
          op.search = this.globals.createLocalSearch({ name: op.name, last_name: op.last_name, username: op.username })
        });
        _self.filteredOperator = _self.myControlOp.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value Op: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search Op: ", search);
            return search ? _self._filter(search, _self.operators) : _self.operators.slice()
          })
        );
      },
      err => {
        console.error("error on get Customers", err);
        this.globals.messageServer(err);
      }
    )
  }

  // Prendi incarico
  takeTicket(tk) {
    this.globals.stampa("Take: ", this.take);
    if (this.take) {
      this.operators.forEach((op) => {
        if (op.user_id == this.userActive.user_id) {
          this.op = op;
        }
      })
    } else {
      this.op = '';
    }
  }


  assignTickets(tk, ope) {
    this.ticketService.assignTicket(this.token, tk.ticket_id, ope.user_id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.ticketService.setStatus(this.token, tk.ticket_id, { state: "In attesa", color: tk.color }).subscribe(
          res => {
            this.globals.messageServer(res);
            this.globals.showNotification("Ticket assegnato", "success")
            this.close();
          },
          err => {
            console.error("Errore nell'assegnare il ticket", err);
            this.globals.messageServer(err);
          }
        )
      },
      err => {
        console.error("Errore nell'assegnare l'utente", err);
        this.globals.messageServer(err);
      }
    );
  }

  showDoc() {
    if (this.tk.ticket.folder_id) {
      this.dialog.open(OpenfolderComponent, {
        width: "70%",
        data: {
          folder_id: this.tk.ticket.folder_id
        }
      }).afterClosed().subscribe(result => {
        if (result != 'Cancel') { }
        err => {
          console.error("Errore in getFolderContent: ", err);
          this.globals.messageServer(err);
        }
      });
    }

  }

  async uploadFile(id) {
    this.uploadForm.get('profile').setValue(this.file);
    const formData = new FormData();
    for (let i = 0; i < this.file.length; i++) {
      formData.append('file', this.file[i], this.file[i].name);
    }
    this.upload.uploadTicketFile(this.token, formData, id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.close();
        return
      },
      err => {
        console.error(err)
        this.globals.messageServer(err);
      }
    );
  }

  deleteTicket(tk) {
    if (tk && tk.ticket.ticket_id) {
      swal({
        title: 'Eliminare il ticket?',
        // text: 'You will not be able to recover this imaginary file!',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annulla',
        confirmButtonText: 'Conferma',
        cancelButtonClass: "btn",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.ticketService.deleteTicket(this.token, tk.ticket.ticket_id).subscribe(
            res => {
              this.globals.messageServer(res);
              this.globals.showNotification("Ticket eliminato", "success")
              this.spinner.hide();
              this.close();
            },
            err => {
              console.error("Errore nell'assegnare il ticket", err);
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          )
        } else {
        }
      });


    }
  }

  close() {
    this.dialogRef.close();
  }


  ticketActivity() {
    this.spinner.show();
    const dialogRef = this.dialog.open(TicketactivityComponent, {
      width: '80%',
      // height: '80%',
      data: {
        ticket_id: this.tk.ticket.ticket_id
      }
    })

    dialogRef.afterClosed().subscribe(
      result => {
        this.globals.stampa("Attività: ", result);

      }
    )
  }

  svuota() {
    this.file = [];
    this.fileBoolean = false;
    this.amountFile = this.file.length;
  }
}
