import { Injectable } from '@angular/core';

import { GlobalService } from './global.service';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class NewCustomerService {

  constructor(private http: HttpClient, private globals: GlobalService) { }

  newUser(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.newUser, obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }
}
