import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OperatorService } from 'src/app/services/operator.service';
import { environment } from '@environment';
import { StmtModifier } from '@angular/compiler';
// import 'DataTables.net';
declare const $: any;

declare interface DataTable {
  headerRow: string[];
  // footerRow: string[];
}

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  public indirizzi: DataTable;
  envi = environment;

  //Any
  activeUser: any;


  //String
  token: string;
  email: string;

  //Boolean
  validEmailType: boolean
  rSociale: boolean;
  fiscalC: boolean;
  iva: boolean;
  show: boolean = false;
  view: boolean = false;
  modify: boolean = true;

  //Array
  selectedCompany: any = {};
  emailArray: Array<any> = [];
  address: Array<any> = [];
  types: Array<any> = [];
  selectedAddress: Array<any> = [];

  //Map
  TypeMap: any = new Map();

  constructor(
    private globals: GlobalService,
    private spinner: NgxSpinnerService,
    private operatorService: OperatorService
  ) { }

  ngOnInit() {
    // this.globals.checkPage(1);
    this.activeUser = this.globals.storagedUser;
    // this.globals.checkPage(this.activeUser.profile_id)
    if(this.activeUser.profile_id != 3){
      this.globals.goTo("homepage")
    }
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.indirizzi = {
      headerRow: ['Id', 'Via', 'Città', 'Provincia', 'Stato', 'CAP', 'Tipo di indirizzo', 'Azione']
      // footerRow: ['#', 'Nome azienda', 'Codice cliente', 'Email', 'Azione']
    }
    this.getInfo();
  }

  getInfo() {
    this.spinner.show();
    this.operatorService.getCompany(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa(res);
        this.selectedCompany = res.company.info;
        this.selectedCompany.description = this.selectedCompany.description ? this.selectedCompany.description :  '';
        this.emailArray = res.company.email;
        this.address = res.company.address;
        this.types = res.company.types;
        this.types.forEach((type) => {
          this.TypeMap[type.type_id] = type.type_name;
        })
        this.createDatatable();
        this.spinner.hide();
      },
      err => {
        console.error("Errore in getCompany: ", err);
        this.globals.messageServer(err)
        this.spinner.hide();
      }
    )

  }

  emailValidationType(e) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(e).toLowerCase())) {
      this.validEmailType = true;
      // document.getElementById("emailSpan").innerHTML = "";
    } else {
      this.validEmailType = false;
      // document.getElementById("emailSpan").innerHTML = "↓ Email non valida";
    }
  }

  updateCompany() {
    if (this.modify) {

      //Controlli
      if (this.selectedCompany.business_name) {

        if (this.selectedCompany.business_name.length > 70) {
          document.getElementById("business").innerHTML = "↓ Il codice fiscale supera 70 caratteri";
          this.rSociale = false;
        } else {
          this.rSociale = true;
          document.getElementById("business").innerHTML = "";
        }

      } else {
        this.rSociale = false;
        document.getElementById("business").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (this.selectedCompany.f_code) {

        if (this.selectedCompany.f_code.length > 16) {
          document.getElementById("CF").innerHTML = "↓ Il codice fiscale supera 16 caratteri";
          this.fiscalC = false;
        } else {
          this.fiscalC = true;
          document.getElementById("CF").innerHTML = "";
        }

      } else {
        this.fiscalC = false;
        document.getElementById("CF").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (this.selectedCompany.vat) {
        if (this.selectedCompany.vat.length > 11) {
          document.getElementById("piva").innerHTML = "↓ Il codice fiscale supera 11 caratteri";
          this.iva = false;
        } else {
          this.iva = true;
          document.getElementById("piva").innerHTML = "";
        }
      } else {
        this.iva = false;
        document.getElementById("piva").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (this.rSociale && this.iva && this.fiscalC && this.selectedCompany.description.length <= 500) {

        this.spinner.show();
        let company = {
          business_name: this.selectedCompany.business_name,
          vat: this.selectedCompany.vat,
          f_code: this.selectedCompany.f_code,
          description: this.selectedCompany.description
        }

        this.operatorService.updateCompany(this.token, { company: company }).subscribe(
          res => {
            this.globals.messageServer(res);
            this.globals.goTo("homepage")
            this.selectedCompany = res.values;
            this.view = false;
            this.spinner.hide();
          },
          err => {
            console.error("Errore in updateCompany: ", err);
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        )
      } else {
        if (this.selectedCompany.description.length <= 500) {
          this.globals.showNotification("Dati mancanti o errati", "warning");
        } else {
          this.globals.showNotification("La descrizione supera i 500 caratteri", "warning");
        }
      }
    } else {
      this.globals.showNotification("Stai modificando un indirizzo", "warning")
    }
  }

  addAddress(addr) {
    if (addr.address || addr.city || addr.province || addr.country || addr.zip_code || addr.type_id) {
      // let tempAddrs = addr;
      if (addr.address) {
        document.getElementById("via").innerHTML = "";
      } else {
        document.getElementById("via").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addr.city) {
        document.getElementById("citta").innerHTML = "";
      } else {
        document.getElementById("citta").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addr.province) {
        document.getElementById("provincia").innerHTML = "";
      } else {
        document.getElementById("provincia").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addr.country) {
        document.getElementById("stato").innerHTML = "";
      } else {
        document.getElementById("stato").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addr.zip_code) {
        document.getElementById("cap").innerHTML = "";
      } else {
        document.getElementById("cap").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addr.type_id) {
        document.getElementById("tipo").innerHTML = "";
      } else {
        document.getElementById("tipo").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addr.address && addr.city && addr.province && addr.country && addr.zip_code && addr.type_id) {

        if (!this.show) {
          this.spinner.show();

          let address = {
            address: addr.address,
            province: addr.province,
            zip_code: addr.zip_code,
            city: addr.city,
            country: addr.country,
            type_id: addr.type_id,
          }

          this.operatorService.addAddress(this.token, { address: address }).subscribe(
            res => {
              this.modify = true;
              this.globals.messageServer(res)
              this.address = res.values;
              this.view = false;
              this.selectedAddress = [];
              this.reCreateTable();
              this.spinner.hide();
            },
            err => {
              console.error("Errore in addAddress: ", err);
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          )
        } else {
          this.spinner.show();

          let address = {
            address_id: addr.address_id,
            address: addr.address,
            province: addr.province,
            zip_code: addr.zip_code,
            city: addr.city,
            country: addr.country,
            type_id: addr.type_id,
          }

          this.operatorService.updateAddress(this.token, { address: address }).subscribe(
            res => {
              this.modify = true;
              this.globals.messageServer(res)
              this.address = res.values;
              this.selectedAddress = [];
              this.reCreateTable();
              this.spinner.hide();
              this.show = false;
              this.view = false;
            },
            err => {
              console.error("Errore in addAddress: ", err);
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          )
        }
      }
    } else {
      document.getElementById("via").innerHTML = "";
      document.getElementById("citta").innerHTML = "";
      document.getElementById("provincia").innerHTML = "";
      document.getElementById("stato").innerHTML = "";
      document.getElementById("cap").innerHTML = "";
      document.getElementById("tipo").innerHTML = "";
      this.globals.showNotification("Inserisci i dati dell'indirizzo", 'warning');
    }

  }

  showAddress(addres) {
    if (this.modify) {
      this.modify = false;
      this.view = true;
      this.selectedAddress = addres;
      this.address.splice(this.address.indexOf(addres), 1);
      this.reCreateTable(true);
      this.show = true;
    } else {
      this.globals.showNotification("Stai già modificando un indirizzo", "warning")
    }
  }

  deleteAddres(addres) {
    if (this.modify) {
      this.spinner.hide();
      this.operatorService.deleteAddress(this.token, addres.address_id).subscribe(
        res => {
          this.globals.messageServer(res)
          this.address = res.values;
          this.reCreateTable();
          this.spinner.hide();
        },
        err => {
          console.error("Errore in deleteAddress: ", err);
          this.globals.messageServer(err);
          this.spinner.hide();
        }
      )
    } else {
      this.globals.showNotification("Stai già modificando un indirizzo", "warning")
    }
  }

  pushEmail(mail) {

    let check = true;

    this.emailArray.forEach(email => {
      if (mail == email.email) {
        check = false;
      }
    });

    if (check) {
      this.spinner.show();
      let email = {
        email: mail
      }

      if (this.validEmailType) {
        this.operatorService.addEmail(this.token, { email: email }).subscribe(
          res => {

            this.globals.messageServer(res);
            this.emailArray = res.values;
            this.email = '';
            this.spinner.hide();
          },
          err => {
            console.error("Errore in addEmail: ", err);
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        )
      } else {
        this.globals.showNotification("Email non valida", "warning");
        this.spinner.hide();
      }
    } else {
      this.globals.showNotification("L'email è già presente in lista", "warning");
    }
  }

  deleteNew(list) {
    this.spinner.hide();
    this.operatorService.deleteEmail(this.token, list.email_id).subscribe(
      res => {
        this.globals.messageServer(res)
        this.emailArray = res.values;
        this.spinner.hide();
      },
      err => {
        console.error("Errore in deleteEmail: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      }
    )
  }



  reCreateTable(colum?) {
    if (!colum) {
      if (!$.fn.DataTable.isDataTable('#indirizziTable')) {
        let _self = this;
        $(document).ready(function () {
          $('#indirizziTable').DataTable({
            "pagingType": "full_numbers",
            "lengthMenu": [
              [10, 25, 50, -1],
              [10, 25, 50, "All"]
            ],
            responsive: {
              details: {
                type: 'column',
                target: 'tr'
              }
            },
            language: {
              search: "_INPUT_",
              searchPlaceholder: "Ricerca indirizzi",
              url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
            },
            columnDefs: [
              {
                targets: [0],
                visible: false
              },
              {
                targets: [7],
                visible: true,
                render: function (data, type, full, meta) {
                  return `
                <a data-add="${full}" matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                  <i class="material-icons">edit</i>
                </a>
                <a data-add="${full}" matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                  <i class="material-icons">close</i>
                </a>`
                }
              }
            ]
          });
        })
      } else {
        $('#indirizziTable').DataTable().destroy();
        let _self = this;
        $(document).ready(function () {
          $('#indirizziTable').DataTable({
            "pagingType": "full_numbers",
            "lengthMenu": [
              [10, 25, 50, -1],
              [10, 25, 50, "All"]
            ],
            responsive: {
              details: {
                type: 'column',
                target: 'tr'
              }
            },
            language: {
              search: "_INPUT_",
              searchPlaceholder: "Ricerca indirizzi",
              url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
            },
            columnDefs: [
              {
                targets: [0],
                visible: false
              },
              {
                targets: [7],
                visible: true,
                render: function (data, type, full, meta) {
                  return `
                <a data-add="${full}" matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                  <i class="material-icons">edit</i>
                </a>
                <a data-add="${full}" matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                  <i class="material-icons">close</i>
                </a>`
                }
              }
            ]
          });
        })
      }
    } else {
      $('#indirizziTable').DataTable().destroy();
      let _self = this;
      $(document).ready(function () {
        $('#indirizziTable').DataTable({
          "pagingType": "full_numbers",
          "lengthMenu": [
            [10, 25, 50, -1],
            [10, 25, 50, "All"]
          ],
          responsive: {
            details: {
              type: 'column',
              target: 'tr'
            }
          },
          language: {
            search: "_INPUT_",
            searchPlaceholder: "Ricerca indirizzi",
            url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
          },
        });
      })
    }
  }

  createDatatable() {
    let _self = this;
    $(document).ready(function () {
      $('#indirizziTable').DataTable({
        "pagingType": "full_numbers",
        "lengthMenu": [
          [10, 25, 50, -1],
          [10, 25, 50, "All"]
        ],
        responsive: {
          details: {
            type: 'column',
            target: 'tr'
          }
        },
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Ricerca indirizzi",
          url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
        },
        columnDefs: [
          {
            targets: [0],
            visible: false
          },
          {
            targets: [7],
            visible: true,
            render: function (data, type, full, meta) {
              return `
                <a data-add="${full}" matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                  <i class="material-icons">edit</i>
                </a>
                <a data-add="${full}" matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                  <i class="material-icons">close</i>
                </a>`
            }
          }
        ]
      });
    })

    $('#indirizziTable').on('click', 'a.btn-success', function () {
      _self.globals.stampa($(this).data('add'));
      let addObj = $(this).data('add').split(',')
      let add: any = {};
      for (let i = 0; i < addObj.length; i++) {
        add[_self.indirizzi.headerRow[i]] = addObj[i];
      }
      _self.globals.stampa("Address: ", add);
      _self.address.forEach((addr) => {
        if (addr.address_id == add.Id) {
          _self.showAddress(addr);
        }
      })
    });
    $('#indirizziTable').on('click', 'a.btn-danger', function () {
      _self.globals.stampa($(this).data('add'));
      let addObj = $(this).data('add').split(',')
      let add: any = {};
      for (let i = 0; i < addObj.length; i++) {
        add[_self.indirizzi.headerRow[i]] = addObj[i];
      }
      _self.globals.stampa("AddressDel: ", add);
      _self.address.forEach((addr) => {
        if (addr.address_id == add.Id) {
          _self.deleteAddres(addr);
        }
      })
    });
  }

  addressVisible() {
    this.view = true;
  }
}
