import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './component/login/login.component';
import { HomepageComponent } from './component/homepage/homepage.component';
import { DocumentaryComponent } from './component/documentary/documentary.component'
import { ActivityComponent } from './component/activity/activity.component';
import { OperatorComponent } from './component/operator/operator.component';
import { CustomerComponent } from './component/customer/customer.component';
import { TicketComponent } from './component/ticket/ticket.component';
import { ContractComponent } from './component/contract/contract.component';
import { GroupComponent } from './component/group/group.component';
import { FullcalendarComponent } from './component/fullcalendar/fullcalendar.component';
import { ProfileComponent } from './component/profile/profile.component';
import { CompanyComponent } from './component/company/company.component';
import { ManageUserComponent } from './component/manage-user/manage-user.component';
import { ActivitiesComponent } from './component/activities/activities.component';
import { FulfillmentComponent } from './component/fulfillment/fulfillment.component';
import { ListDeadlineComponent } from './component/list-deadline/list-deadline.component';






const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'homepage', component: HomepageComponent },
  { path: 'documentary', component: DocumentaryComponent },
  { path: 'activity', component: ActivitiesComponent },
  { path: 'operator', component: OperatorComponent },
  { path: 'customer', component: CustomerComponent },
  { path: 'ticket', component: TicketComponent },
  { path: 'contract', component: ContractComponent },
  { path: 'group', component: GroupComponent },
  { path: 'calendar', component: FullcalendarComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'company', component: CompanyComponent },
  { path: 'user', component: ManageUserComponent },
  { path: 'fulfillment', component: FulfillmentComponent},
  { path: 'deadline-list', component: ListDeadlineComponent}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
