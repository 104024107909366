import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService
  ) { 

  }
  newContract(token, contract): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.getNewContractURL, contract, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getContracts(token): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getGetContractsURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getContract(token, contractId): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getContractURL(contractId), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateContract(token, contract, contractId): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put<any>(this.globals.updateContractURL(contractId), contract, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteContract(token, contractId): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteContractURL(contractId), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  assocContractToCustomers(token, customers, contractId): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.getAssocContractToCustomerURL(contractId), customers, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getCustomerContract(token, customer_id): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getAssocedCustomerToContractURL(customer_id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getAssocOperatorToContract(token, contractId): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getAssocedOperatorToContractURL(contractId), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getFulfillment(token): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getGetFulfillmentsURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }
}
