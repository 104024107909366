
import { View } from './view';
import { Toolbar } from './toolbar';
import { PropertyPane } from './propertyPane';
export class Application {
    view: any;
    toolbar: any;
    propertyPane: any;

    constructor(canvas_id, toolbar_id, propertyPane_id, parentScope){
        this.view    = new View(canvas_id);;
        this.toolbar = new Toolbar(toolbar_id, this.view);
        this.propertyPane = new PropertyPane(propertyPane_id, this.view, parentScope)
    }
}
