export class User {
    user_id?: number;
    username: string;
    password?: string;
    email?: string;
    name?: string;
    last_name?: string;
    alfresco_id?: string;
    enabled?: string;
    createdat?: string;
    createdby?: number
    updatedat?: string;
    updatedby?: number;
    company_id?: number;
    pack_id?: number;
    rememberme?: boolean;
    schema?: string;
    profile_id?: number;
    description?: string;
    authorityId?: string;
    base64?: string;
    search?: string;
    view_name?: string;
    user_customer_profile_id?: any;

}

export class Numbers {
    operators: number;
    customers: number;
    activity: number;
    tickets: number;
}

export class Contract {
    contract_id?: number;
    contract_name?: string;
    description?: string;
    start_date?: any;
    end_date?: any;
    autorenew?: any;
    search?: string;
}

export class Header {
    key?: number;
    value?: string;
    URL?: string;
    enabled?: string
    icontype?: string;
    type?: string;
    title?: string;
}

export class Profile {
    profile_id?: number;
    profile_name?: string;
}

export class Procces {
    procces_id?: number;
    instance_id?: number;
}

export class Customer {
    customer_id: number;
    name?: string;
    type?: string;
    last_name?: string;
    business_name?: string;
    birth_date?: any;
    birth_city?: string;
    sl_city?: string;
    f_code?: string;
    vat?: string;
    telephone?: string;
    email?: string;
    gender?: string;
    alfresco_id?: string;
    folder_id?: string;
    folder_name?: string;
    province?: string;
    sl_province?: string;
    sl_addres?: string;
    country?: string;
    sl_cpuntry?: string;
    sl_cap?: string;
    code?: string;
    view_name: string;
    createdat: Date;
    updatedat: Date;
    createdby: number;
    updatedby: number;
    enabled?: string;
    conpmany_id?: number;
    pack_id?: number;
    authorityId?: string;
    search?: string;
}

export class InfoInstance {
    pop_up: string;
    state: string;
    customer: Customer;
}

export class homepage {
    events: Array<any> = [];
    tickets: Array<any> = [];
    procces: Array<any> = [];
    activity: Array<any> = [];
}

export class Type {
    type_id: number;
    type_name: string;
    type_descritpion?: string;
    description?: string;
}

export class Group {
    group_id?: number;
    group_name?: string;
    description?: string;
    alfresco_id?: string;
    createdat?: string;
    updatedat?: string;
    createdby?: number;
    updatedby?: number;
    select?: any;
    authorityId?: string;
    search?: string
}

export class Participants {
    operators: Array<User> = [];
    customers: Array<Customer> = [];
    groups: Array<Group> = [];
}

export class Event {
    calendar_id?: number;
    calendar_name: string;
    description?: string;
    system_deadline?: string;
    start_date?: any;
    end_date?: any;
    state?: string;
    type_id: number;
    activity_id?: string;
    instance_id?: number;
    allday?: boolean = false;
    json?: any = {};
    deadline_id?: number;
    info_deadline?: any = {};
    filed?: string;
    createdat?: string;
    createdby?: number;
    updatedat?: string;
    updatedby?: number;
    participants?: Participants;
    ticket_id?: number;
    view_start_date?: any;
    view_end_date?: any;
}
export class TicketInfo {
    ticket: Ticket;
    openBy: User;
    customer: Customer;
    assignedAt: Array<User>;
}
export class Ticket {
    ticket_id?: number;
    ticket_name: string;
    description?: string = "";
    descriptions?: string;
    folder_id?: string;
    createdby?: number;
    createdat?: any;
    updatedby?: number;
    updatedat?: Date;
    assignedat?: number;
    state: string;
    priority: number;
    customer_id?: number;
    correctOpenat?: any;
    openby?: any;
    openFor?: any;
    color?: string;
    position: number;
    username?: any;
    view_name?: any;
}

export class Notification {
    number: number = 0;
    notify: Array<any> = [];
}

export class ProcessTemplate {
    process_tamplate_id?: number;
    name: string;
    description?: string;
    json?: any;
    createdat?: any;
}

export class UserData {
    type: string;
    task_name: string;
    name: string;
    function: string;
    generate_on_start: boolean = false;
    error_description?: string;
    description?: string;
    recipients?: Array<string>;
    email_text?: string;
    task_description?: string = '';
    html_template?: string;
    state?: string;
    dayBeforeEnd: number;
}

export class Shape {
    shape_id: number;
    shape_type: string;
    shape_proprieties: any;
    shape_element: any;
}

export class Node {
    type?: string;
    id?: string;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    alpha?: number;
    selectable?: boolean;
    draggable?: boolean;
    angle?: number;
    userData: UserData;
    cssClass?: string;
    ports?: Array<any>;
    bgColor?: any;
    color?: any;
    stroke?: number;
    radius?: number;
    dasharray?: number;
    add?: any;
    createPort?: any;
    getPorts: any;
}

export class Process {
    process_id?: number;
    name: string = '';
    description?: string = '';
    json?: any = {};
    createdat?: any;
    createdby?: number;
    updatedat?: any;
    updatedby?: number;
    search?: string;
}

export class Deadline {
    deadline_id?: number;
    name: string = '';
    description?: string = '';
    start_date?: any;
    recurrence?: Recurrance = new Recurrance();
    search?: any = '';
    repeat_every_year: boolean = false;
    previous_year: boolean = false;
}

export class Recurrance {
    repeat?: Repeat = new Repeat();
    end?: End = new End();
}

export class End {
    date?: any;
    after?: number;
    never?: boolean = null;
}

export class Repeat {
    step: number = 1;
    period?: string;
    by_day_of_week?: boolean = false; //il giorno della settimane nel numero della settimana del mese, false -> il numero del giorno del mese, true -> il nome del giorno della settimana del mese
    last_day_of_month?: boolean = false; // false se non è l'ultimo giorno della settimana, vera se è l'ultimo giorno della settimana
    day_of_week?: Day_Of_Week = new Day_Of_Week();
}

export class Day_Of_Week {
    mon?: boolean = false;
    tue?: boolean = false;
    wed?: boolean = false;
    thu?: boolean = false;
    fri?: boolean = false;
    sat?: boolean = false;
    sun?: boolean = false;
}

export class Fulfillment {
    fulfillment_id?: number;
    name: string;
    deadline?: Deadline = new Deadline();
    processes?: Array<Process> = [];
}

export class ActivityTime {
    activity_time_id?: number;
    start_date?: any;
    end_date?: any;
    start_time: any;
    end_time: any;
    calendar_id?: number;
    user_id?: number;
}