import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NewCustomerService } from 'src/app/services/new-customer.service';
import { Profile } from 'selenium-webdriver/firefox';
import { MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss']
})
export class NewCustomerComponent implements OnInit {
  token: string;
  selectedNewCustomer: any = {};
  validEmailType: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<NewCustomerComponent>,
    private newCustomerService: NewCustomerService,
    private globals: GlobalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.spinner.hide();
  }

  emailValidationType(e) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(e).toLowerCase())) {
      this.validEmailType = true;
    } else {
      this.validEmailType = false;
    }
  }

  addNewUser(newCus, cPassowrd) {
    cPassowrd = cPassowrd ? cPassowrd : '';
    newCus.password = newCus.password ? newCus.password : '';

    if (newCus.password == cPassowrd) {
      if (newCus.name && newCus.last_name && newCus.username && newCus.email) {
        if (this.validEmailType) {
          if (newCus.password == cPassowrd) {
            this.spinner.show();
            delete this.selectedNewCustomer.description;
            let obj: object = {
              user: newCus,
              profile: 1
            }
            this.newCustomerService.newUser(this.token, obj).subscribe(
              res => {
                this.globals.messageServer(res);
                this.globals.showNotification("Utente creato!", "success")
                this.globals.stampa("Risposta -> ", res);
                this.spinner.hide();
                this.dialogRef.close({ passUser: res.values });
              },
              err => {
                console.error("Errore in addNewUser: ", err);
                this.globals.messageServer(err);
                this.spinner.hide();
              }
            )
          } else {
            this.globals.showNotification("Le password non corrispondono", "warninng");
          }
        } else {
          this.globals.showNotification("Email non valida", "warning");
        }
      } else {
        this.globals.showNotification("Dati mancanti", "warning");
      }
    } else {
      this.globals.showNotification("Le password non corrispondono", "warning");
    }
  }

  canceling() {
    this.dialogRef.close('cancel');
  }

}
