import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewfolderService {

  constructor(private http: HttpClient, private globals: GlobalService) { }

  getGroups(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getGroupsURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  newGroup(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.newGroupURL, obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  newFolder(token, parentF, folderN) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.newFolder(parentF, folderN), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  setPermission(token, obj, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put<any>(this.globals.setNodePermission(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }


  getCustomer(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getCustomer, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getPermission(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getNodePermission(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateNameFolder(token, id, name) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put<any>(this.globals.updateFolderName(id), name, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  delFolder(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteFolder(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

}
