import { Injectable } from '@angular/core';


import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from './global.service';
import { of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Deadline } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService,
  ) { }

  addEvent(token, event): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.addEventURL, event, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getEvents(token): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getEventsURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  inputInstance(token, instance_id): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.getInputInstance, { procces: { procces_id: 5, instance_id: instance_id } }, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getEventParticipants(token, calendar_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getEventParticipantsURL(calendar_id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateEvent(token, obj, calendar_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.updateEventURL(calendar_id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateSingleDeadline(token, obj, calendar_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.updateSingleDeadlineURL(calendar_id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateDeadline(token, obj, deadline_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.updateDeadlineURL(deadline_id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteEvent(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteEventURL(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteDeadline(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteDeadlineURL(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteSingleDeadlineURL(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteSingleDeadlineURL(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getStartStop(token, cid, uid) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getStartStopURL(cid, uid), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  start(token, calendar_id, start) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.startURL(calendar_id), start, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  stop(token, calendar_id, stop) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.stopURL(calendar_id), stop, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getActivity(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getActivity(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  resizeEvent(token, event) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.resizeEventURL, event, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  setDeadline(token, deadline: Deadline) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.setDeadline, { deadline }, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getDeadlineList(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getDeadlineList(), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getRecipientsList(token, calendar_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getRecipientsList(calendar_id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getListActivity(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getListActivity(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteActivityTime(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteActivityTime(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getFulfillment(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getFulfillment(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateActivityTime(token, obj, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put<any>(this.globals.updateActivityTime(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  addActivityTime(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.addActivityTime(), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }
}
