import { Injectable } from '@angular/core';

import { GlobalService } from './global.service';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService
  ) { }


  getInitPage(token: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getOperatorPageURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )

  }
  getOperator(token: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getOperator, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  addOperator(token, operator) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.addOperator, operator, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getOperatorProfile(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getOperatorProfile, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteProfile(token, user_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteProfile(user_id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateProfile(token, user_id, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.updateUser(user_id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getDisponibility(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.operatorDisponibilityURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getCompany(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getCompanyURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateCompany(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.updateCompany(), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  addEmail(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.addEmail(), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteEmail(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteEmail(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  addAddress(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.addAddress(), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateAddress(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.updateCompanyAddress(), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteAddress(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteCompanyAddress(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

}
