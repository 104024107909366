import { Component, OnInit, Renderer2, ViewChild, ElementRef, Directive } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { LoginService } from 'src/app/services/login.service';
import { ListActivityComponent } from 'src/app/popup/list-activity/list-activity.component';
import { MatDialog } from '@angular/material';
import { ChangepwdComponent } from 'src/app/popup/changepwd/changepwd.component';
import { NavbarService } from 'src/app/services/navbar.service';
import { User, Type } from 'src/app/classes';
import { NgxSpinnerService } from 'ngx-spinner';

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare var $: any;
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private _router: Subscription;
    token: string = '';
    activeUser: any;
    activityNumber: number = 0;
    notify: number = 0;
    type_notification: Array<Type>;
    notificationMap: Map<string, number> = new Map();
    @ViewChild('app-navbar-cmp', { static: false }) button: any;

    constructor(
        location: Location,
        private renderer: Renderer2,
        private element: ElementRef,
        private router: Router,
        private globals: GlobalService,
        private loginService: LoginService,
        private navbarService: NavbarService,
        private spinner: NgxSpinnerService,
        public dialog: MatDialog
    ) {
        this.location = location;
        this.nativeElement = element.nativeElement;
    }
    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }
    hideSidebar() {
        const body = document.getElementsByTagName('body')[0];
        const sidebar = document.getElementsByClassName('sidebar')[0];

        if (misc.hide_sidebar_active === true) {
            setTimeout(function () {
                body.classList.remove('hide-sidebar');
                misc.hide_sidebar_active = false;
            }, 300);
            setTimeout(function () {
                sidebar.classList.remove('animation');
            }, 600);
            sidebar.classList.add('animation');

        } else {
            setTimeout(function () {
                body.classList.add('hide-sidebar');
                // $('.sidebar').addClass('animation');
                misc.hide_sidebar_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    ngOnInit() {
        this.token = this.globals.getToken(true);
        //check for Navigation Timing API support
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        this.activeUser = this.globals.storagedUser;
        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this._router = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            //this.sidebarClose();

            const $layer = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }
        });

        if (this.token) {
            this.navbarService.getNotificationType(this.token).subscribe(
                res => {
                    this.globals.messageServer(res);
                    this.type_notification = res;
                    this.type_notification.forEach((t_noty) => {
                        this.notificationMap.set(t_noty.type_name, t_noty.type_id);
                    });
                    this.globals.stampa("Tipologie: ", this.type_notification);
                },
                err => {
                    console.error("Error on get notifications: ", err);

                }
            )
            this.checkPWDPopup();
            // this.getNotifications();
            this.globals.activityNumber;
            this.globals.notify;
        }
        if (this.token) {
            this.globals.getNotify();
        }
    }

    openActivity() {
        this.spinner.show();
        const dialogRef = this.dialog.open(ListActivityComponent, {
            width: '80%',
            // height: '70%',
            // data: {
            //   customer: res,
            // },
            // disableClose: true
        })
        dialogRef.afterClosed().subscribe(result => {
            // this.reload();
            if (result != 'cancel') {

            } else {
            }
        })
    }

    onResize(event) {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }
    sidebarOpen() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function () {
            $toggle.classList.add('toggled');
        }, 430);

        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');


        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        } else if (body.classList.contains('off-canvas-sidebar')) {
            document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }

        setTimeout(function () {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = function () { //asign a function
            body.classList.remove('nav-open');
            this.globals.mobile_menu_visible = 0;
            this.globals.sidebarVisible = false;

            $layer.classList.remove('visible');
            setTimeout(function () {
                $layer.remove();
                $toggle.classList.remove('toggled');
            }, 400);
        }.bind(this);

        body.classList.add('nav-open');
        this.globals.mobile_menu_visible = 1;
        this.globals.sidebarVisible = true;
    };
    sidebarClose() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.globals.sidebarVisible = false;
        body.classList.remove('nav-open');
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        setTimeout(function () {
            $toggle.classList.remove('toggled');
        }, 400);

        this.globals.mobile_menu_visible = 0;
    };
    sidebarToggle() {
        if (this.globals.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        for (let i = 0; i < this.listTitles.length; i++) {
            if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
                return this.listTitles[i].title;
            } else if (this.listTitles[i].type === "sub") {
                for (let j = 0; j < this.listTitles[i].children.length; j++) {
                    let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
                    // this.globals.stampa(subtitle)
                    // this.globals.stampa(titlee)
                    if (subtitle === titlee) {
                        return this.listTitles[i].children[j].title;
                    }
                }
            }
        }
        return 'Dashboard';
    }

    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }

    logOut() {
        this.loginService.doLogout(this.token).subscribe(
            res => {
                this.token = null;
                this.globals.messageServer(res);
                this.globals.removeCache();
                this.globals.goToLogin();
                this.globals.stopInterval();
                
            },
            err => {
                console.error("Error on do log out"), err;
                this.globals.messageServer(err);
            }
        )
    }

    checkPWDPopup() {
        this.globals.checkToken(this.token).subscribe(
            res => {
                this.globals.messageServer(res);
                this.globals.password_changed = res.user.password_changed;
                if (this.globals.password_changed == false) {
                    this.spinner.show();
                    const dialogRef = this.dialog.open(ChangepwdComponent, {
                        width: '70%',
                        // height: '35%',
                        data: {
                            canClose: false
                        },
                        disableClose: true
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result != 'cancel') {

                        }
                    });
                }
            },
            err => {
                console.error("checkToken -> ERR", err);
                this.globals.messageServer(err);
            }
        )
    }

    notifyAction(nty) {
        this.globals.stampa("Notifica: ", nty);

        if (nty.type_id == this.notificationMap.get('Profilo')) {
            this.globals.goTo('profile');
        }
        if (nty.type_id == this.notificationMap.get('Calendario')) {
            this.router.navigate(['/calendar', { calendar_id: nty.references_id }]);
        }
        if (nty.type_id == this.notificationMap.get('Ticket')) {
            this.router.navigate(['/ticket', { ticket_id: nty.references_id }]);
        }
        this.navbarService.notificationRead(this.token, nty.notification_id).subscribe(
            res => {
                this.globals.messageServer(res);
                this.globals.notify.notify.splice(this.globals.notify.notify.indexOf(nty), 1);
                this.globals.getNotifications();
            },
            err => {
                console.error("Errore: ", err);
            }
        )
    }
}
