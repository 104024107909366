import { Component, OnInit, Inject } from '@angular/core';
import { Process } from 'src/app/classes';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivityService } from 'src/app/services/activity.service';

export interface DialogData {
  process: Process;
  new: boolean;
}

@Component({
  selector: 'app-seveprocess',
  templateUrl: './seveprocess.component.html',
  styleUrls: ['./seveprocess.component.scss']
})
export class SeveprocessComponent implements OnInit {

  token: string;
  constructor(public dialogRef: MatDialogRef<SeveprocessComponent>,
    public spinner: NgxSpinnerService,
    public globals: GlobalService,
    public activityService: ActivityService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { 
    }

  ngOnInit() {
    if(!this.token){
      this.token = this.globals.getToken();
    }
    this.spinner.hide();
  }

  save(){
    let obj;
    if(this.data.new){
      obj = {
        process: this.data.process
      }
      this.activityService.newProcess(this.token, obj).subscribe(
        (res) => {
          this.globals.stampa('Processo salvato.');
          this.globals.messageServer(res);
          this.dialogRef.close();
        },
        (err) => {
          console.error("Error on save process");
          this.globals.messageServer(err);
        }
      )
    } else {
      obj = {
        process: this.data.process
      }
      this.activityService.saveProcess(this.token, obj, this.data.process.process_id).subscribe(
        (res) => {
          this.globals.stampa('Processo salvato.');
          this.globals.messageServer(res);
          this.dialogRef.close();
        },
        (err) => {
          console.error("Error on save process");
          this.globals.messageServer(err);
        }
      )
    }
    
  }

  onNoClick(): void {
    this.dialogRef.close('cancel');
  }

}
