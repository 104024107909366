import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { FormControl } from '@angular/forms';
import { Observable, pairs } from 'rxjs';
import { Type, User, Customer, Group, Participants, Event } from 'src/app/classes';
import { startWith, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

export interface List{
  name: string;
  operators?: Array<any>,
  customers?: Array<any>,
  groups?: Array<any>,

}

@Component({
  selector: 'app-memberselector',
  templateUrl: './memberselector.component.html',
  styleUrls: ['./memberselector.component.scss']
})

export class MemberselectorComponent implements OnInit {

  
  @Input('lists') lists: List;
  @Input('participants') participants: Participants;
  @Output() messageEvent = new EventEmitter<any>();
  myControlOp = new FormControl();
  myControlCus = new FormControl();
  myControlGrp = new FormControl();

  filteredOperators: Observable<any[]>;
  filteredCustomers: Observable<any[]>;
  filteredGroups: Observable<any[]>;
  op: any;
  cus: any;
  grp: any;

  constructor(
    public globals: GlobalService,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    if(!this.participants || !this.participants.customers || !this.participants.operators || !this.participants.groups){
      this.participants = new Participants();
    }
    this.createElements();
  }

  createElements(){
    let _self = this;
    _self.lists[0].operators.forEach(op => {
      op.search = this.globals.createLocalSearch({ name: op.name, last_name: op.last_name, username: op.username })
    });

    _self.filteredOperators = _self.myControlOp.valueChanges.pipe(
      startWith(''),
      map((value) => {
        _self.globals.stampa("Value Op: ", value);
        return typeof value === "string" ? value : value.search
      }),
      map((search) => {
        _self.globals.stampa("Search Op: ", search);
        return search ? _self._filter(search, _self.lists[0].operators) : _self.lists[0].operators.slice()
      })
    );
    _self.lists[1].customers.forEach((cus) => {
      if(cus.type=='GIU'){
        cus.search = this.globals.createLocalSearch({name: cus.view_name, business_name: cus.business_name})
      } else {
        cus.search = this.globals.createLocalSearch({view_name: cus.view_name, name: cus.name, last_name: cus.last_name });
      }
    })

    _self.filteredCustomers = _self.myControlCus.valueChanges.pipe(
      startWith(''),
      map((value) => {
        _self.globals.stampa("Value Cus: ", value);
        return typeof value === "string" ? value : value.search
      }),
      map((search) => {
        _self.globals.stampa("Search Cus: ", search);
        return search ? _self._filter(search, _self.lists[1].customers) : _self.lists[1].customers.slice()
      })
    );
    _self.lists[2].groups.forEach((grp) => {
      grp.search = this.globals.createLocalSearch({ name: grp.group_name }); 
    })
    _self.filteredGroups = _self.myControlGrp.valueChanges.pipe(
      startWith(''),
      map((value) => {
        _self.globals.stampa("Value Op: ", value);
        return typeof value === "string" ? value : value.search
      }),
      map((search) => {
        _self.globals.stampa("Search Op: ", search);
        return search ? _self._filter(search, _self.lists[2].groups) : _self.lists[2].groups.slice()
      })
    );
    
    _self.spinner.hide();
    // this.globals.stampa('Operatori filtrati: ', this.filteredOperators);
  }

  private _filter(name: string, array: Array<any>): User[] {
    const filterValue = name.toLowerCase();
    this.globals.stampa("NameOp: ", name);

    return array.filter(
      ele => ele.search.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  displayFnOp(user: User): string {
    return user && `${user.last_name} ${user.name} (Username: ${user.username})`;
  }

  displayFnCus(cus: Customer): string {
    return cus && `${cus.view_name}`;
  }

  displayFnGrp(grp: Group): string {
    return grp && `${grp.group_name}`;
  }

  push(add) {
    let toAdd = true;
    if (add) {
      if (add.user_id) {
        this.participants.operators.forEach((op) => {
          if (add.user_id == op.user_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.operators.push(add);
          this.op = '';
          document.getElementById("addOperator").blur();
        } else {
          document.getElementById("addOperator").blur();
          this.globals.stampa("Already exist");
          this.op = '';
          this.globals.showNotification("Operatore già presente in lista", "warning");
        }
      }

      if (add.customer_id) {
        this.participants.customers.forEach((cus) => {
          if (add.customer_id == cus.customer_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.customers.push(add);
          this.cus = '';
          document.getElementById("addCustomer").blur();
        } else {
          this.globals.stampa("Already exist");
          this.globals.showNotification("Cliente già presente in lista", "warning");
          this.cus = '';
          document.getElementById("addCustomer").blur();
        }
      }

      if (add.group_id) {
        this.participants.groups.forEach((grp) => {
          if (add.group_id == grp.group_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.groups.push(add);
          this.grp = '';
          document.getElementById("addGroup").blur();
        } else {
          document.getElementById("addGroup").blur();
          this.globals.showNotification("Gruppo già presente in lista", "warning");
          this.globals.stampa("Already exist");
          this.grp = '';
        }
      }
    }
    this.sendData();
  }

  delete(remove) {
    if (remove) {
      if (remove.customer_id) {
        // if (this.participants.customers.length == 1 && this.participants.operators.length == 0 && this.participants.groups.length == 0) {
        //   // this.globals.showNotification("Impossibile lasciare un evento senza partecipanti", 'danger')
        // } else {
          this.globals.stampa("posizione: ", this.participants.customers.indexOf(remove));
          if (this.participants.customers.indexOf(remove) < 0) {
            this.globals.stampa("Doesen't exist");
          } else {
            this.participants.customers.splice(this.participants.customers.indexOf(remove), 1)
          }
        // }
      }
      if (remove.user_id) {
        //if (this.participants.customers.length == 0 && this.participants.operators.length == 1 && this.participants.groups.length == 0) {
        //  // this.globals.showNotification("Impossibile lasciare un evento senza partecipanti", 'danger')
        //} else {
          this.globals.stampa("posizione: ", this.participants.operators.indexOf(remove));

          if (this.participants.operators.indexOf(remove) < 0) {
            this.globals.stampa("Doesen't exist");
          } else {
            this.participants.operators.splice(this.participants.operators.indexOf(remove), 1)
          }
        // }
      }
      if (remove.group_id) {
        // if (this.participants.customers.length == 0 && this.participants.operators.length == 0 && this.participants.groups.length == 1) {
        //   // this.globals.showNotification("Impossibile lasciare un evento senza partecipanti", 'danger')
        // } else {
          this.globals.stampa("posizione: ", this.participants.groups.indexOf(remove));

          if (this.participants.groups.indexOf(remove) < 0) {
            this.globals.stampa("Doesen't exist");

          } else {
            this.participants.groups.splice(this.participants.groups.indexOf(remove), 1)
          }
        // }
      }
    }
    this.sendData();
  }

  sendData(){
    this.messageEvent.emit(this.participants);
  }
}
