import CustomContextPad from './CustomContextPad';
import CustomPalette from './CustomPalette';
import CustomRenderer from './CustomRenderer';
import CustomReplaceMenuProvider from './CustomReplaceMenuProvider';

export default {
  __init__: ['customContextPad', 'customPalette', 'customRenderer', 'replaceMenuProvider'],
  customContextPad: ['type', CustomContextPad],
  customPalette: ['type', CustomPalette],
  customRenderer: ['type', CustomRenderer],
  replaceMenuProvider: ['type', CustomReplaceMenuProvider]
};