import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Fulfillment } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class FulfillmentService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService
  ) { }

  getFulfillments(token): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getGetFulfillmentsURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateFulfillment(token, fulfillment: Fulfillment): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put<any>(this.globals.getUpdateFulfillmentsURL(fulfillment.fulfillment_id), {fulfillment}, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  newFulfillment(token, fulfillment: Fulfillment): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.newFulfillmentURL, {fulfillment}, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }
}
