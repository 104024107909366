import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { EliminaService } from 'src/app/services/elimina.service';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';
import { AddeventComponent } from 'src/app/popup/addevent/addevent.component';
import { MatDialog } from '@angular/material';
import { GlobalService } from 'src/app/services/global.service';
import { CalendarService } from 'src/app/services/calendar.service';
import * as moment from 'moment-timezone';

declare const $: any;

@Component({
  selector: 'app-list-deadline',
  templateUrl: './list-deadline.component.html',
  styleUrls: ['./list-deadline.component.scss']
})
export class ListDeadlineComponent implements OnInit {

  envi = environment;
  isOnlyRead: boolean = false;
  choose: boolean = false;

  listDeadline: Array<any> = [];
  token: string;

  constructor(
    private Delete: EliminaService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private calendarService: CalendarService,
    public globals: GlobalService
  ) { }

  ngOnInit() {
    this.spinner.show();
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.getListDeadline();
  }

  reload() {
    this.choose = false;
    this.listDeadline = [];
    this.getListDeadline();
  }

  getListDeadline() {
    this.spinner.show();
    this.calendarService.getDeadlineList(this.token).subscribe(
      res => {
        this.listDeadline = res.values;
        this.globals.stampa("List_deadline -> ", this.listDeadline);
        this.globals.messageServer(res);
        if ($.fn.DataTable.isDataTable('#deadline_table')) {
          $('#deadline_table').DataTable().destroy();
          this.createTable();
        } else {
          this.createTable(true);
        }
        // this.spinner.hide();
      }, err => {
        console.error("Errore: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      }
    )
  }

  createTable(withFunction?) {
    let _self = this;
    let l_deadline = [];
    let tableObject: any = {};
    this.listDeadline.forEach((dead) => {
      let obj = {
        deadline_id: dead.deadline_id,
        name: dead.info_deadline.name,
        data: moment(dead.info_deadline.start_date).format('DD-MM-YYYY'),
        enabled: dead.enabled
      }
      l_deadline.push(obj);
    });
    tableObject.data = l_deadline;
    let config = {
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: {
        details: {
          type: 'column',
          target: 'tr'
        }
      },
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Ricerca utenti",
        url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
      },
      ajax: (data, callback, settings) => {
        callback(tableObject)
      },
      columns: [
        { title:'ID', data: 'deadline_id' },
        { title:'Nome', data: 'name' },
        { title:'Data', data: 'data' },
        { title:'Azione', data: null, className: 'td-actions text-right' }
      ],
      columnDefs: [
        {
          targets: [0],
          visible: false
        },
        {
          targets: [3],
          visible: true,
          render: function (data, type, full, meta) {
            if (full.enabled == 'N') {
              full = JSON.stringify(full);
              return `<a data-use='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                          <i class="material-icons">search</i>
                        </a>
                        <a data-use='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                          <i class="material-icons">close</i>
                        </a>`
            } else {
              full = JSON.stringify(full);
              return `<a data-use='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                          <i class="material-icons">search</i>
                        </a>
                        <a data-use='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                          <i class="material-icons">edit</i>
                        </a>
                        <a data-use='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                          <i class="material-icons">close</i>
                        </a>`
            }
          }
        }
      ]
    }
    $(document).ready(function () {
      $('#deadline_table').DataTable(config);
      if (withFunction) {
        $('#deadline_table').on('click', 'a.btn-info', function () {
          let list_dead: any = $(this).data('use')
          _self.listDeadline.forEach((ld) => {
            if (ld.name == list_dead.name) {
              _self.showForm(ld, true);
            }
          })
        });
        $('#deadline_table').on('click', 'a.btn-success', function () {
          let list_dead = $(this).data('use')
          _self.listDeadline.forEach((use) => {
            if (use.name == list_dead.name) {
              _self.showForm(use);
            }
          })
        });
        $('#deadline_table').on('click', 'a.btn-danger', function () {
          let list_dead = $(this).data('use')
          _self.listDeadline.forEach((use) => {
            if (use.name == list_dead.name) {
              _self.delProfile(use);
            }
          })
        });
      }
      _self.spinner.hide();
    })
  }

  showForm(dead, onlyShow?) {

    this.spinner.show();
    if (onlyShow) {
      const dialogRef = this.dialog.open(AddeventComponent, {
        width: '70%',
        // height: '80%',
        data: { start: dead.start_date, end: dead.end_date, eventTypes: dead.type_id, new: false, event: dead, default_type: this.globals.deadlineTypeID, onlyRicorrenza: true, onlyShow: true }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cancel') {

          this.reload();
        }
      });
    } else {
      const dialogRef = this.dialog.open(AddeventComponent, {
        width: '70%',
        // height: '80%',
        data: { start: dead.start_date, end: dead.end_date, eventTypes: dead.type_id, new: false, event: dead, default_type: this.globals.deadlineTypeID, onlyRicorrenza: true, onlyShow: false }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cancel') {
          this.reload();
        }
      });
    }

  }

  delProfile(dead) {
    swal({
      title: 'Vuoi eliminare questa ricorrenza ?',
      text: 'verranno cancellate solo le ricorrenze future dalla data di oggi',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annulla',
      confirmButtonText: 'Elimina',
      cancelButtonClass: "btn btn-danger",
      confirmButtonClass: "btn btn-success",
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.calendarService.deleteDeadline(this.token, dead.deadline_id).subscribe(
          res => {
            this.globals.messageServer(res);
            this.reload();
          },
          err => {
            console.error("Error on delete event");
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        )
        // this.globals.showNotification("Tutte","danger")
      } else {

      }
    });
  }

  newDeadline() {
    this.spinner.show();
    const dialogRefSub = this.dialog.open(AddeventComponent, {
      width: '80%',
      // height: '80%',
      data: { eventTypes: [{ type_id: this.globals.deadlineTypeID, type_name: 'Scadenza' }], new: true, default_type: this.globals.deadlineTypeID }
    });

    dialogRefSub.afterClosed().subscribe(
      res => {
        this.reload();
      }
    )
  }

}
