import { Component, OnInit, Input, Inject } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NewfolderService } from 'src/app/services/newfolder.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Group, Customer, User, Participants } from 'src/app/classes';
import { OperatorService } from 'src/app/services/operator.service';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-change-name-folder',
  templateUrl: './change-name-folder.component.html',
  styleUrls: ['./change-name-folder.component.scss']
})
export class ChangeNameFolderComponent implements OnInit {

  myControlOp = new FormControl();
  myControlCus = new FormControl();
  myControlGrp = new FormControl();
  filteredOperator: Observable<any[]>;
  filteredCustomer: Observable<any[]>;
  filteredGroups: Observable<any[]>;

  op: any;
  cus: any;
  grp: any;

  participants: Participants;

  //boolean
  check: boolean
  newGroup = false;
  changeFolderName = false;
  changed = false;
  other = true;
  permits: boolean = false;


  //array
  operators: Array<User> = [];
  group: Array<any> = [];
  groups: Array<Group> = [];
  groupAdd: Array<any> = [];
  customers: Array<Customer> = [];
  customer: Array<any> = [];
  customerF: Array<any> = [];
  customerG: Array<any> = [];
  groupAddC: Array<any> = [];
  groupAddModify: Array<any> = [];
  groupAddGrp: Array<any> = [];
  customerAdd: Array<any> = [];
  operatorsAdd: Array<any> = [];
  groupsAdd: Array<any> = [];
  activeUser: any;

  //string
  token: string;
  nameGroup: string = '';
  changeNameFolder: string;
  folder: string = 'Cartella senza nome';
  folderName;
  id;
  constructor(
    private globals: GlobalService,
    public newfolderService: NewfolderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangeNameFolderComponent>,
    private spinner: NgxSpinnerService,
    private operatorService: OperatorService
  ) {
    this.participants = new Participants();
  }


  //Input
  @Input() file;

  ngOnInit() {
    this.globals.stampa("Data: ", this.data);
    this.folderName = this.data.name;
    this.id = this.data.id;
    this.globals.stampa("folderName: ", this.folderName);
    this.globals.stampa("ID: ", this.id);
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.activeUser = this.globals.storagedUser;
    this.getOperators();
    this.getCustomer();
    this.getGroups();
    this.setList();
    // this.spinner.hide();
  }

  displayFnCus(cus: Customer): string {
    return cus && `${cus.view_name}`;
  }

  displayFn(user: User): string {
    return user && `${user.last_name} ${user.name} (Username: ${user.username})`;
  }

  displayFnGrp(grp: Group): string {
    return grp && `${grp.group_name}`;
  }

  private _filter(name: string, array: Array<any>): User[] {
    const filterValue = name.toLowerCase();
    this.globals.stampa("NameOp: ", name);

    return array.filter(
      ele => ele.search.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  getOperators() {
    let _self = this;
    this.operatorService.getOperator(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.operators = res;
        this.operators.forEach(op => {
          op.search = this.globals.createLocalSearch({ name: op.name, last_name: op.last_name, username: op.username })
        });
        _self.filteredOperator = _self.myControlOp.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value Op: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search Op: ", search);
            return search ? _self._filter(search, _self.operators) : _self.operators.slice()
          })
        );
        // this.spinner.hide();
      }
    )
  }
  getCustomer() {
    let _self = this;
    this.newfolderService.getCustomer(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.customers = res;
        this.customer = res;
        this.customer.forEach((element, k) => {
          if (element.type == "F") {
            this.customerF.push(this.customer[k])
          } else {
            this.customerG.push(this.customer[k]);
          }
          this.customers.forEach(cus => {
            if(cus.type=='GIU'){
              cus.search = this.globals.createLocalSearch({name: cus.view_name, business_name: cus.business_name})
            } else {
              cus.search = this.globals.createLocalSearch({view_name: cus.view_name, name: cus.name, last_name: cus.last_name });
            }
          });
          _self.filteredCustomer = _self.myControlCus.valueChanges.pipe(
            startWith(''),
            map((value) => {
              this.globals.stampa("Value Cus: ", value);
              return typeof value === "string" ? value : value.search
            }),
            map((search) => {
              this.globals.stampa("Search Cus: ", search);
              return search ? _self._filter(search, _self.customers) : _self.customers.slice()
            })
          );
        });
        // this.spinner.hide();
      },
      err => {
        console.error("Errore in getOperators", err);
        this.globals.messageServer(err);
      }
    )
  }

  getGroups() {
    let _self = this;
    this.newfolderService.getGroups(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.groups = res;
        this.groups.forEach(grp => {
          grp.select = false;
        })
        this.group = res;
        this.group.forEach(grp => {
          grp.select = false;
        })
        this.groups.forEach(grp => {
          grp.search = this.globals.createLocalSearch({ name: grp.group_name })
        });
        _self.filteredGroups = _self.myControlGrp.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value Op: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search Op: ", search);
            return search ? _self._filter(search, _self.groups) : _self.groups.slice()
          })
        );
        // this.spinner.hide();
      },
      err => {
        console.error("Errori in getGroups: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  setList() {
    this.spinner.show();
    if (this.id) {
      this.changeFolderName = true;
      this.changed = true;
      this.newfolderService.getPermission(this.token, this.id).subscribe(
        res => {
          this.globals.messageServer(res);
          if (res.locallySet) {
            if (res.locallySet.length > 0) {
              res.locallySet.forEach((element) => {
                if (element.customer_id) {
                  if (this.participants.customers.indexOf(element.customer_id) >= 0) {
                    this.globals.stampa("Already exist");
                  } else {
                    element.view_name = element.customer_name;
                    this.participants.customers.push(element);
                    this.globals.stampa("Participanti", this.participants);
                  }
                }
                if (element.user_id) {
                  if (this.participants.operators.indexOf(element.user_id) >= 0) {
                    this.globals.stampa("Already exist");
                  } else {
                    this.participants.operators.push(element);
                    this.globals.stampa("Participanti", this.participants);
                  }
                }
                if (element.group_id) {
                  if (this.participants.groups.indexOf(element.group_id) >= 0) {
                    this.globals.stampa("Already exist");
                  } else {
                    this.participants.groups.push(element);
                    this.globals.stampa("Participanti", this.participants);
                  }
                }
              })
              this.groupAdd = res.locallySet;
              this.groupAdd.forEach(element => {
                if (element.group_name) {
                  this.groupAddGrp.push(element);
                } else {
                  this.groupAddC.push(element);
                }
              })
            }
          }
          if (res.inherited) {
            if (res.inherited.length > 0) {
              res.inherited.forEach((element) => {
                if (element.customer_id) {
                  if (this.participants.customers.indexOf(element.customer_id) >= 0) {
                    this.globals.stampa("Already exist");
                  } else {
                    element.view_name = element.customer_name;
                    this.participants.customers.push(element);
                    this.globals.stampa("Participanti", this.participants);

                  }
                }
                if (element.user_id) {
                  if (this.participants.operators.indexOf(element.user_id) >= 0) {
                    this.globals.stampa("Already exist");
                  } else {
                    this.participants.operators.push(element);
                    this.globals.stampa("Participanti", this.participants);

                  }
                }
                if (element.group_id) {
                  if (this.participants.groups.indexOf(element.group_id) >= 0) {
                    this.globals.stampa("Already exist");
                  } else {
                    this.participants.groups.push(element);
                    this.globals.stampa("Participanti", this.participants);

                  }
                }
              })
              this.groupAdd = res.inherited;
              this.groupAdd.forEach(element => {
                if (element.group_name) {
                  this.groupAddGrp.push(element);
                } else {
                  this.groupAddC.push(element);
                }
              })
            }
          }
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          console.error("Errore in getPermission: ", err);
          this.globals.messageServer(err);
        }
      )
      this.groupAdd.forEach(element => {
        if (element.group_name) {
          this.groupAddGrp.push(element);
        } else {
          this.groupAddC.push(element)
        }
      })
    }
  }

  delFolder() {
    this.newfolderService.delFolder(this.token, this.id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.dialogRef.close();
      },
      err => {
        console.error("Errore in deleteFolder: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  add() {
    this.newGroup = true;
  }

  push(add) {
    let toAdd = true;
    if (add) {

      if (add.user_id) {
        this.participants.operators.forEach((op) => {
          if (add.user_id == op.user_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.operators.push(add)
          document.getElementById("addOperator").blur();
          this.op = '';
        } else {
          this.op = '';
          document.getElementById("addOperator").blur();
          this.globals.stampa("Already exist");
          this.globals.showNotification("Operatore già presente in lista", "warning");
        }
      }

      if (add.customer_id) {
        this.participants.customers.forEach((cus) => {
          if (add.customer_id == cus.customer_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.customers.push(add)
          document.getElementById("addCustomer").blur();
          this.cus = '';
        } else {
          this.cus = '';
          document.getElementById("addCustomer").blur();
          this.globals.stampa("Already exist");
          this.globals.showNotification("Cliente già presente in lista", "warning");
        }
      }

      if (add.group_id) {
        this.participants.groups.forEach((grp) => {
          if (add.group_id == grp.group_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.groups.push(add)
          document.getElementById("addGroup").blur();
          this.grp = '';
        } else {
          this.grp = '';
          document.getElementById("addGroup").blur();
          this.globals.stampa("Already exist");
          this.globals.showNotification("Gruppo già presente in lista", "warning");
        }
      }
    }
  }

  delete(list) {
    this.globals.stampa("Delete");

    if (list.customer_id) {
      if (this.participants.customers.length == 1 && this.participants.operators.length == 0 && this.participants.groups.length == 0) {
        this.globals.showNotification("Impossibile non associare permessi", 'danger')
      } else {
        let obj = {
          customer_id: list.customer_id,
          alfresco_id: list.alfresco_id
        }
        this.participants.customers.splice(this.participants.customers.indexOf(list), 1);
      }
    }

    if (list.user_id) {
      if (this.participants.customers.length == 0 && this.participants.operators.length == 1 && this.participants.groups.length == 0) {
        this.globals.showNotification("Impossibile non associare permessi", 'danger')
      } else {
        let obj = {
          user_id: list.user_id,
          alfresco_id: list.alfresco_id
        }
        this.participants.operators.splice(this.participants.operators.indexOf(list), 1);
      }
    }

    if (list.group_id) {
      if (this.participants.customers.length == 0 && this.participants.operators.length == 0 && this.participants.groups.length == 1) {
        this.globals.showNotification("Impossibile non associare permessi", 'danger')
      } else {
        let obj = {
          group_id: list.group_id,
          alfresco_id: list.alfresco_id
        }
        this.participants.groups.splice(this.participants.groups.indexOf(list), 1)
      }
    }
  }

  updateFolder() {
    if (this.changed == true) {
      if (this.data.isFolder == true && this.folderName.length > 100) {
        this.globals.showNotification("Il nome supera i 100 caratteri", "warning")
      } else {
        this.spinner.show();
        this.newfolderService.updateNameFolder(this.token, this.id, { name: this.folderName }).subscribe(
          res => {
            this.globals.messageServer(res);
            if (this.participants.customers.length > 0 || this.participants.operators.length > 0 || this.participants.groups.length > 0) {
              let obj: any;
              obj = {
                auth: [],
                inherited: false
              }
              if (this.participants.customers.length > 0) {
                this.participants.customers.forEach(element => {
                  obj.auth.push({
                    authorityId: element.alfresco_id ? element.alfresco_id : element.authorityId,
                    name: "Contributor",
                    accessStatus: "ALLOWED",
                  })
                });
              }
              if (this.participants.operators.length > 0) {
                this.participants.operators.forEach(element => {
                  obj.auth.push({
                    authorityId: element.alfresco_id ? element.alfresco_id : element.authorityId,
                    name: "Contributor",
                    accessStatus: "ALLOWED",
                  })
                });
              }
              if (this.participants.groups.length > 0) {
                this.participants.groups.forEach(element => {
                  obj.auth.push({
                    authorityId: element.alfresco_id ? element.alfresco_id : element.authorityId,
                    name: "Contributor",
                    accessStatus: "ALLOWED",
                  })
                });
              }
              let id: string = res.id
              this.newfolderService.setPermission(this.token, obj, this.id).subscribe(
                res => {
                  this.globals.messageServer(res);
                },
                err => {
                  console.error("Errore in setPermission: ", err);
                  this.globals.messageServer(err);
                }
              )
            }
            this.spinner.hide();
            this.dialogRef.close();
          },
          err => {
            console.error("Errore in addFolder: ", err);
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        )
      }
    }
  }

  addGroup() {
    if (this.nameGroup) {
      let obj = {
        group: {
          group_name: this.nameGroup
        },
        customers: []
      }
      this.newfolderService.newGroup(this.token, obj).subscribe(
        res => {
          this.globals.messageServer(res);
          this.getGroups();
          this.newGroup = false;
          this.nameGroup = ''
        }, err => {
          console.error("Errore in addGroup: ", err);
          this.globals.messageServer(err);
        }
      )
    } else {
      // alert("Inserisci il nome del gruppo")
      this.globals.showNotification("Inserisci il nome del gruppo", "warning");
    }
  }

  smista() {
    this.groupAdd.forEach(element => {
      if (element.group_name) {
        this.groupAddGrp.push(element);
      } else {
        this.groupAddC.push(element)
      }
    });
  }

  permitsFunc() {
    if (this.permits) {
      this.permits = false;
    } else {
      this.permits = true;
    }

  }

  canceling() {
    this.dialogRef.close('Cancel');
  }

}
