import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/utils/format-datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { User, Event } from 'src/app/classes';
import { TicketService } from '../../services/ticket.service';
import { AddeventComponent } from '../addevent/addevent.component';
import { environment } from '@environment';
import * as moment from 'moment-timezone';
import { FormControl } from '@angular/forms';
import swal from 'sweetalert2';

import { CalendarService } from 'src/app/services/calendar.service';

declare const $: any;

export interface DialogData {
  ticket_id: number;
}
@Component({
  selector: 'app-ticketactivity',
  templateUrl: './ticketactivity.component.html',
  styleUrls: ['./ticketactivity.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class TicketactivityComponent implements OnInit {

  token: string;
  userActive: User;
  activities: Array<Event>

  envi = environment;

  constructor(
    public dialogRef: MatDialogRef<TicketactivityComponent>,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public globals: GlobalService,
    private ticketService: TicketService,
    private calendarService: CalendarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.userActive = this.globals.storagedUser;
    this.token = this.globals.getToken();
    if (this.data.ticket_id) {
      this.getTicketActivity();
    }
    // this.spinner.hide();
  }

  reload() {
    this.getTicketActivity();
  }

  addActivity() {
    this.spinner.show();
    let now = new Date()
    const dialogRefSub = this.dialog.open(AddeventComponent, {
      width: '80%',
      // height: '80%',
      data: { start: now, end: now, eventTypes: [{ type_id: this.globals.activityTypeId, type_name: 'Attività' }], new: true, ticket_id: this.data.ticket_id, default_type: this.globals.activityTypeId }
    });

    dialogRefSub.afterClosed().subscribe(
      res => {
        this.globals.messageServer(res);
        this.reload();
      }
    )
  }

  delActivity(calendar_id) {
    swal({
      title: `Cancellare l'attivita?`,
      // text: 'You will not be able to recover this imaginary file!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.calendarService.deleteEvent(this.token, calendar_id).subscribe(
          res => {
            this.globals.messageServer(res);
            this.getTicketActivity();
          },
          err => {
            this.spinner.hide();
            this.globals.stampa("Errore in deleteEvent: ", err);
            this.globals.messageServer(err);
          }
        )
      }
    })
  }

  getTicketActivity() {
    this.spinner.show();
    let _self = this;
    // this.globals.stampa("Jquery: ", $);
    this.ticketService.getTicketActivity(this.token, this.data.ticket_id).subscribe(
      res => {
        this.activities = res;
        this.globals.messageServer(res);
        this.activities.forEach(act => {
          act.view_start_date = new FormControl(act.start_date);
          act.view_end_date = new FormControl(act.end_date);
          act.view_start_date = moment(act.view_start_date).format('DD-MM-YYYY');
          act.view_end_date = moment(act.view_end_date).format('DD-MM-YYYY');
        });

        this.globals.stampa(this.activities);
        if ($.fn.DataTable.isDataTable('#datatablesActivity')) {
          $('#datatablesActivity').DataTable().destroy();
          let _self = this;
          let activitiess = [];
          let tableObject = {
            data: []
          }
          this.activities.forEach((acti) => {
            let obj = {
              calendar_id: acti.calendar_id,
              calendar_name: acti.calendar_name,
              view_start_date: acti.view_start_date,
              view_end_date: acti.view_end_date,
              state: acti.state,
            }
            activitiess.push(obj);
          });
          tableObject.data = activitiess;
          let config = {
            pagingType: "full_numbers",
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "All"]
            ],
            responsive: {
              details: {
                type: 'column',
                target: 'tr'
              }
            },
            language: {
              search: "_INPUT_",
              searchPlaceholder: "Ricerca operatori",
              url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
            },
            ajax: (data, callback, settings) => {
              callback(tableObject)
            },
            columns: [
              { title:'ID', data: 'calendar_id' },
              { title:'Nome', data: 'calendar_name' },
              { title:'Inizio', data: 'view_start_date' },
              { title:'Fine', data: 'view_end_date' },
              { title:'Stato', data: 'state' },
              { title:'Azione', data: null, className: 'td-actions text-right' }
            ],
            columnDefs: [
              {
                targets: [0],
                visible: false
              },
              {
                targets: [5],
                visible: true,
                render: function (data, type, full, meta) {
                  full = JSON.stringify(full);
                  return `<a data-act='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                          <i class="material-icons">search</i>
                        </a>
                        <a data-act='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                          <i class="material-icons">edit</i>
                        </a>
                        <a data-act='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                          <i class="material-icons">close</i>
                        </a>`
                }
              }
            ]
          }
          $(document).ready(function () {
            this.table = $('#datatablesActivity').DataTable(config);
            this.spinner.hide();
          })
        } else {

          let _self = this;
          let activitiess = [];
          let tableObject = {
            data: []
          }
          this.activities.forEach((acti) => {
            let obj = {
              calendar_id: acti.calendar_id,
              calendar_name: acti.calendar_name,
              view_start_date: acti.view_start_date,
              view_end_date: acti.view_end_date,
              state: acti.state,
            }
            activitiess.push(obj);
          });
          tableObject.data = activitiess;
          let config = {
            pagingType: "full_numbers",
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "All"]
            ],
            responsive: {
              details: {
                type: 'column',
                target: 'tr'
              }
            },
            language: {
              search: "_INPUT_",
              searchPlaceholder: "Ricerca operatori",
              url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
            },
            ajax: (data, callback, settings) => {
              callback(tableObject)
            },
            columns: [
              { data: 'calendar_id' },
              { data: 'calendar_name' },
              { data: 'view_start_date' },
              { data: 'view_end_date' },
              { data: 'state' },
              { data: null, className: 'td-actions text-right' }
            ],
            columnDefs: [
              {
                targets: [0],
                visible: false
              },
              {
                targets: [5],
                visible: true,
                render: function (data, type, full, meta) {
                  full = JSON.stringify(full);
                  return `<a data-act='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                          <i class="material-icons">edit</i>
                          </a>
                          <a data-act='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                          <i class="material-icons">close</i>
                          </a>`
                }
              }
            ]
          }
          $(document).ready(function () {
            this.table = $('#datatablesActivity').DataTable(config);


            $('#datatablesActivity').on('click', 'a.btn-success', function () {
              let actObj = $(this).data('act')
              _self.activities.forEach((activity) => {
                if (activity.calendar_id == actObj.calendar_id) {
                  _self.openActivity(activity);
                }
              })
            });

            $('#datatablesActivity').on('click', 'a.btn-danger', function () {
              let actObj = $(this).data('act').split(',')
              _self.activities.forEach((activity) => {
                if (activity.calendar_id == actObj.calendar_id) {
                  _self.delActivity(activity.calendar_id);
                }
              })
            });
            _self.spinner.hide();
          })
        }

        // this.spinner.hide();
      },
      err => {
        console.error("Error on get ticket activities");
        this.spinner.hide();
        this.globals.messageServer(err);
      }
    )
  }

  openActivity(act) {
    this.spinner.show();
    const dialogRefSub = this.dialog.open(AddeventComponent, {
      width: '80%',
      // height: '80%',
      data: { start: act.start_date, end: act.end_date, new: false, ticket_id: this.data.ticket_id, event: act, default_type: this.globals.activityTypeId }
    });

    dialogRefSub.afterClosed().subscribe(
      res => {
        this.globals.messageServer(res);
        this.reload();
      }
    )
  }

  save() {
    this.dialogRef.close();
  }

  onNoClick() {
    this.dialogRef.close()
  }

}