import { Component, OnInit, Input, Inject } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { faPlus, faFolderOpen as fafolderopen, faUser } from '@fortawesome/free-solid-svg-icons';
import { faFile, faFolderOpen, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


export interface DialogData {
  folder_id: any;
}

@Component({
  selector: 'app-openfolder',
  templateUrl: './openfolder.component.html',
  styleUrls: ['./openfolder.component.scss']
})
export class OpenfolderComponent implements OnInit {

  folder_id;

  token: string;

  selectedDir: any;

  parentID: Array<any> = [];
  menu: Array<any> = [];
  navigationArray: Array<any> = [];

  backDir: string;
  // boolean
  show = false;
  enterIf = false;
  disable = false;
  double = false;

  // number
  i = 1;

  // formData
  formData: any;
  uploadForm: FormGroup;

  // Icone
  faPlus = faPlus;
  faFile = faFile;
  faFolderOpen = faFolderOpen;
  faUserCircle = faUserCircle;
  fafolderopen = fafolderopen;
  faUser = faUser;

  folderIdActive: string;

  constructor(
    private globals: GlobalService,
    public dialogRef: MatDialogRef<OpenfolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { 
    this.folder_id = this.data.folder_id;

  }

  ngOnInit() {
    if (!this.token) {
      this.token = this.globals.getToken();
    } else {
      this.folder_id = this.data.folder_id;
    }
  }

  canceling() {
    this.dialogRef.close('cancel');
  }

}
