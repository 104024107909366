import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService
  ) { 

  }

  newGroup(token, newGroup): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.newGroupURL, newGroup, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  };
  
  getGroups(token): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getGroupsURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    );
  }

  getGroup(token, groupId): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getGetGroupURL(groupId), httpOptions).pipe(
      catchError(this.globals.handleError)
    );
  }

  delGroup(token, groupId): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.getDeleteGroupURL(groupId), httpOptions).pipe(
      catchError(this.globals.handleError)
    );
  }

  delMember(token, id, obj): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.getDelMembersGroupURL(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    );
  }

  addMember(token, id, obj): Observable<any>{
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.getAddMembersToGroupURL(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    );
  }

  updateNameGroup(token, id, obj){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.getUpdateGroupNameURL(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    );
  }

  getOperatorsGroup(token){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getOperatorsGroupsURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    );
  }

}