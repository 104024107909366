import { Component, OnInit, Inject } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalendarService } from 'src/app/services/calendar.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivityTime, User } from 'src/app/classes';

declare const $: any;

export interface DialogData {
  calendar_id: any;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MM YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-activity-time-user-list',
  templateUrl: './activity-time-user-list.component.html',
  styleUrls: ['./activity-time-user-list.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ActivityTimeUserListComponent implements OnInit {

  envi = environment;
  token: string;
  activeUser: User;
  calendar_id: any;
  listActivity: Array<any> = [];
  activity_list_table: any;
  selectedActivity: ActivityTime = new ActivityTime();
  modify: boolean = false;
  new: boolean = false;
  updateList: boolean = false;

  constructor(
    public spinner: NgxSpinnerService,
    public globals: GlobalService,
    public calendarService: CalendarService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ActivityTimeUserListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.spinner.show();
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.activeUser = this.globals.storagedUser;
    this.calendar_id = this.data.calendar_id;
    this.selectedActivity = new ActivityTime();
    this.getListActivity();
  }


  getListActivity() {
    this.spinner.show();
    this.modify = false;
    this.updateList = false;
    this.new = false;
    this.calendarService.getListActivity(this.token, this.calendar_id).subscribe(
      res => {
        this.listActivity = res.values;
        this.listActivity.forEach(act => {
          if (act.start_time) {
            act.startTime = this.globals.cleanDateAndHour(act.start_time);
          } else {
            act.startTime = '';
          }
          if (act.end_time) {
            act.endTime = this.globals.cleanDateAndHour(act.end_time);
          } else {
            act.startTime = '';
          }
        });
        if ($.fn.DataTable.isDataTable('#listActivity')) {
          $('#listActivity').DataTable().destroy();
          this.createTable();
        } else {
          this.createTable(true);
        }
      }, err => {
        this.globals.messageServer(err);
        console.error("Error on stop activity", err);
        this.spinner.hide();
      }
    )
  }

  newLine() {
    this.selectedActivity.start_date = new Date(moment().format());
    this.selectedActivity.end_date = new Date(moment().format());
    this.selectedActivity.start_time = new Date();
    this.selectedActivity.end_time = new Date();
    this.selectedActivity.start_time.setHours(0);
    this.selectedActivity.start_time.setMinutes(0);
    this.selectedActivity.start_time.setSeconds(0);
    this.selectedActivity.end_time.setHours(0);
    this.selectedActivity.end_time.setMinutes(0);
    this.selectedActivity.end_time.setSeconds(0);
    this.selectedActivity.start_time = this.globals.getOnlyTime(this.selectedActivity.start_time);
    this.selectedActivity.end_time = this.globals.getOnlyTime(this.selectedActivity.end_time);
    this.new = true;
    this.modify = true;
    this.updateList = false;
  }

  createTable(withFunctions?) {
    let _self = this;

    // IN RITARDO
    let ListActivityTable = [];
    let tableObject_activity = {
      data: []
    }
    this.listActivity.forEach((act) => {
      let obj = {
        activity_time_id: act.activity_time_id,
        index_number: act.index_number,
        start_time: act.startTime,
        end_time: act.endTime
      }
      ListActivityTable.push(obj);
    });
    tableObject_activity.data = ListActivityTable;
    let configListActivity = {
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: {
        details: true
      },
      order: [[1, "asc"]],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Ricerca operatori",
        url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
      },
      ajax: (data, callback, settings) => {
        callback(tableObject_activity);
      },
      columns: [
        { title:'ID', data: 'activity_time_id' },
        { title:'Indice', data: 'index_number' },
        { title:'Inizio', data: 'start_time' },
        { title:'Fine', data: 'end_time' },
        { title:'Azione', data: null, className: 'td-actions text-right' }
      ],
      columnDefs: [
        {
          targets: [0],
          visible: false
        },
        {
          targets: [4],
          visible: true,
          render: function (data, type, full, meta) {
            full = JSON.stringify(full);
            return `
                <a data-act='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                  <i class="material-icons">edit</i>
                </a>
                <a data-act='${full}' matTooltip="Stop attività" mat-raised-button type="button" class="btn btn-danger action-button">
                  <i class="material-icons">close</i>
                </a>`
          }
        }
      ]
    }

    $(document).ready(function () {
      _self.activity_list_table = $('#listActivity').DataTable(configListActivity);

      if (withFunctions) {

        $('#listActivity').on('click', 'a.btn-success', function () {
          let ACT: any = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.listActivity.forEach((act) => {
            if (act.activity_time_id == ACT.activity_time_id) {
              _self.modifyActivity(act);
            }
          })
        });
        $('#listActivity').on('click', 'a.btn-danger', function () {
          // _self.globals.stampa($(this).data('cus'));
          let ACT = $(this).data('act')
          _self.globals.stampa("activity: ", ACT);
          _self.listActivity.forEach((act) => {
            if (act.activity_time_id == ACT.activity_time_id) {
              _self.deleteActivity(act);
            }
          })
        });
      }
      _self.spinner.hide();
    })
  }

  saveNew() {
    this.spinner.show();
    let splitStart: Array<string> = this.selectedActivity.start_time.split(':');
    let hours = parseInt(splitStart[0])
    let minuts = parseInt(splitStart[1]);
    this.selectedActivity.start_date = new Date(moment(this.selectedActivity.start_date).format())
    this.selectedActivity.start_date.setHours(hours);
    this.selectedActivity.start_date.setMinutes(minuts);

    let splitEnd: Array<string> = this.selectedActivity.end_time.split(':');
    hours = parseInt(splitEnd[0]);
    minuts = parseInt(splitEnd[1]);
    this.selectedActivity.end_date = new Date(moment(this.selectedActivity.end_date).format())
    this.selectedActivity.end_date.setHours(hours);
    this.selectedActivity.end_date.setMinutes(minuts);

    this.selectedActivity.start_time = moment(this.selectedActivity.start_date).format();
    this.selectedActivity.end_time = moment(this.selectedActivity.end_date).format();
    this.selectedActivity.calendar_id = this.calendar_id;
    this.selectedActivity.user_id = this.activeUser.user_id;

    this.calendarService.addActivityTime(this.token, {activityTime: this.selectedActivity}).subscribe(
      res => {
        this.selectedActivity = new ActivityTime();
        this.getListActivity()
        this.globals.messageServer(res);
      }, err => {
        this.globals.messageServer(err);
        console.error("Error on update activity time", err);
        this.spinner.hide();
      }
    )
  }

  update() {
    this.spinner.show();
    let splitStart: Array<string> = this.selectedActivity.start_time.split(':');
    let hours = parseInt(splitStart[0])
    let minuts = parseInt(splitStart[1]);
    this.selectedActivity.start_date = new Date(moment(this.selectedActivity.start_date).format())
    this.selectedActivity.start_date.setHours(hours);
    this.selectedActivity.start_date.setMinutes(minuts);

    let splitEnd: Array<string> = this.selectedActivity.end_time.split(':');
    hours = parseInt(splitEnd[0]);
    minuts = parseInt(splitEnd[1]);
    this.selectedActivity.end_date = new Date(moment(this.selectedActivity.end_date).format())
    this.selectedActivity.end_date.setHours(hours);
    this.selectedActivity.end_date.setMinutes(minuts);

    this.selectedActivity.start_time = moment(this.selectedActivity.start_date).format();
    this.selectedActivity.end_time = moment(this.selectedActivity.end_date).format();

    this.calendarService.updateActivityTime(this.token, { activityTime: this.selectedActivity }, this.selectedActivity.activity_time_id).subscribe(
      res => {
        this.selectedActivity = new ActivityTime();
        this.getListActivity()
        this.globals.messageServer(res);
      }, err => {
        this.globals.messageServer(err);
        console.error("Error on update activity time", err);
        this.spinner.hide();
      }
    )
  }

  modifyActivity(act) {
    this.selectedActivity.activity_time_id = act.activity_time_id
    this.selectedActivity.start_date = new Date(moment(act.start_time).format());
    this.selectedActivity.end_date = new Date(moment(act.end_time).format());
    this.selectedActivity.start_time = new Date();
    this.selectedActivity.end_time = new Date();
    this.selectedActivity.start_time.setHours(this.selectedActivity.start_date.getHours());
    this.selectedActivity.start_time.setMinutes(this.selectedActivity.start_date.getMinutes());
    this.selectedActivity.start_time.setSeconds(0);
    this.selectedActivity.end_time.setHours(this.selectedActivity.end_date.getHours());
    this.selectedActivity.end_time.setMinutes(this.selectedActivity.end_date.getMinutes());
    this.selectedActivity.end_time.setSeconds(0);
    this.selectedActivity.start_time = this.globals.getOnlyTime(this.selectedActivity.start_time);
    this.selectedActivity.end_time = this.globals.getOnlyTime(this.selectedActivity.end_time);
    this.modify = true;
    this.updateList = true;
    this.new = false;
  }

  deleteActivity(act) {
    swal({
      title: 'Vuoi eliminare questo record ?',
      // text: 'Non possono essere cancellate occorrenze in corso o passate',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annulla',
      confirmButtonText: 'Elimina',
      cancelButtonClass: "btn btn-danger",
      confirmButtonClass: "btn btn-success",
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.calendarService.deleteActivityTime(this.token, act.activity_time_id).subscribe(
          res => {
            this.getListActivity();
          }, err => {
            this.globals.messageServer(err);
            console.error("Error on delete activity time", err);
            this.spinner.hide();
          }
        )
      }
    })
  }

  canceling() {
    this.dialogRef.close('cancel');
  }

}
