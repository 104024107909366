export class BusinessObject {
    public id: string;
    public name: string;
    public type: string;
    public script: string;
    public scriptFormat: string;
    public expression: string;
    public conditionExpression: ConditionExpression;
};

export class ConditionExpression {
    public type: string;
    public body: string;
}

export class PropertiesPanelSelectOption {
    public name: string;
    public value: string;
}

export const SCRIPT_OPTIONS: Array<PropertiesPanelSelectOption> = [
    {
        name: 'Funzione Log',
        value: 'console.log("Proviamo: ", this.environment.variables.color);\nnext(null, "loggato");'
    },
    {
        name: 'Stampa colore',
        value: 'console.log(this.environment.variables.color);\nnext(null, "Colore loggato");'
    }/*,
    {
        name: 'TEST',
        value: 'Lallo'
    }*/
];

export const SERVICE_OPTIONS: Array<PropertiesPanelSelectOption> = [
    {
        name: 'Invio Mail',
        value: '${environment.services.sendMail}'
    },{
        name: 'Invio email raccolta documenti',
        value: '${environment.services.sendDocumentMail}'
    },{
        name: 'Email documenti caricati',
        value: '${environment.services.sendUploadedMail}'
    }/*,
    {
        name: 'TEST',
        value: 'Lallo'
    }*/
];

export const CONDITION_OPTIONS: Array<PropertiesPanelSelectOption> = [
    {
        name: 'Colore',
        value: '${environment.services.check("color")}'
    },{
        name: 'Primo giro',
        value: '${environment.services.first("first")}'
    },{
        name: 'Colore non cambiato',
        value: '${environment.services.checkColor("color")}'
    },{
        name: 'Documenti Ok',
        value: '${environment.services.checkDoc("doc_ok")}'
    }/*,
    {
        name: 'TEST',
        value: 'Lallo'
    }*/
];
