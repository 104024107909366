import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { GlobalService } from '../services/global.service';
import { LoginService } from '../services/login.service';
import { Header, User } from '../classes';
import { environment } from '@environment';
import { version } from '../../../package.json';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export var ROUTES: RouteInfo[] = [];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    envi = environment;
    public menuItems: RouteInfo[] = [];
    ps: any;
    token: string = '';
    userName = '';
    header: Array<Header>;
    userActive: User;
    version: any = version;
    constructor(
        private globals: GlobalService,
        private loginService: LoginService
    ) {
        this.header = this.globals.header;
        this.menuItems = [];
        ROUTES = [];
        this.header.forEach(headerElement => {
            let menuItem: RouteInfo = {
                path: headerElement.URL,
                title: headerElement.title,
                type: headerElement.type,
                icontype: headerElement.icontype
            }
            //this.menuItems.push(menuItem);
            ROUTES.push(menuItem);

        });
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        this.userActive = this.globals.storagedUser;
        this.userName = this.globals.storagedUser.username;
        this.token = this.globals.getToken(true);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }

    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');
        this.globals.sidebarVisible = false;
        body.classList.remove('nav-open');
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }
        this.globals.mobile_menu_visible = 0;
    };
    logOut() {
        this.sidebarClose();
        this.loginService.doLogout(this.token).subscribe(
            res => {
                // this.globals.messageServer(res);
                this.globals.removeCache();
                this.globals.goToLogin();
                clearInterval(this.globals.notifyInterval);

            },
            err => {
                console.error("Error on do log out"), err;
                this.globals.messageServer(err);
            }
        )
    }

    profile() {
        this.globals.goTo("profile");
    }

    company() {
        this.globals.goTo("company");
    }
}
