import { Component, OnInit, Inject, ViewChild} from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { OperatorService } from 'src/app/services/operator.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GroupService } from 'src/app/services/group.service';
import { Customer, User, Group, Participants } from 'src/app/classes';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MemberselectorComponent } from 'src/app/component/memberselector/memberselector.component';

@Component({
  selector: 'app-updatemembers',
  templateUrl: './updatemembers.component.html',
  styleUrls: ['./updatemembers.component.scss']
})

export class UpdatemembersComponent implements OnInit {
  token: string;
  operators: Array<User> = [];
  customers: Array<Customer> = [];
  groups: Array<Group> = [];
  lists: Array<any> = [];
  participants: Participants;
  load: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UpdatemembersComponent>,
    private globals: GlobalService,
    private operatorService: OperatorService,
    private customerService: CustomerService,
    private groupService: GroupService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {   }

  ngOnInit() {
    // this.spinner.show()
    if(!this.token){
      this.token = this.globals.getToken();
    }
    if(this.data.participants){
      this.participants = this.data.participants;
    } else {
      this.participants = new Participants();
    }
    this.getLists();
  }

  getOperators(){
    return new Promise((resolve, reject) => {
      this.operatorService.getOperator(this.token).subscribe(
        (res) => {
          this.globals.messageServer(res);
          this.operators = res;
          resolve();
        },
        (err) => {
          console.error("Error on get operators");
          this.globals.messageServer(err);
          reject();
        }
      )
    })
  }

  getCustomers(){
    return new Promise((resolve, reject) => {
      this.customerService.getCustomer(this.token).subscribe(
        (res) => {
          this.globals.messageServer(res);
          this.customers = res;
          resolve();
        },
        (err) => {
          console.error("Error on get customers");
          this.globals.messageServer(err);
          reject();
        }
      )
    })
  }

  getGroups(){
    return new Promise((resolve, reject) => {
      this.groupService.getOperatorsGroup(this.token).subscribe(
        (res) => {
          this.globals.messageServer(res);
          this.groups = res;
          resolve();
        },
        (err) => {
          console.error("Error on get groups");
          this.globals.messageServer(err);
          reject();
        }
      )
    })
  }

  getLists(){
    this.getOperators().then(() => {
      return this.getCustomers()
    }).then(()=> {
      return this.getGroups()
    }).then(() => {
      this.lists.push({
        name: 'Operatori: ',
        operators: this.operators
      });
      this.lists.push({
        name: 'Clienti: ',
        customers: this.customers
      })
      this.lists.push({
        name: 'Gruppi: ',
        groups: this.groups
      });
      this.spinner.hide();
      this.load = true;
    }).catch(() => {
      console.error("Error on load lists");
      this.spinner.hide();
    })
  }

  onNoClick(): void {
    this.dialogRef.close('cancel');
  }

  reciveMessage($event){
    this.participants = $event;
  }

  save(){
    this.dialogRef.close(this.participants);
  }
}
