import { Component, OnInit, HostListener } from '@angular/core';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { CookieService } from 'ngx-cookie-service';
import { User, Header } from '../../classes';
import { LoginService } from '../../services/login.service';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  tk: any;
  login = false;
  business: boolean;
  profil: Array<any> = [];
  key: any;
  operator: boolean = false;

  manager: Array<any> = [];
  customer: Array<any> = [];

  /* VARABLES */
  faUser = faUser;
  faLock = faLock;
  schema = '';
  user: User = {
    username: '',
    password: ''
  };

  header: Header = {};
  message = '';
  constructor(
    private loginService: LoginService,
    private cookie: CookieService,
    public dialog: MatDialog,
    private globals: GlobalService,
    private spinner: NgxSpinnerService,
    private navbarService: NavbarService,
  ) {

  }

  ngOnInit() {
    this.checkToken()
  }
  focus() {
    document.getElementById("pwd-input").focus();
  }

  doLogin() {
    let userA = this.user.username.split('@'); // Splitto lo username per ottenere lo schema e lo username
    if (userA.length > 1) {
      this.schema = userA[1];
      this.user.username = userA[0];
      this.user.schema = userA[1];
      userA = [];
      this.loginService.doLogin(this.user).subscribe(
        res => {
          // this.globals.messageServer(res);
          this.profil = res.profiles;
          if (this.profil.length > 1) {
            this.profil.forEach(element => {
              if (element.isoperator == "Y") {
                element.description = element.name + " " + element.last_name;
                this.manager.push(element);
                this.operator = true;
              } else {
                if (element.business_name != null) {
                  element.description = element.business_name;
                } else {
                  element.description = element.cname + " " + element.clast_name
                }
                this.customer.push(element);
              }
              this.tk = res.token;
              this.login = true;
            });
          } else {
            this.storageToken(res.token, this.user.rememberme);
            this.tk = this.globals.getToken(true);
            this.firstLogin(res.profiles[0].user_customer_profile_id);
          }
        },
        (err) => {
          this.message = "Errore durante il login";
          this.globals.messageServer(err);
        }
      )
    }
    else {
      if (this.schema) {
        this.user.schema = this.schema;
        this.loginService.doLogin(this.user).subscribe(
          res => {
            // this.globals.messageServer(res);
            this.globals.stampa("res: ", res);
            this.profil = res.profiles;
            if (this.profil.length > 1) {
              this.profil.forEach(element => {
                if (element.isoperator == "Y") {
                  element.description = element.name + " " + element.last_name;
                  this.manager.push(element);
                  this.operator = true;
                } else {
                  if (element.business_name != null) {
                    element.description = element.business_name;
                  } else {
                    element.description = element.cname + " " + element.clast_name
                  }
                  this.customer.push(element);
                }
                this.tk = res.token;
                this.login = true;
              });
            } else {
              this.storageToken(res.token, this.user.rememberme);
              this.tk = this.globals.getToken(true);
              this.firstLogin(res.profiles[0].user_customer_profile_id);
            }
          },
          err => {
            this.message = "Errore durante il login";
            this.globals.messageServer(err);
          }
        )
      } else {
        this.message = "username non valido (username@azienda)";
      }
    }
  }

  firstLogin(cpid) {
    let tempRes: any;
    this.spinner.show();
    let tk = this.globals.tokenName;
    this.loginService.secondLogin(this.tk, cpid).subscribe(
      res => {
        // this.globals.messageServer(res);
        this.storageToken(res.token, this.user.rememberme);
        this.setHeader(res.header);
        this.setUser(res.user);
        tempRes = res;
        if (this.cookie.check(tk)) {
          this.globals.checkToken(this.cookie.get(tk)).subscribe(
            res => {
              // this.globals.messageServer(res);
              this.globals.stampa("checkToken -> OK", res);
              this.navbarService.getNotifications(this.cookie.get(tk)).subscribe(
                res => {
                  // this.globals.messageServer(res);
                  this.globals.activityNumber = res.activityNumber;
                  this.globals.notify = res.notify;
                  if (this.globals.storagedUser.profile_id > 1) {
                    this.globals.password_changed = tempRes.user.password_changed;
                    this.globals.goTo('operator');
                  } else {
                    this.globals.password_changed = tempRes.user.password_changed;
                    this.globals.goToHome();
                  }
                },
                err => {
                  console.error("Errore in getNotifications: ", err);
                  this.globals.messageServer(err);
                }
              )
              this.spinner.hide();
            },
            err => {
              this.spinner.hide();
              console.error("checkToken -> ERR", err);
              this.globals.messageServer(err);
            })
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        console.error("KO", err);
        this.message = "Errore durante il secondo login";
        this.globals.messageServer(err);
      }
    )
  }

  checkToken() {
    let tk = this.globals.tokenName;
    if (this.cookie.check(tk)) {
      this.globals.checkToken(this.cookie.get(tk)).subscribe(
        res => {
          // this.globals.messageServer(res);
          this.globals.password_changed = res.user.password_changed;
          this.globals.goToHome();
        },
        err => {
          console.error("checkToken -> ERR", err);
          this.globals.messageServer(err);
        })
    }
  }

  storageToken(token: string, rememberme: boolean) {
    this.cookie.set(this.globals.tokenName, token);
  }

  setHeader(header) {
    this.globals.header = header;
    this.globals.header.forEach(component => {
      component.URL = this.getUrlByKey(component.key)
    })
    localStorage.setItem('header', JSON.stringify(header))
  }
  setUser(user) {
    this.globals.storagedUser = user;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUrlByKey(key) {
    switch (key) {
      case 1: {
        return '/operator'
      }
      case 2: {
        return '/customer'
      }
      case 3: {
        return '/documentary'
      }
      case 4: {
        return '/activity'
      }
      case 5: {
        return '/ticket'
      }
      case 6: {
        return '/contract'
      }
      case 7: {
        return '/group'
      }
      case 8: {
        return '/calendar'
      }
      case 9: {
        return '/user'
      }
      case 10: {
        return '/fulfillment'
      }
      case 11: {
        return '/deadline-list'
      }
    }
  }

  backLogin() {
    this.message = null;
    this.login = false;
    this.manager = [];
    this.customer = [];
    this.profil = [];
    this.header = {};
    this.tk = '';
    this.schema = '';
    this.user.username = '';
    this.user.password = '';
    this.operator = false;
  }
}