import { Component, OnInit, Inject } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { Type, User, Customer, Group, Participants, Event, Deadline } from 'src/app/classes';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CustomerService } from 'src/app/services/customer.service';
import { GroupService } from 'src/app/services/group.service';
import { OperatorService } from 'src/app/services/operator.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment-timezone';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import swal from 'sweetalert2';
import { RecipientslistComponent } from '../recipientslist/recipientslist.component';

export interface DialogData {
  start: Date;
  end: Date;
  eventTypes: Array<Type>;
  new: boolean;
  event: Event;
  ticket_id?: number;
  default_type: number;
  onlyShow?: boolean;
  onlyRicorrenza?: boolean;
  state: string;
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MM YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-addevent',
  templateUrl: './addevent.component.html',
  styleUrls: ['./addevent.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AddeventComponent implements OnInit {

  myControlOp = new FormControl();
  myControlCus = new FormControl();
  myControlGrp = new FormControl();
  filteredOperator: Observable<any[]>;
  filteredCustomer: Observable<any[]>;
  filteredGroups: Observable<any[]>;

  op: any;
  cus: any;
  grp: any;

  radio_value: any = 'data';


  token: string;
  event: Event = {
    calendar_name: '',
    type_id: null,
    start_date: null,
    allday: false,
    description: '',
  };
  start: boolean = false;
  stop: boolean = false;

  type_id: number;
  startTime: string;
  endTime: string;
  allDay: boolean = false;
  customers: Array<Customer>;
  operators: Array<User>;
  groups: Array<Group>
  userActive: User;
  participants: Participants;
  start_date: any;
  end_date: any;
  every_year_check: boolean = true;
  isOnlyRead: boolean = false;

  deadline: Deadline = new Deadline();

  type_day: Array<any> = [
    {
      type: 'Giorno'
    },
    {
      type: 'Settimana'
    },
    {
      type: 'Mese'
    },
    {
      type: 'Anno'
    },
  ]
  selected_period: string = '';

  check1: boolean;
  check2: boolean;

  day: number;
  repetions: string;
  type_month: Array<any> = [];
  d: any;
  onlyShow: boolean = false;
  onlyDeadline: boolean = false;

  month_type_selected: number = 1;
  deadline_bool: boolean = false;

  fulfillment: Array<any> = [];
  fulfill = false;

  constructor(
    public dialogRef: MatDialogRef<AddeventComponent>,
    public spinner: NgxSpinnerService,
    public globals: GlobalService,
    public customerServices: CustomerService,
    public dialog: MatDialog,
    public groupServices: GroupService,
    public operatorServices: OperatorService,
    public calendarService: CalendarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.participants = new Participants();
  }

  ngOnInit() {
    this.spinner.show();
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
    }
    if (this.data.onlyShow) {
      this.onlyShow = this.data.onlyShow;
    }

    this.userActive = this.globals.storagedUser;
    this.token = this.globals.getToken();
    this.startTime = this.globals.getOnlyTime(this.data.start);
    this.endTime = this.globals.getOnlyTime(this.data.end);
    // this.type_day.forEach(type => {
    // });
    if (this.data.event) {
      this.event = this.data.event;
    }

    if (!this.data.new) {
      if (this.event.type_id == this.globals.activityTypeId) {
        this.callFulfillment();
      }
    }

    if (this.data.default_type) {
      this.event.type_id = this.data.default_type;
    } else {
      this.event.type_id = this.globals.eventTypeId;
    }
    if (this.event.view_start_date) {
      delete this.event.view_end_date;
      delete this.event.view_start_date;
    }

    this.start_date = new Date(moment(this.data.start).format('L'));
    this.end_date = new Date(moment(this.data.end).format('L'));
    if (this.userActive.profile_id != 1) {
      this.getCustomers();
      this.getGroups();
      this.getOperators();
      if (!this.data.new) {
        this.getSartStop();
      }

    } else {
      this.spinner.hide();
    }
    if (!this.data.new) {
      this.calendarService.getEventParticipants(this.token, this.event.calendar_id).subscribe((res) => {
        this.globals.messageServer(res);
        this.participants = res;
        // this.spinner.hide();
      },
        (err) => {
          console.error("Errore: ", err);
          this.globals.messageServer(err);
        })
    }
    if (this.data.ticket_id) {
      this.event.ticket_id = this.data.ticket_id;
    }
    if (this.data.new && this.event.type_id == this.globals.activityTypeId) {
      this.event.state = "Aperto";
    }

    if (this.data.state) {
      this.event.state = this.data.state;
    }

    this.scadenza();
    if (!this.data.new) {
      if (this.event.type_id == this.globals.deadlineTypeID) {
        this.deadline = this.data.event.info_deadline;
        if (this.deadline.recurrence.end.date) {
          this.d = new Date(moment(this.deadline.recurrence.end.date).format('L'));
        } else {
          this.d = new Date(moment().format('L'));
        }
        // this.deadline.recurrence.end.after = 0;
        this.setSecondPeriodod();
      }
    }
    this.checkNext();
    if (this.data.onlyShow) {
      this.isOnlyRead = true;
    }
    $(document).ready(() => {
      if (this.data.onlyRicorrenza == true) {
        this.deadline_bool = true;
        let element = document.getElementById("link2");
        element.classList.add("active");
        element = document.getElementById("occorrenza");
        element.classList.remove("active");
        element = document.getElementById("ricorrenza");
        element.classList.add("active");

        element = document.getElementById("occorrenza");
        // element.classList.remove("active");
        element.classList.add("disabled");
        element = document.getElementById("link1");
        element.classList.remove("active");
      }
    })
    // this.spinner.hide();
  }

  callFulfillment() {
    this.calendarService.getFulfillment(this.token, this.event.calendar_id).subscribe(
      res => {
        if (res.values) {
          this.globals.stampa("fulfillment: ",res)
          this.fulfillment = res.values;
          this.fulfill = true;
        }
      }, err => {
        console.error("Error on get start button");
        this.globals.messageServer(err);
        // this.spinner.hide();
      }
    )
  }

  scadenza() {
    this.type_month = [];
    let start_date;
    if (this.deadline.start_date) {
      start_date = new Date(this.deadline.start_date);
    } else {
      start_date = new Date(this.start_date);
      this.deadline.start_date = start_date;
    }
    this.start_date = this.deadline.start_date;
    this.getDayPosition(start_date);
    if (this.radio_value == 'data') {
      if (this.deadline.recurrence.end.date) {
        this.d = new Date(moment(this.deadline.recurrence.end.date).format('L'));
      } else {
        if (new Date(start_date).getTime() < new Date(this.deadline.start_date).getTime()) {
          this.d = new Date(moment(start_date).format('L'));
        } else {
          this.d = new Date(moment(this.deadline.start_date).format('L'));
        }
        // this.d = new Date(moment().format('L'));
      }


      // this.d = new Date(moment(this.deadline.recurrence.end.date).format('L'));
    } else {

      if (new Date(start_date).getTime() < new Date(this.deadline.start_date).getTime()) {
        this.d = new Date(moment(start_date).format('L'));
      } else {
        this.d = new Date(moment(this.deadline.start_date).format('L'));
      }
    }
    if (!this.deadline.recurrence.end.after) {
      this.deadline.recurrence.end.after = 1;
    }
    if (this.selected_period == '') {
      this.selected_period = 'Giorno';
    } else {
    }
    this.day = start_date.getDate();
    this.type_month.push({ key: 1, value: `Il giorno ${this.day} del mese` });
  }

  getDayPosition(date) {
    let first_day = new Date(date);
    let dayOfWeek = date.getUTCDay();
    first_day.setDate(1);
    let temp = new Date(first_day);
    let position = 0;
    do {
      let tempDay = temp.getUTCDay();
      if (tempDay == dayOfWeek) {
        position++;
      }
      temp.setDate(temp.getDate() + 1);
    } while (temp <= date);
    this.getNameFromNumber(position)
  }

  getNameFromNumber(number) {
    let day = "";
    let start_date;
    if (this.deadline.start_date) {
      start_date = new Date(this.deadline.start_date);
    } else {
      start_date = new Date(this.start_date);
      this.deadline.start_date = start_date;
    }
    let n = start_date.getDay();
    switch (n) {
      case 0:
        day = "domenica";
        break;
      case 1:
        day = "lunedì";
        break;
      case 2:
        day = "martedì";
        break;
      case 3:
        day = "mercoledì";
        break;
      case 4:
        day = "giovedì";
        break;
      case 5:
        day = "venerdì";
        break;
      case 6:
        day = "sabato";
        break;
    }
    switch (number) {
      case 1:
        this.repetions = `Il primo ${day} del mese`;
        break;
      case 2:
        this.repetions = `Il secondo ${day} del mese`;
        break;
      case 3:
        this.repetions = `Il terzo ${day} del mese`;
        break;
      case 4:
        this.repetions = `Il quarto ${day} del mese`;
        break;
      case 5:
        this.repetions = `L'ultimo ${day} del mese`;
        break;
      case 6:
        this.repetions = `L'ultimo ${day} del mese`;
        break;
    }
    this.type_month.push({ key: 2, value: this.repetions });

    let s_date = new Date(this.deadline.start_date);
    let d = new Date(s_date.getFullYear(), s_date.getMonth() + 1, 0);
    if (s_date.getDate() == d.getDate()) {
      this.repetions = `L'ultimo giorno del mese`;
      this.type_month.push({ key: 3, value: this.repetions });
    } else {
    }

  }

  getSartStop() {
    this.calendarService.getStartStop(this.token, this.event.calendar_id, this.userActive.user_id).subscribe(
      res => {
        this.globals.messageServer(res);
        if (!res.start) {
          this.start = false;
          this.stop = true
        } else {
          this.stop = false;
          this.start = true;
        }
        this.spinner.hide();
      },
      err => {
        console.error("Error on get start button");
        this.globals.messageServer(err);
        this.spinner.hide();
      }
    )
  }

  onNoClick(): void {
    this.dialogRef.close('cancel');
  }

  displayFnCus(cus: Customer): string {
    return cus && `${cus.view_name}`;
  }

  displayFn(user: User): string {
    return user && `${user.last_name} ${user.name} (Username: ${user.username})`;
  }

  displayFnGrp(grp: Group): string {
    return grp && `${grp.group_name}`;
  }

  private _filter(name: string, array: Array<any>): User[] {
    const filterValue = name.toLowerCase();

    return array.filter(
      ele => ele.search.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  getCustomers() {
    let _self = this;
    this.customerServices.getCustomer(this.token).subscribe((res) => {
      this.globals.messageServer(res);
      this.customers = res
      this.customers.forEach(cus => {
        if (cus.type == 'GIU') {
          cus.search = this.globals.createLocalSearch({ name: cus.view_name, business_name: cus.business_name })
        } else {
          cus.search = this.globals.createLocalSearch({ view_name: cus.view_name, name: cus.name, last_name: cus.last_name });
        }
      });
      // this.spinner.hide();
      _self.filteredCustomer = _self.myControlCus.valueChanges.pipe(
        startWith(''),
        map((value) => {
          return typeof value === "string" ? value : value.search
        }),
        map((search) => {
          return search ? _self._filter(search, _self.customers) : _self.customers.slice()
        })
      );
    },
      (err) => {
        console.error("Error on get customers: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      })
  }

  getOperators() {
    let _self = this;
    this.operatorServices.getOperator(this.token).subscribe((res) => {
      this.globals.messageServer(res);
      this.operators = res;
      this.operators.forEach(op => {
        op.search = this.globals.createLocalSearch({ name: op.name, last_name: op.last_name, username: op.username })
      });
      _self.filteredOperator = _self.myControlOp.valueChanges.pipe(
        startWith(''),
        map((value) => {
          return typeof value === "string" ? value : value.search
        }),
        map((search) => {
          return search ? _self._filter(search, _self.operators) : _self.operators.slice()
        })
      );
      // this.spinner.hide();
    },
      (err) => {
        console.error("Error on get operators: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      })
  }

  getGroups() {
    let _self = this;
    this.groupServices.getOperatorsGroup(this.token).subscribe((res) => {
      this.globals.messageServer(res);
      this.groups = res;
      this.groups.forEach(grp => {
        grp.search = this.globals.createLocalSearch({ name: grp.group_name })
      });
      _self.filteredGroups = _self.myControlGrp.valueChanges.pipe(
        startWith(''),
        map((value) => {
          return typeof value === "string" ? value : value.search
        }),
        map((search) => {
          return search ? _self._filter(search, _self.groups) : _self.groups.slice()
        })
      );
      this.spinner.hide();
    },
      (err) => {
        console.error("Error on get groups: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      })
  }

  push(add) {
    let toAdd = true;
    if (add) {

      if (add.user_id) {
        this.participants.operators.forEach((op) => {
          if (add.user_id == op.user_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.operators.push(add);
          this.op = '';
          document.getElementById("addOperator").blur();
        } else {
          document.getElementById("addOperator").blur();
          this.op = '';
          this.globals.showNotification("Operatore già presente in lista", "warning");
        }
      }

      if (add.customer_id) {
        this.participants.customers.forEach((cus) => {
          if (add.customer_id == cus.customer_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.customers.push(add);
          this.cus = '';
          document.getElementById("addCustomer").blur();
        } else {
          this.globals.showNotification("Cliente già presente in lista", "warning");
          this.cus = '';
          document.getElementById("addCustomer").blur();
        }
      }

      if (add.group_id) {
        this.participants.groups.forEach((grp) => {
          if (add.group_id == grp.group_id) {
            toAdd = false
          }
        })
        if (toAdd) {
          this.participants.groups.push(add);
          this.grp = '';
          document.getElementById("addGroup").blur();
        } else {
          document.getElementById("addGroup").blur();
          this.globals.showNotification("Gruppo già presente in lista", "warning");
          this.grp = '';
        }
      }
    }

  }

  delete(remove) {
    if (remove) {
      if (remove.customer_id) {
        if (this.participants.customers.length == 1 && this.participants.operators.length == 0 && this.participants.groups.length == 0) {
          this.globals.showNotification("Impossibile lasciare un evento senza partecipanti", 'danger')
        } else {
          if (this.participants.customers.indexOf(remove) < 0) {
          } else {
            this.participants.customers.splice(this.participants.customers.indexOf(remove), 1)
          }
        }
      }
      if (remove.user_id) {
        if (this.participants.customers.length == 0 && this.participants.operators.length == 1 && this.participants.groups.length == 0) {
          this.globals.showNotification("Impossibile lasciare un evento senza partecipanti", 'danger')
        } else {

          if (this.participants.operators.indexOf(remove) < 0) {
          } else {
            this.participants.operators.splice(this.participants.operators.indexOf(remove), 1)
          }
        }
      }
      if (remove.group_id) {
        if (this.participants.customers.length == 0 && this.participants.operators.length == 0 && this.participants.groups.length == 1) {
          this.globals.showNotification("Impossibile lasciare un evento senza partecipanti", 'danger')
        } else {

          if (this.participants.groups.indexOf(remove) < 0) {

          } else {
            this.participants.groups.splice(this.participants.groups.indexOf(remove), 1)
          }
        }
      }
    }
  }

  log(obj) {
  }

  save() {
    let dateCheck: boolean = false;
    this.event.start_date = new Date(this.start_date);
    this.event.end_date = new Date(this.end_date);
    dateCheck = this.dataCheck(this.event.start_date, this.event.end_date)

    if (!this.event.allday) {


      let splitStart: Array<string> = this.startTime.split(':');
      let hours = parseInt(splitStart[0])
      let minuts = parseInt(splitStart[1]);
      this.event.start_date.setHours(hours);
      this.event.start_date.setMinutes(minuts);
      let splitEnd: Array<string> = this.endTime.split(':');
      hours = parseInt(splitEnd[0]);
      minuts = parseInt(splitEnd[1]);
      this.event.end_date.setHours(hours);
      this.event.end_date.setMinutes(minuts);
      this.event.start_date = moment(this.event.start_date).format();
      this.event.end_date = moment(this.event.end_date).format();
    } else {

    }



    let objToSend: any = {
      event: this.event,
      participants: this.participants
    }

    if (this.event.calendar_name) {
      document.getElementById("name").innerHTML = "";
    } else {
      document.getElementById("name").innerHTML = "↓ Il campo è obbligatorio";
    }

    this.event.description = this.event.description ? this.event.description : '';

    if ((this.event.calendar_name && this.event.calendar_name.length <= 80) && this.event.description.length <= 300) {
      if (dateCheck) {
        if (this.data.new) {

          this.calendarService.addEvent(this.token, objToSend).subscribe(
            (res) => {
              this.globals.messageServer(res);
              this.dialogRef.close();
            },
            (err) => {
              console.error("Errore nel creare l'evento: ", err);
              this.globals.messageServer(err);

            })
        } else {
          delete objToSend.event.backgroundColor;
          delete objToSend.event.borderColor;

          this.calendarService.updateEvent(this.token, objToSend, this.event.calendar_id).subscribe(
            (res) => {
              this.globals.messageServer(res);
              this.dialogRef.close();

            },
            (err) => {
              console.error("Errore nell'aggiornare il cliente: ", err);
              this.globals.messageServer(err);
            })
        }
      } else {
        this.globals.showNotification("La data inizio è più grande della data fine", 'warning');
      }
    } else {
      if (this.event.description.length < 300 && this.event.calendar_name.length < 80) {
        this.globals.showNotification("Dati mancanti", "warning");
      } else {
        if (this.event.description.length > 300) {
          this.globals.showNotification("La descrizione supera i 300 caratteri", "warning");
        }
        if (this.event.calendar_name.length > 80) {
          this.globals.showNotification("Il titolo supera i 80 caratteri", "warning");
        }
      }
    }
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  deleteDeadline() {

    if (!this.deadline_bool) {
      // update calendario
      swal({
        title: 'Vuoi eliminare questa occorrenza ?',
        text: 'Non possono essere cancellate occorrenze in corso o passate',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annulla',
        confirmButtonText: 'Elimina',
        cancelButtonClass: "btn btn-danger",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          let today = new Date();
          let date = new Date(this.event.start_date);
          if (date.getTime() > today.getTime()) {
            this.spinner.show();
            this.calendarService.deleteSingleDeadlineURL(this.token, this.event.calendar_id).subscribe(
              res => {
                this.globals.messageServer(res);
                this.spinner.hide();
                this.dialogRef.close();
              },
              err => {
                console.error("Error on delete event");
                this.globals.messageServer(err);
              }
            )
          } else {
            this.globals.showNotification("La ricorrenza selezionata non può essere cancellata", "danger");
          }
        }
      })
    } else {
      //update deadline
      swal({
        title: 'Vuoi eliminare questa ricorrenza ?',
        text: 'verranno cancellate solo le ricorrenze future dalla data di oggi',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annulla',
        confirmButtonText: 'Elimina',
        cancelButtonClass: "btn btn-danger",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.calendarService.deleteDeadline(this.token, this.event.deadline_id).subscribe(
            res => {
              this.globals.messageServer(res);
              this.spinner.hide();
              this.dialogRef.close()
            },
            err => {
              console.error("Error on delete event");
              this.globals.messageServer(err);
              this.spinner.hide();
            }
          )
          // this.globals.showNotification("Tutte","danger")
        } else {

        }
      });
    }
  }

  deleteEvent() {
    this.calendarService.deleteEvent(this.token, this.event.calendar_id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.dialogRef.close()
      },
      err => {
        console.error("Error on delete event");
        this.globals.messageServer(err);
      }
    )

  }

  startActivity() {
    this.spinner.show();
    let now = new Date();
    this.calendarService.start(this.token, this.event.calendar_id, { start: { start_time: moment(now).format() } }).subscribe(
      res => {
        this.globals.messageServer(res);
        this.event.state = res.values.cstate;
        this.getSartStop();
      }, err => {
        this.spinner.hide();
      }
    )
  }

  stopActivity() {
    this.spinner.show();
    let now = new Date();
    this.calendarService.stop(this.token, this.event.calendar_id, { stop: { end_time: moment(now).format() } }).subscribe(
      res => {
        this.globals.messageServer(res);
        this.event.state = res.values.cstate;
        this.getSartStop();
      }, err => {
        this.spinner.hide();
      }
    )
  }

  checkNext() {
    if (this.start_date > this.end_date) {
      this.end_date = this.start_date;
    }
    if (this.event.type_id == 2) {
      let s_date = new Date(this.start_date);
      let e_date = new Date(this.d);
    
      if (s_date.getFullYear() + 1 < e_date.getFullYear()) {
        // CHECKBOX DISABILITATE
        this.every_year_check = false;
        this.deadline.previous_year = false;
        this.deadline.repeat_every_year = false;
      } else {
        if (s_date.getFullYear() + 1 == e_date.getFullYear()) {
        
          if (s_date.getMonth() < e_date.getMonth()) {
            // CHECKBOX DISABILITATE
            this.every_year_check = false;
            this.deadline.previous_year = false;
            this.deadline.repeat_every_year = false
          } else {
            // CHECKBOX ABILITATE
            this.every_year_check = true;
          }
        }
      }
    }
  }

  checkBefore() {
    if (this.end_date < this.start_date) {
      this.start_date = this.end_date;
    }
  }

  dataCheck(dateI, dateF) {
    let checkD = false;
    dateI = new Date(dateI);
    dateF = new Date(dateF);

    if (dateF.getTime() >= dateI.getTime()) {
      // document.getElementById("Data_inizio").innerHTML = "";
      checkD = true;
      return checkD;
    } else {
      // document.getElementById("Data_inizio").innerHTML = "↓ La data inizio è superiore a quella di fine";
      checkD = false;
      return checkD;
    }

  }

  check(n) {
    if (n == 1) {
      this.type_day = [
        {
          type: 'Giorno'
        },
        {
          type: 'Settimana'
        },
        {
          type: 'Mese'
        },
        {
          type: 'Anno'
        },
      ]

      if (this.selected_period == 'Giorni') {
        this.selected_period = 'Giorno'
      }

      if (this.selected_period == 'Settimane') {
        this.selected_period = 'Settimana'
      }

      if (this.selected_period == 'Mesi') {
        this.selected_period = 'Mese'
      }

      if (this.selected_period == 'Anni') {
        this.selected_period = 'Anno'
      }

    } else {
      this.type_day = [
        {
          type: 'Giorni'
        },
        {
          type: 'Settimane'
        },
        {
          type: 'Mesi'
        },
        {
          type: 'Anni'
        },
      ]

      if (this.selected_period == 'Giorno') {
        this.selected_period = 'Giorni'
      }

      if (this.selected_period == 'Settimana') {
        this.selected_period = 'Settimane'
      }

      if (this.selected_period == 'Mese') {
        this.selected_period = 'Mesi'
      }

      if (this.selected_period == 'Anno') {
        this.selected_period = 'Anni'
      }

    }

  }

  setSecondPeriodod() {
    let mapPeriod = new Map();
    mapPeriod.set('day', 'Giorno');
    mapPeriod.set('week', 'Settimana');
    mapPeriod.set('month', 'Mese');
    mapPeriod.set('year', 'Anno');


    mapPeriod.forEach((value, key) => {
      if (this.deadline.recurrence.repeat.period == key) {
        this.selected_period = value
      }
    });
    this.check(this.deadline.recurrence.repeat.step);

    if (!this.deadline.recurrence.repeat.last_day_of_month) {
      if (this.deadline.recurrence.repeat.by_day_of_week == false) {
        this.type_month.forEach(type => {
          if (type.key == 1) {
            this.month_type_selected = type.key;
          }
        });
      } else {
        this.type_month.forEach(type => {
          if (type.key == 2) {
            this.month_type_selected = type.key;
          }
        });
      }
    } else {
      this.type_month.forEach(type => {
        if (type.key == 3) {
          this.month_type_selected = type.key;
        }
      });
    }

    if (this.deadline.recurrence.end.after) {
      this.radio_value = 'dopo';
      this.deadline.recurrence.end.date = new Date();
      this
    } else {
      this.radio_value = 'data';
      this.deadline.recurrence.end.after = 1;

    }
  }

  setPeriod() {
    let mapPeriod = new Map();
    mapPeriod.set('Giorno', 'day');
    mapPeriod.set('Giorni', 'day');
    mapPeriod.set('Settimana', 'week');
    mapPeriod.set('Settimane', 'week');
    mapPeriod.set('Mese', 'month');
    mapPeriod.set('Mesi', 'month');
    mapPeriod.set('Anno', 'year');
    mapPeriod.set('Anni', 'year');

    mapPeriod.forEach((value, key) => {
      if (this.selected_period == key) {
        this.deadline.recurrence.repeat.period = value
      }
    });
  }

  checkRepeat() {
    if (this.deadline.recurrence.repeat.step < 0) {
      this.deadline.recurrence.repeat.step = 0;
    }
  }

  checkRepeat2() {
    if (this.deadline.recurrence.end.after <= 0) {
      this.deadline.recurrence.end.after = 1;
    }
  }

  saveScadenza() {
    new Promise((resolve, reject) => {
      this.setPeriod();
      resolve()
    }).then(() => {
      this.spinner.show();

      this.deadline.recurrence.end.date = this.d;
      if (this.checkDeadline(this.deadline)) {
        // this.d = new Date(this.deadline.recurrence.end.date);
        this.deadline.start_date = moment(this.deadline.start_date).format();
        // this.deadline.recurrence.end.date = this.d.toDateString();
        if (this.data.new) {
          this.calendarService.setDeadline(this.token, this.deadline).subscribe(
            res => {
              this.globals.messageServer(res);
              this.spinner.hide();
              this.globals.messageServer(res);
              this.dialogRef.close();
            },
            err => {
              this.spinner.hide();
              console.error("Errore nel creare l'evento: ", err);
              this.globals.messageServer(err);
            }
          )
        } else {
          if (!this.deadline_bool) {
            // update calendario
            this.calendarService.updateSingleDeadline(this.token, this.event, this.event.calendar_id).subscribe(
              res => {
                this.globals.messageServer(res);
                this.spinner.hide();
                this.dialogRef.close();
              },
              err => {
                this.spinner.hide();
                console.error("Errore nel creare l'evento: ", err);
                this.globals.messageServer(err);
              }
            )
          } else {
            //update deadline
            let now = new Date();
            let date = new Date(this.start_date);
            if (date.getTime() < now.getTime()) {
              // now.setDate(now.getDate() + 1);
              date = now;
            }
            let obj = {
              start_date_event: moment(date).format(),
              deadline: this.deadline
            }
            this.calendarService.updateDeadline(this.token, obj, this.event.deadline_id).subscribe(
              res => {
                this.spinner.hide();
                this.globals.messageServer(res);
                this.dialogRef.close();
              },
              err => {
                this.spinner.hide();
                console.error("Errore nel creare l'evento: ", err);
                this.globals.messageServer(err);
              }
            )
          }
        }

      } else {
        this.spinner.hide();
        // this.globals.showNotification("Impossibile generare scadenze", "warning")
      }


    }).catch((err) => {
      this.globals.messageServer(err);
    });
  }

  checkDeadline(deadline: Deadline) {
    let toSend: boolean = true;
    if (toSend && !deadline.name) {
      toSend = false;
      this.globals.showNotification("Il titolo è obbligartorio", "warning");
    }
    if (toSend && deadline.description && deadline.description.length > 300) {
      toSend = false;
      this.globals.showNotification("La descrizione supera i 300 caratteri", "warning");
    }
    // se è "data"
    if (toSend && this.radio_value == 'data') {
      if (deadline.recurrence.end.date != null) {
        if (this.dataCheck(deadline.start_date, deadline.recurrence.end.date)) {
          if (deadline.recurrence.repeat.period == 'day') {
            deadline.recurrence.end.after = null;
            deadline.recurrence.repeat.by_day_of_week = null;
            deadline.recurrence.repeat.day_of_week.mon = null;
            deadline.recurrence.repeat.day_of_week.tue = null;
            deadline.recurrence.repeat.day_of_week.wed = null;
            deadline.recurrence.repeat.day_of_week.thu = null;
            deadline.recurrence.repeat.day_of_week.fri = null;
            deadline.recurrence.repeat.day_of_week.sat = null;
            deadline.recurrence.repeat.day_of_week.sun = null;
          }

          if (deadline.recurrence.repeat.period == 'week') {
            deadline.recurrence.end.after = null;
            deadline.recurrence.repeat.by_day_of_week = null;
          }

          if (deadline.recurrence.repeat.period == 'month') {
            deadline.recurrence.end.after = null;
            deadline.recurrence.repeat.day_of_week.mon = null;
            deadline.recurrence.repeat.day_of_week.tue = null;
            deadline.recurrence.repeat.day_of_week.wed = null;
            deadline.recurrence.repeat.day_of_week.thu = null;
            deadline.recurrence.repeat.day_of_week.fri = null;
            deadline.recurrence.repeat.day_of_week.sat = null;
            deadline.recurrence.repeat.day_of_week.sun = null;
            if (this.month_type_selected != 3) {
              if (this.month_type_selected == 2) {
                deadline.recurrence.repeat.by_day_of_week = true;
              } else {
                deadline.recurrence.repeat.by_day_of_week = false;
              }
            } else {
              deadline.recurrence.repeat.last_day_of_month = true;
              deadline.recurrence.repeat.by_day_of_week = null;
            }

          }

          if (deadline.recurrence.repeat.period == 'year') {
            deadline.recurrence.end.after = null;
            deadline.recurrence.repeat.day_of_week.mon = null;
            deadline.recurrence.repeat.day_of_week.tue = null;
            deadline.recurrence.repeat.day_of_week.wed = null;
            deadline.recurrence.repeat.day_of_week.thu = null;
            deadline.recurrence.repeat.day_of_week.fri = null;
            deadline.recurrence.repeat.day_of_week.sat = null;
            deadline.recurrence.repeat.day_of_week.sun = null;
          }

        } else {
          this.globals.showNotification("La data di inizio è più grande della data di fine", "warning");
          toSend = false;
        }
      } else {
        this.globals.showNotification("Inserisci la data ", "warning");
        toSend = false;
      }

      // Se è "dopo"
    } else if (toSend && this.radio_value == 'dopo') {
      if (deadline.recurrence.repeat.period == 'day') {
        deadline.recurrence.end.date = null;
        deadline.recurrence.repeat.by_day_of_week = null;
        deadline.recurrence.repeat.by_day_of_week = null;
        deadline.recurrence.repeat.day_of_week.mon = null;
        deadline.recurrence.repeat.day_of_week.tue = null;
        deadline.recurrence.repeat.day_of_week.wed = null;
        deadline.recurrence.repeat.day_of_week.thu = null;
        deadline.recurrence.repeat.day_of_week.fri = null;
        deadline.recurrence.repeat.day_of_week.sat = null;
        deadline.recurrence.repeat.day_of_week.sun = null;
      }

      if (deadline.recurrence.repeat.period == 'week') {
        deadline.recurrence.end.date = null;
        deadline.recurrence.repeat.by_day_of_week = null;
      }

      if (deadline.recurrence.repeat.period == 'month') {
        deadline.recurrence.repeat.by_day_of_week = null;
        deadline.recurrence.repeat.day_of_week.mon = null;
        deadline.recurrence.repeat.day_of_week.tue = null;
        deadline.recurrence.repeat.day_of_week.wed = null;
        deadline.recurrence.repeat.day_of_week.thu = null;
        deadline.recurrence.repeat.day_of_week.fri = null;
        deadline.recurrence.repeat.day_of_week.sat = null;
        deadline.recurrence.repeat.day_of_week.sun = null;
        if (this.month_type_selected != 3) {
          if (this.month_type_selected == 2) {
            deadline.recurrence.repeat.by_day_of_week = true;
          } else {
            deadline.recurrence.repeat.by_day_of_week = false;
          }
        } else {
          deadline.recurrence.repeat.last_day_of_month = true;
          deadline.recurrence.repeat.by_day_of_week = null;
        }
        deadline.recurrence.end.date = null;
      }

      if (deadline.recurrence.repeat.period == 'year') {
        deadline.recurrence.repeat.by_day_of_week = null;
        deadline.recurrence.repeat.day_of_week.mon = null;
        deadline.recurrence.repeat.day_of_week.tue = null;
        deadline.recurrence.repeat.day_of_week.wed = null;
        deadline.recurrence.repeat.day_of_week.thu = null;
        deadline.recurrence.repeat.day_of_week.fri = null;
        deadline.recurrence.repeat.day_of_week.sat = null;
        deadline.recurrence.repeat.day_of_week.sun = null;
        deadline.recurrence.end.date = null;
      }

    }
    return toSend;
  }

  radioChange(e) {
  }

  updateInfo() {
    this.scadenza();
  }

  deadN() {
    this.deadline_bool = false;
  }

  deadY() {
    this.deadline_bool = true;
  }

  dataOn() {
    this.radio_value = 'data';
  }

  dopoOn() {
    this.radio_value = 'dopo';
  }

  getRecipientsList() {
    this.spinner.show();
    const dialogRef = this.dialog.open(RecipientslistComponent, {
      width: '30%',
      // height: '80%',
      data: { calendar_id: this.event.calendar_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {

      }
    });
  }


}
