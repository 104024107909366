import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { environment } from '@environment';
import swal from 'sweetalert2';
import * as moment from 'moment-timezone';
import { ClienteService } from 'src/app/services/cliente.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { GlobalService } from 'src/app/services/global.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ClienteComponent } from '../../popup/cliente/cliente.component'
import { ExceluploadComponent } from 'src/app/popup/excelupload/excelupload.component';
import { OpenfolderComponent } from 'src/app/popup/openfolder/openfolder.component';
import { EliminaService } from 'src/app/services/elimina.service';
import { Type, Customer, User } from 'src/app/classes';
import { StmtModifier } from '@angular/compiler';

declare interface DataTable {
  headerRow: string[];
  // footerRow: string[];
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MM YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

declare const $: any;

@Component({
  selector: 'app-operator',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class CustomerComponent implements OnInit {
  public dataTableCustomer: DataTable;
  public dataTableFisiche: DataTable;
  public dataTableGiuridiche: DataTable;
  public indirizzi: DataTable;
  envi = environment;
  log: boolean = this.envi.log;

  //string
  token: string;
  fname: string;
  fnameChange: string;
  _listFilter = '';
  selectProfile: string;
  nextId: number = 0;
  code: string = '';

  //any
  tempUser: any;
  idCustomer: any;

  //number
  id: number;
  profile: number;
  disponibility: number;

  //Oggetto
  obj: any = {};
  objUpdate: any = {};
  selectedAddress: any = {};
  selectedCustomer: any = {};
  activeUser: User;

  //Array
  id_profile: Array<any> = [];
  customer: Array<Customer> = [];
  address: Array<any> = [];
  types: Array<Type> = [];
  customerGIU: Array<Customer> = [];
  customerFIS: Array<Customer> = [];

  //Boolean
  popupSet = false;
  del_update = false;
  new = true;
  choose: boolean;
  form = true;
  fisic = false;
  legal = false;
  indirizzo: boolean;
  iva: boolean;
  partIva: boolean;
  validEmailType: boolean = false;
  isOnlyRead: boolean = false;
  change: boolean = false;
  cognome: boolean;
  email: boolean;
  vat: boolean;
  rSociale: boolean;
  auto: boolean = false;
  modify: boolean = true;

  addres: boolean = false;

  //Map
  TypeMap: any = new Map();

  customerTable: any;

  constructor(
    private customerService: CustomerService,
    private globals: GlobalService,
    private Delete: EliminaService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private clienteService: ClienteService,
  ) {

  }

  ngOnInit() {
    this.spinner.show();
    this.globals.checkPage(2);
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.getCustomer();
    this.newUser();
    this.activeUser = this.globals.storagedUser;
    this.getDisponibility();
  }

  reload() {
    if (this.choose) {

      if (this.new) {

        this.spinner.show();
        this.addres = false;
        this.code = '';
        this.customerGIU = [];
        this.customerFIS = [];
        this.isOnlyRead = false;
        this.tempUser = null;
        this.auto = false;
        this.newUser();
        this.form = true;
        this.fisic = false;
        this.legal = false;
        this.popupSet = false;
        this.del_update = false;
        this.new = true;
        this.address = [];
        this.selectedAddress = {};
        if ($.fn.DataTable.isDataTable('#datatablesCustomer')) {
          $('#datatablesCustomer').DataTable().destroy();
        }
        if ($.fn.DataTable.isDataTable('#datatablesFisiche')) {
          $('#datatablesFisiche').DataTable().destroy();
        }
        if ($.fn.DataTable.isDataTable('#datatablesGiuridiche')) {
          $('#datatablesGiuridiche').DataTable().destroy();
        }
        this.getCustomer();
      } else {

        if (this.indirizzo) {
          if (this.modify) {

            this.spinner.show();
            this.addres = false;
            this.code = '';
            this.customerGIU = [];
            this.customerFIS = [];
            this.isOnlyRead = false;
            this.tempUser = null;
            this.auto = false;
            this.newUser();
            this.form = true;
            this.fisic = false;
            this.legal = false;
            this.popupSet = false;
            this.del_update = false;
            this.new = true;
            this.address = [];
            this.selectedAddress = {};
            if ($.fn.DataTable.isDataTable('#datatablesCustomer')) {
              $('#datatablesCustomer').DataTable().destroy();
            }
            if ($.fn.DataTable.isDataTable('#datatablesFisiche')) {
              $('#datatablesFisiche').DataTable().destroy();
            }
            if ($.fn.DataTable.isDataTable('#datatablesGiuridiche')) {
              $('#datatablesGiuridiche').DataTable().destroy();
            }
            this.getCustomer();
          } else {
            this.globals.showNotification("Stai modificando un indirizzo", "warning")
          }

        } else {
          this.globals.showNotification("L'indirizzo è obbligatorio", "warning")
        }
      }

    } else {
      this.spinner.show();
      this.addres = false;
      this.code = '';
      this.customerGIU = [];
      this.customerFIS = [];
      // this.selectedCustomer = { birth_date: new Date() };
      this.isOnlyRead = false;
      this.tempUser = null;
      this.auto = false;
      this.newUser();
      this.form = true;
      this.fisic = false;
      this.legal = false;
      this.popupSet = false;
      this.del_update = false;
      this.new = true;
      this.address = [];
      this.selectedAddress = {};
      if ($.fn.DataTable.isDataTable('#datatablesCustomer')) {
        $('#datatablesCustomer').DataTable().destroy();
      }
      if ($.fn.DataTable.isDataTable('#datatablesFisiche')) {
        $('#datatablesFisiche').DataTable().destroy();
      }
      if ($.fn.DataTable.isDataTable('#datatablesGiuridiche')) {
        $('#datatablesGiuridiche').DataTable().destroy();
      }
      this.getCustomer();
    }
  }

  getCustomer() {
    this.spinner.show();
    this.customerService.getCustomer(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.customer = res;
        this.customer.forEach((custo) => {
          custo.birth_date = new Date(moment(custo.birth_date).format("L"));
        })

        this.customer.forEach((cus) => {
          if (cus.type == 'GIU') {
            this.customerGIU.push(cus);
          } else {
            this.customerFIS.push(cus);
          }
        });

        let _self = this;
        let cust = [];
        let cusTypeMap = new Map();
        cusTypeMap.set('FLA', 'Lavoratore autonomo');
        cusTypeMap.set('FPI', 'Ditta individuale');
        cusTypeMap.set('FNI', 'Persona fisica');
        cusTypeMap.set('GIU', 'Persona giuridica');
        this.customer.forEach((cus) => {
          let obj = {
            customer_id: cus.customer_id,
            view_name: cus.view_name,
            vat: cus.vat,
            f_code: cus.f_code,
            email: cus.email,
            type: cusTypeMap.get(cus.type)
          }
          cust.push(obj);
        })
        let tableObject: any = {};
        tableObject.data = cust;

        let cusTableConf = {
          pagingType: "full_numbers",
          lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "All"]
          ],
          responsive: {
            details: {
              type: 'column',
              target: 'tr'
            }
          },
          language: {
            search: "_INPUT_",
            searchPlaceholder: "Ricerca clienti",
            url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
          },
          ajax: (data, callback, settings) => {
            callback(tableObject)
          },
          columns: [
            { title: 'ID', data: 'customer_id' },
            { title: 'Denominazione', data: 'view_name' },
            { title: 'Partita IVA', data: 'vat' },
            { title: 'Codice ficale', data: 'f_code' },
            { title: 'Email', data: 'email' },
            { title: 'Tipologia', data: 'type' },
            { title: 'Azione', data: null, className: 'td-actions text-right' }
          ],
          columnDefs: [
            {
              targets: [0],
              visible: false
            },
            {
              targets: [6],
              visible: true,
              render: function (data, type, full, meta) {
                for (let prop in full) {
                  if (typeof full[prop] === typeof "Stringa") {
                    full[prop] = full[prop].replace(/'/g, "**");
                  }
                }
                full = JSON.stringify(full);
                return `<a data-cus='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                            <i class="material-icons">search</i>
                          </a>
                          <a data-cus='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                            <i class="material-icons">edit</i>
                          </a>
                          <a data-cus='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                            <i class="material-icons">close</i>
                          </a>`
              }
            }
          ]
        }

        // Creazione
        $(document).ready(function () {
          _self.customerTable = $('#datatablesCustomer').DataTable(cusTableConf);
          $('#datatablesCustomer').on('click', 'a.btn-info', function () {
            let cus = $(this).data('cus')
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.showFormCus(customer, true);
              }
            })
          });
          $('#datatablesCustomer').on('click', 'a.btn-success', function () {
            let cus = $(this).data('cus');
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.showFormCus(customer);
              }
            })
          });
          $('#datatablesCustomer').on('click', 'a.btn-danger', function () {
            let cus = $(this).data('cus');
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.delProfile(customer);
              }
            })
          });
          _self.spinner.hide();
        })
      },
      err => {
        this.spinner.hide();
        console.error("Errore in getOperators", err);
        this.globals.messageServer(err);

      }
    )
  }

  crateTable(create) {
    this.spinner.show();
    if (create == 'all') {

      let _self = this;
      let cust = [];
      let cusTypeMap = new Map();
      cusTypeMap.set('FLA', 'Lavoratore autonomo');
      cusTypeMap.set('FPI', 'Ditta individuale');
      cusTypeMap.set('FNI', 'Persona fisica');
      cusTypeMap.set('GIU', 'Persona giuridica');
      this.customer.forEach((cus) => {
        let obj = {
          customer_id: cus.customer_id,
          view_name: cus.view_name,
          vat: cus.vat,
          f_code: cus.f_code,
          email: cus.email,
          type: cusTypeMap.get(cus.type)
        }
        cust.push(obj);
      })
      let tableObject: any = {};
      tableObject.data = cust;

      let cusTableConf = {
        pagingType: "full_numbers",
        lengthMenu: [
          [10, 25, 50, -1],
          [10, 25, 50, "All"]
        ],
        responsive: {
          details: {
            type: 'column',
            target: 'tr'
          }
        },
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Ricerca clienti",
          url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
        },
        ajax: (data, callback, settings) => {
          callback(tableObject)
        },

        columns: [
          { title: 'ID', data: 'customer_id' },
          { title: 'Denominazione', data: 'view_name' },
          { title: 'Partita IVA', data: 'vat' },
          { title: 'Codice ficale', data: 'f_code' },
          { title: 'Email', data: 'email' },
          { title: 'Tipologia', data: 'type' },
          { title: 'Azione', data: null, className: 'td-actions text-right' }
        ],
        columnDefs: [
          {
            targets: [0],
            visible: false
          },
          {
            targets: [6],
            visible: true,
            render: function (data, type, full, meta) {
              for (let prop in full) {
                if (typeof full[prop] === typeof "Stringa") {
                  full[prop] = full[prop].replace(/'/g, "**");
                }
              }
              full = JSON.stringify(full);
              return `<a data-cus='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                            <i class="material-icons">search</i>
                          </a>
                          <a data-cus='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                            <i class="material-icons">edit</i>
                          </a>
                          <a data-cus='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                            <i class="material-icons">close</i>
                          </a>`
            }
          }
        ]
      }

      if (!$.fn.DataTable.isDataTable('#datatablesCustomer')) {
        // Creazione
        $(document).ready(function () {
          $('#datatablesCustomer').DataTable(cusTableConf);
          $('#datatablesCustomer').on('click', 'a.btn-info', function () {
            let cus = $(this).data('cus');
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.showFormCus(customer, true);
              }
            })
          });
          $('#datatablesCustomer').on('click', 'a.btn-success', function () {
            let cus = $(this).data('cus');
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.showFormCus(customer);
              }
            })
          });
          $('#datatablesCustomer').on('click', 'a.btn-danger', function () {
            let cus = $(this).data('cus');
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.delProfile(customer);
              }
            })
          });
          _self.spinner.hide();
        })
      } else {
        // Creazione
        $('#datatablesCustomer').DataTable().destroy();
        $('#datatablesCustomer').DataTable(cusTableConf);
        _self.spinner.hide();
      }
    }
    if (create == 'fisica') {
      this.spinner.show();

      let _self = this;
      let cust = [];

      this.customerFIS.forEach((cus) => {
        let obj = {
          customer_id: cus.customer_id,
          view_name: cus.view_name,
          last_name: cus.last_name,
          name: cus.name,
          vat: cus.vat,
          f_code: cus.f_code,
          email: cus.email,
        }
        cust.push(obj);
      })
      let tableObject: any = {};
      tableObject.data = cust;

      let cusTableConf = {
        pagingType: "full_numbers",
        lengthMenu: [
          [10, 25, 50, -1],
          [10, 25, 50, "All"]
        ],
        responsive: {
          details: {
            type: 'column',
            target: 'tr'
          }
        },
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Ricerca clienti",
          url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
        },
        ajax: (data, callback, settings) => {
          callback(tableObject)
        },
        columns: [
          { title: 'ID', data: 'customer_id' },
          { title: 'Denominazione', data: 'view_name' },
          { title: 'Cognome', data: 'last_name' },
          { title: 'Nome', data: 'name' },
          { title: 'Paritta IVA', data: 'vat' },
          { title: 'Codice fiscale', data: 'f_code' },
          { title: 'Email', data: 'email' },
          { title: 'Azione', data: null, className: 'td-actions text-right' }
        ],
        columnDefs: [
          {
            targets: [0],
            visible: false
          },
          {
            targets: [7],
            visible: true,
            render: function (data, type, full, meta) {
              for (let prop in full) {
                if (typeof full[prop] === typeof "Stringa") {
                  full[prop] = full[prop].replace(/'/g, "**");
                }
              }
              full = JSON.stringify(full);
              return `<a data-cus='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                            <i class="material-icons">search</i>
                          </a>
                          <a data-cus='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                            <i class="material-icons">edit</i>
                          </a>
                          <a data-cus='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                            <i class="material-icons">close</i>
                          </a>`
            }
          }
        ]
      }

      if (!$.fn.DataTable.isDataTable('#datatablesFisiche')) {

        // Creazione
        $(document).ready(function () {
          $('#datatablesFisiche').DataTable(cusTableConf);
          $('#datatablesFisiche').on('click', 'a.btn-info', function () {
            let cus = $(this).data('cus');
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.showFormCus(customer, true);
              }
            })
          });
          $('#datatablesFisiche').on('click', 'a.btn-success', function () {
            let cus = $(this).data('cus');
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.showFormCus(customer);
              }
            })
          });
          $('#datatablesFisiche').on('click', 'a.btn-danger', function () {
            let cus = $(this).data('cus');
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.delProfile(customer);
              }
            })
          });
          _self.spinner.hide();
        })

      } else {
        $('#datatablesFisiche').DataTable().destroy();
        $('#datatablesFisiche').DataTable(cusTableConf);
        _self.spinner.hide();
      }
    }
    if (create == 'giuridica') {
      this.spinner.show();

      let _self = this;
      let cust = [];

      this.customerGIU.forEach((cus) => {
        let obj = {
          customer_id: cus.customer_id,
          view_name: cus.view_name,
          vat: cus.vat,
          f_code: cus.f_code,
          email: cus.email,
        }
        cust.push(obj);
      })
      let tableObject: any = {};
      tableObject.data = cust;

      let cusTableConf = {
        pagingType: "full_numbers",
        lengthMenu: [
          [10, 25, 50, -1],
          [10, 25, 50, "All"]
        ],
        responsive: {
          details: {
            type: 'column',
            target: 'tr'
          }
        },
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Ricerca clienti",
          url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
        },
        ajax: (data, callback, settings) => {
          callback(tableObject)
        },
        columns: [
          { title: 'ID', data: 'customer_id' },
          { title: 'Denomianzione', data: 'view_name' },
          { title: 'Partita IVA', data: 'vat' },
          { title: 'Codice fiscale', data: 'f_code' },
          { title: 'Email', data: 'email' },
          { title: 'Azione', data: null, className: 'td-actions text-right' }
        ],
        columnDefs: [
          {
            targets: [0],
            visible: false
          },
          {
            targets: [5],
            visible: true,
            render: function (data, type, full, meta) {
              for (let prop in full) {
                if (typeof full[prop] === typeof "Stringa") {
                  full[prop] = full[prop].replace(/'/g, "**");
                }
              }
              full = JSON.stringify(full);
              return `<a data-cus='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                            <i class="material-icons">search</i>
                          </a>
                          <a data-cus='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                            <i class="material-icons">edit</i>
                          </a>
                          <a data-cus='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                            <i class="material-icons">close</i>
                          </a>`
            }
          }
        ]
      }

      if (!$.fn.DataTable.isDataTable('#datatablesGiuridiche')) {
        // Creazione
        $(document).ready(function () {
          $('#datatablesGiuridiche').DataTable(cusTableConf);
          $('#datatablesGiuridiche').on('click', 'a.btn-info', function () {
            let cus = $(this).data('cus');
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.showFormCus(customer, true);
              }
            })
          });
          $('#datatablesGiuridiche').on('click', 'a.btn-success', function () {
            let cus = $(this).data('cus');
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.showFormCus(customer);
              }
            })
          });
          $('#datatablesGiuridiche').on('click', 'a.btn-danger', function () {
            let cus = $(this).data('cus');
            for (let prop in cus) {
              if (typeof cus[prop] === typeof "Stringa") {
                cus[prop] = cus[prop].replace(/\*\*/g, "'");
              }
            }
            _self.globals.stampa("Cus: ", cus);
            _self.customer.forEach((customer) => {
              if (customer.customer_id == cus.customer_id) {
                _self.delProfile(customer);
              }
            })
          });
          _self.spinner.hide();
        })
      } else {
        $('#datatablesGiuridiche').DataTable().destroy();
        $('#datatablesGiuridiche').DataTable(cusTableConf);
      }
    }
    this.spinner.hide();

  }

  newUser() {
    this.choose = false;
    this.form = true;
    this.customerService.getCustomerProfile(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.id_profile = res;
        let i = 0;
        this.id_profile.forEach(element => {
          if (element.profile_name == 1) {
            i++;
          }
        });
      },
      err => {
        console.error("Errore in getProfile: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  getACustomer(id) {
    this.spinner.show();
    this.customerService.getACustomer(this.token, id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.selectedCustomer = res.customer;
        this.address = res.address.address;
        this.types = res.address.type;
        this.types.forEach((type) => {
          this.TypeMap[type.type_id] = type.type_name;
        })
        if (this.address.length > 0) {
          this.indirizzo = true
        } else {
          this.indirizzo = false;
        }
        this.spinner.hide();
      },
      err => {
        console.error("Errore: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  changeStatus() {
    this.fisic = false;
    this.legal = false;
    this.choose = true;
    this.selectedCustomer = {}
    this.del_update = false;
    this.new = true;
    this.form = false;
    this.fname = null;
    this.fnameChange = null;

  }

  addressVisible() {
    this.addres = true;
  }

  createTableIndirizzi() {
    let _self = this;
    let addr = [];

    this.address.forEach((add) => {
      let obj = {
        country: add.country,
        city: add.city,
        province: add.province,
        address: add.address,
        zip_code: add.zip_code,
      }
      addr.push(obj);
    })
    let tableObject: any = {};
    tableObject.data = addr;

    let cusTableConf = {
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: {
        details: {
          type: 'column',
          target: 'tr'
        }
      },
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Ricerca clienti",
        url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
      },
      ajax: (data, callback, settings) => {
        callback(tableObject)
      },
      columns: [
        { data: 'country' },
        { data: 'city' },
        { data: 'province' },
        { data: 'via' },
        { data: 'cap' },
        { data: null, className: 'td-actions text-right' }
      ],
      columnDefs: [
        {
          targets: [5],
          visible: true,
          render: function (data, type, full, meta) {
            for (let prop in full) {
              if (typeof full[prop] === typeof "Stringa") {
                full[prop] = full[prop].replace(/'/g, "**");
              }
            }
            full = JSON.stringify(full);
            return `<a data-cus='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                            <i class="material-icons">search</i>
                          </a>
                          <a data-cus='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                            <i class="material-icons">edit</i>
                          </a>
                          <a data-cus='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                            <i class="material-icons">close</i>
                          </a>`
          }
        }
      ]
    }
  }

  showFormCus(cus, onlyRead?) {
    this.addres = false;
    this.globals.stampa("Mostra cus: ", cus);
    this.auto = false;
    this.idCustomer = cus.customer_id;

    this.indirizzi = {
      headerRow: ['Via', 'Città', 'Provincia', 'Stato', 'CAP', 'Tipo di indirizzo', 'Azione']
      // footerRow: ['#', 'Nome azienda', 'Codice cliente', 'Email', 'Azione']
    }

    this.getTypes();
    this.isOnlyRead = false;
    if (onlyRead) {
      this.isOnlyRead = true;
      this.globals.stampa(cus);
    }

    this.fnameChange = cus.folder_name;
    this.svuotalabel();
    this.change = false;
    // this.indirizzo = false;
    if (cus.type == 'FNI') {
      this.partIva = false
    } else {
      this.partIva = true;
    }


    this.getACustomer(cus.customer_id);


    this.change = true;
    this.popupSet = true;
    this.objUpdate = cus;
    this.del_update = true;
    this.choose = true;
    this.new = false;
    cus.birth_date = moment(cus.birth_date.value).format('yyyy-MM-dd');
    this.selectedCustomer = cus;


    this.id = cus.user_id;
    this.form = true;

    if (cus.type == 'FNI' || cus.type == 'FLA' || cus.type == 'FPI') {
      this.fisic = true;
      this.legal = false;
    }
    if (cus.type == "GIU") {
      this.legal = true;
      this.fisic = false;
    }

  }

  svuota(type) {
    this.fname = null;
    this.fnameChange = null;
    this.change = false;
    this.selectedCustomer = {};
    this.selectedCustomer.type = type;
  }

  select(selectPerson) {

    if (this.new) {

      this.spinner.show();
      this.addres = false;
      this.auto = false;
      this.isOnlyRead = false;
      this.selectedAddress = {};
      this.address = [];
      this.getTypes();
      this.svuotalabel();
      if (selectPerson == 'FLA') {
        this.selectProfile = 'Lavoratore autonomo'
      }
      if (selectPerson == 'FPI') {
        this.selectProfile = 'Ditta individuale'
      }
      if (selectPerson == 'FNI') {
        this.selectProfile = 'Persona fisica'
      }
      if (selectPerson == 'GIU') {
        this.selectProfile = 'Persona giuridica'
      }

      this.change = false;

      this.indirizzi = {
        headerRow: ['Stato', 'Città', 'Provincia', 'Via', 'CAP', 'Tipo di indirizzo', 'Azione']
        // footerRow: ['#', 'Nome azienda', 'Codice cliente', 'Email', 'Azione']
      }

      this.changeStatus();
      if (selectPerson == 'FNI' || selectPerson == 'FLA' || selectPerson == 'FPI') {
        // this.addAddress();
        this.fisic = true;
        this.legal = false;
        this.selectedCustomer.type = selectPerson;
        this.svuotalabel();
      }
      if (selectPerson == "GIU") {
        // this.indirizzo = false;
        this.legal = true;
        this.fisic = false;
        this.selectedCustomer.type = selectPerson;
        this.svuotalabel();
      }

      this.indirizzo = false;

      if (this.selectedCustomer.type == "FLA" || this.selectedCustomer.type == "FPI" || this.selectedCustomer.type == "GIU") {
        this.partIva = true;
      } else {
        this.partIva = false;
      }

    } else {

      if (this.indirizzo) {
        if (this.modify) {

          this.spinner.show();
          this.addres = false;
          this.auto = false;
          this.isOnlyRead = false;
          this.selectedAddress = {};
          this.address = [];
          this.getTypes();
          this.svuotalabel();
          if (selectPerson == 'FLA') {
            this.selectProfile = 'Lavoratore autonomo'
          }
          if (selectPerson == 'FPI') {
            this.selectProfile = 'Ditta individuale'
          }
          if (selectPerson == 'FNI') {
            this.selectProfile = 'Persona fisica'
          }
          if (selectPerson == 'GIU') {
            this.selectProfile = 'Persona giuridica'
          }

          this.change = false;

          this.indirizzi = {
            headerRow: ['Stato', 'Città', 'Provincia', 'Via', 'CAP', 'Tipo di indirizzo', 'Azione']
            // footerRow: ['#', 'Nome azienda', 'Codice cliente', 'Email', 'Azione']
          }

          this.changeStatus();
          if (selectPerson == 'FNI' || selectPerson == 'FLA' || selectPerson == 'FPI') {
            // this.addAddress();
            this.fisic = true;
            this.legal = false;
            this.selectedCustomer.type = selectPerson;
            this.svuotalabel();
          }
          if (selectPerson == "GIU") {
            // this.indirizzo = false;
            this.legal = true;
            this.fisic = false;
            this.selectedCustomer.type = selectPerson;
            this.svuotalabel();
          }

          this.indirizzo = false;

          if (this.selectedCustomer.type == "FLA" || this.selectedCustomer.type == "FPI" || this.selectedCustomer.type == "GIU") {
            this.partIva = true;
          } else {
            this.partIva = false;
          }

        } else {
          this.globals.showNotification("Stai modificando un indirizzo", "warning")
        }

      } else {
        this.globals.showNotification("L'indirizzo è obbligatorio", "warning")
      }

    }

  }

  delProfile(user) {
    swal({
      title: `Cancellare ${user.view_name}?`,
      // text: 'You will not be able to recover this imaginary file!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false
    }).then((result) => {

      if (result.value) {
        this.Delete.deleteCustomer(this.token, user.customer_id).subscribe(
          res => {
            this.globals.messageServer(res);
            swal({
              title: `Cancellare anche la cartella di ${user.view_name}?`,
              // text: 'You will not be able to recover this imaginary file!',
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Si',
              cancelButtonText: 'No',
              confirmButtonClass: "btn btn-success",
              cancelButtonClass: "btn btn-danger",
              buttonsStyling: false
            }).then((result) => {
              if (result.value) {
                this.globals.showNotification(`${user.view_name} cancellato!`, 'success');
                this.Delete.delFolder(this.token, user.folder_id).subscribe(
                  res => {
                    this.globals.messageServer(res);
                    this.globals.showNotification("Cartella cancellata", 'success');
                    this.reload();
                  }, err => {
                    console.error("Errore in deleteFolder: ", err);
                    this.globals.showNotification(`Errore nel cancellare la cartella`, 'danger');
                    this.globals.messageServer(err);
                    this.reload();
                  }
                )
              } else {
                // this.toaster.success(`${user.view_name} cancellato!`);
                this.globals.showNotification(`${user.view_name} cancellato!`, 'success');
                this.reload();
              }
            })
          },
          err => {
            console.error("Errore in delOperator ", err);
            this.globals.messageServer(err);
          }
        )
      } else {
      }
    })

  }

  updateProfile(customerComplete: Customer, nameFolderChange: string) {

    if (this.modify) {
      let bdayCheck: boolean = true;
      let code: boolean;

      if (this.selectedCustomer.birth_date) {


        if (customerComplete.type != 'GIU') {
          let today = new Date();
          // let bday = new Date(moment(customerComplete.birth_date).format("L"));
          let bday = new Date(moment(this.selectedCustomer.birth_date).format('L'));
          if (today.getTime() > bday.getTime()) {
            this.globals.stampa("Today: ", today.getTime(), "BDay: ", bday.getTime())
            document.getElementById("BRT_Date").innerHTML = "";
            bdayCheck = true;
          } else {
            this.globals.stampa("Today: ", today.getTime(), "BDay: ", bday.getTime())
            if (bday.getTime() == NaN) {
              document.getElementById("BRT_Date").innerHTML = "↓ Data non valida";
            } else {
              document.getElementById("BRT_Date").innerHTML = "↓ Data non valida";
              this.globals.showNotification("La data inserita è più grande della data di oggi", "warning")
            }
            bdayCheck = false;
          }
        }

      } else {
        bdayCheck = true;
      }


      this.globals.stampa("Customer: ", customerComplete);
      if (customerComplete.type == 'FLA' || customerComplete.type == 'FPI' || customerComplete.type == 'FNI') {
        if (customerComplete.name) {
          // this.nome = true;
          document.getElementById("cusName").innerHTML = "";
        } else {
          // this.nome = false;
          document.getElementById("cusName").innerHTML = "↓ Il campo è obbligatorio";
        }

        if (customerComplete.last_name) {
          this.cognome = true;
          document.getElementById("cusLast_Name").innerHTML = "";
        } else {
          this.cognome = false;
          document.getElementById("cusLast_Name").innerHTML = "↓ Il campo è obbligatorio";
        }
      } else {
        if (customerComplete.type == 'GIU') {
          if (customerComplete.business_name) {
            this.rSociale = true;
            document.getElementById("cusBusinessName").innerHTML = "";
          } else {
            this.rSociale = false;
            document.getElementById("cusBusinessName").innerHTML = "↓ Il campo è obbligatorio";
          }
        }
      }

      if (customerComplete.code) {
        if (customerComplete.code.length > 20) {
          document.getElementById("C_Code").innerHTML = "↓ Il codice cliente supera i 20 caratteri";
          code = false;
        } else {
          document.getElementById("C_Code").innerHTML = "";
          code = true;
        }
      } else {
        code = true;
      }

      if (customerComplete.email) {
        this.email = true;
        document.getElementById("cusEmail").innerHTML = "";
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(customerComplete.email).toLowerCase())) {
          this.validEmailType = true;
          document.getElementById("cusEmail").innerHTML = "";
        } else {
          this.validEmailType = false;
          document.getElementById("cusEmail").innerHTML = "↓ Email non valida";
        }
      } else {
        this.email = false;
        document.getElementById("cusEmail").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (customerComplete.f_code) {

        if (customerComplete.f_code.length > 16) {
          document.getElementById("CF").innerHTML = "↓ Il codice fiscale supera 16 caratteri";
          this.vat = false;
        } else {
          this.vat = true;
          document.getElementById("CF").innerHTML = "";
        }

      } else {
        this.vat = false;
        document.getElementById("CF").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (customerComplete.type == 'FLA' || customerComplete.type == 'FPI' || customerComplete.type == "GIU") {
        if (customerComplete.vat) {
          if (customerComplete.vat.length > 11) {
            this.iva = false;
            document.getElementById("piva").innerHTML = "↓ la partita iva supera 11 caratteri";
          } else {
            this.iva = true;
            document.getElementById("piva").innerHTML = "";
          }
        } else {
          this.iva = false;
          document.getElementById("piva").innerHTML = "↓ Il campo è obbligatorio";
        }
      }

      if (((customerComplete.name && this.cognome) || this.rSociale) && this.email && this.validEmailType && bdayCheck && this.vat) {
        if (customerComplete.type == 'FLA' || customerComplete.type == 'FPI' || customerComplete.type == 'GIU') {
          if (this.iva) {
            if (this.indirizzo) {
              this.spinner.show();
              let customer = Object.assign({}, customerComplete);
              if (customerComplete.birth_date) {
                customer.birth_date = moment(customer.birth_date).format("L");
              }
              if (nameFolderChange) {
                customerComplete.folder_name = nameFolderChange;
              }

              delete customerComplete["description"];

              let updateUbj = {
                customer: customer,
                address: this.address
              }

              updateUbj.address.forEach((addres) => {
                if (addres.residence) {
                  addres.residence = 'Y';
                } else {
                  addres.residence = 'N';
                }

                if (addres.domicile) {
                  addres.domicile = 'Y';
                } else {
                  addres.domicile = 'N';
                }

              });
              this.customerService.updateCustomer(this.token, customerComplete.customer_id, updateUbj).subscribe(
                res => {
                  this.globals.messageServer(res);
                  this.spinner.show();
                  let f_name = `${this.code} - ${this.selectedCustomer.folder_name}`
                  this.customerService.updateFolderName(this.token, customerComplete.folder_id, { name: f_name }).subscribe(
                    res => {
                      this.globals.messageServer(res);
                      this.spinner.hide();
                      this.reload();
                      this.globals.stampa("Risposta in updateCustomer: ", res)
                    },
                    err => {
                      this.spinner.hide();
                      console.error("Errore in updateFolderName: ", err);
                      this.globals.messageServer(err);
                    }
                  )
                },
                err => {
                  this.spinner.hide();
                  console.error("Errore in updateUser", err);
                  this.globals.messageServer(err);
                }
              )
            } else {
              this.globals.showNotification("Manca l'indirizzo associato al cliente", 'warning');
            }
          }
        } else {
          if (this.indirizzo) {
            this.spinner.show();
            if (nameFolderChange) {
              customerComplete.folder_name = nameFolderChange;
            }
            delete customerComplete["description"];
            let customer = Object.assign({}, customerComplete);
            customer.birth_date = moment(customer.birth_date).format("L");
            let updateUbj = {
              customer: customer,
              address: this.address
            }

            updateUbj.address.forEach((addres) => {
              if (addres.residence) {
                addres.residence = 'Y';
              } else {
                addres.residence = 'N';
              }

              if (addres.domicile) {
                addres.domicile = 'Y';
              } else {
                addres.domicile = 'N';
              }

            });

            this.customerService.updateCustomer(this.token, customerComplete.customer_id, updateUbj).subscribe(
              res => {
                this.globals.messageServer(res);
                this.spinner.show();
                this.customerService.updateFolderName(this.token, customerComplete.folder_id, { name: customerComplete.folder_name }).subscribe(
                  res => {
                    this.globals.messageServer(res);
                    this.spinner.hide();
                    this.reload();
                  },
                  err => {
                    console.error("Errore in updateFolderName: ", err);
                    this.spinner.hide();
                    this.globals.messageServer(err);
                  }
                )
              },
              err => {
                this.spinner.hide();
                console.error("Errore in updateUser", err);
                this.spinner.hide();
                this.globals.messageServer(err);
              }
            )
          } else {
            this.globals.showNotification("Manca l'indirizzo associato al cliente", 'warning');
          }
        }
      } else {
        this.globals.showNotification("Dati mancanti o errati", "warning");
        if (this.email && !this.validEmailType) {
          document.getElementById("cusEmail").innerHTML = "↓ Email non valida";
        }
      }
    } else {
      this.globals.showNotification("Stai modificando un indirizzo", "warning")
    }
  }


  folderNameChange() {
    if (!this.auto) {
      if (this.selectedCustomer.code) {
        if (this.legal) {
          let business = this.selectedCustomer.business_name ? this.selectedCustomer.business_name : '';
          this.fname = `${business}`
          this.code = `${this.selectedCustomer.code} -`;
        } else {
          let name = this.selectedCustomer.name ? this.selectedCustomer.name : '';
          let last_name = this.selectedCustomer.last_name ? this.selectedCustomer.last_name : '';
          this.fname = `${last_name} ${name}`
          this.code = `${this.selectedCustomer.code} -`;
        }
      } else {
        if (this.legal) {
          let business = this.selectedCustomer.business_name ? this.selectedCustomer.business_name : '';
          let id = this.selectedCustomer.customer_id ? this.selectedCustomer.customer_id : this.nextId;
          this.fname = `${business}`
          this.code = `${id} -`;
        } else {
          let name = this.selectedCustomer.name ? this.selectedCustomer.name : '';
          let last_name = this.selectedCustomer.last_name ? this.selectedCustomer.last_name : '';
          let id = this.selectedCustomer.customer_id ? this.selectedCustomer.customer_id : this.nextId;
          this.fname = `${last_name} ${name}`
          this.code = `${id} -`;
        }
      }
    } else {
    }
  }

  emailValidationType(e) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(e).toLowerCase())) {
      this.validEmailType = true;
      document.getElementById("cusEmail").innerHTML = "";
    } else {
      this.validEmailType = false;
      document.getElementById("cusEmail").innerHTML = "↓ Email non valida";
    }
  }

  confirmCustomer(customer, fName) {

    if (this.modify) {

      let code: boolean = true;
      let bdayCheck: boolean = true;
      if (customer.type != 'GIU') {
        if (customer.birth_date) {
          let today = new Date();

          let bday = new Date(moment(customer.birth_date).format('L'));
          if (today.getTime() > bday.getTime()) {
            this.globals.stampa("Today: ", today.getTime(), "BDay: ", bday.getTime())
            document.getElementById("BRT_Date").innerHTML = "";
            bdayCheck = true;
          } else {
            this.globals.stampa("Today: ", today.getTime(), "BDay: ", bday.getTime())
            document.getElementById("BRT_Date").innerHTML = "↓ L'anno è superiore a quello corrente";
            bdayCheck = false;
          }
        }
      } else {
        bdayCheck = true;
      }
      if (customer.type == 'FLA' || customer.type == 'FPI' || customer.type == 'FNI') {
        if (customer.name) {
          // this.nome = true;
          document.getElementById("cusName").innerHTML = "";
        } else {
          // this.nome = false;
          document.getElementById("cusName").innerHTML = "↓ Il campo è obbligatorio";
        }

        if (customer.last_name) {
          this.cognome = true;
          document.getElementById("cusLast_Name").innerHTML = "";
        } else {
          this.cognome = false;
          document.getElementById("cusLast_Name").innerHTML = "↓ Il campo è obbligatorio";
        }
      } else {
        if (customer.type == 'GIU') {
          if (customer.business_name) {
            this.rSociale = true;
            document.getElementById("cusBusinessName").innerHTML = "";
          } else {
            this.rSociale = false;
            document.getElementById("cusBusinessName").innerHTML = "↓ Il campo è obbligatorio";
          }
        }
      }
      if (customer.email) {
        this.email = true;
        document.getElementById("cusEmail").innerHTML = "";
      } else {
        this.email = false;
        document.getElementById("cusEmail").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (customer.code) {
        if (customer.code.length > 20) {
          document.getElementById("C_Code").innerHTML = "↓ Il codice cliente supera i 20 caratteri";
          code = false;
        } else {
          document.getElementById("C_Code").innerHTML = "";
          code = true;
        }
      } else {
        code = true;
      }

      if (customer.f_code) {

        if (customer.f_code.length > 16) {
          document.getElementById("CF").innerHTML = "↓ Il codice fiscale supera 16 caratteri";
          this.vat = false;
        } else {
          this.vat = true;
          document.getElementById("CF").innerHTML = "";
        }

      } else {
        this.vat = false;
        document.getElementById("CF").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (customer.type == 'FLA' || customer.type == 'FPI' || customer.type == "GIU") {
        if (customer.vat) {
          if (customer.vat.length > 11) {
            this.iva = false;
            document.getElementById("piva").innerHTML = "↓ la partita iva supera 11 caratteri";
          } else {
            this.iva = true;
            document.getElementById("piva").innerHTML = "";
          }
        } else {
          this.iva = false;
          document.getElementById("piva").innerHTML = "↓ Il campo è obbligatorio";
        }
      }

      if (((customer.name && this.cognome) || this.rSociale) && this.email && this.vat && this.validEmailType && code && bdayCheck) {
        if (((customer.name && this.cognome) || this.rSociale) && this.email) {
          if (customer.type == 'FLA' || customer.type == 'FPI' || customer.type == 'GIU') {
            if (this.iva) {
              if (this.indirizzo) {
                this.spinner.show();
                let cus = Object.assign({}, customer);
                if (customer.birth_date) {
                  cus.birth_date = moment(cus.birth_date).format('L');
                }
                let objcus = {
                  customer: cus,
                  folder_name: fName,
                  address: this.address
                }
                objcus.customer.folder_name = fName;

                this.clienteService.addCustomer(this.token, objcus).subscribe(
                  res => {
                    this.globals.messageServer(res);
                    this.globals.showNotification("Creato!", 'success');
                    swal({
                      // title: `Vuoi associale il cliente `,
                      text: 'Vuoi associare il cliente ad un account?',
                      type: 'success',
                      showCancelButton: true,
                      cancelButtonText: 'No',
                      confirmButtonText: 'Si',
                      cancelButtonClass: "btn btn-danger",
                      confirmButtonClass: "btn btn-success",
                      buttonsStyling: false
                    }).then((result) => {
                      if (result.value) {
                        this.spinner.show();
                        const dialogRef = this.dialog.open(ClienteComponent, {
                          width: '50%',
                          // height: '70%',
                          data: {
                            customer: res.values,
                          },
                          // disableClose: true
                        })
                        dialogRef.afterClosed().subscribe(result => {

                          if (result == 'cancel') {
                            this.reload();
                          } else {
                            this.reload();
                          }
                        });
                      } else {
                        this.reload();
                      }
                    })
                    this.selectedCustomer = {};
                    this.choose = false;
                    this.spinner.hide();
                  },
                  err => {
                    console.error("errore in addCustomer: ", err)
                    this.spinner.hide();
                    this.globals.messageServer(err);
                  })
              } else {
                this.globals.showNotification(`Manca l'indirizzo associato al cliente`, 'warning');
              }
            }
          } else {
            if (this.indirizzo) {
              this.spinner.hide();
              let cus = Object.assign({}, customer);;
              cus.birth_date = moment(cus.birth_date).format('L');
              // if (this.change) {
              //   fName = this.fnameChange
              // }

              let objcus = {
                customer: cus,
                folder_name: fName,
                address: this.address
              }

              objcus.customer.folder_name = fName;

              this.clienteService.addCustomer(this.token, objcus).subscribe(
                res => {
                  this.globals.messageServer(res);
                  this.globals.showNotification("Creato!", 'success');
                  swal({
                    // title: `Vuoi associale il cliente `,
                    text: 'Vuoi associare il cliente ad un account?',
                    type: 'success',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    confirmButtonText: 'Si',
                    cancelButtonClass: "btn btn-danger",
                    confirmButtonClass: "btn btn-success",
                    buttonsStyling: false
                  }).then((result) => {
                    if (result.value) {
                      // this.globals.stampa('entrato: ', result)
                      this.spinner.show();
                      const dialogRef = this.dialog.open(ClienteComponent, {
                        width: '50%',
                        // height: '70%',
                        data: {
                          customer: res,
                        },
                        // disableClose: true
                      })
                      dialogRef.afterClosed().subscribe(result => {

                        if (result == 'cancel') {
                          this.reload();
                        } else {
                          this.reload();
                        }
                      });
                    } else {
                      this.reload();
                    }
                  })
                  this.choose = false;
                },
                err => {
                  console.error("errore in addCustomer: ", err)
                  this.spinner.hide();
                  this.globals.messageServer(err);
                })
            } else {
              // this.toaster.error(`Manca l'indirizzo associato al cliente`);
              this.globals.showNotification("Manca l'indirizzo associato al cliente", 'warning');
            }
          }
        }
      } else {
        this.globals.showNotification("Dati mancanti o errati", "warning");
        if (this.email && !this.validEmailType)
          document.getElementById("cusEmail").innerHTML = "↓ Email non valida";
      }
    } else {
      this.globals.showNotification("Stai modificando un indirizzo", "warning")
    }
  }

  svuotalabel() {
    if (document.getElementById("cusEmail")) {
      document.getElementById("cusEmail").innerHTML = "";
    }
    if (document.getElementById("CF")) {
      document.getElementById("CF").innerHTML = "";
    }
    if (document.getElementById("cusLast_Name")) {
      document.getElementById("cusLast_Name").innerHTML = ""
    }
    if (document.getElementById("cusBusinessName")) {
      document.getElementById("cusBusinessName").innerHTML = ""
    }
    if (document.getElementById("cusName")) {
      document.getElementById("cusName").innerHTML = ""
    }
    if (document.getElementById("BRT_Date")) {
      document.getElementById("BRT_Date").innerHTML = "";
    }
  }

  aggiornaPopup() {
    this.spinner.show();
    const dialogRef = this.dialog.open(ClienteComponent, {
      width: '50%',
      // height: '70%',
      data: {
        customer: this.objUpdate,
      },
      // disableClose: true
    })
    dialogRef.afterClosed().subscribe(result => {
    });

  }

  getDisponibility() {
    this.customerService.getDisponibility(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.disponibility = res.disponibility;
      },
      err => {
        console.error("Error on get disponibility", err);
        this.globals.messageServer(err);
      }
    )
  }

  addAddress(addres) {
    let add: boolean = false;
    let city: boolean = false;
    let provi: boolean = false;
    let coun: boolean = false;
    let zip: boolean = false;
    let type: boolean = false;
    if (addres.address || addres.city || addres.province || addres.country || addres.zip_code || addres.type_id) {
      // let tempAddress = addres;
      if (addres.address) {
        document.getElementById("via").innerHTML = "";
        add = true;
      } else {
        document.getElementById("via").innerHTML = "↓ Il campo è obbligatorio";
        add = false;
      }

      if (addres.city) {
        document.getElementById("citta").innerHTML = "";
        city = true;
      } else {
        document.getElementById("citta").innerHTML = "↓ Il campo è obbligatorio";
        city = false;
      }

      if (addres.province) {
        document.getElementById("provincia").innerHTML = "";
        provi = true;
      } else {
        document.getElementById("provincia").innerHTML = "↓ Il campo è obbligatorio";
        provi = false;
      }

      if (addres.country) {
        document.getElementById("stato").innerHTML = "";
        coun = true;
      } else {
        document.getElementById("stato").innerHTML = "↓ Il campo è obbligatorio";
        coun = false;
      }

      if (addres.zip_code) {
        if (addres.zip_code.length <= 5) {
          document.getElementById("cap").innerHTML = "";
          zip = true;
        } else {
          document.getElementById("cap").innerHTML = "↓ Il campo supera i 5 caratteri";
          zip = false;
        }
      } else {
        document.getElementById("cap").innerHTML = "↓ Il campo è obbligatorio";
        zip = false;
      }

      if (addres.type_id) {
        document.getElementById("tipo").innerHTML = "";
        type = true;
      } else {
        document.getElementById("tipo").innerHTML = "↓ Il campo è obbligatorio";
        type = false;
      }

      if (add && city && provi && coun && zip && type) {
        // this.address.splice(tempAddress);
        this.address.push(addres);
        this.selectedAddress = {};
        this.addres = false;
      }
    } else {
      document.getElementById("via").innerHTML = "";
      document.getElementById("citta").innerHTML = "";
      document.getElementById("provincia").innerHTML = "";
      document.getElementById("stato").innerHTML = "";
      document.getElementById("cap").innerHTML = "";
      document.getElementById("tipo").innerHTML = "";
      this.globals.showNotification("Inserisci i dati dell'indirizzo", 'warning');
    }

    if (this.address.length > 0) {
      this.indirizzo = true
    } else {
      this.indirizzo = false;
    }
    // obj = [];

  }

  showAddress(addres) {
    if (this.modify) {
      this.modify = false;
      this.addres = true;
      this.selectedAddress = addres;
      this.address.splice(this.address.indexOf(addres), 1);
      this.globals.stampa(this.address);
    } else {
      this.globals.showNotification("Stai già modificando un indirizzo", "warning")
    }
  }

  updateAddress(addres) {
    if (addres.address || addres.city || addres.province || addres.country || addres.zip_code || addres.type_id) {
      // let tempAddress = addres;
      if (addres.address) {
        document.getElementById("via").innerHTML = "";
      } else {
        document.getElementById("via").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addres.city) {
        document.getElementById("citta").innerHTML = "";
      } else {
        document.getElementById("citta").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addres.province) {
        document.getElementById("provincia").innerHTML = "";
      } else {
        document.getElementById("provincia").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addres.country) {
        document.getElementById("stato").innerHTML = "";
      } else {
        document.getElementById("stato").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addres.zip_code) {
        document.getElementById("cap").innerHTML = "";
      } else {
        document.getElementById("cap").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addres.type_id) {
        document.getElementById("tipo").innerHTML = "";
      } else {
        document.getElementById("tipo").innerHTML = "↓ Il campo è obbligatorio";
      }

      if (addres.address && addres.city && addres.province && addres.country && addres.zip_code && addres.type_id) {

        if (this.selectedAddress.address_id) {
          addres.customer_id = this.idCustomer

          this.globals.stampa(addres);

          this.customerService.updateAddress(this.token, { address: addres }, this.selectedAddress.address_id).subscribe(
            res => {
              this.globals.messageServer(res);
              this.spinner.show();
              this.modify = true;
              this.getACustomer(this.idCustomer);
              this.addres = false;
              this.globals.showNotification("Indirizzo aggiornato", 'success');
              this.selectedAddress = {};
              this.spinner.hide();
            },
            err => {
              console.error("Errore in updateAddress: ", err);
              this.spinner.hide();
              this.globals.messageServer(err);
            }
          )
        } else {
          addres.customer_id = this.idCustomer

          this.globals.stampa(addres);

          this.customerService.addAddress(this.token, { address: addres }).subscribe(
            res => {
              this.globals.messageServer(res);
              this.spinner.show();
              this.modify = true;
              this.getACustomer(this.idCustomer);
              // this.toaster.success('Indirizzo Aggiunto');
              this.globals.showNotification("Indirizzo aggiunto", 'success');
              this.addres = false;
              this.selectedAddress = {};
              this.spinner.hide();
            },
            err => {
              console.error("Errore in updateAddress: ", err);
              this.spinner.hide();
              this.globals.messageServer(err);
            }

          )
        }
      }
    } else {
      document.getElementById("via").innerHTML = "";
      document.getElementById("citta").innerHTML = "";
      document.getElementById("provincia").innerHTML = "";
      document.getElementById("stato").innerHTML = "";
      document.getElementById("cap").innerHTML = "";
      document.getElementById("tipo").innerHTML = "";
      this.globals.showNotification("Inserisci i dati dell'indirizzo", 'warning');
    }

  }

  deleteAddres(addres) {
    if (this.modify) {

      swal({
        title: `Cancellare l'indirizzo?`,
        // text: 'You will not be able to recover this imaginary file!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: false
      }).then((result) => {

        if (result.value) {
          if (!this.new) {
            this.customerService.deleteAddress(this.token, addres.address_id).subscribe(
              res => {
                this.globals.messageServer(res);
                this.spinner.show();
                this.getACustomer(this.idCustomer);
                // this.toaster.success('Indirizzo cancellato')
                this.globals.showNotification("Indirizzo cancellato", 'success');
                // this.selectedAddress = {};
                this.spinner.hide();

                if (this.address.length > 0) {
                  this.indirizzo = true
                } else {
                  this.indirizzo = false;
                }
              },
              err => {
                console.error("Errore in delOperator ", err);
                this.spinner.hide();
                this.globals.messageServer(err);
              }
            )
          } else {
            this.address.splice(this.address.indexOf(addres), 1);
            // this.globals.stampa(this.address);

            if (this.address.length > 0) {
              this.indirizzo = true
            } else {
              this.indirizzo = false;
            }
          }
        }
      })
    } else {
      this.globals.showNotification("Stai modificando un indirizzo", "warning")
    }
  }

  addExcelPop() {
    this.spinner.show();
    const dialogRef = this.dialog.open(ExceluploadComponent, {
      width: '70%',
      // height: '60%'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.globals.stampa('The dialog was closed', result);
      if (result != 'cancel') {
        this.reload();
      }
      this.spinner.hide();
    });
  }

  openCustomerfolder() {
    this.spinner.show();
    const dialogRef = this.dialog.open(OpenfolderComponent, {
      width: '70%',
      // height: '80%',
      data: {
        folder_id: this.selectedCustomer.folder_id,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.globals.stampa('The dialog was closed', result);
      if (result != 'cancel') {
        // this.getCustomer();
        // this.newUser();
      }
    });
  }

  getTypes() {
    this.customerService.getTypes(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.types = res;
        this.types.forEach((type) => {
          this.TypeMap[type.type_id] = type.type_name;
        })
        this.customerService.nextId(this.token).subscribe(
          res => {
            this.globals.messageServer(res);
            this.nextId = res;
            this.spinner.hide();
            this.folderNameChange()
          },
          err => {
            console.error("Error on get customer_id: ", err);
            this.globals.messageServer(err);
            this.spinner.hide();
          }
        )
      }, err => {
        console.error("Error on get customer_id: ", err);
        this.globals.messageServer(err);
        this.spinner.hide();
      }
    )
  }

}