import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class ManageUserService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService
  ) { }

  getUserCustomer(token) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getUserCustomer, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  udateUserCustomer(token, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post(this.globals.updateUserCustomer, obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  setCustomer(token, id, obj) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post(this.globals.setCustomer(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getCustomerAssociati(token, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getCustomerAssociati(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

}
