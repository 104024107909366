import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageUserService } from 'src/app/services/manage-user.service';
import { CustomerService } from 'src/app/services/customer.service';
import { User } from 'src/app/classes';

import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

export interface DialogData {
  user_id: any;
}

@Component({
  selector: 'app-list-customer-associates',
  templateUrl: './list-customer-associates.component.html',
  styleUrls: ['./list-customer-associates.component.scss']
})
export class ListCustomerAssociatesComponent implements OnInit {


  myControl = new FormControl();
  filteredCustomer: Observable<User[]>;

  token: string;
  user_id: any;
  customerAssociati: Array<any> = []
  customer: Array<any> = [];
  tempArray: Array<Object> = [];
  selected: string;

  constructor(
    public dialogRef: MatDialogRef<ListCustomerAssociatesComponent>,
    private globals: GlobalService,
    private customerService: CustomerService,
    public dialog: MatDialog,
    private manageUser: ManageUserService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.spinner.show();
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.user_id = this.data.user_id;

    this.getCustomerAssociati();
    this.getCustomer();
    // this.spinner.hide();
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    this.globals.stampa("Name: ", name);

    return this.customer.filter(
      user => user.search.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  displayFn(user: User): string {
    return user && `${user.last_name} ${user.name}`;
    // user.name ? user.name : ''; ;
  }

  updateUser() {
    this.spinner.show();
    this.manageUser.setCustomer(this.token, this.user_id, { customers: this.tempArray }).subscribe(
      res => {
        this.globals.messageServer(res);
        this.dialogRef.close();
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.globals.messageServer(err);
        console.error("Errore in updateUser: ", err);
      }
    )
  }

  getCustomer() {
    let _self = this;
    this.spinner.show();
    this.customerService.getCustomer(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.customer = res;
        this.customer.forEach(cus => {
          cus.search = this.globals.createLocalSearch({ name: cus.name, last_name: cus.last_name })
        });
        // this.user = this.globals.createLocalSearch({})
        _self.filteredCustomer = _self.myControl.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search: ", search);
            return search ? _self._filter(search) : _self.customer.slice()
          })
        );
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.globals.messageServer(err);
        console.error("Errore in getCustomer: ", err);
      }
    )
  }

  getCustomerAssociati() {
    this.spinner.show();
    this.manageUser.getCustomerAssociati(this.token, this.user_id).subscribe(
      res => {
        this.globals.messageServer(res);
        this.customerAssociati = res;
        if (this.customerAssociati.length > 0) {
          this.customerAssociati.forEach(cus => {
            this.tempArray.push({ customer_id: cus.customer_id });
          });
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.globals.messageServer(err);
        console.error("Errore in getCUstomerAssociati: ", err);
      }
    )
  }

  delete(cus_id){
    this.tempArray.splice(this.tempArray.indexOf(cus_id), 1);
    this.customerAssociati.splice(this.customerAssociati.indexOf(cus_id), 1);
  }

  addUser(passUser) {
    let control: boolean = false
    if (passUser.customer_id) {

      this.customerAssociati.forEach(cus => {
        if (cus.customer_id == passUser.customer_id) {
          control = true;
        }
      });


      if (control == false) {
        this.customerAssociati.push(passUser);
        this.globals.stampa("Array: ", this.customerAssociati)
        this.tempArray.push({ customer_id: passUser.customer_id });
        this.selected = '';
        document.getElementById("addMember").blur();

      } else {
        this.globals.showNotification("Il profilo selezionato è già stato inserito nella lista", "warning");
        document.getElementById("addMember").blur();
        this.selected = '';
      }
    } else {
      this.globals.showNotification("Seleziona l'utente", "warning");
      document.getElementById("addMember").blur();
      this.selected = '';
    }

  }

  canceling() {
    this.dialogRef.close();
  }

}
