export class PropertyPane {
    html: any;
    view: any;
	selectedFigure: any;
	attributesToWatch: any;
    updateCallback: any;
	parentScope: any;
    constructor(element_id, view, parentScope){
		this.html = $("#"+element_id);
		this.view = view;
		this.parentScope = parentScope;
		// Databinding: helper attributes for the databinding
		this.selectedFigure = null;
		this.updateCallback = null;
		
		view.on("select", $.proxy(this.onSelectionChanged,this));
    }

   
	/**
	 * @method
	 * Called if the selection in the canvas has been changed. You must register this
	 * on the canvas to receive this event.
	 *
     * @param {draw2d.Canvas} emitter
     * @param {Object} event
     * @param {draw2d.Figure} event.figure
	 */
	/**
	 * @method
	 * Called if the selection in the canvas has been changed. You must register this
	 * on the canvas to receive this event.
	 *
     * @param {draw2d.Canvas} emitter
     * @param {Object} event
     * @param {draw2d.Figure} event.figure
	 */
	onSelectionChanged(emitter: any, event: any){
		console.log("Figura", event.figure);
		let _self = this;
		// Databinding: deregister the old update listener
		if(_self.selectedFigure!==null && _self.updateCallback !==null){
			_self.selectedFigure.off(_self.updateCallback);
		}
		
		_self.selectedFigure = event.figure;
		console.log("ID: ", _self.selectedFigure.id);
		
		_self.html.html("");
        if(event.figure instanceof draw2d.shape.node.Node){
    		_self.showPropertiesOpAmp(event.figure);
		}
		event.figure.on("unselect", () => {
			_self.html.html("");
			
		})
	}
    
    /**
	 * @method
	 * Called if the selection in the canvas has been changed. You must register this
	 * on the canvas to receive this event.
	 * 
	 * @param {draw2d.Figure} figure
	 */
	showPropertiesOpAmp(figure){
		let _self = this;
		// let figure.kemata = figure.kemata;
		let figure_id = figure.id;
		let childrens = figure.getChildren();
			console.log("Figli: ", childrens);
			let label = childrens.data[0];
			figure.kemata.task_name = label.getText()
			// figure.repaint();
		if(!figure.kemata){
			figure.kemata = Object.assign({},figure.getUserData());
		}
		if(figure.kemata.html_template){
			this.html.append(
				`${figure.kemata.html_template}`
			);
			$(`#name`).val(`${figure.kemata.name}`);
			$(`#taskName`).val(`${figure.kemata.task_name}`);
			$(`#description`).val(figure.kemata.description);
			$(`#taskDescription`).val(figure.kemata.task_description);
			$(`#taskMaxTime`).val(figure.kemata.max_time);
			$(`#textEmail`).val(figure.kemata.email_text);
			if(figure.kemata){
				$(`#recipientsCheck`).prop("checked", figure.kemata.select);
				// $(`#taskBeforeExecutorsCheck`).prop("checked", figure.kemata.executors_before);
				$(`#taskBeforeRecipientsCheck`).prop("checked", figure.kemata.recipients_before);
				if (figure.kemata.executors_by_contract){
					$('#checkContract').prop("checked", figure.kemata.executors_by_contract);
					$('.test').hide();
				} else {
					$('#checkExecutors').prop("checked", !figure.kemata.executors_by_contract);
					$('.test').show();
				}
				$(`#single`).prop("checked", figure.kemata.port_single);
				$(`#multiple`).prop("checked", figure.kemata.port_multiple);
				$(`#errorTime`).prop("checked", figure.kemata.port_error);
				$('#generateOnStart').prop("checked", figure.kemata.generate_on_start);
				$('#contractActivities').prop("checked", figure.kemata.contract_activities_number);
				if(!figure.kemata.select){
					$(`#recipientsEmail`).addClass('disabled');
				}
			}
			
		} else {
			this.html.append(`<div id="property_position_container" class="panel panel-default">
			<div class="panel-heading"> Proprietà </div>
			<div class="panel-body" id="position_panel">
				<div class="input-group" ></div>
				<div class="form-group">
					Tipo: <input id="taskName" type="text" class="form-control-style" value="${figure.kemata.name}" disabled>
					Descrizione: <textarea id="description" class="form-control-style" disabled>${figure.kemata.description}</textarea>
				</div>
			</div>
			</div>`)
		}
		$('#single').on('change', () => {
			if(figure.kemata.port_single){
				let port;
				let ports = figure.getOutputPorts();
				ports.data.forEach((prt) => {
					if(prt.name == 'OutputSingle'){
						port = prt;
					}
				});
				figure.kemata.port_single = false;
				figure.removePort(port);
			} else {
				figure.kemata.port_single = true;
				let port = new draw2d.OutputPort({ color: 'blue', bgColor:'blue'});
				port.setName('OutputSingle');
                figure.addPort(port, new draw2d.layout.locator.OutputPortLocator());
			}
			figure.repaint();
		})
		$('#multiple').on('change', () => {
			if(figure.kemata.port_multiple){
				let port;
				let ports = figure.getOutputPorts();
				ports.data.forEach((prt) => {
					if(prt.name == 'OutputMultiple'){
						port = prt;
					}
				});
				figure.kemata.port_multiple = false;
				figure.removePort(port);
			} else {
				figure.kemata.port_multiple = true;
				let port = new draw2d.OutputPort({color: 'orange', bgColor: 'orange'});
				port.setName('OutputMultiple');
                figure.addPort(port, new draw2d.layout.locator.OutputPortLocator());
			}
			figure.repaint();
		})
		$('#errorTime').on('change', () => {
			if(figure.kemata.port_error){
				let port;
				let ports = figure.getOutputPorts();
				ports.data.forEach((prt) => {
					if(prt.name == 'OutputError'){
						port = prt;
					}
				});
				figure.kemata.port_error = false;
				figure.removePort(port);
			} else {
				figure.kemata.port_error = true;
				let port = new draw2d.OutputPort({color: 'red', bgColor:'red'});
				port.setName('OutputError');
                figure.addPort(port, new draw2d.layout.locator.OutputPortLocator());
			}
			figure.repaint();
		})
		
		$(`#taskDescription`).on("change", () => {
			figure.kemata.task_description =  $(`#taskDescription`).val();
			figure.kemata.operators=[ {test: "casa"}];
			console.log("Figure: ", _self.view.getFigures());
		})
		$(`#taskMaxTime`).on("change", () => {
			let maxTime = _self.checkMaxTime($(`#taskMaxTime`).val().toString());
			if(maxTime){
				figure.kemata.max_time = maxTime;
				$(`#taskMaxTime`).val(maxTime);
			} else {
				console.log("Dati non validi");
				$(`#taskMaxTime`).val("");
			}
		});
		$('#taskName').on('change', () => {
			figure.kemata.task_name = $('#taskName').val();
			console.log("Figura: ", figure);
			let childrens = figure.getChildren();
			console.log("Figli: ", childrens);
			let label = childrens.data[0];
			label.setText($('#taskName').val());
			figure.repaint();
		})
		$(`#participants`).on('click', () => {
			console.log("Click");
			_self.setPartecipants();
		});
		$(`#recipients`).on('click', () => {
			console.log("Click");
			_self.setRecipients();
		});
		$(`#recipientsEmail`).on('click', () => {
			console.log("Click");
			_self.setRecipients();
		});
		$(`#executors`).on('click', () => {
			console.log("Click");
			_self.setExecutors();
		});
		$(`#recipientsCheck`).on('click', () => {
			console.log("click", $(`#recipientsCheck`).val());
			figure.kemata.select = true;
			figure.kemata.executors_before = false;
			figure.kemata.recipients_before = false;

			$(`#recipientsEmail`).removeClass('disabled');
		});
		// $(`#taskBeforeExecutorsCheck`).on('click', () => {
		// 	console.log("click", $(`#recipientsCheck`).val());
		// 	figure.kemata.select = false;
		// 	figure.kemata.executors_before = true;
		// 	figure.kemata.recipients_before = false; 
		// 	$(`#recipientsEmail`).addClass('disabled');
		// });
		$(`#taskBeforeRecipientsCheck`).on('click', () => {
			console.log("click", $(`#recipientsCheck`).val());
			figure.kemata.select = false;
			figure.kemata.executors_before = false;
			figure.kemata.recipients_before = true;
			$(`#recipientsEmail`).addClass('disabled');
		});
		$(`#textEmail`).on('change', () => {
			figure.kemata.email_text = $(`#textEmail`).val();
		});
		$('#checkContract').on('change', () => {
			figure.kemata.executors_by_contract = true;
			$('.test').hide();
		});
		$('#selectExecutors').on('click', () => {
			figure.kemata.executors_by_contract = false;
			$('.test').show();
			$('#checkExecutors').prop('checked', !figure.kemata.executors_by_contract);
			_self.setExecutors();
		});
		$('#checkExecutors').on('change', () => {
			figure.kemata.executors_by_contract = false;
			$('.test').show();
		})
		$('#generateOnStart').on("change", () => {
			if(figure.kemata.generate_on_start){
				figure.kemata.generate_on_start = false;
			} else {
				figure.kemata.generate_on_start = true; 
			}
		});
		$('#contractActivities').on("change", () => {
			if(figure.kemata.contract_activities_number){
				figure.kemata.contract_activities_number = false;
			} else {
				figure.kemata.contract_activities_number = true;
			}
		});
		
	}
	
	public setPartecipants(){
		console.log("Stampa test");
		this.parentScope.setPartecipants(this.selectedFigure.kemata.participants, (respo) => {
			if(respo && respo != 'cancel' && respo != 'Cancel'){
				this.selectedFigure.kemata.participants = respo;
			}
		});
	}

	public setRecipients(){
		console.log("Inserisco destinatari");
		this.parentScope.setPartecipants(this.selectedFigure.kemata.recipients, (respo) => {
			if(respo && respo != 'cancel' && respo != 'Cancel'){
				this.selectedFigure.kemata.recipients = respo;
			}
		})	
	}

	public setExecutors(){
		console.log("Inserisco Esecutori");
		this.parentScope.setPartecipants(this.selectedFigure.kemata.executors, (respo) => {
			if(respo && respo != 'cancel' && respo != 'Cancel'){
				this.selectedFigure.kemata.executors = respo;
			}
		})
	}
	
	checkMaxTime(maxTime: string): string{
		// maxTime Format: GG HH:MM
		let split1 = maxTime.split(" ");
		let split2, days, hours, minuts;
		if(split1.length == 2){
			split2 = split1[1].split(":");
			if(split2.length == 2){
				days = parseInt(split1[0]);
				hours = parseInt(split2[0]);
				minuts = parseInt(split2[1]);
				if(isNaN(days) || isNaN(hours) || isNaN(minuts)){
					return '0 0:0';
				}
				if(minuts >= 60){
					for(let i = 0; minuts >= 60; i+=60){
						minuts -= 60;
						hours++;
					}
				}
				if(hours >= 24){
					for(let i = 0; hours >= 24; i+=24){
						hours -= 24;
						days++;
					}
				}
				return `${days} ${hours}:${minuts}`;
			}
		}
	}
}