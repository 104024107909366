import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { startWith, map } from 'rxjs/operators';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

import { GlobalService } from '../../services/global.service';
import { ContractService } from 'src/app/services/contract.service';
import { AssocComponent } from 'src/app/popup/assoc/assoc.component';
import { CustomerService } from 'src/app/services/customer.service';
import { EliminaService } from 'src/app/services/elimina.service';
import { User } from 'src/app/classes';
import { GroupService } from 'src/app/services/group.service';


declare const $: any;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MM YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ContractComponent implements OnInit {

  myControl = new FormControl();
  myControlFul = new FormControl();
  filteredCustomer: Observable<any[]>;
  filteredFulfillment: Observable<any[]>;

  envi = environment;
  //string
  _listFilter = '';

  //any
  activeUser: any;
  toAssoc: any = {}
  selected: any;
  fillment: any;
  days_change: any;

  //temp
  tempUser: any;
  type: any;

  //array
  selectedContract: any = { customer: {} };
  fulfillments: Array<any> = [];
  filteredContract: Array<any> = [];
  customers: Array<any> = [];
  test: Array<any>;
  // participants: Array<any> = [];
  dayss: Array<any> = [
    {
      type: 'Prima'
    },
    {
      type: 'Dopo'
    },
  ]

  type_day: string = 'Prima'

  //boolean
  choose: boolean = false;
  newCont = false;
  del = false;
  nome: boolean;
  dataI: boolean;
  dataF: boolean;
  associa: boolean;
  isOnlyRead: boolean = false;
  groups: boolean = false;

  table: any;
  token: any;
  contracts: any;
  constructor(
    private modalService: NgbModal,
    private globals: GlobalService,
    private contractServices: ContractService,
    private customerService: CustomerService,
    private Delete: EliminaService,
    private spinner: NgxSpinnerService
  ) {
    this.filteredContract = this.contracts;
  }

  ngOnInit() {
    this.spinner.show();
    this.activeUser = this.globals.storagedUser;
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    this.getCustomers();
    this.getContracts();
  }

  get listFilter(): string {
    return this._listFilter;
  }

  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredContract = this.listFilter ? this.doFilter(this.listFilter) : this.contracts;

  }

  doFilter(filterBy: string) {
    filterBy = filterBy.toLocaleLowerCase();
    return this.contracts.filter((contract) =>
      contract.descriptions.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  reload() {
    $('#datatablesContract').DataTable().destroy();
    this.isOnlyRead = false;
    this.svuotaSpan();
    this.globals.checkPage(6);
    this.selectedContract = { fulfillments: [], customer: {} };
    this.del = false;
    this.newCont = false
    this.tempUser = null;
    this.choose = false;
    this.selected = null;
    this.groups = false;
    this.getContracts();
  }

  private _filterFul(name: string, array: Array<any>): any[] {
    const filterValue = name.toLowerCase();
    this.globals.stampa("Name: ", name);

    return array.filter(
      array => array.search.toLowerCase().indexOf(filterValue) >= 0
    );

  }

  displayFul(ful: any): string {
    return ful && `${ful.name}`;
  }

  fulfillmentSearch() {
    let _self = this;
    this.contractServices.getFulfillment(this.token).subscribe(
      res => {
        _self.globals.messageServer(res);
        _self.fulfillments = res.values.fulfillments;
        _self.globals.stampa("fulfillment", _self.fulfillments)
        _self.fulfillments.forEach((ful) => {
          ful.search = _self.globals.createLocalSearch({ name: ful.name });
        });
        _self.filteredFulfillment = _self.myControlFul.valueChanges.pipe(
          startWith(''),
          map((value) => {
            _self.globals.stampa("Value: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            _self.globals.stampa("Search: ", search);
            return search ? _self._filterFul(search, _self.fulfillments) : _self.fulfillments.slice()
          })
        );
      },
      err => {
        console.error("Errore nell'ottenere i customers", err);
        this.globals.messageServer(err);
      }
    )

  }

  getContracts() {
    let _self = this;
    this.contractServices.getContracts(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.globals.stampa("Risposta getContracts: ", res)
        this.contracts = res.values.contracts ? res.values.contracts : [];
        this.contracts.forEach((cont) => {
          cont.fulfillments.forEach(ful => {
            const temp1 = new Date(ful.start_exec);
            ful.next_deadline = moment(ful.next_deadline).format('DD-MM-YYYY');
            ful.start_exec = moment(ful.start_exec).format('DD-MM-YYYY');
            temp1.setDate(temp1.getDate() + ful.days_changed);
            ful.exec = moment(temp1).format('DD-MM-YYYY');
          });
          cont.descriptions = `${cont.contract_id} ${cont.contract_name} ${cont.contract_name} ${cont.contract_number}`;
          cont.startDate = new Date(moment(cont.start_date).format('L'));
          cont.endDate = new Date(moment(cont.end_date).format('L'));
          cont.correctStartDate = moment(cont.start_date).format('DD-MM-YYYY');
          cont.correctEndDate = moment(cont.end_date).format('DD-MM-YYYY');
        });
        this.fulfillmentSearch();
        this.globals.stampa(this.contracts);
        this.listFilter = '';
        let contractss = [];
        let tableObject = {
          data: []
        }
        this.contracts.forEach((cont) => {
          let obj = {
            contract_id: cont.contract_id,
            contract_name: cont.contract_name,
            start_date: cont.correctStartDate,
            end_date: cont.correctEndDate,
            view_name: cont.customer.view_name,
          }
          contractss.push(obj);
        });

        tableObject.data = contractss;
        let config = {
          pagingType: "full_numbers",
          lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "All"]
          ],
          responsive: {
            details: {
              type: 'column',
              target: 'tr'
            }
          },
          language: {
            search: "_INPUT_",
            searchPlaceholder: "Ricerca contratti",
            url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
          },
          ajax: (data, callback, settings) => {
            callback(tableObject)
          },
          columns: [
            { title:'ID', data: 'contract_id' },
            { title:'Contratto', data: 'contract_name' },
            { title:'Data inizio', data: 'start_date' },
            { title:'Data fine', data: 'end_date' },
            { title:'Cliente', data: 'view_name' },
            { title:'Azione', data: null, className: 'td-actions text-right' }
          ],
          columnDefs: [
            {
              targets: [0],
              visible: false
            },
            {
              targets: [5],
              visible: true,
              render: function (data, type, full, meta) {
                full = JSON.stringify(full);
                return `<a data-cont='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
                        <i class="material-icons">search</i>
                      </a>
                      <a data-cont='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
                        <i class="material-icons">edit</i>
                      </a>
                      <a data-cont='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
                        <i class="material-icons">close</i>
                      </a>`
              }
            }
          ]
        }
        $(document).ready(function () {
          this.table = $('#datatablesContract').DataTable(config);
          $('#datatablesContract').on('click', 'a.btn-info', function () {
            let contr: any = $(this).data('cont')
            _self.contracts.forEach((cont) => {
              if (cont.contract_id == contr.contract_id) {
                _self.showForm(cont, true);
              }
            })
          });
          $('#datatablesContract').on('click', 'a.btn-success', function () {
            let contr = $(this).data('cont')
            _self.contracts.forEach((cont) => {
              if (cont.contract_id == contr.contract_id) {
                _self.showForm(cont);
              }
            })
          });
          $('#datatablesContract').on('click', 'a.btn-danger', function () {
            let contr = $(this).data('cont')
            _self.contracts.forEach((cont) => {
              if (cont.contract_id == contr.contract_id) {
                _self.deleteContract(cont);
              }
            })
          });
          _self.spinner.hide();
        })

      },
      err => {
        this.spinner.hide();
        console.error("Error on get contract: ", err);
        this.globals.messageServer(err);
      }
    )
  }

  showForm(contract, onlyRead?) {
    this.isOnlyRead = false;
    if (onlyRead) {
      this.isOnlyRead = true;
    }
    this.svuotaSpan();
    this.newCont = false;
    this.del = true;
    this.choose = true;
    this.selectedContract = contract;
    this.days_change = 0;
  }

  newContract() {
    this.svuotaSpan();
    this.selected = null;
    this.isOnlyRead = false;
    this.del = false;
    this.choose = true;
    this.selected = '';
    this.newCont = true;
    this.selectedContract = { fulfillments: [], customer: {} };
    this.days_change = 0;
  }

  addContract() {
    this.globals.stampa("Contratto passato: ", this.selectedContract);
    let DateCheck: boolean = false;
    if (this.selectedContract.startDate) {
      document.getElementById("Data_inizio").innerHTML = "";
      let IDate = new Date(moment(this.selectedContract.startDate).format('L'));
      let FDate = new Date(moment(this.selectedContract.endDate).format('L'));


      if (FDate.getTime() > IDate.getTime()) {
        this.globals.stampa("FDate: ", FDate.getTime(), "IDate: ", IDate.getTime())
        document.getElementById("Data_inizio").innerHTML = "";
        DateCheck = true;
      } else {
        this.globals.stampa("FDate: ", FDate.getTime(), "IDate: ", IDate.getTime())
        document.getElementById("Data_inizio").innerHTML = "↓ La data inizio è superiore a quella di fine";
        DateCheck = false;
      }

    } else {
      document.getElementById("Data_inizio").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (this.selectedContract.contract_name) {
      document.getElementById("name").innerHTML = "";
    } else {
      document.getElementById("name").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (this.selectedContract.customer.customer_id) {
      document.getElementById("associat").innerHTML = "";
    } else {
      document.getElementById("associat").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (this.selectedContract.endDate) {
      document.getElementById("Data_fine").innerHTML = "";
    } else {
      document.getElementById("Data_fine").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (!this.selectedContract.description) {
      this.selectedContract.description = '';
    }

    // let falsetrue = false;


    this.globals.stampa("OBJ DA MANDARE -> ", this.selectedContract)

    if (this.selectedContract.contract_name && this.selectedContract.startDate && this.selectedContract.endDate && (this.selectedContract.customer.customer_id  && this.selectedContract.contract_name.length <= 80) && this.selectedContract.description.length <= 300) {
      if (DateCheck) {
        this.spinner.show();

        this.selectedContract.start_date = moment(this.selectedContract.startDate).format('L');
        this.selectedContract.end_date = moment(this.selectedContract.endDate).format('L');
        let SD = this.selectedContract.start_date;
        let ED = this.selectedContract.end_date;
        let contract = this.selectedContract;
        delete contract.startDate;
        delete contract.endDate;

        this.contractServices.newContract(this.token, { contract: contract }).subscribe(
          res => {
            this.selectedContract.start_date = SD;
            this.selectedContract.end_date = ED;
            this.globals.stampa(this.selectedContract)
            this.globals.messageServer(res);
            this.globals.showNotification('Dati salvati', 'success');
            this.reload();
            this.selectedContract = { fulfillments: [], customer: {} };
          },
          err => {
            this.selectedContract.start_date = SD;
            this.selectedContract.end_date = ED;
            this.spinner.hide();
            console.error(err);
            this.globals.messageServer(err);
          }
        )
      }
    } else {
      if (!this.selectedContract.contract_name || !this.selectedContract.customer.customer_id || !this.selectedContract.startDate || !this.selectedContract.startDate) {
        this.globals.showNotification('Dati mancanti', 'warning');
      } else {
        if (this.selectedContract.contract_name.length > 80) {
          this.globals.showNotification('Il titolo supera i 80 caratteri', 'warning');
        } else {
          if (this.selectedContract.description.length > 300) {
            this.globals.showNotification('La descrizione supera i 300 caratteri', 'warning');
          }
        }
      }
    }
  }

  svuotaSpan() {
    if (document.getElementById("name_contract")) {
      document.getElementById("name_contract").innerHTML = "";
    }
    if (document.getElementById("DI")) {
      document.getElementById("DI").innerHTML = "";
    }
    if (document.getElementById("DF")) {
      document.getElementById("DF").innerHTML = "";
    }
  }

  deleteContract(contract) {

    swal({
      title: `Cancellare il contratto?`,
      // text: 'You will not be able to recover this imaginary file!',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annulla',
      confirmButtonText: 'Conferma',
      cancelButtonClass: "btn btn-danger",
      confirmButtonClass: "btn btn-success",
      buttonsStyling: false
    }).then((result) => {
      this.globals.stampa('entrato: ', result)
      if (result.value) {
        this.Delete.deleteContract(this.token, contract.contract_id).subscribe(
          res => {
            this.globals.messageServer(res);
            this.tempUser = null;
            this.reload();
            this.globals.showNotification("Contratto eliminato", "success")
          },
          err => {
            console.error("Errore in delContract ", err);
            this.globals.messageServer(err);
          }
        )
      } else {
      }
    });

  }

  updateContract(contra) {

    let DateCheck: boolean = false;
    if (contra.startDate) {
      document.getElementById("Data_inizio").innerHTML = "";
      let IDate = new Date(moment(contra.startDate).format('L'));
      let FDate = new Date(moment(contra.endDate).format('L'));


      if (FDate.getTime() > IDate.getTime()) {
        this.globals.stampa("FDate: ", FDate.getTime(), "IDate: ", IDate.getTime())
        document.getElementById("Data_inizio").innerHTML = "";
        DateCheck = true;
      } else {
        this.globals.stampa("FDate: ", FDate.getTime(), "IDate: ", IDate.getTime())
        document.getElementById("Data_inizio").innerHTML = "↓ La data inizio è superiore a quella di fine";
        DateCheck = false;
      }

    } else {
      document.getElementById("Data_inizio").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (contra.contract_name) {
      document.getElementById("name").innerHTML = "";
    } else {
      document.getElementById("name").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (contra.customer.customer_id) {
      document.getElementById("associat").innerHTML = "";
    } else {
      document.getElementById("associat").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (contra.endDate) {
      document.getElementById("Data_fine").innerHTML = "";
    } else {
      document.getElementById("Data_fine").innerHTML = "↓ Il campo è obbligatorio";
    }

    if (!contra.description) {
      contra.description = '';
    }

    this.globals.stampa(this.selectedContract);
    this.globals.stampa(contra);

    if ((contra.contract_name && contra.contract_name.length <= 80) && contra.startDate && contra.endDate && contra.description.length <= 300) {
      if (DateCheck) {

        this.selectedContract.start_date = moment(this.selectedContract.startDate).format('L');
        this.selectedContract.end_date = moment(this.selectedContract.endDate).format('L');
        let contract = this.selectedContract;
        delete contract.correctEndDate
        delete contract.correctStartDate
        delete contract.endDate
        delete contract.startDate
        delete contract.descriptions
        

        this.contractServices.updateContract(this.token, { contract: contract }, this.selectedContract.contract_id).subscribe(
          res => {
            this.globals.messageServer(res);
            this.reload();
            this.globals.showNotification('Dati salvati', 'success');
            this.globals.stampa("Risposta in updateContract: ", res);
          },
          err => {
            console.error("Errore in updateContract: ", err);
            this.globals.messageServer(err);
          }
        )
      }
    } else {
      if (!contra.contract_name || !this.selectedContract.customer.customer_id || !this.selectedContract.startDate || !this.selectedContract.startDate) {
        this.globals.showNotification('Dati mancanti', 'warning');
      } else {
        if (contra.contract_name.length > 80) {
          this.globals.showNotification('Il titolo supera i 80 caratteri', 'warning');
        } else {
          if (contra.description.length > 300) {
            this.globals.showNotification('La descrizione supera i 300 caratteri', 'warning');
          }
        }
      }
    }
  }

  assoc(id) {
    const modalRef = this.modalService.open(AssocComponent, { backdrop: 'static', centered: true })
    // modalRef.componentInstance.token = this.token;
    modalRef.componentInstance.id = id;

    modalRef.result.then(result => {

      if (result != 'cancel') {
        this.reload();
      }
    }, reason => {
    });
  }

  push(us) {
    if (us.customer_id) {
      this.selectedContract.customer.customer_id = us.customer_id;
      this.globals.stampa("selectedContract.customer: ", this.selectedContract.customer.customer_id)
    }
    document.getElementById("addMember").blur();
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    this.globals.stampa("Name: ", name);

    return this.customers.filter(
      user => user.search.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  displayFn(user: User): string {
    return user && `${user.view_name}`;
  }

  getCustomers() {
    let _self = this;
    this.customerService.getCustomer(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.customers = res;
        this.customers.forEach(cus => {
          if (cus.type == 'GIU') {
            cus.search = this.globals.createLocalSearch({ name: cus.view_name, business_name: cus.business_name })
          } else {
            cus.search = this.globals.createLocalSearch({ view_name: cus.view_name, name: cus.name, last_name: cus.last_name });
          }
        });
        _self.filteredCustomer = _self.myControl.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.globals.stampa("Value: ", value);
            return typeof value === "string" ? value : value.search
          }),
          map((search) => {
            this.globals.stampa("Search: ", search);
            return search ? _self._filter(search) : _self.customers.slice()
          })
        );
      },
      err => {
        console.error("Errore nell'ottenere i customers", err);
        this.globals.messageServer(err);
      }
    )
  }

  check() {
    if (this.days_change < 0 || this.days_change == null || this.days_change == undefined) {
      this.days_change = 0
    }
  }

  pushFul(fillment) {
    this.fillment = fillment;
  }

  pushFulfill(day, type) {
    this.globals.stampa("day: ", day, "type:", type, "fullfilment: ", this.fillment)
    if (this.fillment.fulfillment_id) {
      if (day != 0) {
        if (type == 'Prima') {
          day = day * (-1);
        }
      }
      let date = moment(this.fillment.deadline.next_deadline).date(moment(this.fillment.deadline.next_deadline).date()+day)
      let obj = {
        days_changed: day,
        next_deadline: date.format('DD-MM-YYYY'),
        groups: this.groups,
        fulfillment: {
          fulfillment_id: this.fillment.fulfillment_id,
          name: this.fillment.name
        }
      }
      let check = true;
      this.selectedContract.fulfillments.forEach(fillment => {
        if (fillment.fulfillment.fulfillment_id == obj.fulfillment.fulfillment_id) {
          check = false;
        }
      });
      if (check) {
        this.selectedContract.fulfillments.push(obj);
      } else {
        this.globals.showNotification("Adempimento già in lista", "warning")
      }
      this.fillment = '';
      this.groups = false;
      this.type_day = 'Prima';
      this.days_change = 0;
    } else {
      this.globals.showNotification("Seleziona l'adempimento", "warning")
    }

  }

  deleteFulfillment(fulfill) {
    this.selectedContract.fulfillments.forEach((fill, k) => {
      if (fill.fulfillment.fulfillment_id == fulfill.fulfillment.fulfillment_id) {
        this.selectedContract.fulfillments.splice(this.selectedContract.fulfillments.indexOf(fulfill), 1);
        this.globals.stampa("Selected -> ", this.selectedContract.fulfillments)
      }
    });
  }

}
