import { Component, OnInit } from '@angular/core';
import { Header } from '../../classes';
import { GlobalService } from '../../services/global.service';
import { LoginService} from '../../services/login.service';
import { faBars, faEllipsisH } from '@fortawesome/free-solid-svg-icons';;
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  header: Array<Header>;
  word: boolean = true;
  token: string = '';
  faBars = faBars;
  faEllipsisH = faEllipsisH;
  constructor(
    private globals: GlobalService,
    private loginService: LoginService
  ) {
    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
      });
    });
  }

  ngOnInit() {
    this.header = this.globals.header;
    this.token = this.globals.getToken();
  }

  changeWord() {
    if (this.word == false) {
      this.word = true;
    } else {
      this.word = false;
    }
  }


  active(id) {
    $(document).ready(() => {
      let element = document.getElementById(`${id}class`);
      if (element) {
        element.className = "nav-link active";
      }
    });
  }

  logOut(){
    this.loginService.doLogout(this.token).subscribe(
      res => {
        this.token = null;
        this.globals.messageServer(res);
        this.globals.removeCache();
        this.globals.goToLogin();
        this.globals.stopInterval();
      },
      err => {
        console.error("Error on do log out"), err;
        this.globals.messageServer(err);
      }
    )
  }
}
