import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';
import { tokenName } from '@angular/compiler';

export interface DialogData {
  activeUser?: any;
  canClose?: boolean;
}

@Component({
  selector: 'app-changepwd',
  templateUrl: './changepwd.component.html',
  styleUrls: ['./changepwd.component.scss']
})
export class ChangepwdComponent implements OnInit {

  activeUser: any;
  disable: boolean = true;

  //Sting
  token: string

  constructor(
    public dialogRef: MatDialogRef<ChangepwdComponent>,
    public usersService: UserService,
    public spinner: NgxSpinnerService,
    public globals: GlobalService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    if (!this.token) {
      this.token = this.globals.getToken();
    }
    if (this.data.activeUser) {
      this.activeUser = this.data.activeUser
    }

      this.disable = this.data.canClose
      this.spinner.hide()
    }

  save(old, newP, checkP) {
    if (old && newP && checkP) {
      if (newP == checkP) {
        this.spinner.show();
        let infoPwd = {
          old: old,
          new1: newP,
          new2: checkP
        }
        this.usersService.updatePassword(this.token, { infoPwd: infoPwd }).subscribe(
          res => {
            this.dialogRef.close();
            this.globals.messageServer(res)
            this.spinner.hide();
          },
          err => {
            console.error("Errore in changePassword: ", err);
            this.globals.messageServer(err)
            this.spinner.hide();
          }
        )
      } else {
        this.globals.showNotification("Le nuove password non corrispondono", "warning")
      }
    } else {
      this.globals.showNotification("Inserisci tutti i campi", "warning")
    }
  }

  canceling() {
    this.dialogRef.close('cancel');
  }

}
