import { Injectable } from '@angular/core';

import { GlobalService } from './global.service';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private http: HttpClient,
    private globals: GlobalService
    ) { }

  getCustomer(token){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getCustomer, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getACustomer(token, id){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getGetCustomer(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getCustomerProfile(token){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getCustomerProfile, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  addCustomer(token, obj){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.addCustomer, obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateCustomer(token, id, obj){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.updateCustomer(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteCustomer(token, id){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteCustomer(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateFolderName(token, id, name){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put<any>(this.globals.updateFolderName(id), name, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getDisponibility(token){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.customerDisponibilityURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  getTypes(token){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getAddressTypesURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  addAddress(token, obj){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.addAddressURL, obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  updateAddress(token, obj, id){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post<any>(this.globals.updateAddress(id), obj, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  deleteAddress(token, id){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.delete<any>(this.globals.deleteAddress(id), httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

  nextId(token){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<any>(this.globals.getNextIdCusURL, httpOptions).pipe(
      catchError(this.globals.handleError)
    )
  }

}
