import { Component, OnInit } from '@angular/core';
import { ActivityService } from 'src/app/services/activity.service';
import { GlobalService } from 'src/app/services/global.service';
import { ProcessTemplate, Shape, Node, Process } from 'src/app/classes';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material';
import { UpdatemembersComponent } from 'src/app/popup/updatemembers/updatemembers.component';
import { Application } from './js/application'; 
import { SeveprocessComponent } from '../../popup/seveprocess/seveprocess.component';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';


declare const $: any;


@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})

export class ActivitiesComponent implements OnInit {
  protected drawer: boolean = false;
  protected newProcess: boolean = false;
  protected token: string;
  protected templates: Array<ProcessTemplate>;
  protected shapes: Array<Shape>;

  envi = environment;
  process: Process;
  myProcess: Array<Process>;
  nodeS: Node;
  nodeSE: Node;
  nodeP: Node;
  nodeSemail: Node;
  canvas: any;
  app: any;
  toolbar: any;
  properties: any;
  table: boolean = true;
  constructor(
    private globals: GlobalService,
    private activityService: ActivityService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) {
    this.process = new Process();
  }
  instances = [];

  ngOnInit() {
    this.spinner.show();
    
    if(!this.token){
      this.token = this.globals.getToken();
    }
    this.getTemplates();
    this.getInstances();
  }

  getInstances(){
    this.activityService.getInstances(this.token).subscribe(
      (res) => {
        this.globals.messageServer(res);
        this.instances = res.values;
        this.createTable();
      }  ,
      (err) => {
        this.globals.messageServer(err);
        console.error("Error on get Instances: ", err);
      }
    )
  }

  getTemplates(){
    this.activityService.getTemplate(this.token).subscribe(
      res => {
        this.globals.messageServer(res);
        this.templates = res.templates;
        this.shapes = res.shapes;
        this.myProcess = res.myProcess;
        this.shapes.forEach((shape) => {
          shape.shape_proprieties = JSON.stringify(shape.shape_proprieties);
        })
        this.globals.stampa("Templates: ", this.templates);
        this.globals.stampa("Shapes: ", this.shapes);
        this.globals.stampa("MyProcess: ", this.myProcess);
        let _self = this;
        // this.spinner.hide();
      },
      err => {
        console.error("Error on get templetes: ", err);
        this.spinner.hide();
      }
    )
  }

  stampaJson(){
    console.log("nodeS: ", this.nodeS);
    console.log("nodeP: ", this.nodeP);
    let writer = new draw2d.io.json.Writer();
    writer.marshal(this.app.view, function(json){
      console.log("Json: ", json);
      console.log("Json to String: ", JSON.stringify(json))
    });
    // let writer2 = new draw2d.io.Writer();
    writer.marshal(this.app.view, (result) => {
      console.log("Result: ", result);
    })
  }

  getJson(){
    return new Promise((resolve, reject) => {
      let writer = new draw2d.io.json.Writer();
      writer.marshal(this.app.view, (json) => {
       resolve(json);
      })
    })
  }

  renderJson(json){
    let jsonDocument = json;
    console.log("json doc: ", jsonDocument);
    let reader = new draw2d.io.json.Reader();
    reader.unmarshal(this.app.view, jsonDocument);
    let figures = this.app.view.getFigures();
    console.log("Figures: ", figures);
    figures.data.forEach((shape) => {
      let userD = shape.getUserData();
      let label = new draw2d.shape.basic.Label(
        {
          text: userD.task_name ? userD.task_name : userD.name ,
          stroke:0,
          resizeable: false
        }
      );
      if(userD.task_name){
        label.installEditor(new draw2d.ui.LabelInplaceEditor()); 
      }
      shape.add(label, new draw2d.layout.locator.CenterLocator(shape));
      shape.repaint();
      if(!shape.kemata){
        shape.kemata = Object.assign({}, shape.getUserData());
      }
      // shape = this.addPorts(shape)
    })    
  }

  openDrawer(json, process, template){
    let _self = this;
    this.spinner.show();
    this.drawer = true
    setTimeout(() => {
      this.app = new Application('canvas', "drawer-toolbar", "properties", _self);
      console.log("Canvas: ", this.app.view);        
      this.app.view.installEditPolicy(new draw2d.policy.connection.DragConnectionCreatePolicy({
        createConnection: function(event){
            return new HoverConnection();
        }
      }));
      this.app.view.installEditPolicy(new draw2d.policy.canvas.ShowGridEditPolicy());
    }, 500);

    $('canvas').on('select', (event) => {
      console.log("Click on canvas", event);
      
    })
    if (json){
      if(template) {
        this.newProcess = true;
      }
      else {
        this.newProcess = false;

      }
      this.process = process;
      setTimeout(() => {
        // json = JSON.stringify(json);
        this.globals.stampa("Json: ", json);
        this.renderJson(json);
      }, 500)
    } else {
      this.newProcess = true;
      setTimeout(() => {
      }, 500);
    }
    this.spinner.hide();
  }

  reload(){
    this.drawer = false;
    this.newProcess = false;
    this.table = true;
    this.process = new Process();
    if ($.fn.DataTable.isDataTable('#activities')) {
      $('#datatablesProcesses').DataTable().destroy();
      this.createTable();
    } else {
      this.createTable(true);
    }

    this.getTemplates();
  }

  getOutputLocator(figure, position: string){
    // tl In alto a sinistra
    // bl In basso a sinistra
    // tr In alto a destra
    // br In basso a destra
    switch(position){
      case 'br': {
        return draw2d.layout.locator.PortLocator.extend({
          init:function( ){
            this._super();
          },
          relocate:function(index, figure){
            var p = figure.getParent();
            this.applyConsiderRotation(figure, p.getWidth(), p.getHeight());
          }
        });
      }
    }
  }
  // popUp = UpdatemembersComponent;
  setPartecipants(participants, cb){
    console.log("Funzione di test dentro scope principale!");
    this.spinner.show();
    const dialogRef = this.dialog.open(UpdatemembersComponent,
      {
        width: '60%',
        autoFocus: true,
        data: {
          participants: participants
        }
      });
    dialogRef.afterClosed().subscribe((result) => {
      this.globals.stampa('The dialog was closed', result);
      if(result){
        cb(result);
      }else{
        cb();
      }
    });
    dialogRef.afterOpened().subscribe(() => {
      console.log("Aperto: ");
    })
  }

  save(){
    let figures = this.app.view.getFigures();
    console.log("Figure: ", figures);
    figures.data.forEach((figure) => {
      figure.userData = figure.kemata;
    })
    this.getJson().then((r) =>{
      this.process.json = JSON.stringify(r);
      console.log("process: ", this.process);
      const dialogRef = this.dialog.open(SeveprocessComponent, {
        width: '70%',
        // height: '80%',
        data: {process: this.process, new: this.newProcess}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cancel'){
          this.globals.stampa("Ritornato dal popup: ", result);
          this.reload();
        }
      });
    })
  }

  deleteProcess(proc){
    this.spinner.show();
    this.activityService.deleteProcess(this.token, proc.process_id).subscribe((res) => {
      this.globals.stampa("Processo eliminato");
      this.globals.messageServer(res);
      this.spinner.hide();
      this.myProcess = res.values;
    },
    (err) => {
      console.error("Errore nell'eliminare il processo");
      this.globals.messageServer(err);
      this.spinner.hide();
    })
  }

  newPeocess(){
    this.drawer = false;
    this.newProcess = true;
    this.table = false;
  }

  addPorts(figure){
    if(figure.kemata.task_type != 'system'){
        let ports = figure.getOutputPorts();
        figure.removePort(ports.data[0]);
        if(figure.kemata.port_single){
            let port = new draw2d.OutputPort({ color: 'blue', bgColor: 'blue'});
            figure.addPort(port, new draw2d.layout.locator.OutputPortLocator());
            port.setName('OutputSingle');
        }
        if(figure.kemata.port_multiple){
            let port = new draw2d.OutputPort({ color: 'orange', bgColor: 'orange'});
            figure.addPort(port, new draw2d.layout.locator.OutputPortLocator());
            port.setName('OutputMultiple');
        }
        if(figure.kemata.port_error){
            let port = new draw2d.OutputPort({ color: 'red', bgColor: 'red'});
            figure.addPort(port, new draw2d.layout.locator.OutputPortLocator());
            port.setName('OutputError');
        }
    }
    return figure
  }

  createTable(withFunctions?){
    this.spinner.show();
    let _self = this;
    let tableObject = { data:[] };
    let tableInstance = [];
    this.instances.forEach((instance) => {
      let tempObj = {
        instance_id: instance.instance_id,
        process_name: instance.process_name,
        state: instance.state,
        start_date: moment(instance.start_date).format('DD-MM-YYYY'),
        fulfillment_name: instance.fulfillment_name
      }
      // console.log("tempoObj: ", tempObj)
      tableInstance.push(tempObj);
    })
    tableObject.data = tableInstance;
    let config = {
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: {
        details: {
          type: 'column',
          target: 'tr'
        }
      },
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Processi",
        url: `${_self.envi.assetsBaseUrl}/assets/i18n/italianDataTables.json`
      },
      ajax: (data, callback, settings) => {
        callback(tableObject)
      },
      columns: [
        { title:'ID', data: 'instance_id' },
        { title:'Nome processo', data: 'process_name' },
        { title:'Stato', data: 'state' },
        { title:'Data inizio', data: 'start_date'},
        { title:'Nome Adempimento', data: 'fulfillment_name' },
        { title:'Azione', data: null, className: 'td-actions text-right' }
      ],
      columnDefs: [
        {
          targets: [5],
          visible: true,
          render: function (data, type, full, meta) {
            full = JSON.stringify(full);
            // return `<a data-op='${full}' matTooltip="Visualizza" mat-raised-button type="button" class="btn btn-info">
            //     <i class="material-icons">search</i>
            //   </a>
            //   <a data-op='${full}' matTooltip="Modifica" mat-raised-button type="button" class="btn btn-success action-button">
            //     <i class="material-icons">edit</i>
            //   </a>
            //   <a data-op='${full}' matTooltip="Cancella"  mat-raised-button type="button" class="btn btn-danger action-button">
            //     <i class="material-icons">close</i>
            //   </a>`
            return `Nessuna azione`
          }
        }
      ]
    }
    $(document).ready(function () {
      this.table = $('#datatablesProcesses').DataTable(config);
      if (withFunctions) {
        $('#datatablesProcesses').on('click', 'a.btn-info', function () {
          let OP: any = $(this).data('op')
          _self.globals.stampa("Op: ", OP);
          // _self.operators.forEach((op) => {
          //   if (op.user_id == OP.user_id) {
          //     // _self.showFormOp(op, true);
          //   }
          // })
        });
        $('#datatablesProcesses').on('click', 'a.btn-success', function () {
          // _self.globals.stampa($(this).data('cus'));
          let OP = $(this).data('op')
          _self.globals.stampa("Op: ", OP);
          // _self.operators.forEach((op) => {
          //   if (op.user_id == OP.user_id) {
          //     // _self.showFormOp(op);
          //   }
          // })
        });
        $('#datatablesProcesses').on('click', 'a.btn-danger', function () {
          // _self.globals.stampa($(this).data('cus'));
          let OP = $(this).data('op')
          _self.globals.stampa("Op: ", OP);
          // _self.operators.forEach((op) => {
          //   if (op.user_id == OP.user_id) {
          //     // _self.delProfile(op);
          //   }
          // })
        });
      }
      _self.spinner.hide();
    });
  }
}
